@import '../../../../styles/vars';

.scp-schedule-wizard {
  @media #{$la_lg} {
    max-width: 814px;
    width: 814px;
  }

  .required:before {
    content: '*';
    color: $required-red-color;
    font-size: 14px;
    margin-left: -5px;
  }

  .label-section {
    width: 100%;
    font: normal normal 14px/16.8px $rionasans-medium;
    letter-spacing: normal;
    display: flex;
    align-items: flex-start;
    color: $light-gray-text-color-v2;
    margin-bottom: 4px;
    &.disabled {
      opacity: .50;
      cursor: not-allowed;
    }
  }

  -webkit-font-smoothing: antialiased;
  padding: 0 15px !important;
  margin: 32px 0 32px 0 !important;

  @media #{$la_lg} {
    padding: 40px 0px !important;
    margin: 0 auto !important;
  }

  .gm-select-service__header {
    font-family: $rionasans-bold;
    margin-bottom: 32px !important;
    font-size: 23px !important;
    line-height: 27.6px !important;
    color: $text-color-V2;

  }

  .insurance-form {
    max-width: 860px;
    margin: 0 auto;

    .action-buttons {
      display: flex;
      justify-content: flex-end;

      @media #{$mobile} {
        flex-direction: column-reverse;
      }

      >button {
        min-width: 200px;
        height: 40px;
        margin-bottom: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        border: 1px solid $light-indigo-V2;
        color: $light-indigo-V2;
        font-family: $rionasans-bold;
        font-size: 16px;
        background-color: $white-bg-color-v2;
        cursor: pointer;

        @media #{$mobile} {
          min-width: unset;
          width: 100%;
          margin-bottom: 16px;
        }

        &:disabled {
          opacity: 0.35;
        }

        &.btn-back {
          margin-right: 56px;

          &:disabled {
            cursor: not-allowed;
          }

          &:hover:not(:disabled) {
            background-color: rgba(123, 38, 201, 0.15);
          }

          &:active {
            background-color: rgba(123, 38, 201, 0.3) !important;
          }
        }

        &.btn-next {
          background-color: $light-indigo-V2;
          color: $white-bg-color-v2;

          &:disabled {
            cursor: not-allowed;
          }

          &:hover:not(:disabled) {
            background-color: $scp-button-hover-color;
          }

          &:active {
            background-color: $purple-600 !important;
          }
        }
      }
    }

    .scp-insurance-fields {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap-reverse;

      @media #{$la_md} {
        flex-wrap: unset;
        border-bottom: 1px solid $scp-border-color;
        margin-bottom: 32px;
        padding-bottom: 32px;
      }

      .insurance-group {
        width: 100%;
        display: flex;
        flex-flow: wrap;
      }

      .scp-insurance-sections {
        width: 470px;
        margin-right: 16px;
        min-width: 100%;
        display: flex;
        flex-direction: column;

        @media #{$la_md} {
          min-width: 503px;
          margin-right: 24px;
          padding-right: 24px;
          border-right: 2px solid #edeff3;
        }

        @media #{$mobile} {
          padding-top: 32px;
        }

        .scp-insurance-section {
          margin-bottom: 0px;

          &.insurance-name-section {
            @media #{$mobile} {
              padding-bottom: 16px;
            }
          }

          .form-row {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 16px;

            @media #{$la_md} {
              flex-direction: row;
              margin-bottom: 16px;
            }
            &.insurance-form-form{
              margin-bottom: 0px;
              @media #{$la_md} {
                margin-bottom: 8px;
              }
            }
            &.disabled {
              .radio-label{
                opacity: .50;
                cursor: not-allowed;
              }
            }

            .form-col {
              width: 100%;
              margin-bottom: 16px;

              @media #{$la_md} {
                width: 227px;
                margin-bottom: 0px;
              }
            }

            .datefield-wrapper .rdt .form-control:hover {
              border: solid 1px #70cbf1;
            }


            .input-wrapper .el-input .el-input__inner,
            .datefield-wrapper .rdt .form-control,
            .input-mask-wrapper input,
            .select-wrapper .el-select .el-input .el-input__inner {
              height: 40px;
              font: $scp-form-element-font;
              letter-spacing: normal;

              @media #{$mobile} {
                height: 40px;
              }
            }

            .select-wrapper .el-select {
              .el-select-dropdown {
                font: $scp-form-element-font;

                .el-select-dropdown__item {
                  color: $text-color-V2;
                  font: $scp-form-element-font;

                  &.selected {
                    color: #ffffff;
                  }
                }
              }
            }
          }

          .self-pay-checkbox{
            margin-bottom: 16px;
            @media #{$mobile} {
              margin-bottom: 5px;
            }
            .guardian-label{
              .label-section {
                padding-top: 0;
                font: normal 500 16px/1.43 $rionasans-medium;
                letter-spacing: normal;
                color: #262837;
              }
            }
          }
        }
      }
    }

    .insurance-form-radiogroup.scp-gm-radio-control {
      font-size: 14px;
      width: 100%;

      @media #{$mobile} {
        height: unset;
      }

      .gm-radio-label {
        padding-top: 0;
        font: $scp-insurance-star-font;
        letter-spacing: normal;
        color: $text-color-V2;
      }

      .gm-radio-group {
        align-items: flex-start;
        flex-flow: column;
        padding-top: 0;

        .radio:first-child {
          margin-left: 0;
          margin-bottom: 15px;
        }
      }

      &.insurance-form-radiogroup {
        .scp-gm-radio-control__invalid {
          bottom: -20px;
          left: 25px;
        }

        &.fz15px {
          justify-content: flex-start;
          height: auto;

          .gm-radio-group {
            display: flex;
            flex-direction: row;
          }

          .gm-radio-label {
            display: none;
            padding: 0;
            margin: 0;
          }

          .radio {
            margin-top: 0px;
            margin-right: 5px;
            margin-bottom: 0px;
            margin-left: 0px;
          }

          .radio:first-child {
            margin-bottom: 0px;
            margin-right: 35px;
          }
        }

        &.fz15px .scp-gm-radio-control__invalid {
          font-size: 15px !important;
        }
      }

      .radio {
        background: transparent;

        input[type='radio'] {
          +.radio-label {
            padding: 8px 0;
            font: $scp-review-page-label-font-V3 !important;
            letter-spacing: normal;
            text-align: left;
            color: $text-color-V2;

            &:before {
              border: 2px solid $form-element-border;
              width: 1em;
              height: 1em;
              top: 0.15em;
              margin-right: 0.8em;
              background-color: transparent;
            }
          }

          &:checked {
            +.radio-label {
              &:before {
                background-color: $purple-500;
                border: 1px solid $purple-300;
                box-shadow: inset 0 0 0 3.5px #fff;
              }
            }
          }

          &:focus {
            +.radio-label {
              &:before {
                border-color: $purple-300;
              }
            }
          }
        }
      }
    }
  }
}
.lab-insurance-description {
  font-family: $rionasans-regular;
  font-size: 16px;
  line-height: 20px;

}