@import '../../../styles/vars';

[role='scp-dialog']
.modal-backdrop.in {
  background-color: rgba(0,0,0,.45);
}

.modal-backdrop.in {
  background-color: black;
  opacity: 0.35;
}

.modal {
  text-align: center;
  padding: 0 !important;
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
    @media (max-width: 400px) {
      content: '';
    }
  }
  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    margin: auto; // --- end fix for phones ---
    .modal-content {
      border-radius: 0;
      box-shadow: none;
      .modal-header {
        height: 48px;
        border-bottom: 0;
      }
      .modal-body {
        padding-top: 0;
      }
    }
  }
}

.modal-close-button {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 24px;
  color: #000;
  cursor: pointer;
  padding: 12px 20px;
  opacity: 0.2;
  text-decoration: none;
  &:hover {
    opacity: 0.5;
  }
}

// copypasted modal max-sizes
@mixin modal-width-mixin {
  width: calc(100% - 10px);
  @media (min-width: 500px) {
    width: calc(100% - 40px);
  }
}

.md-full {
  &:before {
    content: none !important;
  }
  .modal-dialog {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: auto;
  }
}

.md900 {
  .modal-dialog {
    @include modal-width-mixin;
    max-width: 900px;
  }
}

.md850 {
  .modal-dialog {
    @include modal-width-mixin;
    max-width: 850px;
  }
}

.md600 {
  .modal-dialog {
    @include modal-width-mixin;
    max-width: 600px;
  }
}

.md420 {
  .modal-dialog {
    @include modal-width-mixin;
    max-width: 420px;
  }
}
