@import "../../../../styles/vars";

.abn-download-modal {
  -webkit-font-smoothing: antialiased;

  .modal-header {
    height: 56px !important;
    border-bottom: 1px solid $page-background-color-V2  !important;

    .header-text {
      font: bold 16px/22px $rionasans-bold;
      color: $text-color-V2;
      display: flex;
      align-items: center;

      img {
        height: 24px;
        width: 24px;
      }

      span {
        margin-left: 16px;
      }
    }

    .modal-close-button {
      display: none;
    }
  }

  .modal-body {
    padding: 0;
  }

  .abn-download-modal-body {
    padding: 32px;

    .checkmark-center {
      display: flex;
      justify-content: center;

      img {
        height: 36px;
        width: 36px;
      }
    }

    .title {
      display: flex;
      justify-content: center;
      margin-top: 16px;
      margin-bottom: 16px;
      font-family: $rionasans-medium;
      font-size: 18px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.39;
      letter-spacing: normal;
      text-align: center;
      color: #262837;
    }

    .abn-content {
      font-family: RionaSans;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: center;
      color: #262837;

      span {
        color: $purple-500;
        cursor: pointer;
      }
    }

    .confirm-title {
      font: normal 13px/18px $rionasans-medium;
      color: $text-color-V2;
      margin-top: 32px;
      padding: 0px 16px 0px 16px;
    }

    .confirm-buttons {
      margin-top: 24px;
      display: flex;
      justify-content: center;

      .btn-default {
        font: normal 13px/18px $rionasans-medium;
        width: 160px;
        height: 36px;
        border-radius: 6.1px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $light-indigo-V2  !important;
        text-transform: none;
        font-weight: 500;
      }
    }
  }
}