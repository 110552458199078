@import '../../../styles/vars.scss';
$menu-color: #37236a;

.mobile-menu {
  display: block;
  z-index: 1000;
  width: 100%;
  height: 85px;
  background-color: white;
  position: relative;
  @media #{$header-md} {
    height: $header-height;
  }
  @media #{$media-header-visible} {
    display: none;
  }
  .header-logo {
    display: inline-block;
    background-image: url('../../../images/logo.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 126px;
    height: 63px;
    margin-top: 10px;
    @media #{$md} {
      margin-top: 15px;
    }
  }
  .open-button {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    padding: 12px;
    margin: 16px 0 12px 12px;
    cursor: pointer;
    @media #{$md} {
      margin-top: 26px;
    }
  }
}

.mobile-menu-modal.modal-dialog {
  @media #{$media-landing-bottom-stripes} {
    width: 100%;
  }
}

.menu-modal {
  .modal-content {
    background-color: $menu-color;
    width: 100%;
    min-height: 100%;
    border: none;
    .modal-body {
      padding: 0;
      width: 100%;
      min-height: 100%;
      position: relative;
      .modal-panel {
        height: 85px;
        position: relative;
        @media #{$header-md} {
          height: $header-height;
        }
        .close-button {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          padding: 12px;
          margin: 16px;
          cursor: pointer;
          @media #{$header-md} {
            margin: 25px;
          }
        }
      }
      .menu-list {
        width: 100%;
        transition: 0.25s ease-in-out;
        padding-top: 0;
        padding-bottom: 30px;
        text-align: center;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch; // enable cute scrolling on iOS
        border-top: 1px solid rgba(256, 256, 256, 0.5);
        margin: 0;
        ul {
          list-style: none;
          padding-right: 30px;
        }
        .item-signin,
        .item {
          margin: auto;
          color: white;
          width: 220px;
          font-size: 20px;
          height: 70px;
          line-height: 70px;
          cursor: pointer;
        }
        .item {
          border-bottom: 1px solid rgba(256, 256, 256, 0.5);
        }
        .item-signin {
          margin-top: 40px;
          .signin-button {
            font-size: 0.95em;
            width: 200px;
            height: 50px;
          }
        }
      }
    }
  }
}
