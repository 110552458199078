@import '../../../styles/vars';
.header-confirmation-notice {
  width: calc(100% + 60px);
  text-align: center;
  z-index: 1001;
  margin: 0 -30px;
  background-color: #ffeeee;
  @media #{$la_md} {
    position: absolute;
    top: 0;
    left: calc(50% - 240px);
    width: 480px;
    margin: 0;
  }
  @media #{$media-header-visible} {
    left: calc(50% - 440px);
    width: 880px;
    background-color: transparent;
  }
  .header-confirmation-notice-text {
    display: inline-block;

    font-size: 12px;
    font-weight: 400;
    color: $text-color-brown;
    background-color: #ffeeee;
    padding: 2px 6px;
  }
  .header-confirmation-notice-link {
    display: inline-block;
    padding-left: 5px;
    text-decoration: underline;
    color: $link-color;
    cursor: pointer;
  }
  .header-confirmation-notice-link__processing {
    color: $light-gray-text-color;
  }
  .header-confirmation-notice-link__completed {
    color: $text-color-brown;
    text-decoration: none;
    cursor: auto;
  }
  .header-confirmation-notice-icon {
    font-size: 13px;
    color: $coupon-error-color;
    display: inline-block;
    padding-right: 5px;
  }
}
