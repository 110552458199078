.btn-group-panel {
  .btn-group {
    .btn {
      width: 100%;
      height: 100%;
      border: 1px solid #DCDCDC;
      border-radius: 3px;
      padding: 10px 12px;
    }
    .btn-group-active {    
      background-color: rgba(0,174,239,0.13);
      border: 1px solid #00AEEF;
    }
  }
}