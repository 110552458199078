@import "../../../../styles/mixins";
@import "../../../../styles/vars";

.scp-upload-document-dialog {
  -webkit-font-smoothing: antialiased;
  .modal-dialog {
    width: 100vw;
    height: 100vh;
    left: 2px;
    @media #{$la_sm} {
      max-width: 448px;
      height: 531px;
      left: 0px;
    }
    .modal-header {
      border-bottom: 1px solid $page-background-color-V2 !important;
      padding: 17px 16px 17px 16px;
      padding-top: 20px;
      @media #{$la_sm} {
        height: 48px !important;
        padding-top: 17px;
      }
      height: 60px !important;
      .header-text {
        @media #{$la_sm} {
          font-size: 16px;
        }
        font-size: 18px;
        color: $text-color-V2;
        font-family: $rionasans-bold;
      }
      .ion-close-round:before {
        font-size: 17px;
        @media #{$la_sm} {
          font-size: 12px;
        }
      }
      .modal-close-button {
        opacity: 1;
        padding-top: 16px;
        @media #{$la_sm} {
          padding-top: 12px;
        }
      }
      .modal-close-button:hover {
        color: $text-color-V2;
      }
    }
    .modal-body {
      padding: 0;
    }
    .modal-content {
      border-radius: 4px;
      min-height: 100vh;
      @media #{$la_sm} {
        min-height: unset;
      }
      .scp-uploading-modal {
        padding: 0;
        .allowed-files {
          font-size: 11px;
          line-height: 15px;
          font-family: $rionasans-regular;
          color: $info-color;
        }
        h2 {
          font-size: 25px;
          line-height: 30px;
          color: $text-color-brown;
        }
        .gm-form-field.uploading-modal-dropzone {
          margin-bottom: 0;
          .file-type-text {
            font: normal 11px/1.36 $rionasans-regular;
            min-height: 15px;
            color: $info-color;
            margin-top: 3px;
          }
          .dropzone-rectangle {
            margin-top: 32px;
            height: 136px;
          }
          span.error {
            font: normal 11px/15px $rionasans-regular;
            color: $required-red-color;
          }
          .dropzone-uploaded-files {
            margin-top: 32px;
            .file-progress-container {
              flex-basis: 100%;
              margin-bottom: 15px;
              @media #{$la_sm} {
                margin-bottom: 0px;
              }
              .fa-times-circle {
                margin-top: 1px;
              }
              .fa-times-circle:before {
                font-size: 18px !important;
                color: $light-indigo-V2;
              }
              .dropzone-progressbar {
                width: calc(100% - 25px);
                @media #{$la_sm} {
                  width: 94%;
                }
                height: 4px;
                border-radius: 100px;
              }
              svg.dropzone-progressbar .rc-progress-line-path {
                stroke: $light-indigo-V2;
                stroke-dashoffset: 0;
                stroke-dasharray: 700;
                stroke-width: 2;
              }
              .filename {
                font: normal 14px $rionasans-regular;
                word-break: break-word;
                @media #{$la_sm} {
                  font: normal 12px/17px $rionasans-regular;
                }
                color: $text-color-V2;
              }
            }
            .file-progress-container.file-error {
              .fa-times-circle:before {
                color: $required-red-color !important;
              }
            }
            .child-fields {
              flex-basis: 100%;
              margin-top: 0;
            }
            .document-type-field{
              .gm-form-control{
                input {
                  border: none;
                }
              }
            }
            .file-progress-container li {
              background-color: $required-red-color;
            }
          }
        }
        .gm-form-field.required {
          label:before {
            content: "*";
            color: $required-red-color;
            font: bold 12px/17px $rionasans-bold;
          }
        }
        .gm-form-field {
          margin-top: 0px;
          padding: 0px 16px 0px 16px;
          .gm-form-label {
            font-family: $rionasans-regular;
            line-height: 17px;
            font-size: 14px;
            @media #{$la_sm} {
              font-size: 12px;
            }
            color: $text-color-V2;
          }
          .gm-form-control {
            margin: 0;
            min-height: 0;
            .Select-value {
              padding: 7px 8px 7px 8px;
              font: normal 13px/17px $rionasans-regular;
              color: $text-color-V2;
            }
            .Select.gm-select {
              .Select-control {
                height: 48px;
                border-radius: 4px;
                @media #{$la_sm} {
                  height: 32px;
                  border-radius: 3px;
                }
                .Select-multi-value-wrapper {
                  .Select-value {
                    padding-top: 15px;
                    padding-left: 12px;
                    @media #{$la_sm} {
                      padding: 7px 8px 7px 8px;
                    }
                    .Select-value-label {
                      font-size: 16px;
                      @media #{$la_sm} {
                        font-size: 13px;
                      }
                    }
                  }
                }
                .Select-input {
                  height: 32px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                }
                .Select-placeholder {
                  line-height: 32px;
                  font-size: 16px;
                  padding-top: 7px;
                  padding-left: 12px;
                  @media #{$la_sm} {
                    font-size: 13px;
                    padding: 0px 10px;
                  }
                }
                .Select-arrow {
                  border-color: $field-border-color-V2 transparent transparent;
                }
              }
              .Select-control:disabled {
                background-color: $page-background-color-V2;
              }
              .Select-control:hover:not(:disabled) {
                border: 1px solid $hover-border-color-v2;
              }
              .Select-control:focus:not(:disabled) {
                border: 1.5px solid $focus-border-color-v2;
              }
              .Select-menu-outer {
                max-height: unset;
                @media #{$la_sm} {
                  max-height: 200px;
                }
                .Select-menu {
                  font-size: 18px;
                  max-height: unset;
                  @media #{$la_sm} {
                    font-size: unset;
                    max-height: 198px;
                  }
                  .Select-option {
                    height: 48px;
                    padding-top: 13px;
                    @media #{$la_sm} {
                      padding: 8px 10px;
                      height: unset;
                    }
                  }
                }
              }
            }
          }
        }
        .gm-form-field.document-type-field {
          margin-bottom: 20px;
          @media #{$la_sm} {
            margin-bottom: 70px;
          }
          padding: 0;
        }
        .btn-container {
          border: none;
          width: 100%;
          @media #{$la_sm} {
            position: unset;
            bottom: unset;
            border-top: 1px solid $page-background-color-V2 !important;
            display: flex;
            justify-content: flex-end;
          }
          margin-top: 32px;
          padding: 12px 16px 12px 16px;
          .btn {
            font: normal 16px $rionasans-medium;
            width: 100%;
            height: 48px;
            border-radius: 24px;
            @media #{$la_sm} {
              font: normal 13px/18px $rionasans-medium; 
              width: 160px;
              height: 36px;
              border-radius: 6.5px;
            }
          }
        }
      }
      .col-section {
        margin-top: 6px;
        @media #{$la_sm} {
          margin-top: 0px;
        }
        padding: 0px 16px 0px 16px;
        .label-section {
          font: normal 14px $rionasans-regular;
          @media #{$la_sm} {
            font: normal 12px/17px $rionasans-regular;
          }
          color: $text-color-V2;
        }
        .label-section.required:before {
          content: "*";
          color: $required-red-color;
          font: bold 12px/17px $rionasans-bold;
        }
        .input-section {
          .input-wrapper {
            .el-input {
              .el-input__inner {
                height: 48px;
                font: normal 16px $rionasans-regular;
                @media #{$la_sm} {
                  height: 32px;
                  font: normal 13px/18px $rionasans-regular;
                }
              }
            }
            .el-textarea {
              .el-textarea__inner {
                min-height: 120px;
                max-height: 350px;
                font: normal 16px $rionasans-regular;
                @media #{$la_sm} {
                  min-height: 87px;
                  max-height: unset;
                  font: normal 13px/18px $rionasans-regular;
                }
              }
            }
          }
        }
      }
      .col-section.description-field {
        margin-top: 26px;
        @media #{$la_sm} {
          margin-top: 19px;
        }
      }
    }
  }
}
