@import '../../../../styles/vars.scss';

$md: '(min-width: 590px)';
$max-md: '(max-width: 589px)';

.email-exist-wrapper.test-request {
  max-width: 816px;
  margin: 24px auto 100px !important;
  background: $white-bg-color-v2;
  z-index: 0;
  -webkit-font-smoothing: antialiased;
 

  .email-exist-header {
    width: 100%;
    padding: 24px 24px 0;
    display: flex;
    flex-direction: column;

    .genome-header-logo {
      width: 100%;
      text-align: center;
      margin-bottom: 24px;

      .genome-header-img {
        max-width: 130px;
      }
    }

    .genome-header-title {
      width: 100%;
      text-align: center;
      padding-bottom: 24px;
      border-bottom: 1px solid #edeff3;

      @media #{$la_md} {
        padding: 0 108px 24px 108px;
      }

      .email-exist-title {
        font-family: $rionasans-medium;
        -webkit-font-smoothing: antialiased;
        font-size: 23px;
        margin: 0 0 4px 0;
        color: $text-color-V2;
        font-weight: 500;
        line-height: 27.6px;
      }

      .email-exist-desc {
        font-family: $rionasans-regular;
        -webkit-font-smoothing: antialiased;
        font-size: 16px;
        color: $text-color-V2;
        line-height: 1.38;
        text-align: center;
      }
    }
  }

  .email-exist-body {
    width: 100%;
    padding: 0 24px 0;

    .email-exist-form {
      width: 100%;

      .form-section {
        display: flex;
        width: 100%;
        padding: 32px 0;
        flex-direction: column;

        .info-section {
          margin-bottom: 32px;

          @media #{$la_md} {
            width: 240px;
            
          }

          .header {
            color: $light-gray-text-color-v2;
            font-family: $rionasans-bold;
            -webkit-font-smoothing: antialiased;
            font-size: 19px;
            font-weight: 500;
            line-height: 22.8px;
            .warning-icon{
              margin-right: 8px;
            }
          }
        }

        .body-section {
          font-family: $rionasans-regular;
          font-size: 16px;
          line-height: 20px;
          margin-left: 32px;

        }
      }
    }
  }
}

