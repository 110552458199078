@import '../../../styles/vars';

.fax-form {
  color: $gray-text-color;
  
  .block {
    background-color: #fefacf;
    padding: 20px;
    text-align: center;
    .fax-title {
      font-size: 18px;
      line-height: 24px;
    }
    .pdf-icon {
      display: block;
      font-size: 40px;
      line-height: 40px;
      color: $link-color;
      padding: 18px 0 13px;
      margin: auto;
    }
    .refer-link {
      color: $link-color;
      width: 148px;
      display: block;
      margin: auto;
    }
  }
  .refer-contacts {
    padding: 10px 0;
  }
}
