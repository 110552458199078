@import '../../../../../../styles/vars';
$md: '(min-width: 500px)';

.scp-appointment-info-with-yellow-hint {
  margin: auto;
  text-align: left;

  .install-zoom {
    .install-zoom-text {
      display: inline-block;
      line-height: 22px;
      vertical-align: middle;

      @media #{$mobile} {
        a {
          padding: 10px 0;
        }
      }
    }

    .free-space {
      height: 10px;
    }
  }

  .scp-gm-link {
    color: $focus-border-color-v2;
    cursor: pointer;

    &:hover {
      color: $focus-border-color-v2;
    }
  }

  .appointment-info-wrapper {
    border: 1px solid #edeff3;
    margin: 24px 0;
    border-radius: 4px;

    .confirm-time-container {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      justify-content: center;
      padding: 16px;
      border-radius: 4px;
      margin: 0 0 16px;

      .label-confirm-time {
        font: normal bold 14px $rionasans-bold;
      }

      .appointment-day,
      .appointment-time {
        font: normal bold 20px $rionasans-bold;
      }
    }

    .appointment-within-24Hrs {
      background-color: #00a044;

      .label-confirm-time {
        color: $white-bg-color-v2;
      }

      .appointment-day,
      .appointment-time {
        color: $white-bg-color-v2;
      }
    }

    .appointment-after-24Hrs {
      background-color: $page-background-color-V2;

      .label-confirm-time {
        color: $info-color;
      }

      .appointment-day,
      .appointment-time {
        color: $text-color-V2;
      }
    }

    .appointment-type-container {
      display: flex;
      justify-content: space-between;
      color: $text-color-V2;
      padding: 16px;

      .consultation-container {
        display: flex;
        flex-flow: column;

        .consultation-name {
          font: normal bold 16px $rionasans-bold;
        }

        .consultation-duration {
          font: normal 16px $rionasans-regular;
        }
      }
    }

    .genetic-counselor-container {
      display: flex;
      justify-content: space-between;
      margin: 16px 12px;
      padding: 16px 4px;
      border-top: 1px solid #edeff3;

      .counselor-photo {
        border-radius: 40px;
        width: 81px;
        height: 81px;
      }

      .counselor-name-container {
        display: flex;
        flex-flow: column wrap;
        justify-content: center;

        .label-counselor {
          font: normal 13px/1.23 $rionasans-regular;
          color: $light-gray-text-color-v2;
        }

        .counselor-name {
          font: normal 500 14px $rionasans-medium;
          color: $text-color-V2;
        }
      }
    }
  }

  .btn-fill-questionnaire.btn {
    border-radius: 24px;
    width: 100%;
    font: normal 500 16px $rionasans-medium;
  }

  .btn-video-instructions.btn {
    border-radius: 24px;
    border: solid 1px $purple-500  !important;
    background-color: #ffffff !important;
    margin-top: 8px;
    width: 100%;
    color: $light-indigo-V2  !important;
    font: normal 500 16px $rionasans-medium;
  }
}

.no-appointments {
  .text {
    font-size: 14px;
    padding: 30px 20px 20px;
    margin: auto;
    max-width: 300px;

    @media #{$md} {
      padding: 50px 20px 30px;
      font-size: 18px;
      max-width: inherit;
    }
  }
}

.scp-cancellation-policy-section {
  margin-top: 52px;
  padding-top: 24px;
  padding-bottom: 20px;

  @media #{$mobile} {
    border-top: unset;
  }

  .cancellation-policy-header {
    font: normal bold 14px/1.43 $rionasans-bold;
    color: $light-gray-text-color-v2;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 4px;
  }

  .cancellation-policy-body {
    font: normal 14px/1.14 $rionasans-regular;
    color: $body-text-color-v2;
    -webkit-font-smoothing: antialiased;
  }
}

.ninetyDays {
  .counselor-name-container {   
    .counselor-name {
      font: normal 500 14px $rionasans-bold !important;
      color: $text-color-V2;
    }
  }
  .scp-cancellation-policy-section {
    margin-top: 0;
    padding-top: 32px !important;

    .cancellation-policy-body {
      font: normal 13px/1.14 $rionasans-medium;
      color: $body-text-color-v2;
      -webkit-font-smoothing: antialiased;
    }
  }
}