@import '../../../styles/vars';

.header-alert-upload-insur-card {
  width: calc(100% + 60px);
  height: 22px;
  text-align: center;
  z-index: 1001;
  margin: 0 -30px;
  background-color: #ffeeee;
  @media #{$la_md} {
    position: absolute;
    left: calc(50% - 240px);
    width: 480px;
    height: 22px;
    margin: 0;
  }
  @media #{$media-header-visible} {
    left: calc(50% - 440px);
    width: 880px;
    height: 22px;
    background-color: transparent;
  }
  .header-alert-upload-insur-card-text {
    display: inline-block;
    background-color: #ffeeef;
    padding: 2px 6px;
    font: normal 12px/1.17 Roboto;
    color: #262837;

    .upload-insurance {
      margin-top: -3px;
    }
  }
  .header-alert-upload-insur-card-link {
    display: inline-block;
    padding-left: 5px;
    text-decoration: underline;
    color: #37b8ef;
    cursor: pointer;
  }
  .header-alert-upload-insur-card-icon {
    font-size: 13px;
    color: $coupon-error-color;
    display: inline-block;
    padding-right: 5px;
  }
}

.has-confirm-notice {
  top: 23px; 
}

.no-confirm-notice {
  top: 0; 
}