@import "../../../../../../styles/vars";

.sw-message-v2 {
  padding: 32px 56px 56px 56px;
  .sw-message-logo-v2 {
    width: 100%;
    text-align: center;
    margin-bottom: 24px;
    .sw-message-img-v2 {
      max-width: 130px;
    }
  }
  .sw-message-icon-v2 {
    margin: 56px auto 16px;
    width: 48px;
    height: 48px;
    &.success {
      background-image: url("./Icon-Success-big.svg");
      background-repeat: no-repeat;
    }
    &.closed {
      background-image: url("./Icon-Closed-big.svg");
      background-repeat: no-repeat;
    }
    &.error {
      background-image: url("./Icon-Error-big.svg");
      background-repeat: no-repeat;
    }
  }

  .sw-message-title-v2 {
    font-family: $rionasans-medium;
    -webkit-font-smoothing: antialiased;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.42;
    text-align: center;
    color: #262837;
  }

  .sw-message-subtitle-v2 {
    font-family: $rionasans-regular;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    line-height: 1.43;
    text-align: center;
    color: #262837;
    margin-top: 8px;
  }
}
