@import '../../../../../styles/vars';
@import '../../../../../styles/mixins';

.scp-profile-page-container {
  -webkit-font-smoothing: antialiased;

  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
  @media #{$la_md} {
    .desktop {
      display: block;
    }
    .mobile {
      display: none;
    }
  }

  @media #{$la_md} {
    padding-top: 24px;
    padding-bottom: 24px;
    min-height: calc(100vh - 290px);
  }

  .page-title {
    text-align: left;
    background-color: $page-background-color-V2;
    display: none;
    @media #{$la_md} {
      display: none;
    }

    h2 {
      font: normal bold 22px/1.45 $rionasans-bold;
      color: $text-color-V2;
      text-transform: capitalize;
      margin: 12px auto 5px;
    }
  }

  > div {
    margin: 0 auto;
    min-height: initial;
    border-radius: 4px;
    @media #{$la_md} {
      width: 1020px;
      min-height: 670px;
    }

    .notification-container-animated {
      overflow: hidden;
      animation: show-hide 6s;
      @keyframes show-hide {
        0% {
          height: 0;
        }
        10% {
          height: 75px;
        }
        80% {
          height: 75px;
        }
        100% {
          height: 0;
        }
      }
    }

    .notification-container-animated-no {
      .gm-alert {
        margin: 10px;
        @media #{$la_md} {
          margin: 20px auto;
        }
      }
    }

    .scp-profile-page {
      background-color: $white-bg-color-v2;
      box-shadow: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      position: relative;
      padding: 0 !important;
      @media #{$la_xsm} {
        padding: 25px 16px;
      }
      @media #{$la_md} {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        padding: 0;
        align-items: stretch;
        justify-content: flex-start;
      }

      &.full-height {
        min-height: initial;
        @media #{$la_md} {
          min-height: 670px;
        }
      }

      .scp-responsive-step-panel__desktop {
        display: none;
        @media #{$la_md} {
          display: block;
          .steps-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .step-item {
              color: $text-color-V2;
              font-weight: bold;
              display: flex;
              justify-content: space-around;
              align-items: flex-start;
              .step-image {
                display: -webkit-inline-box;
                width: 100px;
                .header {
                  margin-left: 10px;
                }
              }
            }
            .step-item.active {
              color: $light-indigo-V2;
              font-weight: normal;
            }
          }
        }
        .steps-container {
          .step-item:first-child {
            display: none;
          }
        }
      }
      .scp-responsive-step-panel__mobile {
        flex-basis: 100%;
        display: none;
        .controlled-button-group {
          width: 100%;
          max-width: 520px;
          @media #{$la_md} {
            width: auto;
          }
          .controlled-button {
            min-width: 0;
            width: 33.3%;
            min-height: 60px;
            white-space: normal;
            text-align: left;
            line-height: 1.4;
            padding: 8px 16px;
          }
        }
      }

      form {
        margin: 40px 0 40px 48.5px;
        width: auto;
        max-width: 473px;
        @media #{$la_lg} {
          max-width: 690px;
        }

        &.gm-form {
          flex-grow: 1;
          min-width: 300px;
          margin: 10px auto 0;
          padding: 15px 15px 96px 15px;
          border-top: 1px solid $page-background-color-V2;
          @media #{$la_md} {
            margin: 40px 0 40px 48.5px;
            padding: 0px;
            border-top: 0px;
          }

          .back {
            font: normal 500 14px/1.17 $rionasans-medium;
            color: $scp-button-focus-color;
            cursor: pointer;

            &::before {
              content: '<';
              font-size: 17px;
              margin-right: 3px;
            }
            @media #{$la_md} {
              display: none;
            }
          }
          .back.visible {
            display: block;
          }

          .scp-gm-radio-card-control-v2 {
            .gm-radio-group {
              .radio-label {
                font-size: 14px;
                @media #{$la_md} {
                  font-size: 12px;
                }
                .radio-label {
                  width: 56%;
                }
                .radio-label2 {
                  width: 33%;
                  text-align: end;
                  @media #{$mobile} {
                    width: 30%;
                  }
                }
              }
            }
          }

          .radio-button-description {
            width: auto !important;
          }
        }

        > .form-title {
          font: $scp-title1-font;
          letter-spacing: normal;
          color: $text-color-V2;
          margin-bottom: 32px;
          text-transform: capitalize;
          display: none;
          @media #{$la_md} {
            display: block;
          }

          &.small {
            font: $scp-insurance-star-font;
            margin: 32px 0 16px;
          }
        }

        > .title {
          font: $scp-title1-font;
          letter-spacing: normal;
          color: $text-color-V2;
          margin-bottom: 24px;
          text-transform: capitalize;
          @media #{$la_md} {
            display: none;
          }

          &.small {
            font: $scp-insurance-star-font;
            margin: 32px 0 16px;
          }
        }

        > .cc-title {
          font: $scp-title1-font;
          font-size: 16px;
          color: $text-color-V2;
          margin-bottom: 16px;
          // @media #{$la_md} {
          //   display: none;
          // }
          .card-image {
            width: 27px;
            height: 16px;
            margin-left: 8px;
          }
        }

        > .sub-title {
          font: $scp-insurance-star-font;
          font-size: 22px;
          color: $text-color-V2;
          margin-bottom: 16px;
          @media #{$la_md} {
            font-size: 14px;
          }

          &.guardian-info {
            margin-top: 32px;
          }
        }
        div.form-field.address-line1 {
          width: 100%;
          @media #{$la_md} {
            width: 370px;
          }
        }
        div.form-field.address-line2 {
          width: 100%;
          @media #{$la_md} {
            width: 370px;
          }
        }
        div.form-field.country {
          width: 100%;
          @media #{$la_md} {
            width: 370px;
          }
        }
        div.form-field {
          margin: unset;
          padding: unset;
          margin-bottom: 24px;
          @media #{$md} {
            width: 473px;
            display: inline-block;
            margin-bottom: 19px;
          }

          .field-title {
            font: $label_font;
            font-size: 14px;
            letter-spacing: normal;
            color: $text-color-V2;
            margin: unset;
            position: relative;
            padding-left: 0px !important;
            @media #{$md} {
              font-size: 12px;
            }

            &.required:before {
              content: '*';
              color: $required-red-color;
              position: absolute;
              left: -6px;
              font-size: 15px;
              top: -1px;
            }
          }

          .select-wrapper {
            .el-select-dropdown__item {
              font-size: 13px;
              @media #{$la_md} {
                font-size: 12px;
              }
            }
            &:hover {
              input:not(:disabled) {
                border: 1px solid $hover-border-color-v2 !important;
              }
            }

            i.is-reverse + input {
              border: 1.5px solid $focus-border-color-v2;
            }

            input {
              height: 48px !important;
              padding: 13px 12px 13px 16px !important;
              font: $scp-form-element-font !important;
              letter-spacing: normal;
              color: $text-color-V2;
              border: 1px solid $field-border-color-V2 !important;
              font-size: 16px !important;

              @media #{$la_md} {
                font-size: 13px !important;
                padding: 7px 8px !important;
                height: 32px !important;
              }

              &::placeholder {
                font: $scp-form-element-font;
                font-size: 16px;
                letter-spacing: normal;
                color: $field-border-color-V2;
                text-transform: capitalize;
                @media #{$la_md} {
                  font-size: 13px;
                }
              }

              &:hover::placeholder,
              &:focus::placeholder {
                color: $field-border-color-V2;
              }

              &:disabled {
                background-color: $page-background-color-V2;
                color: $field-border-color-V2;
              }
            }

            .multi-select {
              input {
                height: 48px !important;
                max-height: 48px !important;
                font-size: 16px;
                @media #{$la_md} {
                  height: 32px !important;
                  max-height: 32px !important;
                }
              }
              .el-select__tags-text {
                font-size: 16px;
                @media #{$la_md} {
                  font-size: 12px;
                }
              }
              .el-tag {
                margin: 3px 0px 0px 3px;
              }
            }
          }

          .input-wrapper,
          .input-mask-wrapper {
            input {
              height: 48px;
              padding: 13px 12px 13px 16px;
              font: $scp-form-element-font;
              font-size: 16px;
              letter-spacing: normal;
              color: $text-color-V2;
              border-radius: 4px;
              border: $scp-form-element-border;

              @media #{$la_md} {
                height: 32px;
                padding: 7px 8px;
                font-size: 13px;
              }

              &:disabled {
                background-color: $page-background-color-V2;
                color: $field-border-color-V2;
              }
            }

            input:hover:not(:disabled) {
              border: 1px solid $hover-border-color-v2;
              outline: 0;
            }
            input:focus:not(:disabled) {
              border: 2px solid $hover-border-color-v2;
              outline: 0;
            }
            .input-error {
              display: inline-block;
            }

            &.input-error-field {
              input {
                border: solid 1px $required-red-color;
              }
            }
          }

          &.mixed-race,
          &.pronouns {
            label {
              span {
                color: #7b8291;
              }
            }
          }

          .profile-page-checkbox {
            .checkbox-wrapper {
              display: flex;
              align-items: center;
              margin-top: 11px;

              .guardian-label {
                margin: 0 !important;
                padding: 0 !important;

                .label-section {
                  font: $scp-review-page-label-font;
                  color: #262837;
                  text-transform: capitalize;
                  padding-top: 7px;
                }

                .checkmark {
                  position: relative;
                  height: 18px !important;
                  width: 18px !important;
                  background-color: #ffffff;
                  border: 1px solid #a0abb9;
                  border-radius: 2px;
                  margin-right: 9px;
                  padding: 1px 2px 5px 1px;

                  &:after {
                    content: '';
                    position: absolute;
                    left: 5px;
                    top: 1px;
                    width: 6px;
                    height: 12px;
                    border: solid white;
                    border-width: 0 2px 2px 0;
                    transform: rotate(45deg);
                  }
                }

                input:checked ~ .checkmark {
                  background-color: #712cc1;
                }
              }
            }
          }
          .profile-page-checkbox.solo-radio {
            margin-top: 3px;
            .checkbox-wrapper {
              margin-top: 0px;
              .checkmark {
                height: 16px !important;
                width: 16px !important;
                margin-right: 7px;
                margin-top: -1px;
                &::after {
                  top: 1px;
                  left: 4px;
                  height: 10px;
                }
              }
              .label-section {
                padding-top: 0px;
                font: normal 12px $rionasans-regular;
                color: $light-gray-text-color-v2;
                text-transform: none;
              }
            }
            .checkbox-wrapper.is-disabled {
              cursor: no-drop;
              opacity: 0.35;

              .guardian-label {
                cursor: no-drop;
              }
            }
          }
        }

        div.form-field.race {
          .more-info-icon {
            width: 14px;
            height: 14px;
            margin-top: -2px;
          }
        }

        div.form-field.pronouns {
          .select-wrapper {
            .el-select.multi-select {
              .el-select__tags {
                max-height: 28px;
              }
            }
          }
        }

        .outer-button {
          text-align: center;
          margin-top: 37px;
          @media #{$md} {
            text-align: left;
            margin-top: 32px;
          }

          & > button {
            padding: 12px;
            width: 345px;
            min-width: 100%;
            height: 48px;
            font: $scp-submit-button-font;
            font-size: 16px;
            letter-spacing: normal;
            text-align: center;
            border-radius: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            @media #{$md} {
              min-width: auto;
            }
            @media #{$la_md} {
              padding: 9px 12px;
              width: 145px;
              border-radius: 6.5px;
              height: 36px;
              font-size: 13px;
            }

            &:disabled {
              background-color: $scp-button-disabled-color !important;
            }

            &:hover:not(:disabled) {
              background-color: $scp-button-hover-color !important;
            }

            &:focus:not(:disabled) {
              background-color: $scp-button-focus-color !important;
            }
          }
          &.v2{
            & > button{
            border-radius: 6.5px;
          }
        }
        }

        .line-fields {
          display: flex;
          flex-wrap: wrap;
          .StripeElement{
            width: 470px;
          }

          .first-name,
          .last-name,
          .dob,
          .ethnicity,
          .phone,
          .phone-type,
          .guardian-relationship,
          .guardian-dob,
          .gender,
          .preferred-name,
          .pronouns,
          .race,
          .sex-assigned-at-birth {
            width: 100%;
            @media #{$la_md} {
              width: 228.5px;
            }
          }

          .first-name,
          .last-name {
            .el-input__inner {
              text-transform: capitalize;
            }
          }

          .card-number,
          .cardholder-name {
            width: 100%;
            margin-bottom: 12px;

            @media #{$la_md} {
              width: 470px;
            }
          }

          .exp-date {
            width: 100%;
            @media #{$la_md} {
              width: 146px;
            }

            & > .field-title {
              display: block;
              @media #{$la_md} {
                display: inline-block;
              }
            }

            & > .select-wrapper {
              width: 48%;
              display: inline-block;
              vertical-align: top;
              @media #{$la_md} {
                width: 69px;
              }

              &:first-of-type {
                margin-right: 8px;
              }
            }
          }

          .cvc {
            width: 100%;
            @media #{$la_md} {
              width: 147px;
            }
          }

          .city {
            width: 100%;
            @media #{$la_md} {
              width: 162px;
            }
          }

          .state {
            width: 100%;
            @media #{$la_md} {
              width: 89px;
            }
          }

          .zip-code {
            width: 100%;
            @media #{$la_md} {
              width: 89px;
            }
          }

          .card-number,
          .cardholder-name,
          .city,
          .state,
          .first-name,
          .phone,
          .guardian-relationship,
          .dob,
          .preferred-name,
          .race {
            margin-right: 0;
            @media #{$la_md} {
              margin-right: 16px;
            }
          }

          .gender {
            .el-input {
              input {
                text-overflow: ellipsis;
              }
            }
          }

          .race {
            .input-field {
              .el-select {
                .el-input {
                  input {
                    text-overflow: ellipsis;
                  }
                }
              }
            }
          }
        }

        @media #{$mobile} {
          .line-fields.line-race-eth-fields {
            display: flex;
          }
          .line-fields.line-race-eth-fields > :nth-child(1) {
            order: 1;
          }
          .line-fields.line-race-eth-fields > :nth-child(2) {
            order: 3;
          }
          .line-fields.line-race-eth-fields > :nth-child(3) {
            order: 2;
          }
        }

        .update-info {
          margin-bottom: 16px;
          font: $label_font;
          color: $info-color;

          span {
            font-family: $rionasans-bold;
            font-weight: bold;
          }

          a {
            color: $focus-border-color-v2;
            text-decoration: underline;
          }
        }
        .billing-address{
          max-width: 470px;
          .form-row{
            display: flex;
            flex-direction: row;
            margin-bottom: 16px;
            .form-col{
              margin-right: 15px;
              &.city-col{
                width: 50%;
              }
              &.state-col{
                width: 20%;
              }
              &.zip-col{
                width: 25%;
              }

              .input-wrapper .el-input .el-input__inner,
              .select-wrapper .el-select .el-input .el-input__inner {
                height: 32px;
                font: $scp-form-element-font;
                letter-spacing: normal;

                @media #{$mobile} {
                  height: 40px;
                }
              }
            }
            .full-col{
              width: 100%;
            }
          }
          .label-section{
            width: 100%;
            font-family: $rionasans-medium;
            font-size: 14px;
            font-weight: 500;
            line-height: 16.8px;
            color: #525968;
            letter-spacing: normal;
            margin-bottom: 4px;
          }
          .required:before {
            content: "*";
            color: $required-red-color;
            font-size: 14px;
            margin-left: -4px;
          }

        }



        .headingv2 {
          font-family: $rionasans-bold;
          font-size: 19px;
          font-weight: 700;
          line-height: 22.8px;
          color: #525968;
          &.form-title{

            @media #{$mobile} {
              display: block;
              margin: 24px 0 16px;
          }
          @media #{$la_md} {
            margin: 32px 0 16px;
        }
          }
        }
      }
    }
  }
}
