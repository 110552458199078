@import '../../../../../styles/vars';

$select-width: 25%;

.appointment-options.scp.v2 {
  color: $gray-text-color;

  @media #{$md} {
    margin: auto;
    flex-direction: column;
  }

  @media #{$la_lg} {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  .select-title {
    font-family: $rionasans-medium;
    font-size: 14px;
    font-weight: 490;
    line-height: 16.8px;
    color: $light-gray-text-color-v2;
    margin-bottom: 4px;

    .help-icon {
      height: 13px;
      width: 13px;
      cursor: pointer;
    }
  }

  .gm-select{
    width:100%;
    input{
    font-size: 16px;
    line-height: 19.2px;
    padding: 11px 0px 11px 12px;
    &:disabled{
      color: #bac3ce !important;
    }
    }
    &.gm-select-specialty {
      width: 100%;
      @media (min-width: 1024px) {
        width: 250px; 
      }
    }
  }

  .select-field{
    margin-top: 16px !important;
    &.select-provider{
      border:unset;
      padding: 0;
    }
    @media #{$la_lg} {
      margin: 16px 16px 0 0 !important ;
      width:25%
    }

  }
}

.canada-language{
  margin:32px 0 0 0;
  .canada-title{
    font-family: $rionasans-bold;
    font-size: 13px;
    font-weight: 700;
    line-height: 15.6px;
    color: #262837;
    margin-bottom: 4px;
  }
  .description{
    p{
      font-family: $rionasans-medium;
      font-size: 13px;
      font-weight: 490;
      line-height: 15.6px;
      color: #525968;
    }
  }
}
