@import '../../../../styles/vars';
$subheader-color: #3E6D8D;
$max-sheet-width: 1228px;
.privacy-container {
  display: flex;
  width: 100%;
  background-color: $content-background-color;
  color: #262837;
  text-align: center;
  padding: 0;
  position: relative;
  overflow: hidden;
  background: $page-background-color-V2 url("../../../../images/gray-ribbon-v2.svg") center/100% no-repeat fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  @media #{$media-header-visible} {
    padding: 3%;
  }
  .sheet {
    margin: auto;
    max-width: $max-sheet-width;
    background-color: white;
    z-index: 10;
    position: relative;
    border-radius: 4px;
    .privacy-header {
      width: 100%;
      border-bottom: solid 2px #edeff3;
      @media (min-width: 700px) {
        border-bottom: solid 2px #edeff3;
      }
      .genome-header-logo {
        width: 100%;
        text-align: center;
        margin-bottom: 24px;
        .genome-header-img {
          max-width: 130px;
        }
      }      
    }
  }
}
