@import '../../../../../../styles/vars';
@import '../../../../../../styles/mixins';

.scp-results-page-block {
  margin: 30px 16px 0px;

  @media #{$la_sm} {
    margin: 40px 0 30px 40px;
  }

  .results-cards-table {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .table.mobile-results-table {
    display: table;
    max-width: 550px;
    margin: 0 auto;

    @media #{$la_sm} {
      display: none;
    }

    thead tr.mobile-header th {
      .mobile-header-item {
        display: inline-block;
        width: 50%;

        &:last-of-type {
          text-align: right;
          padding-right: 15%;
        }
      }
    }

    .mobile-blue-row {
      border: none;
      background: #EFF5F9;

      .blue-row-header {
        color: $light-gray-text-color;
        padding-left: 10px;
      }

      td {
        height: auto;
      }
    }

    .mobile-actions-row {
      border: none;

      .docs-action {
        .delete-img {
          width: 16px;
          height: 16px;
        }

        @media #{$mobile} {
          > div {
            min-height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }

    tr.is-malicious {
      border: 1px solid $error-text-color  !important;

      .malicious-icon {
        width: 24px;
        cursor: unset;
      }
    }

    tr.scanning {
      height: 82px;

      .el-loading-spinner {
        top: 18px;
      }
    }
  }

  .desktop-results-table {
    display: none;

    @media #{$la_sm} {
      display: table;
    }
  }

  .table tbody tr td {
    &.docs-link {
      a {
        color: #262837;
        cursor: pointer;
        text-decoration: none;

        &:hover,
        &:active {
          color: #262837;
        }
      }
    }

    &.docs-link.docs-link__removing {
      a {
        color: $light-gray-text-color;

        &:hover,
        &:active {
          color: $light-gray-text-color;
        }
      }
    }

    &.docs-action {
      color: $link-color;
      text-align: center;

      .delete-img {
        cursor: pointer;
        height: 32px;
        width: 32px;
      }

      .mobile-action-desc {
        color: $light-gray-text-color;
        cursor: pointer;
        padding: 8px;
      }

      @media #{$la_sm} {
        text-align: center;
      }
    }

    &.docs-action__removing {
      color: $light-gray-text-color;
    }
  }

  .no-docs-warn {
    text-align: center;
    max-width: 600px;
    margin: 25px auto;
    font-family: RionaSans;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #7b8291;
  }

  .other-doc-block {
    margin-top: 0 !important;

    .table-responsive {
      overflow-x: hidden !important;
    }
  }

  .results-actions-bar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 20px auto 30px;

    .other-doc-header {
      display: flex;
      align-items: center;

      &.hide-insurance-card {
        margin-top: 0px;
      }

      @media #{$la_sm} {
        justify-content: space-between;
        flex-flow: column wrap;
        align-items: flex-start;
        height: 66px;
      }

      width: 100%;
      margin-top: 48px;
      margin-bottom: 24px;
      justify-content: space-between;

      .Title {
        font-family: $rionasans-bold;
        font-size: 18px;
        display: block;

        @media #{$la_sm} {
          font-family: RionaSans;
          font-size: 14px;
          font-weight: bold;
          display: unset;
        }

        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: #262837;
        margin-right: 16px;
      }

      .results-upload-btn {
        width: 130px !important;
        min-width: 130 !important;
        height: 36px !important;
        border-radius: 6.5px !important;
        border: solid 1px $purple-500  !important;
        background-color: #ffffff !important;
        min-width: 130px;
        font: normal 500 13px $rionasans-medium;
        text-align: left;
        color: $purple-500  !important;
        text-transform: none;
        box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0) !important;

        .btn-inner-container {
          padding-left: 8px;
          padding-right: 8px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .btn-icon {
            width: 28px;
            height: 28px;
            margin-right: 8px;

            img {
              vertical-align: unset;
            }
          }
        }

        &:hover {
          border-radius: 6px !important;
          background-color: rgba(123, 38, 201, 0.15) !important;
        }

        &:active {
          border-radius: 6px !important;
          border: solid 1px $purple-500  !important;
          background-color: rgba(123, 38, 201, 0.3) !important;
        }
      }


      .filters-list__desktop {
        margin: 0 !important;
      }
    }

    .scp-other-doc-table {

      tr {
        height: auto !important;
        border-bottom: solid 1px #d8dde4 !important;
        padding: 0;
        display: flex;

        &.scanning {
          height: 65px !important;
          justify-content: center;

          > td {
            width: 100%;

            .el-loading-spinner {
              top: 18px;
            }
          }
        }

        &.is-malicious {
          border: 1px solid $error-text-color  !important;

          .malicious-icon {
            width: 24px;
            cursor: unset;
            margin-left: 3px;
          }
        }
      }

      .narrow-cell {
        font-family: RionaSans;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: #7b8291;
      }

      .other-doc-type {
        min-width: 154px;
        padding: 0 57px 0 10px !important;
      }

      .other-doc-file {
        width: 350px;
        min-width: 350px;
        padding: 0 66px 0 0 !important;
      }

      .other-doc-date {
        min-width: 130px;
        width: 130px;
        padding: 0 41px 0 0 !important;
      }

      .other-doc-expand-arrow {
        min-width: 68px;
        width: 68px;
        padding: 0;
        margin-top: -11px;
        display: flex;
        justify-content: flex-end;

        img {
          cursor: pointer;
          margin-right: -6px;
        }
      }

      .other-doc-desc {
        width: 280px;
        min-width: 280px;
      }

      tbody tr {
        padding: 11px 0;
        border-bottom: solid 1px #d8dde4 !important;
      }


      tbody tr td {
        padding-left: 0;
        height: auto;
        vertical-align: initial;
        padding-top: 0;
        padding-bottom: 0;
      }

      .document-row-expanded {
        padding: 11px 0 8px;
        border-bottom: none !important;
        border-top: none;
      }

      .expanded-document-description {
        padding: 0 0 8px 10px;

        span {
          max-width: calc(100% - 69px);
        }
      }

      .body-doctype {
        min-width: 160px;
        width: 160px;
        padding: 0 57px 0 10px !important;
        font-family: $rionasans-regular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: normal;
        text-align: left;
        color: #262837;
      }

      .body-docfile {
        width: 350px;
        min-width: 350px;
        padding: 0 60px 0 0 !important;
        font-family: $rionasans-regular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: normal;
        text-align: left;
        color: #262837;

        span {
          cursor: pointer;
        }
      }

      .body-docdate {
        min-width: 130px;
        width: 130px;
        padding: 0 41px 0 0 !important;
        font-family: $rionasans-regular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: normal;
        text-align: left;
        color: #262837;
      }

      .body-doc-desc {
        padding: 0 20px 0 0;
        width: 280px;
        font-family: Roboto;
        font-size: 13px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;
      }

      .docs-action {
        padding-right: 0;
        display: flex;
        align-items: flex-start;

        .threedotaction {
          cursor: pointer;

          &[aria-describedby*='action-popover'] {
            background-color: rgba(123, 130, 145, 0.15);
            border-radius: 3px;
          }
        }

        .threedotaction:hover,
        .threedotaction:active {
          background-color: rgba(123, 130, 145, 0.15);
          border-radius: 3px;
        }

        img {
          cursor: pointer;
          margin-right: 8px;
        }
      }

      .expanded-document-description {
        color: $body-text-color-v2;
        font-family: $rionasans-regular;
        font-size: 14px;
        line-height: 1.36;
      }

      .archived-row {

        td,
        td.docs-link a {
          color: #bac3ce !important;
          pointer-events: none;
        }

      }


    }

    @media #{$la_md} {
      margin: 10px auto 20px;
    }

    @media #{$la_lg} {
      width: 100%;
    }

    .filters-list__empty {
      display: none;

      @media #{$la_md} {
        display: inline-block;
        flex-basis: 80%;
      }
    }

    .filters-list__mobile {
      display: inline-block;
      flex-basis: 58%;
      max-width: 200px;

      @media #{$la_xsm} {
        flex-basis: 62%;
        max-width: 300px;
      }

      @media #{$la_md} {
        display: none;
      }

      .Select-control {
        border-color: rgba($text-color-brown, 0.5);
      }
    }

    .filters-list__desktop {
      list-style: none;
      min-width: 310px;
      margin-top: 3px;
      padding-left: 0;
      display: flex;
      flex-flow: row wrap;
      flex-basis: 86%;
      display: none;

      @media #{$la_md} {
        display: block;
      }

      .filters-list-item {
        display: inline-block;
        font-size: 18px;
        cursor: pointer;
        margin-right: 25px;
      }

      .filters-list-item__active {
        color: $link-color
      }
    }

    .results-upload-btn.btn {
      @include small-btn;
      cursor: pointer;
      flex-basis: 45%;

      @media #{$la_md} {
        margin-left: 5%;
        margin-left: 0;
        flex-basis: auto;
      }
    }
  }

  .insurance-page-block {
    .Your-insurance-card {
      font-family: $rionasans-bold;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: left;
      color: #262837;

    }

    .insurance-upload-block {
      padding-top: 12px;
      display: flex;
      max-width: 552px;
      justify-content: space-between;
      flex-direction: column;

      @media #{$la_md} {
        flex-direction: row;
      }

      .upload-card:first-child {
        margin-bottom: 10px;

        @media #{$la_sm} {
          margin-bottom: 0px;
        }
      }

      .upload-card {
        .uploaddoctitle {
          width: 192px;
          height: 17px;
          font-family: $rionasans-bold;
          font-size: 13px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.67;
          letter-spacing: normal;
          text-align: left;
          color: #262837;
          margin-bottom: 4px;
        }

        .card-img {
          width: 268px;
          height: 160px;

          @media #{$mobile} {
            width: 340px;
            height: 234px;
          }

          .update-image {
            position: absolute;
            left: 20%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: none;

            @media #{$mobile} {
              display: block;
            }

            .camera-img {
              width: 36px;
              height: 36px;
            }

          }

          .delete-image {
            position: absolute;
            right: -7%;
            top: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            cursor: pointer;
            opacity: 0;

            @media #{$mobile} {
              opacity: 1;
            }
          }

          .upload-progressbar {
            position: absolute;
            left: 50%;
            top: 50%;
            -moz-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            width: 195px;
          }

          .doc-img {
            width: 268px;
            height: 160px;
            opacity: 1;

            @media #{$mobile} {
              width: 340px;
              height: 234px;
            }
          }

        }

        // .card-img:hover .hoverdoc-block
        // {
        //   display: block;
        //   .camera-img
        //       {
        //         width: 76px;
        //         height: 76px;
        //       }
        // }
        .border-box {
          border-radius: 4px;
          border: dashed 1px #d8dde4;

          &.scanning {
            border: dashed 1px #d8dde4 !important;

            .el-loading-spinner {
              top: 40%;
            }
          }

          &.is-malicious {
            border: 1px solid $error-text-color;

            .card-img {
              display: flex;
              justify-content: center;
              align-items: center;

              .insurance-card-virus-tooltip {
                .el-tooltip__popper {
                  width: 160px;
                }
              }
            }

            .malicious-icon {
              width: 30px;
              height: 30px;
            }
          }
        }

        .border-box:not(.scanning):hover {
          border-radius: 9px;
          background-color: rgba(0, 0, 0, 0.7);
          border: 1px solid transparent;

          .delete-image {
            opacity: 1;
          }
        }

        @media #{$mobile} {
          .is-uploaded-image {
            border-radius: 9px;
            background-color: rgba(0, 0, 0, 0.7);
            border: 1px solid transparent;

            .doc-img {
              opacity: 0.2;
            }

          }
        }

        .back-border-box:not(.scanning):hover {
          border-radius: 9px;
          border: dashed 1px #d8dde4;
          background-color: transparent;
        }

        .border-box:not(.scanning):hover .doc-img {
          opacity: 0.2;
        }

        .border-box:hover .update-image {
          cursor: pointer;
          display: block;
        }

        .uplpad-img-radius {
          border-radius: 9px;
          border: 1px solid transparent;
          overflow: hidden;

          &.is-malicious:hover {
            overflow: unset;
          }
        }

        .uploaddoc {
          @media #{$la_sm} {
            margin-right: 20px;
            width: 270px;
          }

          @media #{$la_xsm} {
            margin-right: auto;
            width: 270px;
          }

          @media #{$mobile} {
            margin-right: auto;
            width: 340px;
            height: 234px;
          }

          height: 159px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          .uploaddoc-dropblock {

            display: block;
            z-index: 1;
            position: absolute;
            left: 50%;
            top: 50%;
            -moz-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
          }

          .text-center {
            text-align: center;
          }

          .cloud-img {

            width: 40px;
            height: 40px;
          }

          .uploaddoctextContainerMobile {
            @media #{$la_sm} {
              display: none;
            }

            font-size: 15px;
            text-align: center;
            color: $text-color-V2;

            .uploaddoctextContainerMobileText {
              .uploaddoctextContainerMobileTextBold {
                font-family: $rionasans-bold;
              }
            }

            .uploaddoctextContainerMobileCTA {
              margin-top: 10px;
              color: $focus-border-color-v2;
              text-decoration: underline;
            }
          }

          .uploaddoctextContainerDesktop {
            display: none;

            @media #{$la_sm} {
              display: unset;
            }

            .uploaddoctext {
              width: 159px;
              height: 16px;
              font-family: RionaSans;
              font-size: 11px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              line-height: 1.82;
              letter-spacing: normal;
              text-align: left;
              color: #262837;

              .uploaddoclink {
                color: #40b9ec;
                text-decoration: underline;
              }
            }
          }

          .dochover-text {

            height: 20px;
            font-family: RionaSans-Bold !important;
            font-size: 12px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #ffffff;

          }


        }

        .error {
          font-size: 12px;
          margin-top: 10px;
          display: block;
          color: $error-text-color;
          font-family: RionaSans;

        }

        .error-div {
          width: 268px;
        }


      }
    }
  }
}

#scp-uploaded-doc-action-popover {
  padding: 0px;
  overflow: hidden;

  .arrow {
    display: none;
  }

  &.popover {
    @media #{$la_sm} {
      width: 110px;
      font: normal 13px/1.38 $rionasans-regular;
    }

    width: 140px;
    font: normal 15px/1.38 $rionasans-regular;
    margin-top: 0;
    color: #262837;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
    border: none !important;
    cursor: pointer;
  }

  &:hover {
    border-radius: 8px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.35);
  }

  .popover-content {
    padding: 0px;

    .popovr-segment {
      padding: 4px;
      display: flex;
      align-items: center;
      height: 50px;

      &:hover {
        background-color: #EEEEEE;
      }

      @media #{$la_sm} {
        height: unset;

        &:not(:last-child) {
          margin-bottom: 6px;
        }
      }

      img {
        height: 28px;
        width: 28px;
        margin-right: 8px;

        @media #{$la_sm} {
          margin-right: 1px;
        }
      }
    }
  }
}
