@import '../../../../../styles/vars';

.no-consultation-section {

    @media #{$la_lg} {
      max-width: 812px;
      width: 812px;
      padding: 40px 0px;
      margin:0 auto;
    }

    @media #{$la_sm} {
      padding: 80px 0;
    }
    @media #{$mobile} {
        padding:32px 24px 0
    }
    .info-section{
        margin-bottom: 16px;
        .header{
            color: $light-gray-text-color-v2;
            font-family: $rionasans-bold;;
            -webkit-font-smoothing: antialiased;
            font-size: 19px;
            line-height: 22.8px;
            .access-time-icon{
                margin-right: 8px;
            }
        }
    }
    .body-section{
        font-family: $rionasans-regular;
          font-size: 16px;
          line-height: 20px;
          .contact-number{
            font-family: $rionasans-bold;
            font-size: 16px;
            line-height: 20px;
            color: #262837;
          }
    }
}