@import '../../../../styles/vars.scss';

.input-wrapper {
    width: 100%;
    .el-input{
       .el-input__inner{
           height: 40px;
           padding: 3px 8px;
           font-family: $rionasans-medium;
           -webkit-font-smoothing: antialiased;
           font-size: 14px;
           font-weight: 500;
           line-height: 1.43;
           border: 1px solid $field-border-color-V2;
           color: $text-color-V2;
       }

       .el-input__inner:hover:not(:disabled) {
           border: 1px solid $hover-border-color-v2;
       }

       .el-input__inner:focus:not(:disabled) {
            border: 1.5px solid $focus-border-color-v2;
       }

       .el-input__inner::placeholder {
            color: $field-border-color-V2
        }

       .el-input__inner:focus::placeholder,
       .el-input__inner:hover::placeholder {
           color: $field-border-color-V2;
       }
    }
}

.input-wrapper.input-error-field {
    .el-input {
        .el-input__inner {
            background-color: $error-field-bg-color;
            border: 1px solid #fb3a2e;
            color: $error-text-color-v2;
        }
        .el-input__inner:hover,
        .el-input__inner:focus {
            border:1px solid #fb3a2e;
        }

        .el-input__inner::placeholder,
        .el-input__inner:hover::placeholder,
        .el-input__inner:focus::placeholder {
            color: $error-text-color-v2;
        }
    }

    .input-error {
        font-size: 12px;
        font-family: $rionasans-regular;
        -webkit-font-smoothing: antialiased;
        color: $error-input-error-v2;
        height: 19px;
        height: auto;
    }
}
