@import "../../../styles/vars";
@import "../../../styles/mixins";

.scp-header-container {
  -webkit-font-smoothing: antialiased;
  background-color: #ffffff;
  height: 56px;

  @media #{$media-header-visible} {
    height: 64px;
  }

  .nav-container {
    display: none;
    margin: 0 32px;

    @media #{$media-header-visible} {
      display: flex;
    }

    .logo-container {
      flex: 1 1 20%;
      padding: 12px 0;

      .header-logo {
        height: 40px;
      }
    }

    .navigation-container {
      flex: 1 1 60%;
      margin: 0 auto;
      text-align: center;
    }

    .menu-container {
      flex: 1 1 20%;
      text-align: right;
      align-self: center;
      padding: 12px 0;

      div {
        font-family: $rionasans-regular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: right;
        color: #262837;
        cursor: pointer;
        text-decoration: unset;
        outline: none ! important;
        display: inline-block;
        position: relative;

        &:hover {
          text-decoration: unset;
          color: $purple-500;
        }

        &:focus {
          text-decoration: unset;
          color: $purple-700;

          ul {
            display: block;
          }
        }

        &.user-full-name {
          text-transform: capitalize;
        }

        img {
          margin-left: 8px;
          width: 10px;
        }

        ul {
          list-style: none;
          margin: unset;
          display: none;
          position: absolute;
          min-width: 85px;
          border-radius: 4px;
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
          background-color: #ffffff;
          padding: 4px 0;
          top: 49px;
          right: 0;
          z-index: 999;

          li {
            text-align: left;
            padding: 8px;
            color: #262837;

            a {
              color: #262837;
              text-decoration: none;
            }

            &:hover {
              background-color: $purple-200;

              a {
                color: #262837;
              }
            }
          }
        }
      }
    }

    ul.header-list {
      list-style: none;
      margin: unset;
      padding: unset;
      height: 100%;
      display: flex;
      justify-content: center;

      li {
        display: inline-block;
        margin: 0 12px;
        position: relative;
        align-self: center;

        a {
          font-family: $rionasans-regular;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 2;
          letter-spacing: normal;
          color: #525968;
          outline: none;
          text-decoration: unset;
          padding: 0 4px;

          &:hover,
          &:focus {
            color: $purple-500;
            text-decoration: none;

            .line {
              display: none;
            }
          }

          &:active {
            color: $purple-700;
            text-decoration: none;
          }
        }

        div.line {
          height: 4px;
          background-color: $purple-500;
          display: none;
          position: absolute;
          width: 100%;
          bottom: -18px;
        }

        &.active {
          a {
            color: $purple-500;
          }

          .line {
            display: block !important;
          }
        }
      }
    }
  }
}