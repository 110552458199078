@import '../../../../styles/vars.scss';
.clinical-trail-container {
  min-height: 100vh;
  background-color: #edeff3;
  .scp-soft-rgstrtion-container{
    padding-top: 17px;
    min-height: calc(100vh - 290px);
    .soft-rgstrtion-wrapper{
      margin: 0 auto;
      background: transparent;
        box-shadow: none;
      .grail-quali-container{
        background: #ffffff;
        border-radius: 4px;
        .grail-quali-header{
          padding:32px 40px 0;
          &.v2{
          @media #{$mobile} {
            padding: 32px 16px 0;
          }
        }
          .genome-header-logo{
            border-bottom: 3px solid #EDEFF3;
            margin-bottom: 32px;
            .genome-header-img{
              padding-top: 16px;
              padding-bottom: 16px;
              max-width: 150px;
            }
          }
          .genome-header-title{
            padding: unset;
            text-align: unset;
            .grail-quali-title{
              color: #262837;
              font-weight: 700;
              font-size: 33px;
              line-height: 120%;
              text-align: unset;
            }
          }
        }
        .grail-quali-body{
          padding-top:16px;
          iframe {
            width: 100%;
            border-radius: 16px;
            border: 1px solid #BAC3CE;
            margin-bottom: 64px;
            height: 1450px;
            @media #{$mobile} {
              margin-bottom: 0;
              height: 2550px;

            }
          }
        }
      }
      .skip-step-section {
        @media #{$la_lg} {
          text-align: right;
        }
        .skip-step {
          font: normal 500 19px/1.17 $rionasans-regular;
          color: $light-indigo-V2;
          cursor: pointer;
          text-align: right;
          margin-top: 32px;
          @media #{$la_lg} {
          display: inline-flex;
          }
  
          @media #{$mobile} {
            text-align: center;
            border: 1px solid $light-indigo-V2;
            background: #fff;
            font-size: 23px;
            padding: 14px 0;
            margin: 40px 16px 100px 16px;
            float: none;
          }
        }
  
      }
    }
  }
}
