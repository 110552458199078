@import '../../../../styles/vars';

.forgot-password-page {
  min-height: 100vh;
  width: 100vw;
  color: $text-color-V2;
  background-color: $page-background-color-V2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background: $page-background-color-V2 url("../../../../images/bg-patients.svg") center repeat-x fixed;

  .ribbon-img {
    position: absolute;
    width: 100vw;
  }

  .form-wrapper {
    width: 384px;
    min-height: 549px;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    padding: 32px;
    margin: 0 auto;
    z-index: 1;

    .genome-logo {
      display: inline-block;
      background-image: url('../../../../images/logo.svg');
      background-repeat: no-repeat;
      background-size: contain;
      width: 126px;
      height: 63px;
      margin: 0px 92px;
    }

    .forgot-title {
      font-family: $rionasans-medium;
      font-size: 24px;
      font-weight: 500;
      line-height: 1.42;
      margin: 24px 68px 4px;
      display: inline-block;
      color: $text-color-V2;
      -webkit-font-smoothing: antialiased;
    }

    .forgot-desc {
      font: normal 14px/1.43 $rionasans-regular;
      text-align: center;
      color: $text-color-V2;
      margin-bottom: 32px;
      -webkit-font-smoothing: antialiased;
    }

    .forgot-pass-form {
      .forgot-fields {
        .gm-form-label {
          font-family: $rionasans-medium;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.43;
          color: $text-color-V2;
          -webkit-font-smoothing: antialiased;
        }

        .required::before {
          content: "*";
          color: #e74c3c;
        }

        .gm-form-control {
          margin: 7px 0 0 0;

          input {
            border: 1px solid $field-border-color-V2;
            border-radius: 4px;
            padding: 10px 8px;
          }
        }

        .forgot-button {
          margin: 24px 0;
        }
      }/* End: forgot-fields */
    }/* End: forgot-pass-form */
  }/* End: form-wrapper */
}

// .forgot-password-page {
//   @media (max-width: 350px) {
//     .content-block {
//       padding-left: 10px;
//       padding-right: 10px;
//     }
//   }
//   .forgot-block {
//     width: 100%;
//     max-width: 400px;
//     margin: 30px auto;
//     color: $gray-text-color;
//     .forgot-title {
//       font-size: 18px;
//       text-align: center;
//       margin-bottom: 10px;
//     }
//     .forgot-desc {
//       font-size: 14px;
//       text-align: center;
//       padding: 0 10px;
//     }
//   }
// }
