@import "../../../../styles/vars.scss";

$md: "(min-width: 590px)";
$max-md: "(max-width: 589px)";

.consent-form-container {
  background: $page-background-color-V2 url("../../../../images/gray-ribbon-v2.svg") center/100% no-repeat fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .ribbon-img {
    position: absolute;
    width: 100vw;
  }

  .consent-wrapper {
    max-width: 816px;
    margin: 24px auto;
    background: $white-bg-color-v2;
    z-index: 0;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    -webkit-font-smoothing: antialiased;
    padding: 12px 16px;

    @media (min-width: 700px) {
      padding: 32px 40px 24px 40px;
    }
  }

  .consent-header {
    width: 100%;
    border-bottom: solid 0.5px #edeff3;

    @media (min-width: 700px) {
      border-bottom: solid 2px #edeff3;
    }

    .genome-header-logo {
      width: 100%;
      text-align: center;
      margin-bottom: 24px;

      .genome-header-img {
        max-width: 130px;
      }
    }

  }

  .consent-body {
    width: 100%;

    .agree-container {
      margin-top: 24px;
      border-top: 2px solid #edeff3;

      @media (min-width: 700px) {
        margin-top: 24px;
        padding-top: 24px;
      }

      p {
        font: normal 14px/1.71 $rionasans-regular;
        color: $text-color-V2;
        &.web {
          display: none;

          @media (min-width: 700px) {
            display: block;
          }
        }
        .font-medium {
          font-family: $rionasans-medium;
        }

        .agree-continue {
          font-family: $rionasans-medium;
        }
      }

      .buttons-containers {
        margin-top: 24px;

        .rights-buttons {
          margin: auto;
          width: 100%;
          display: flex;
          flex-direction: column-reverse;
          justify-content: end;

          @media (min-width: 700px) {
            flex-direction: row;
            margin: 0 0 0 auto;
            max-width: 310px;
          }

          .agree-button {
            margin: auto;
            width: 100%;

            button {
              width: 100%;
              text-transform: none;

              @media (min-width: 700px) {
                width: 220px;
                max-width: 220px;
              }
            }

            @media (min-width: 700px) {
              margin: unset;
              max-width: 220px;
              width: 220px;
            }
          }

          .button-back {
            margin: auto;
            color: $purple-500;
            text-decoration: none;
            cursor: pointer;
            text-align: left;
            font: $scp-link-button-font;
            letter-spacing: normal;
            line-height: 50px;

            @media (min-width: 700px) {
              margin-right: 16px;
            }

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}