@import "../../../../styles/vars";


@import "../../../../styles/vars";
@import "../../../../styles/mixins";

.switch-affili-container {
  padding-top: 30px;
  min-height: calc(100vh - 290px);

  > div {
    min-height: initial;
    color: #4a494b;
    display: flex;
    flex-flow: column;
    margin: auto;
    border-radius: none;
    box-shadow: none;
    background-color: $white-bg-color-v2;
    max-width: 768px;
    padding: 40px 0 88px;

    .content-title {
      font-family: $rionasans-light;
      font-size: 27px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.3;
      letter-spacing: normal;
      color: #262837;
      margin-bottom: 36px;
    }

    .content-text {
      font: normal 16px/1.5 $rionasans-regular;
      color: $light-gray-text-color-v2;
      margin-bottom: 24px;

      b {
        font-weight: bold;
      }

      .gm-alert {
        p {
          margin-bottom: 0;
        }
      }

      .checkbox-wrapper {
        padding-top: 15px;
      }

      .save-btn-segment {
        .outer-button {
          text-align: unset;
        }
      }

      > b {
        font-family: $rionasans-bold;
        font-weight: bold;
      }

      > a {
        color: #7e2bca;
      }

      p {
        line-height: 1.63;
        margin-bottom: 30px;
        word-wrap: normal;

        .guardian-label {
          .checkmark {
            position: relative;
            height: 20px !important;
            width: 20px !important;
            background-color: #ffffff;
            border: 1px solid #a0abb9;
            border-radius: 2px;
            margin-right: 9px;
            padding: 1px 2px 5px 1px;

            &:after {
              content: "";
              position: absolute;
              left: 6px;
              top: 2px;
              width: 6px;
              height: 12px;
              border: solid white;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
            }
          }

          input:checked~.checkmark {
            background-color: #712cc1;
            border-color: $purple-500;
          }
        }
      }

      .cc-email {
        color: $purple-500  !important;
      }

      .cc-phone {
        color: $purple-500  !important;
        display: inline-block;
      }
    }

    > .header {

      .genome-header-logo {
        width: 100%;
        text-align: center;
        margin-bottom: 9px;
        max-width: 100%;
        margin-left: 0;

        .genome-header-img {
          padding: 55px 20px;
          max-width: 308px;
        }
      }

      > h1 {
        opacity: 0.73;
        font: normal 300 46px $rionasans-regular;
        text-align: center;
        color: $text-color-V2;
      }

      > p {
        opacity: 0.6;
        font: normal 500 15px/1.6 $rionasans-medium;
        text-align: center;
        color: $text-color-V2;
        margin-top: 1px;
        margin-bottom: 48px;
      }

      > div {
        max-width: 618px;
        margin-left: 184px;


      }
    }

    .footer-btn-segment {
      margin-bottom: 44px !important;

      .btn {
        width: fit-content;
        padding: 0 105px;
      }
    }

    .save-btn-segment {
      margin-bottom: 44px !important;

      .btn {
        width: fit-content;
        padding: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .switch-affili-container {
    >div {
      padding: 40px 50px 88px;
    }
  }
}

@media (max-width: 767px) {

  .switch-affili-container {

    >div {
      padding: 0 15px;

      .header {
        .genome-header-logo {
          .genome-header-img {
            padding: 27px 20px 39px 20px;
            max-width: 226px;
          }
        }
      }

      .content-title {
        font-size: 20px;
        margin-bottom: 24px;
      }

      .content-text {
        font-size: 14px;
        line-height: 1.86;

        p {
          margin-bottom: 19px;
        }
      }

      .footer-btn-segment {
        margin-top: 40px !important;
        margin-bottom: 48px;
        margin-left: 15px;
        margin-right: 15px;

        .btn {
          font-size: 16px;
          width: 100%;
          padding: 0;
        }
      }

      .save-btn-segment {
        margin-top: 40px !important;
        margin-bottom: 48px;
        margin-left: 15px;
        margin-right: 15px;

        .btn {
          font-size: 16px;
          width: 100%;
          padding: 0;
        }
      }
    }
  }
}
