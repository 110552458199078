@import "../../../../../styles/vars";
.scp-dropzone-container {
  .dropzone-rectangle {
    height: 200px;
    border: 1px solid $scp-border-color;
    border-style: dashed;
    border-radius: 4px;
    background-color: $white-bg-color-v2;
    display: flex;
    align-items: center;
    .dropzone-tip {
      margin: auto;
      max-width: 220px;
      color: $body-text-color;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      .link {
        color: $link-color;
        cursor: pointer;
      }
      .dropzone-tip-wrapper {
        .dropzone-tip-img {
          img {
              height: 42px;
              width: 42px;
          }
          @media #{$la_sm} {
            img {
              height: 32px;
              width: 32px;
            }
          }
        }
        .dropzone-tip-text-mobile {
          @media #{$la_sm} {
            display: none;
          }
          margin-top: 7px;
          font: normal 15px $rionasans-regular;
          color: $text-color-V2;
          .dropzone-tip-link {
            color: $focus-border-color-v2;
            text-decoration: underline;
          }
        }
        .dropzone-tip-text {
          display: none;
          @media #{$la_sm} {
            display: unset;
          }
          margin-top: 7px;
          font: normal 11px/20px $rionasans-regular;
          color: $text-color-V2;
          .dropzone-tip-link {
            color: $focus-border-color-v2;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .dropzone-uploaded-files {
    list-style: outside none none;
    padding: 0;
    margin: 18px 0 0 0;
    .uploaded-file {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      .file-progress-container {
        display: inline-block;
        flex-basis: 100%;
        color: $link-color;

        font-size: 18px;
        line-height: 24px;
        @media #{$la_md} {
          flex-basis: 50%;
          width: 100%;
        }
        .dropzone-reject {
          background-color: $light-gray-text-color;
          .dropzone-tip {
            display: none;
          }
        }
        .dropzone-progressbar {
          position: relative;
          top: -2px;
          height: 7px;
          width: 77%;
        }
        .filename {
          padding-left: 3px;
        }
        .file-link {
          cursor: pointer;
          text-decoration: none;
          color: $link-color;
          &:visited,
          &:hover {
            color: $link-color;
          }
        }
        .fa-times-circle {
          margin-left: 6px;
          margin-top: -3px;
          vertical-align: middle;
          cursor: pointer;
        }
      }
      .file-progress-container.file-error {
        color: $error-text-color;
        .file-link {
          color: $error-text-color;
        }
      }
      .file-progress-container.removing-file {
        color: $light-gray-text-color;
        .file-link {
          color: $light-gray-text-color;
          &:visited,
          &:hover {
            color: $light-gray-text-color;
          }
        }
      }
      .child-fields {
        display: inline-block;
        flex-basis: 100%;
        @media #{$la_md} {
          flex-basis: 50%;
          margin-top: -12px;
        }
      }
    }
  }
  .error {
    font-size: 14px;
    line-height: 1;
    color: $error-text-color;
  }
}
