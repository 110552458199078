@import ' ../../../../../../styles/vars';

.natera-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .natera-wrapper {
    max-width: 830px;
    margin: auto;
    background: $white-bg-color-v2;
    z-index: 0;
    border-radius: 4px;
    -webkit-font-smoothing: antialiased;
    padding: 12px 16px;

    @media (min-width: 700px) {
      padding: 32px 40px;
    }

    @media #{$mobile} {
      padding: 16px 0;
    }

    .natera-header {
      width: 100%;
      border-bottom: solid 0.5px #edeff3;

      @media (min-width: 700px) {
        border-bottom: solid 2px #edeff3;
      }

      .genome-header-logo {
        width: 100%;
        text-align: center;
        margin-bottom: 24px;

        .genome-header-img {
          max-width: 130px;
        }
      }

    }

    .natera-body {
      width: 100%;
      padding: 16px;

      .natera-story {
        margin-bottom: 16px;
        padding-bottom: 16px;
        -webkit-font-smoothing: antialiased;

        @media screen and (min-width: 425px) {
          margin: 0;
          border-bottom: 1px solid #EDEFF3;
        }

        @media #{$mobile} {
          padding-top: 24px;
          margin-bottom: 32px;
        }

        h2 {
          font: normal normal 32px / 1.44 $rionasans-bold;
          color: #262837;
          margin-bottom: 40px;
          margin-top: 32px;

          @media #{$mobile} {
            font-size: 23px;
            line-height: 27.6px;
            margin-bottom: 32px;
            margin-top: 16px;
          }
        }

        p {
          font: normal 16px/20px $rionasans-regular;
          margin: 8px 0 24px 0;
        }
      }

      .grey-text {
        color: $light-gray-text-color-v2 !important;
      }

      .agree-container {
        @media (min-width: 700px) {}

        .buttons-containers {
          margin-top: 4px;

          @media (min-width: 700px) {
            margin-top: 40px;
          }

          .action-buttons {
            display: flex;
            justify-content: flex-end;

            @media #{$mobile} {
              flex-direction: column-reverse;
            }

            >button {
              min-width: 200px;
              height: 40px;
              margin-bottom: 8px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 6px;
              border: 1px solid $light-indigo-V2;
              color: $light-indigo-V2;
              font-family: $rionasans-bold;
              font-size: 16px;
              background-color: $white-bg-color-v2;
              cursor: pointer;

              @media #{$mobile} {
                min-width: unset;
                width: 100%;
                margin-bottom: 16px;
              }

              &:disabled {
                opacity: 0.35;
              }

              &.btn-back {
                margin-right: 16px;

                &:disabled {
                  cursor: not-allowed;
                }

                &:hover:not(:disabled) {
                  background-color: rgba(123, 38, 201, 0.15);
                }

                &:active {
                  background-color: rgba(123, 38, 201, 0.3) !important;
                }
              }

              &.btn-next {
                background-color: $light-indigo-V2;
                color: $white-bg-color-v2;

                &:disabled {
                  cursor: not-allowed;
                }

                &:hover:not(:disabled) {
                  background-color: $scp-button-hover-color;
                }

                &:active {
                  background-color: $purple-600 !important;
                }
              }
            }
          }
        }
      }
    }

  }


}