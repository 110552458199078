@import '../../../../styles/vars';

.scp-video-modal-dialog {
    border-radius: 0px;
    .modal-content {
        border: none;
    }
    .modal-body {
        padding: 0;
        .close-video-btn {
            font: 13px/1.38 $rionasans-medium;
            font-weight: 500;
            color: $white-bg-color-v2;
            position: absolute;
            right: 0;
            cursor: pointer;
        }
        iframe {
            width: 100%;
            height: 264px;
            margin-top: 8px;
        }
        @media #{$la_md} {
            iframe{
                max-width: 1061px;
                height: 630px;
            }
        }
    }
}
