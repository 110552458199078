@import '../../styles/vars';

.consent-story {
    margin: 0;
    padding: 24px 0 0;
    color: $text-color-V2;
    -webkit-font-smoothing: antialiased;

    @media screen and (min-width: 425px) {
      margin: 0;
    }
    h2 {
        font: normal bold 32px/1.44 $rionasans-bold;
        color: $text-color-V2;
        margin-bottom: 40px;
        &.bullet-point {
            font: normal bold 28px/1.43 $rionasans-bold;
        }
        &.bullet-point-v2 {
          font: normal bold 28px/1.43 $rionasans-bold;
          color: $light-gray-text-color-v2;
          @media #{$mobile} {
              font-size: 19px;
          }
      }
    }
    h3 {
        font: normal bold 24px/1.42 $rionasans-bold;
        margin: 24px 0 8px;
        max-width: 100%;
        color: $text-color-V2;
    }
    .bullet-point {
        font: normal bold 18px/1.39 $rionasans-bold;
        margin: 24px 0 8px;
    }
    .bullet-point-v2 {
      font: normal bold 18px/1.39 $rionasans-bold;
      margin: 24px 0 8px;
      color: $light-gray-text-color-v2;
  }
    p {
        font: normal 14px/1.43 $rionasans-regular;

        margin: 8px 0 40px 0;
        &.bullet-descrption{
          color: $light-gray-text-color-v2;
          @media #{$mobile} {
            font-size: 16px;
        }
        }
        &.acknowledge-title {
            margin: 24px 0 8px;
            font-family: $rionasans-bold;
        }
        .font-bold {
            font-weight: bold;
        }
        @media #{$mobile} {
            a {
                padding: 10px 0;
            }
            font-size: 16px;
        }
    }
    p:last-child {
        margin-bottom: 0;
    }

    ol.acknowledge {
        list-style-type: decimal;
        padding-left: 13px;
        font: normal 14px/1.43 $rionasans-regular;
        color: #000000;
        li {
            padding-top: 12px;
        }
        ol {
            list-style-type: lower-alpha;
        }
    }
    ol.bullet-descrption{
      color: $light-gray-text-color-v2;
      @media #{$mobile} {
        font-size: 16px;
  }
}
    a {
      color: #00aeef;
    }
  }

  .grey-text{
    color: $light-gray-text-color-v2 !important;

  }
