@import '../../../styles/vars';
.page-title {
  background-color: #EFF5F9;
  text-align: center;
  overflow: auto;
  padding: 12px 10px;
  
  .title {
    font-size: 24px;
    line-height: 28px;
    color: $caption-color;
    text-transform: uppercase;
    margin: 15px auto 5px;
    font-weight: 400;
  }
  .desc {
    max-width: 500px;
    color: $gray-text-color;
    margin: 5px auto 15px;
    font-size: 14px;
    line-height: 20px;
  }
}
