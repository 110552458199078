@import '../../../../../styles/vars.scss';

$paddingLeft: 50px;

.scp-schedule-wizard.scp-confirm-form {
  padding: 0 15px !important;
  margin: 31px 0 31px 0 !important;

  @media #{$la_lg} {
    padding: 40px 0px !important;
    margin: 0 auto !important;
  }

  .gm-service-text {
    margin-bottom: 0;
    color: $text-color-V2;
    letter-spacing: normal;
    font: $scp-review-page-value-font;
  }

  .gm-service-text__grey {
    letter-spacing: normal;
    color: #525968;
    font: $scp-review-page-label-font;
  }

  .confirm-form-content {
    position: relative;

    @media #{$la_md} {
      padding: 0 0px 0 0;
    }

    .confirm-form-items {
      display: flex;
      flex-flow: row wrap;
      margin-bottom: 25px;

      @media #{$la_sm} {
        max-height: 270px;

        &.confirm-form-items__reduced {
          max-height: 255px;
        }
      }

      .confirm-content-item {
        margin: 10px 80px 10px 0px;
        width: 100%;
        text-align: left;
        white-space: pre;

        @media #{$la_sm} {
          width: 309px;
        }
      }
    }
  }

  .fine-print {
    padding: 26px 0px 32px 0px;
    letter-spacing: normal;
    color: #7b8291;
    font: $scp-review-page-label-font;
    display: flex;

    span.star {
      color: $purple-500;
      letter-spacing: normal;
      font: $scp-insurance-star-font;
      margin-right: 8px;
      -webkit-font-smoothing: antialiased;
    }
  }

  .controls-section {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    padding: 0 10px;

    @media #{$la_md} {
      padding-left: $paddingLeft;
    }
  }

  .cancellation-policy-section {
    margin-top: 0px;
    padding: 27px 0px 40px 0px;
    border-top: 1px solid #edeff3;
    border-bottom: 1px solid #edeff3;
    display: flex;
    flex-direction: column;

    .cancellation-policy-header {
      height: 11px;
      width: 100%;
      font: normal bold 12px/1.67 $rionasans-bold;
      letter-spacing: normal;
      color: #525968;
    }

    .cancellation-policy-body {
      font: $scp-review-page-label-font;
      letter-spacing: normal;
      color: #7b8291;
      margin-top: 6px;
    }

    .visit-amount-section {
      margin-bottom: 24px;

      .total-fee-section {
        font: $scp-review-page-value-font;
        color: $text-color-V2;
        letter-spacing: normal;

        @media #{$la_sm} {
          width: auto;
        }

        .visit-fee {
          padding-left: 7px;
          font: $scp-review-page-price-font;

          .asterisk {
            display: inline-block;
            padding-left: 2px;
          }
        }
      }
    }
  }

  .narrow-fields-container {
    max-width: 770px;
    margin: 20px auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .review-form {
    .form-row {
      width: 100%;
      display: flex;
      flex-direction: column;

      @media #{$la_md} {
        flex-direction: row;
      }

      .form-col {
        width: 100%;
        margin: 10px 80px 19px 0px;

        @media #{$la_md} {
          width: 309px;
        }

        align-items: left;

        .required:before {
          content: "*";
          color: $required-red-color;
          font-size: 14px;
          margin-left: -5px;
        }

        .label-section {
          width: 100%;
          font: $label_font;
          letter-spacing: normal;
        }

        .input-wrapper .el-input .el-input__inner,
        .select-wrapper .el-select .el-input .el-input__inner {
          height: 32px;
          font: $scp-form-element-font;
          letter-spacing: normal;
        }
      }
    }

    .gm-form-div.error {
      .gm-form-label {
        display: block;
        text-align: left;
        color: red !important;
      }
    }

    .narrow-field {
      width: 370px;
    }

    .font-bold {
      font-weight: bold;
    }
  }

  .button.button-next.book-appointment {
    [data-pp-btn='true'] {
      padding: 0 0 !important;
    }
  }
  &.ninetyDays {
    .gm-select-service__header {
      margin-bottom: 20px !important;
      font: $scp-title-font-V3  !important;
    }
    .confirm-form-content {
      .confirm-form-items {
        margin-bottom: 20px;
        .confirm-content-item {
          margin: 12px 80px 12px 0px;
          .gm-service-text__grey {
            color: $light-gray-text-color-v2;
            font: $scp-review-page-label-font-V3;            
          }
        }
      }
    }
    .cancellation-policy-section {
      padding: 32px 0px;
      border-top: 1px solid #d8dde4;
      border-bottom: 1px solid #d8dde4;
      
      .cancellation-policy-header {
        font: normal bold 13px/15px $rionasans-bold;
        color: $text-color-V2;
      }

      .cancellation-policy-body {
        font: normal bold 13px/15px $rionasans-medium;        
        color: $light-gray-text-color-v2;
      }
      
      .visit-amount-section {
        margin-bottom: 16px;
        

        .total-fee-section {
          font: $scp-review-page-value-font-V3;
          color: $light-gray-text-color-v2;

          .visit-fee {
            color: $text-color-V2;
          }
        }
      }  
    }
    .buttons {
      justify-content: flex-end;
      .button-back {
        margin-right: 56px;
        height: 40px;
        width: 200px;
        background-color: $white-bg-color-v2 !important;
        border: 1px solid $purple-500;
        text-align: center !important;
        font: $scp-submit-button-font-V3 !important;
        text-transform: capitalize !important;
        
        &:disabled {
          background-color: $purple-200 !important;
        }    
        &:hover:not([disabled]) {
          background-color: rgba(123, 38, 201, 0.15) !important;          
          text-decoration: none;
          border: 1px solid $purple-500 !important;
          color: $light-indigo-V2 !important;
        }
        &:focus:not([disabled]) {
          background-color: $purple-500  !important;
        }
      
        &:active:not([disabled]) {
          background-color: rgba(123, 38, 201, 0.3) !important;
        }
      }
      .button.button-next {
        margin-right: 0 !important;
        .btn-default {
          height: 40px !important;
          width: 200px !important;
          font: $scp-submit-button-font-V3 !important;
        }
      }
    }
  }
}

/* For Mobile Device */
.mobile-schedule-container {
  .scp-schedule-wizard.scp-confirm-form {
    .confirm-form-items {
      margin-bottom: 0;
    }

    .review-form {
      .buttons {
        margin-top: 16px;
        flex-direction: column-reverse;

        > div {
          width: 100%;
        }

        .button.button-next {
          margin-right: 0;
        }

        .button.book-appointment {
          margin-bottom: 6px;
        }

        .button.button-next .btn-default {
          height: 48px !important;
          width: 100% !important;
          border-radius: 24px;
          font: normal 500 16px $rionasans-medium  !important;
        }

        .button-back {
          margin-right: 0;
          height: 48px !important;
          width: 100% !important;
          border: 1px solid $purple-500;
          border-radius: 24px;
          font: normal 500 16px $rionasans-medium  !important;
          display: flex !important;
          justify-content: center;
          align-items: center;
        }
      }
    }
    &.ninetyDays {
      @media #{$mobile} {
        .review-form {
          .buttons {
            .button.button-next .btn-default {
              border-radius: 6px !important;
              height: 40px !important;
            }
            .button-back {
              border-radius: 6px !important;
              height: 40px !important;
            }
          }
        }
      }
    }
  }
}

/* Error message popup for book appointment */
.modal.book-appointment-error-modal {
  .modal-dialog {
    min-height: 172px;
    width: 368px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35);
    color: $text-color-V2;
    font: normal 14px $rionasans-medium;
    -webkit-font-smoothing: antialiased;

    .modal-content {
      border: none;

      .modal-header {
        display: flex;
        height: 56px;
        padding: 16px;
        border-bottom: 1px solid #edeff3;
        font: normal bold 16px $rionasans-bold;
        -webkit-font-smoothing: antialiased;

        .warning-icon {
          margin-right: 8px;
        }

        .modal-close-button {
          font-size: 18px;
          top: 4px;
          opacity: 1;
        }
      }

      .modal-body {
        padding: 24px 20px 32px;

        .cc-contact-number {
          font-weight: bold;
        }
      }
    }
  }
}
