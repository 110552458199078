@import '../../../../styles/vars';
@import '../../../../styles/mixins';
.scheduling-page {
  color: #4a494b;
  display: flex;
  flex-flow: column;
  .clinician-container {
    margin: 300px auto 0 20px;
  }
  .registration-wizard {
    .gm-select-service__header {
      display: inline-block;
      color: #4a494b;
      font-family: $sans;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      text-align: center;
  
      margin-bottom: 20px;
      padding-left: 10px;
  
      @media #{$la_sm} {
        text-align: left;
        font-size: 18px;
      }
      @media #{$la_md} {
        padding-left: 50px;
      }
    }
    .form-divider {
      border-top: 1px solid #ebebeb;
    }
    .description-block {
      max-width: 860px;
      margin: 44px 10px 27px;

      text-align: left;
      color: $text-color-brown;
      .description {
        margin-top: 18px;
        padding: 15px 20px 0;
        border-top: 1px solid #ebebeb;
        @media #{$la_sm} {
          padding-left: 60px;
        }
      }
      h3 {
        font-size: 19px;

        letter-spacing: 0.1px;
        color: $text-color-brown;
        padding: 0 20px;
        @media #{$la_sm} {
          padding-left: 6px;
        }
      }
    }
    .buttons {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: 100%;
      font-size: 13px;
      margin: 25px auto 30px;
      padding-top: 13px;
      align-items: center;

      @media #{$la_md} {
        float: none;
      }

      .button {
        min-width: 80px;
        text-transform: uppercase;
        display: inline-block;
        flex-basis: 100%;
        text-align: center;
        margin-left: 10px;
        @media #{$la_sm} {
          flex-basis: 50%;
          &:only-child {
            flex-basis: 100%;
          }
        }
        @media #{$la_sm} and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          flex-basis: 48%;
        }
        @media #{$la_md} {
          padding: 0 5px;
        }
        &.button-back {
          background-color: white;
          color: #07b1f0;
          text-decoration: none;
          cursor: pointer;
          padding: 5px;
          font-size: 16px;
          order: 1;
          margin: 0 auto;
          text-align: left;
          display: none;
          @media (min-width: 700px) {
            display: block;
          }
        }
        &.button-next {
          order: 1;
          @media #{$la_sm} {
            order: 2;
            margin: 0 auto;
            text-align: right;
          }
        }
        &.button-next__wide {
          .btn {
            padding: 0 45px;
          }
        }
      }
    }
  }

  .reservation-timer {
    display: none;
    @media #{$la_lg} {
      display: flex;
    }
  }
}
