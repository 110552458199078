@import '../../../../styles/vars.scss';
$color1: #a0abb9;
//$color2: $purple-500;

.scp-gm-radio-card-control {
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;

  .gm-radio-group {
    display: flex;
    flex-direction: column;
    margin-top: 0px;

    .radio {
      border-radius: 4px;
      border: solid 1px #bac3ce;
      margin-bottom: 12px;
      width: 100%;
      padding: 16px;
      cursor: pointer;

      input[type='radio'] {
        position: absolute;
        opacity: 0;
        z-index: -6;

        +.radio-label {
          padding-left: 0px;
          letter-spacing: normal;
          text-align: left;
          color: #262837;
          font-family: RionaSans;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;

          &:before {
            content: '';
            background: #fff;
            border-radius: 100%;
            border: 1px solid $color1;
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-top: 3px;
            margin-right: 16px;
            position: relative;
            vertical-align: top;
            cursor: pointer;
            text-align: center;
            transition: all 250ms ease;

            @media (min-width: 700px) {}
          }
        }

        &:checked {
          &:before {
            border: 1px solid $purple-500;
          }

          +.radio-label {
            &:before {
              background-color: $purple-500;
              box-shadow: inset 0 0 0 3px #fff;
            }
          }
        }

        &:focus {
          +.radio-label {
            &:before {
              outline: none;
              border-color: $purple-500;
            }
          }
        }

        &:disabled {
          +.radio-label {
            &:before {
              box-shadow: inset 0 0 0 4px #fff;
              border-color: darken($color1, 25%);
              background: darken($color1, 25%);
            }
          }
        }

        +.radio-label {
          &:empty {
            &:before {
              margin-right: 0;
            }
          }
        }
      }

      &:hover {
        border-radius: 4px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
        border: solid 1px #edeff3;

      }

      &:active {
        border-radius: 4px;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
        border: solid 1px #edeff3;
      }

      &.selected {
        border-radius: 4px;
        border: solid 1px $purple-500;

        &:hover {
          border-radius: 4px;
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
          border: solid 1px $purple-500;
        }
      }

      &.error {
        border-radius: 4px;
        border: solid 1px #e74c3c;

        &:hover,
        &:active {
          border: solid 1px #e74c3c;
        }

        input[type='radio'] {
          +.radio-label {
            &:before {
              border: 1px solid #f0968d;
            }
          }

          &:checked {
            &:before {
              border: 1px solid #f0968d;
            }

            +.radio-label {
              &:before {
                background-color: #e74c3c;
                box-shadow: inset 0 0 0 3px #fff;

              }
            }
          }
        }
      }
    }

    .radio-button-description {
      display: inline-block;
      margin-left: 36px;
      margin-top: 4px;
      font: $scp-link-button-font;
      cursor: pointer;
      letter-spacing: normal;
      text-align: left;
      color: #262837;

      strong {
        font-weight: bold;
      }

    }

    .radio-label-row {
      width: 100%;
    }

    .radio-desc-row {
      width: 100%;
    }

    .radio-error-row {
      border-radius: 4px;
      background-color: #f4a4a5;
      margin-top: 8px;
      padding: 8px;
      font-family: RionaSans;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: center;
      color: #400404;

    }
  }

  .gm-radio-control__invalid {
    font-size: 12px;
    line-height: 12px;
    color: rgb(244, 67, 54);
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;

  }
}