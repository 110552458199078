@import '../../../styles/vars';

.controlled-button-group {
  .controlled-button {
    min-width: 145px;
    height: 45px;
    border-color: rgba($text-color-brown, 0.5);
    outline: none;
    &:active {
      outline: none;
      background-color: #EFF5F9;
    }
    &:hover {
      background-color: #EFF5F9;
    }
    @media #{$la_xsm} {
      min-width: 165px;
      width: auto;
    }
  }
  .controlled-button.controlled-button__active {
    color: $landing-background-blue-color;
    border-color: $link-color;
    background: $link-color;
  }
}
