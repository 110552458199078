@import '../../../../../../styles/vars';
$md: '(min-width: 500px)';
.scp-appointment-info-with-yellow-hint {
  margin: auto;
  text-align: left;
  .install-zoom {
    .download-icon {
      margin-right: 15px;
    }
    .install-zoom-icon {
      color: #7d7d7d;
      font-size: 28px;
      padding-right: 25px;
      vertical-align: middle;
    }
    .install-zoom-text {
      display: inline-block;
      line-height: 22px;
      vertical-align: middle;
      @media #{$mobile} {
        a {
          padding: 10px 0;
        }
      }
    }
    .free-space {
      height: 10px;
    }
  }
  .switch-to-phone-block {
    padding: 16px 0 0;
    font: normal 14px/1.36 $rionasans-regular;
    -webkit-font-smoothing: antialiased;
    @media #{$mobile} {
      a {
        padding: 10px 0;
      }
    }
  }
  .by-phone {
    display: flex;
    align-items: center;
    .phone-icon {
      margin-right: 15px;
    }
  }
  .scp-gm-link {
    color: $focus-border-color-v2;
    cursor: pointer;
    &:hover {
      color: $focus-border-color-v2;
    }
  }
}

.no-appointments {
  .text {
    font-size: 14px;
    padding: 30px 20px 20px;
    margin: auto;
    max-width: 300px;
    @media #{$md} {
      padding: 50px 20px 30px;
      font-size: 18px;
      max-width: inherit;
    }
  }
}

.scp-cancellation-policy-section {
  margin-top: 52px;
  padding-top: 24px;
  padding-bottom: 20px;
  border-top: 1px solid #d8dde4;
  .cancellation-policy-header {
    height: 17px;
    font: normal bold 12px/1.67 $rionasans-bold;
    -webkit-font-smoothing: antialiased;
    color: $light-gray-text-color-v2;
  }
  .cancellation-policy-body {
    font: normal 12px/1.33 $rionasans-regular;
    color: $body-text-color-v2;
    -webkit-font-smoothing: antialiased;
  }
}
