@import '../../../styles/vars';
$green: #86ca48;
$blue: #00b2ed;

[data-pp-btn='true'] {
  background-color: $light-indigo-V2  !important;
  width: 320px;
  min-width: 160px;
  height: 48px;
  line-height: 48px;
  padding: 0 30px;
  color: white;
  border-radius: 6.5px;
  border: none;
  outline: none;
  font: normal bold 16px/1.38 $rionasans-bold;
  text-transform: capitalize;
  -webkit-font-smoothing: antialiased;

  &:hover,
  &:focus,
  &:active {
    outline: none !important;
    border: none !important;
    color: white !important;
  }

  &:hover:not([disabled]) {
    background-color: $purple-400  !important;
  }

  &:focus:not([disabled]) {
    background-color: $purple-500  !important;
  }

  &:active:not([disabled]) {
    background-color: $purple-600  !important;
  }

  &[data-pp-btn-type='more'],
  &[data-pp-btn-type='signin'] {
    background-color: $blue  !important;
  }

  &[data-pp-btn-size='large'] {
    height: 50px;
    line-height: 50px;
    padding: 0 40px;
    min-width: 150px;
  }

  &[data-pp-btn-type='white'] {
    background-color: #ffffff !important;
    color: $link-color  !important;
  }

  &[data-pp-btn-type='signin'] {
    border-radius: 4px;
    min-width: 150px;
    background-color: $blue  !important;

    @media (min-width: 1024px) {
      min-width: 190px;
    }
  }

  &[data-pp-btn-type='landing'] {
    border-radius: 4px;
    background-color: $blue  !important;
    font-size: 16px;
    text-transform: none;

    min-width: 220px;
  }

  &[disabled],
  &.disabled {
    opacity: 0.35;
  }
}


.outer-button {
  text-align: center;

  .button-link {
    display: inline-block;
    text-decoration: none;
  }
}

.outline-button {
  border-radius: 6.5px;
  border: solid 1px $purple-500  !important;
  background-color: #ffffff !important;
  color: $purple-500  !important;
  font: $scp-submit-button-font;
  text-align: center;
}