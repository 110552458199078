@import '../../../../styles/vars.scss';


    .select-wrapper{
        width: 100%;
        @media #{$mobile} {
            height: 40px;
        }
        .el-select{
            width: 100%;
            @media #{$mobile} {
                height: 40px;
            }
            .el-input{
                width: 100%;
                @media #{$mobile} {
                    height: 40px;
                    input {
                        height: 40px !important;
                    }
                }
                .el-input__inner{
                    height: 40px;
                    padding: 3px 8px;
                    font-family: $rionasans-medium;
                    -webkit-font-smoothing: antialiased;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 1.43;
                    border: 1px solid $field-border-color-V2;
                }
                .el-input__inner::placeholder{
                    color: $field-border-color-V2
                }
                .el-input__inner:hover{
                    border: 1px solid #70cbf1;
                }
                .el-input__inner:focus{
                  border: solid 1.5px #40b9ec;
                }

                .el-input__inner:focus::placeholder, .el-input__inner:hover::placeholder{
                    color: $field-border-color-V2;
                }
            }
            .el-select-dropdown {
                font-family: $rionasans-medium;
                -webkit-font-smoothing: antialiased;
                .el-select-dropdown__item {
                    color: $text-color-V2;
                }
            }            

            &.multi-select .el-input input.el-input__inner {
                min-height: 32px !important;
                max-height: 40px !important;
                overflow-y: auto;
            }
              
            &.multi-select .el-input input.el-input__inner:hover {
                border: solid 1px #70cbf1;
            }
            
            &.multi-select .el-input input.el-input__inner:active {
                border: solid 1.5px #40b9ec;
            }
            
            &.multi-select .el-select__tags {
                top: unset !important;
                transform: unset !important;
                max-height: 37px;
                overflow-y: auto;
                margin: 1px 0px 0px 2px;
                background: #FFFFFF;
                .el-select__tags-text {
                    font: $label_font;
                }
            }
            
            &.multi-select .el-select__tags .el-tag {
                margin: 7px 0px 3px 6px;
            }
            
            &.multi-select .el-select__tags .el-input {
                max-height: 60px;
            }
        }
    }

.select-wrapper.input-error-field{
    .el-input{
        .el-input__inner{
            background-color: rgba(254, 145, 137, 0.5);
            border: 1px solid #fb3a2e;
            color: #fe5c50;
        }
        .el-input__inner:hover,
        .el-input__inner:focus
        {
            border:1px solid #fb3a2e;
        }
        .el-input__inner::placeholder,
        .el-input__inner:hover::placeholder,
        .el-input__inner:focus::placeholder{
            color: #fe5c50;

        }
        .el-input__icon{color:#fe5c50;}
    }
    .input-error {
        font-size: 12px;
        color: #ff0000;
        font-family: $rionasans-regular;
        -webkit-font-smoothing: antialiased;
    }
}

