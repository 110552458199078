@import '../../../styles/vars';

.forgot-pass-form {
  -webkit-font-smoothing: antialiased;
  
  .label-field {
    font: normal 500 14px/1.43 $rionasans-medium;
    color: $text-color-V2;
    margin-bottom: 7px;    
  }

  .required::before {
    content: '*';
    color: $required-red-color;
    font-size: 14px;
    margin-left: -5px;
  }
  
  .input-section {
    input {
      font: normal 16px/1.71 $rionasans-regular;
    }    

    .gm-form-control.email {
      padding: 0 1px;
    } 
  }
  
  .gm-form-field {
    margin-top: 8px !important;
    .error-label {
      font: normal 12px $rionasans-regular;
      color: $error-input-error-v2 !important;
      height: 19px !important;
    }
  }

  .reset-button {
    margin: 24px 0;
    button {
      width: 100%;
    }
  }

  .form-inline-center {
    text-align: center;
    .back-signin {
      color: $light-indigo-V2;
      cursor: pointer;
      font: normal 500 14px/1.71 $rionasans-medium;       
      @media #{$mobile} {
        padding: 10px 0;
      }
    }
  }

  .gm-form-label {
    font: normal 500 14px/1.43 $rionasans-medium;
    color: $text-color-V2;
  }
}

// .forgot-pass-form {
//   input {
//     font-size: 16px;
//   }
//   .forgot-fields {
//     padding: 20px 0 0 0;
//     max-width: 303px;
//     margin: auto;
//     text-align: left;
//     overflow-x: hidden;
//     .gm-form-control.email {
//       padding: 0 1px;
//     }
//     .gm-form-label {
//       color: #9b9b9b;
//     }
//     .forgot-button {
//       margin: 20px 0;
//       button {
//         width: 100%;
//       }
//     }
//     .form-inline-center {
//       text-align: center;
//       .back-signin {
//         text-decoration: none;
//         color: #00aeef;
//         cursor: pointer;
//       }
//     }
//   }
// }
