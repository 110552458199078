@import "../../../../styles/vars";

.scp-stepspanel-container {
  -webkit-font-smoothing: antialiased;
  padding-left: 32px;
  padding-top: 104px;

  .steps-container {
    .step-item {
      font: $scp-review-page-value-font;
      line-height: 1.36;
      letter-spacing: normal;
      color: $body-text-color-v2;
      margin-bottom: 24px;
      cursor: pointer;
      text-transform: capitalize;
      max-width: 130px;

      &.active {
        font-family: $rionasans-bold;
        color: $light-indigo-V2;
      }
    }
  }
}
