@import "../../styles/vars.scss";

.no-access {
	margin: 0;
	padding: 0 15px;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #f4f4f9;
	color: $text-color-V2;

	.noaccesstext {
		font: $no-access-label-font;
		text-align: center;
		max-width: 800px;
	}

	.noaccess_goback {
		text-decoration: underline;
		color: $light-indigo-V2;
		cursor: pointer;
	}
}