@import "../../../../styles/vars.scss";

$md: "(min-width: 590px)";
$max-md: "(max-width: 589px)";

.scp-email-verification-soft-reg-container {
  .scp-soft-rgstrtion-container {
    background: $page-background-color-V2 url('../../../../images/gray-ribbon-v2.svg') center repeat-x fixed;
    padding-top: 0px;
    min-height: calc(100vh - 290px);

    @media #{$la_lg} {
      padding: 17px 0;
    }

    .soft-rgstrtion-wrapper {
      margin: 0 auto;
      background: $white-bg-color-v2;
      border-radius: 4px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      -webkit-font-smoothing: antialiased;

      @media #{$la_lg} {
        width: 1020px;
      }

    }
  }
}

.grail-quali-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  min-height: calc(100vh - 290px);

  .grail-quali-wrapper {
    max-width: 816px;
    margin: auto;
    z-index: 0;
    -webkit-font-smoothing: antialiased;
  }

  .grail-quali-header {
    width: 100%;
    padding: 70px 24px 0;

    .genome-header-logo {
      width: 100%;
      text-align: center;
      max-width: 100%;
      margin-left: 0;

      .genome-header-img {
        padding: 55px 20px;
        max-width: 308px;
      }
    }

    .genome-header-title {
      width: 100%;
      text-align: center;
      padding-bottom: 50px;

      @media #{$la_md} {
        padding: 0 108px 10px 108px;
      }

      .grail-quali-title {
        font-family: $rionasans-light;
        -webkit-font-smoothing: antialiased;
        margin: 0 0 4px 0;
        color: $text-color-V2;
        color: #4c3bd0;
        font-size: 25px;
        font-weight: 300;
        line-height: 0.62;
        letter-spacing: normal;
        text-align: center;
      }

      h4 {
        font-family: $rionasans-regular;
        -webkit-font-smoothing: antialiased;
        line-height: 1.67;
        margin-top: 17px;
        color: #262837;
        opacity: 0.57;
        font-size: 16px;

        &.v2 {
          @media #{$mobile} {
            font-size: 19px;
            line-height: 23.75px;
          }
        }
      }
    }
  }

  .grail-quali-body {
    width: 100%;
    padding: 0 24px 0;

    &.v2 {
      @media #{$mobile} {
        padding-left: 16px;
        padding-right: 16px;
      }
    }

    .grail-quali-form {
      width: 100%;

      .el-tooltip.set-pwd-tooltip>div.el-tooltip__rel {
        margin-top: -3px;
      }

      .input-wrapper .el-input .el-input__inner:focus,
      .input-mask-field-wrapper input:focus,
      .input-mask-wrapper input:focus {
        border: solid 1.5px #40b9ec !important;
      }

      .form-section {
        display: flex;
        width: 100%;
        border-top: 1px solid #edeff3;
        flex-direction: column;

        @media #{$la_md} {
          flex-direction: row;
        }

        .info-section {
          margin-bottom: 24px;

          @media #{$la_md} {
            width: 240px;
            margin-bottom: unset;
          }

          .header {
            color: $text-color-V2;
            font-family: $rionasans-medium;
            -webkit-font-smoothing: antialiased;
            font-size: 16px;
            font-weight: 500;
            margin: 0 0 4px 0;
          }

          .body {
            color: #7b8291;
            font-family: $rionasans-regular;
            -webkit-font-smoothing: antialiased;
            font-size: 12px;
            line-height: 1.33;
          }
        }

        .body-section {
          @media #{$la_md} {
            width: 100%
          }

          .row-section {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            border-bottom: 1px solid #edeff3;
            padding: 24px 0 24px 38px;

            @media #{$la_md} {
              flex-direction: row;
            }

            &:last-child {
              @media #{$la_md} {
                margin-bottom: 0;
              }
            }

            .col-section {
              margin-bottom: 20px;

              @media #{$la_md} {
                margin-bottom: unset;
              }

              &.full-col {
                width: 100%;
              }

              .required:before {
                content: "*";
                color: $required-red-color;
                font-size: 14px;
                margin-left: -5px;
              }

              .label-section {
                color: $text-color-V2;
                font-size: 18px;
                font-family: $rionasans-medium;
                -webkit-font-smoothing: antialiased;
                line-height: 1.43;
                font-weight: 500;
                margin-bottom: 18px;
                display: flex;
                align-items: center;

                .label-no {
                  font-size: 40px;
                  color: #8dcf36;
                  width: 82px;
                }

                .label-content {
                  width: calc(100% - 82px);

                  .sub-text {
                    font-family: $rionasans-regular;
                    font-size: 16px;
                    margin-top: 7px;
                    font-weight: normal;
                    color: #262837;
                  }

                  .note {
                    margin-top: 24px;
                    font-family: $rionasans-regular;
                    font-weight: normal;
                    color: #262837;
                    opacity: 0.99;
                    font-size: 14px;

                    b {
                      font-weight: bold;
                    }
                  }
                }

              }

              .input-section {
                margin-left: 82px;

                .el-radio-button {
                  margin: 0 24px 0 0;

                  .el-radio-button__inner {
                    font-family: $rionasans-regular;
                    border-radius: 8px;
                    width: 162px;
                    height: 56px;
                    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
                  }
                }

              }


            }
          }

          .q5 {
            .el-radio-button {
              width: 100%;
              margin-bottom: 18px !important;

              .el-radio-button__inner {
                width: 350px !important;
                justify-content: left;
                padding-left: 26px;
              }
            }
          }

          .q6 {
            .col-section {
              .input-section {
                .multi-input-section {
                  .common-input-label-small {
                    margin-top: 24px;
                    font-family: RionaSans;
                    font-size: 14px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: $light-gray-text-color-v2;
                    margin-bottom: 4px;

                    &:first {
                      margin-top: 0px;
                    }
                  }
                }

                .two-inputs-section {
                  display: flex;
                  justify-content: space-between;

                  .input-wrapper {
                    width: 160px;
                  }

                }

                @media #{$max-md} {
                  .two-inputs-section {
                    .input-wrapper {
                      width: 45%;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .grail-quali-footer {
        width: 100%;
        padding: 100px 0;

        .sign-button-wrapper {
          display: flex;
          flex-direction: column-reverse;
          margin: 24px 0 82px;

          @media #{$la_md} {
            flex-direction: row;
            margin: unset;
          }
        }

        .btn-section {
          margin-bottom: 24px;

          @media #{$la_md} {
            margin-left: 20px;
            margin-bottom: unset;
          }

          button {
            width: 100% !important;

            @media #{$la_md} {
              width: unset;
            }
          }
        }
      }
    }
  }
}

.grail-quali-wrapper {
  .grail-quali-header {
    display: flex;
    flex-direction: column;
  }
}

.fields-container {
  display: flex;
  padding: 18px 18px 11px;
  border: solid 1px $page-background-color-V2;


  >.fields-title {
    width: 149px;
    font: normal 500 14px/1.43 $rionasans-medium;
    letter-spacing: 0.03px;
    text-align: left;
    color: $text-color-V2;

    &.required {
      &:before {
        content: "*";
        color: $required-red-color;
        font-size: 14px;
        margin-left: -5px;
      }
    }
  }

  >.checkboxes {
    width: 470px;
    padding: 18px 18px 11px;
    border-radius: 4px;

    >.custom-checkbox {
      display: flex;
      align-items: center;
      margin-bottom: 7px;
      cursor: pointer;

      >div {
        margin: unset;
      }

      >.custom-checkbox-rect {
        width: 15px;
        height: 15px;
        border-color: $scp-smoke-color;
      }

      >.custom-checkbox-label {
        font: normal 500 15px/1.38 $rionasans-medium;
        color: $text-color-V2;
        margin-left: 11px;
      }

      &.active {
        >.custom-checkbox-rect {
          border-color: $purple-300;

          >i {
            color: $light-indigo-V2;
            font-size: 10px;
            top: 1px;
            left: 1.5px;

            @media (max-width: 767px) {}
          }
        }
      }
    }
  }
}

.grail-quali-header {
  >.outer-button {
    margin-bottom: 22px;
    display: flex;

    button {
      background-color: unset !important;
      width: unset;
      height: unset;
      margin: unset;
      padding: unset;
      color: $focus-border-color-v2;
      font: normal 14px/1.36 $rionasans-regular;

      &:hover,
      &:focus,
      &:active {
        color: $focus-border-color-v2 !important;
        text-decoration: underline !important;
        box-shadow: unset;
        background-color: unset !important;
      }
    }
  }
}

@media #{$la_lg} {
  .qualification-wrapper {
    width: 900px !important;
  }
}

@media (max-width: 767px) {
  .grail-quali-container {
    .grail-quali-wrapper {
      width: 100%;

      .grail-quali-header {
        .genome-header-logo {
          margin-bottom: 12px;

          .genome-header-img {
            padding: 27px 20px 39px 20px;
            max-width: 226px;
          }
        }

        .genome-header-title {
          padding-bottom: 14px;

          .grail-quali-title {
            font-family: $rionasans-medium;
            font-size: 22px;
            line-height: 1;
          }

          h4 {
            font-size: 16px;
          }
        }
      }

      .grail-quali-body {
        .grail-quali-form {
          .form-section {
            border-top: none;

            .row-section {
              padding: 40px 0 32px 8px;

              .label-section {
                font-size: 15px !important;
                align-items: unset !important;

                .label-no {
                  font-size: 33px !important;
                  width: 36px !important;
                }

                .label-content {
                  width: calc(100% - 36px) !important;

                  .sub-text {
                    font-size: 13px !important;
                    display: inline;
                  }

                  .note {
                    font-size: 14px !important;
                  }
                }
              }

              .input-section {
                margin-left: 36px !important;

                .radio-group-wrapper {
                  .el-radio-group {
                    width: 100%;

                    .el-radio-button {
                      width: 100%;
                      margin: 0 0 15px 0;

                      .el-radio-button__inner {
                        width: 100%;
                      }
                    }
                  }
                }
              }

            }

            .q5 {
              .input-section {
                .radio-group-wrapper {
                  .el-radio-group {
                    width: 100%;

                    .el-radio-button:nth-last-child(1) {
                      margin-bottom: 0 !important;
                    }

                    .el-radio-button {
                      .el-radio-button__inner {
                        width: 100% !important;
                        white-space: break-spaces;
                        text-align: left;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .grail-quali-footer {
          padding: 20px 0;
        }
      }
    }
  }
}