@import '../../../../styles/vars.scss';
$menu-color: #37236a;

.scp-mobile-menu {
  display: flex;
  justify-content: space-between;
  z-index: 1000;
  width: 100%;
  height: 56px;
  background-color: white;
  position: relative;

  @media #{$media-header-visible} {
    display: none;
  }

  .middel-block {
    // flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 18px;

    .header-logo {
      width: 70px;
      height: 35px;
    }
  }

  .open-button {
    margin: 18px;
    min-width: 32px;
    cursor: pointer;

    @media #{$md} {
      margin-top: 26px;
    }
  }

  .dummy-block {
    min-width: 32px;
    margin: 18px;
  }

  &.ninetyDays {
    @media #{$mobile} {
      .header-logo {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.scp-mobile-menu-modal.modal-dialog {
  @media #{$media-landing-bottom-stripes} {
    width: 100%;
  }
}

.scp-menu-modal {
  .modal-content {
    background-color: #ffffff;
    width: 85%;
    min-height: 100%;
    border: none;
    margin-left: 15%;

    .modal-body {
      padding: 0;
      width: 100%;
      min-height: 100%;
      position: relative;

      .modal-panel {
        height: 64px;
        position: relative;
        display: flex;
        flex-direction: row;

        .close-button {
          height: 16px;
          width: 16px;
          margin: 24px 20px;
        }
      }

      .menu-list {
        width: 100%;
        height: calc(100vh - 64px);
        transition: 0.25s ease-in-out;
        padding-top: 0;
        padding-bottom: 0px;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch; // enable cute scrolling on iOS
        border-top: solid 1px $scp-border-color;
        margin: 0;
        display: flex;
        flex-direction: column;

        ul {
          list-style: none;
          padding: 0px;
          width: 100%;
          margin: 0px;
        }

        .item-signin,
        .item {
          margin: auto;
          padding: 16px 20px;
          color: #262837;
          cursor: pointer;
          font: $scp-menu-item;
          display: flex;
          align-items: center;
        }

        &:active {
          color: $purple-700;
        }

        .item.active {
          color: $purple-500;
        }

        .item.contactus-item {
          display: block;
          border-bottom: solid 1px $scp-border-color;

          .contactus-arrow {
            margin-left: 4px;
            transform: scale(0.5, 0.7);
          }

          ul {
            margin: 0 16px 0;

            .sub-menu {
              padding: 6px 0;
              margin: 8px 2px;
              color: $purple-500;

              a {
                color: $purple-500;
              }

              .contactus-icon {
                margin-right: 3px;
              }

              .call-icon {
                filter: invert(7%) sepia(100%) saturate(6446%) hue-rotate(254deg) brightness(99%) contrast(140%);
              }
            }
          }
        }

        .item.profile-item {
          border-top: solid 1px $scp-border-color;
          border-bottom: solid 1px $scp-border-color;
        }

        .item-signin {
          margin-bottom: 32px;

          .signin-button {
            height: 36px;
            width: 160px;
          }
        }
      }
    }
  }
}