@import '../../../../../styles/vars';

.scp-shipping-wizard.scp-shipping-address-stage {
  .scp-shipping-address-form {
    max-width: 860px;
    margin: 0 auto;
    .main-fields-container {
      max-width: 770px;
    }
    .narrow-fields-container,
    .dependant-fields-container {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;

      @media #{$md} {
        width: 470px;
      }

      .form-row {
        width: 100%;
        margin-bottom: 19px;
        display: flex;
        justify-content: space-between;
        margin-left: 5px;
        .form-col {
          width: 100%;
          .required:before {
            content: '*';
            color: $required-red-color;
            font-size: 14px;
            margin-left: -5px;
          }
          .label-section {
            width: 100%;
            font: $label_font;
            letter-spacing: normal;
            -webkit-font-smoothing: antialiased;
          }
          .input-wrapper .el-input .el-input__inner,
          .select-wrapper .el-select .el-input .el-input__inner {
            height: 32px;
            font: $scp-form-element-font;
            letter-spacing: normal;
            -webkit-font-smoothing: antialiased;
            @media #{$mobile} {
              height: 40px;
            }
          }
          .select-wrapper .el-select {
            .el-select-dropdown {
              font: $scp-form-element-font;
              .el-select-dropdown__item {
                color: $text-color-V2;
                font: $scp-form-element-font;
                &.selected {
                  color: #ffffff;
                }
              }
            }
          }
        }
        .form-col.city-col {
          width: 227px;
          @media #{$mobile} {
            width: 50%;
          }
        }
        .form-col.state-col {
          width: 65px;
          @media #{$mobile} {
            width: 20%;
          }
        }
        .form-col.zip-col {
          width: 146px;
          @media #{$mobile} {
            width: 25%;
          }
        }
      }
      .form-row.address-title {
        font: bold 14px/1.43 $rionasans-bold;
        margin-top: 32px;
        color: #262837;
        @media #{$mobile} {
          margin-top: 12px;
        }
      }
      .form-row.shipping-checkbox {
        margin-bottom: 16px !important;
        .checkbox-wrapper {
          height: 42px;
          display: flex;
          align-items: center;
          padding: 12px;
          border-radius: 4px;
          border: solid 1px #edeff3;
          background-color: #ffffff;
          .guardian-label {
            margin: 0 !important;
            padding: 0 !important;
            .label-section {
              font: normal 500 13px/1.38 $rionasans-medium;
              color: #262837;
              text-transform: capitalize;
            }
            .checkmark {
              top: 1px !important;
              margin-right: 13px !important;
            }
          }
        }
        .checkbox-wrapper:hover {
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
        }
        .checkbox-wrapper:focus,
        .checkbox-wrapper:active {
          box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
        }
      }
      .form-row.shipping-checkbox.active .checkbox-wrapper {
        border: solid 1px $light-indigo-V2;
      }
    }
  }
}
