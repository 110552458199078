@import "../../../styles/vars";

$modal-header-height: 56px;
$error-header-height: 24px;

.error-modal {
  font-family: $rionasans-regular;
  .modal-dialog {
    width: 369px;

    .modal-content {
      border-radius: 4px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.35);

      .modal-header {
        padding: 16px;
        height: $modal-header-height;
        border-bottom: solid 1px $scp-border-color;

        .modal-close-button {
          font-size: 14px;
          line-height: 0px;
          padding: 21px 20px;
          height: $modal-header-height;
        }
      }
    }
  }

  .error-header {
    height: $error-header-height;
    font-family: $rionasans-bold;
    font-size: 16px;
    color: $text-color-V2;
    span {
      display: inline-block;
      line-height: $error-header-height;
      height: $error-header-height;
      vertical-align: middle;
    }

    .material-icons.icon {
      color: $scp-info-box-failure-color;
      vertical-align: middle;
      margin-right: 5px;
    }
  }

  .error-body {
    margin-top: 28px;
    color: $text-color-brown;
    p {
      margin-bottom: 10px;
    }
  }
}
