$day-color: #00AEEF;
;
.calendar-day {
  width: 89px;
  height: 89px;
  border: 2px solid $day-color;
  margin: auto;
  .month {
    height: 27px;
    background-color: $day-color;
    color: white;
    text-align: center;
    font-size: 16px;
    line-height: 27px;
  }
  .day {
    font-size: 36px;
    text-align: center;
    line-height: 62px;
    color: $day-color;
  }
}
