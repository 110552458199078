@import '../../../styles/vars';

.gm-link {
  color: $link-color !important;
  cursor: pointer;
  text-decoration: none !important;
  &:hover {
    color: #3fd7ff !important;
  }
}
