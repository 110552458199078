@import '../../../styles/vars';
$lg: '(min-width: 1000px)';
$md: '(min-width: 500px)';

.set-password-page-container {
  min-height: 100vh;
  width: 100vw;
  color: $text-color-V2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background: $page-background-color-V2 url("../../../images/bg-patients.svg") center repeat-x fixed;
  -webkit-font-smoothing: antialiased;

  .form-wrapper {
    width: 384px;
    min-height: 591px;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    padding: 32px;
    margin: 0 auto;
    box-sizing: border-box;

    .genome-logo {
      display: inline-block;
      background-image: url('../../../images/logo.svg');
      background-repeat: no-repeat;
      background-size: contain;
      width: 126px;
      height: 63px;
      margin: 0px 92px;
    }

    .set-password-title {
      font: normal 500 24px/1.42 $rionasans-medium;
      margin: 24px 52px 4px;
      display: inline-block;
      -webkit-font-smoothing: antialiased;
    }

    .set-password-desc {
      font: normal 14px/1.43 $rionasans-regular;
      text-align: center;
      margin-bottom: 32px;
      margin-left: -1px;
    }

    .set-password-form {

      .input-section{
        margin-bottom: 27px;
      }

      .label-field {
        font: normal 500 14px/1.43 $rionasans-medium;
        margin-bottom: 7px;
        margin-top: 8px;
      }

      .required::before {
        content: '*';
        color: $required-red-color;
        font-size: 14px;
        margin-left: -5px;
      }

      .submit-button {
        margin-top: 48px;
      }

      .set-pwd-tooltip {
        align-self: center;
      }
      // input {
      //   font-size: 16px;
      // }
      // @media #{$md} {
      //   margin-top: 30px;
      // }
      // .gm-form-field .gm-form-label {
      //   color: #9b9b9b;
      // }
      // .password-field {
      //   position: relative;
      // }
      // .password-hint {
      //   @media #{$lg} {
      //     position: absolute;
      //     left: calc(100% + 30px);
      //     top: -65px;
      //     width: 258px;
      //   }
      // }
      // .submitButton {
      //   width: 100%;
      //   margin: 25px 0;
      //   button {
      //     width: 100%;
      //   }
      // }
    }

    .tos-modal {
      .tos-title {
        font-size: 24px;
        text-transform: uppercase;
        color: #37256C;
        padding-bottom: 10px;
      }
      .tos-desc {
        padding: 0 20px;
      }
    }
  }
}
