@import '../../../../styles/vars.scss';

.eligibility-check-wrapper {
  background: $page-background-color-V2 url("../../../../images/gray-ribbon-v2.svg") center/100% no-repeat fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  .eligibility-check-section {
    max-width: 384px;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    margin: 91px 0;
    padding: 32px;

    .ribbon-img {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .gm-eligibility-check-header {
      font-family: $rionasans-medium;
      -webkit-font-smoothing: antialiased;
      font-size: 24px;
      font-weight: 500;
      line-height: 1.42;
      text-align: center;
      color: #262837;
      margin-bottom: 24px;
    }

    .form-wrapper {
      .genome-header-logo {
        width: 100%;
        text-align: center;
        margin-bottom: 24px;

        .genome-header-img {
          max-width: 130px;
        }
      }

      .gm-eligibility-check-form {
        .gm-eligibility-check-fields {
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;

          .gm-eligibility-check-field {
            width: 100%;
            margin-bottom: 19px;

            input::-ms-clear {
              display: none;
            }

            .gm-alert {
              font-size: 12px;
              font-family: $rionasans-regular;
              -webkit-font-smoothing: antialiased;
              color: #ff0000;
              padding: 0;
              margin: 0;
              background-color: #ffffff;
              border: none;
            }
          }

          .input-wrapper .el-input .el-input__inner:focus {
            border: solid 1.5px #40b9ec;
          }

          .label-section {
            font-family: $rionasans-medium;
            --webkit-font-smoothing: antialiased;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.43;
            text-align: left;
            color: #262837;
            margin-bottom: 7px;
          }

          .required:before {
            content: "*";
            color: $required-red-color;
            font-size: 14px;
            margin-left: -5px;
          }

          .gm-helper-text {
            margin: 27px 0 0 0;
            border-radius: 4px;
            background-color: #f4a4a5;
            padding: 8px;
            font-family: $rionasans-regular;
            -webkit-font-smoothing: antialiased;
            font-size: 14px;
            line-height: 1.43;
            text-align: center;
            color: #400404;
            margin-top: 8px;
          }
        }
      }

      .gm-eligibility-check-appendix {
        display: none;
        font-family: $rionasans-regular;
        -webkit-font-smoothing: antialiased;
        font-size: 12px;
        line-height: 1.33;
        text-align: center;
        color: #7b8291;

        &.visible {
          display: block;
        }
      }

      .submit-button > button {
        margin: 24px auto 0 auto;
      }

      .submit-button > button:hover {
        background-color: $purple-400  !important;
      }

      .submit-button > button:active {
        background-color: $purple-600  !important;
      }

      .submit-button > button:focus {
        background-color: $purple-500  !important;
        border: 2px solid rgba(189, 146, 228, 0.5) !important;
      }

      .submit-button > button:disabled {
        background-color: $purple-500  !important;
        opacity: 0.35;
      }

      .submit-button > button:disabled > span {
        opacity: 0.35;
      }
    }

    .eligibility-warning {
      font-family: $rionasans-regular;
      -webkit-font-smoothing: antialiased;
      font-size: 12px;
      line-height: 1.33;
      text-align: center;
      color: #7b8291;
      margin-top: 16px;

      .gm-link > a {
        color: $purple-500  !important;
      }
    }
  }
}
