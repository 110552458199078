@import "../../../../styles/vars";

.video-setup-container {
  -webkit-font-smoothing: antialiased;

  .today {
    margin: 0px 16px 0px 16px;
  }

  @media #{$la_md} {
    .today {
      margin: 0px;
    }
  }

  .icon {
    margin-right: 4px;
  }

  .content {
    padding: 14px !important;

    ul.appointment-help {
      padding-left: 0;
      margin: 0 auto;
      list-style-type: none;
      counter-reset: steps;
      margin: 0;
      font-family: $rionasans-bold;

      li {
        padding: 0 0 8px 24px;
        position: relative;
        margin: 0;

        .title {
          font: normal 12px/1.67 $rionasans-bold;
          color: $text-color-V2;
        }

        .description {
          font: normal 12px/1.5 $rionasans-regular;
          color: $text-color-V2;
        }

        a {
          color: $focus-border-color-v2;
          text-decoration: underline;
        }
      }

      li:after {
        position: absolute;
        top: 2px;
        left: 0;
        content: counter(steps);
        counter-increment: steps;
        border-radius: 50%;
        height: 17px;
        width: 17px;
        background: $purple-200;
        color: $scp-button-focus-color;
        font-size: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      li:before {
        position: absolute;
        left: 8px;
        top: 2px;
        content: "";
        height: 100%;
        width: 0;
        border-left: 1px dashed $purple-200;
      }

      li:last-child {
        padding-bottom: 0px;
      }

      li:last-of-type:before {
        border: none;
      }
    }
  }
}