@import '../../../../styles/vars';
.scp-app-cancel-block {
  margin-top: 32px;
  .link {
    color: $link-color-v2;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    font: normal 500 12px/1.33 $rionasans-medium;
    &.reschedule-link {
      margin-left: 55px;
    }
    @media #{$mobile} {
      padding: 12px 0;
    }
  }
  .link:hover{
  	text-decoration: underline;
  }
}

.ninetyDays {
  .link {
    font: normal 500 16px/19px $rionasans-medium;
    color: $purple-500;
    margin-left: 48px;
    .cancel-icon {
      margin-right: 2px;
    }
    &.reschedule-link {
      margin-left: 0;
      .reschedule-icon {
        margin-bottom: 2px;
      }
    }
  }
}