@import '../../../../../styles/vars';

$md: '(min-width: 500px)';

.scp-coupon-applier {
  width: 100%;
  border-radius: 4px;
  padding: 16px 8px;
  border: solid 1px #d8dde4;
  line-height: 22px;
  -webkit-font-smoothing: antialiased;

  &.ismobile{
    border:none;
    padding:0;
    .coupon-container{
      .total-block{
        padding-left: 0;
        .price-label{
          font-family: $rionasans-bold;
            font-size: 19px;
            font-weight: 700;
            line-height: 22.8px;
            color: #525968;
          .visit-deposit-label{
            font-family: $rionasans-bold;
            font-size: 19px;
            font-weight: 700;
            line-height: 22.8px;
            color: #525968;
          }
          .promo-code-label{
            font-size: 14px;
            font-family: $rionasans-medium;
            font-weight: 500;
          }
        }
        .total-values{
    font: $scp-review-page-price-font;
    letter-spacing: normal;
    text-align: left;
    color: $text-color-V2;
        }
      }
      .form-row{
        padding-left: 0;
      }
      .coupon-field-block{
        .coupon-label-applied{
          .coupon-label-code{
            font-family: $rionasans-bold;
            font-size: 19px;
            font-weight: 700;
            line-height: 22.8px;
            color: #525968;
          }
          .undo-coupon{
            font-size: 14px;
            font-family: $rionasans-medium;
            font-weight: 500;
          }
        }
        .coupon-applied-code{
          font: $scp-review-page-price-font;
          letter-spacing: normal;
          text-align: left;
          color: $text-color-V2;
        }
      }
    }
  }
  &.v2{
    .code-summary {
      font-family: $rionasans-bold;
      font-size: 15px;
      font-weight: 700;
    }
    .coupon-service-name{
      font-family: $rionasans-medium;
      font-size: 13px;
      line-height: 15.6px;
    }
    .speciality-name {
      font: normal 500 12px/14.4px $rionasans-medium;
      letter-spacing: normal;
      text-align: left;
      color: #525968;
    }
  }

  @media #{$la_md} {
    width: 308px;
  }

  .code-summary {
    padding: 8px 8px 0 8px;
    font-family: $rionasans-medium;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: $text-color-V2;
    -webkit-font-smoothing: antialiased;
  }

  .visit-container {
    margin-bottom: 16px;
    padding: 0 8px;

    .coupon-service-name {
      font-family: $rionasans-regular;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.36;
      letter-spacing: normal;
      text-align: left;
      color: $text-color-V2;
      margin-top: 32px;
      -webkit-font-smoothing: antialiased;
    }

    .speciality-name {
      font: $scp-review-page-label-font;
      letter-spacing: normal;
      text-align: left;
      color: #7b8291;
    }
  }

  .coupon-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    .form-row {
      width: 100%;
      padding: 0 8px 24px 8px;

      .form-col {
        width: 100%;

        .label-section {
          font-family: $rionasans-regular;
          font-size: 12px;
          font-weight: normal;
          color: $text-color-V2;
          line-height: 1.42;
          -webkit-font-smoothing: antialiased;
        }

        .promo-code-input {
          display: flex;

          .error {
            .el-input__inner {
              background-color: rgba(254, 145, 137, 0.5);
              border: solid 1px #e74c3c;
              color: #fe5c50;
            }

            .input-error {
              font-size: 10px;
              color: #e74c3c;
              line-height: 1.4;
              font-family: $rionasans-regular;
              font-weight: normal;
              -webkit-font-smoothing: antialiased;
            }
          }

          .promo-input-text {
            width: calc(100% - 96px);
          }

          .el-input__inner {
            height: 32px;
            height: 32px;
            padding: 7px 8px;
            font-size: 13px;
          }
        }
      }
    }

    @media #{$md} {
      flex-wrap: nowrap;
    }
  }

  .total-block {
    display: flex;
    flex-flow: wrap;
    border-top: solid 1px #d8dde4;
    font-size: 18px;
    padding: 0 8px;
    padding-top: 24px;
    justify-content: space-between;
    font-family: $rionasans-regular;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: $text-color-V2;

    .promo-code-label {
      font-size: 12px;
      color: #40b9ec;
      font-family: $rionasans-medium;
      font-weight: 500;
      cursor: pointer;
      -webkit-font-smoothing: antialiased;
    }

    .visit-deposit-block {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-bottom: 24px;

      .price-label {
        max-width: calc(100% - 80px);
        width: 100%;

        .visit-deposit-label {
          color: $text-color-V2;
          font-family: $rionasans-regular;
          -webkit-font-smoothing: antialiased;
          font-size: 14px;
          font-weight: normal;
          line-height: 15.6px;
        }
      }
    }
  }

  .pad-bt-24 {
    padding-bottom: 24px;
  }

  .price-value {
    min-width: 80px;
    padding-left: 5px;
    font: $scp-review-page-price-font;
    letter-spacing: normal;
    text-align: left;
    color: $text-color-V2;
  }

  .total-values {
    min-width: 80px;
    padding-left: 5px;
    font: normal 16px/1.3 "RionaSans";
    letter-spacing: normal;
    text-align: left;
    color: $text-color-V2;
  }

  .coupon-form {
    flex-grow: 1;
    margin-top: 5px;

    .form-row {
      width: 100%;
      display: flex;
      flex-direction: column;

      .form-col {
        width: 180px;
        flex-direction: row;
        display: flex;
        height: 32px;

        .input-wrapper .el-input .el-input__inner,
        .select-wrapper .el-select .el-input .el-input__inner {
          height: 32px;
          width: 180px;
          font: $scp-form-element-font;
          letter-spacing: normal;
        }
      }
    }
  }

  .coupon-field-block {
    display: flex;
    align-items: center;

    @media #{$md} {
      display: block;
    }
  }

  .coupon-label {
    font-size: 14px;
    font-weight: 400;
  }

  .coupon-field {
    display: inline-block;
    width: 100%;
    margin-top: 0 !important;
    margin-right: 12px;
    margin-bottom: 5px;

    @media #{$md} {
      width: auto;
    }

    input {
      outline: none;
      background-color: white !important;
    }

    &.applied {
      input {
        padding-right: 40px;
      }

      i {
        display: block !important;
      }
    }
  }

  .coupon-control {
    width: 100%;
    position: relative;

    @media #{$md} {
      max-width: 215px;
    }

    i {
      display: none;
      position: absolute;
      right: 15px;
      top: 12px;
      color: #3baa41;
      font-size: 16px;
    }
  }

  .coupn-apply-btn {
    padding: 8px 12px;
    display: inline-block;
    border-radius: 6.5px;
    background-color: $purple-500;
    font-family: $rionasans-medium;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    width: 80px;
    border: 0;
    height: 32px;
    margin-left: 16px;

    &:disabled {
      opacity: 0.5;
      color: #ffffff;

      &:hover {
        opacity: 0.5;
        border-color: $purple-500;
        background-color: $purple-500;
        color: #ffffff;
      }
    }

    &:hover {
      border-color: $purple-400;
      background-color: $purple-400;
      color: #ffffff;
    }

    &:focus {
      border-color: $purple-400;
      background-color: $purple-400;
      outline: 0;
      color: #ffffff;
    }
  }

  .coupon-applied-block {
    display: flex;
    vertical-align: middle;
    width: 100%;
    padding: 0 8px 24px 8px;

    .coupon-label-code {
      color: $text-color-V2;
      font-family: $rionasans-regular;
      font-size: 14px;
      font-weight: normal;
      text-transform: uppercase;
    }

    .gm-link {
      font-size: 12px;
      color: #40b9ec;
      font-family: $rionasans-regular;
      font-weight: normal;
      cursor: pointer;
    }

    .coupon-applied-code {
      min-width: 80px;
      padding-left: 0px;
      font: normal 16px/1.3 "RionaSans";
      letter-spacing: normal;
      text-align: left;
      color: $text-color-V2;
    }

    .coupon-label-applied {
      width: calc(100% - 80px);
    }
  }

  .coupon-label-applied {
    color: $body-text-color;
  }

  .coupon-error {
    color: red;
    margin-top: 5px;
  }

  .no-payment {
    background-color: #fefacf;
    padding: 26px 38px;
    margin-top: 30px;
    display: flex;
    align-content: center;
    flex-wrap: wrap;

    @media (min-width: 500px) {
      margin-right: 20px;
      flex-wrap: nowrap;
    }

    .confirm-icon {
      margin-right: 20px;
    }

    .no-payment-text {
      align-self: center;
    }
  }
}
