@import '../../../../styles/vars.scss';

$md: '(min-width: 700px)';

.scp-schedule-breadcrumb {
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  padding: 18px 0;
  display: none;

  @media #{$md} {
    display: flex;
  }

  border-bottom: 1px solid #edeff3;

  .breadcrump__item {

    min-width: auto;
    margin: 1px;
    display: flex;
    align-items: center;
    margin-left: 14px;

    @media #{$md} {
      display: block;
      display: flex;
    }

    .breadcrump__text {
      padding: 0 7px;
      font-family: $rionasans-bold;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      text-align: left;
      color: $field-border-color-V2;
      -webkit-font-smoothing: antialiased;
      margin-right: 14px;
    }

    .breadcrump__nav {
      position: relative;
      top: 2px;

      .arrow-right {
        fill: #bac3ce;
      }
    }
  }

  .breadcrump__item__done {

    .breadcrump__text {
      color: $purple-300;
    }

    .breadcrump__nav {
      .arrow-right {
        fill: $purple-300;
      }
    }
  }

  .breadcrump__item__active {
    .breadcrump__text {
      color: $light-indigo-V2;
    }
  }
}

.mob-schedule-appointment-progress-bar {
  @media #{$md} {
    display: none;
  }

  height: 4px;
  background-color: $purple-300;
  transition-duration: .5s;
}

.scp-mob-schedule-breadcrumb {
  background-color: #ffffff;
  height: 80px;
  display: flex;
  position: relative;
  border-top: solid 1px $scp-border-color;
  border-bottom: solid 1px $scp-border-color;

  @media #{$md} {
    display: none;
  }

  .m-breadcrumb-content {
    margin: auto;
  }

  .arrow-left {
    width: 28px;
    position: absolute;
    left: 10px;
    top: 20px;
    padding: 10px;
    cursor: pointer;

    @media (min-width: 390px) {
      left: 30px;
    }

    @media #{$mobile} {
      height: 40px;
    }
  }

  .step {
    font-size: 14px;
    line-height: 20px;
    color: $body-text-color;
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  .step-label {
    color: $light-gray-text-color-v2;
    font: $scp-review-page-value-font;
  }

  .step-name {
    color: $text-color-V2;
    font: $scp-title1-font;
  }

  .m-scheduling-breadcrumb-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 18px 15px;
    width: 100%;

    .header-logo {
      width: 24px;
      height: 24px;
    }

    .middel-block {
      display: none;
    }
  }
}