@import '../../styles/vars.scss';
$footer-height: 58px;
$line-height: 40px;
$medium-footer-width: 880px;
$large-footer-width: 1417px;
.footer {
  background-color: $back-color;
  color: white;
  padding: 9px 18px;
  position: relative;
  .footer-container {
    margin: auto;
    @media (min-width: $large-footer-width) {
      height: $footer-height;
    }
    > div {
      display: table;
      width: 100%;
    }
    .footer-text {
      width: 100%;
      height: $line-height;
      padding: 0 13px;
      height: $footer-height;
      margin: auto;
      > div {
        display: table-cell;
        vertical-align: middle;
      }
      
      @media (min-width: 770px) {
        width: 426px;
        float: left;
        text-align: left;
      }
    }
    .links-wrapper {
      width: 300px;
      margin: 0 auto;
      .social-networks {
        img {
          padding: 8px;
        }
      }
      @media (min-width: 770px) {
        float: right;
      }
    }
  }
}
