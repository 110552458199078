@import '../../../styles/vars';
$green: #86ca48;
$blue: #00b2ed;
[data-gm-btn='true'] {
  background-color: $green !important;
  width: auto;
  min-width: 160px;
  height: 43px;
  line-height: 43px;
  color: white;
  padding: 0 30px;
  border-radius: 2px;
  border: none;
  outline: none;
  font-size: 14px;
  
  font-weight: 400;
  text-transform: uppercase;
  &:hover,
  &:focus,
  &:active {
    outline: none !important;
    border: none !important;
    color: white !important;
  }
  &[data-gm-btn-type='more'],
  &[data-gm-btn-type='signin'] {
    background-color: $blue !important;
  }
  &[data-gm-btn-size='large'] {
    height: 50px;
    line-height: 50px;
    padding: 0 40px;
    min-width: 150px;
  }
  &[data-gm-btn-type='white'] {
    background-color: #ffffff !important;
    color: $link-color !important;
  }
  &[data-gm-btn-type='signin'] {
    border-radius: 4px;
    min-width: 150px;
    background-color: $blue !important;
    
    @media (min-width: 1024px) {
      min-width: 190px;
    }
  }
  &[data-gm-btn-type='landing'] {
    border-radius: 4px;
    background-color: $blue !important;
    font-size: 16px;
    text-transform: none;
    
    min-width: 220px;
  }

  &[disabled],
  &.disabled {
    background-color: #b1b1b1 !important;
  }
}

.outer-button {
  text-align: center;
  .button-link {
    display: inline-block;
    text-decoration: none;
  }
}
