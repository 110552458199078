@import "../../../../../styles/vars";

.radioButtonGroupContainer {
    width: 100%;
    &::-webkit-scrollbar {
      width:  3px;
    }
    &::-webkit-scrollbar-thumb {
      background: #bac3ce;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    .radioButtonRowContainer {
      width: 100% !important;
      display: flex !important;
      flex-direction: row !important;
      padding-left: 14px;
      white-space:normal !important ;
      padding-bottom: 16px;
    }
  }


  .radioButtonRowContainer > .el-radio__label > .singleSelectRadioLabel {
    padding-left: 13px;
    font-family: $rionasans-regular;
  }

  .radioButtonRowContainer > span.el-radio__input.is-checked > span.el-radio__inner {
    border-color: $purple-500 !important;
    background: unset !important;
  }

  .radioButtonRowContainer > span.el-radio__input.is-checked > span.el-radio__inner::after {
    background-color: $purple-500 !important;
    width: 7.5px;
    height: 7.5px;
  }

  .radioButtonRowContainer > span.el-radio__input {
    padding-top: 3px !important;
    padding-left: 1px;
  }

  .radioButtonRowContainer > span.el-radio__input > span.el-radio__inner {
    border: 2px solid #939394;
    width: 17px;
    height: 17px;
  }

  .radioButtonRowContainer > span.el-radio__input > span.el-radio__inner:hover {
    border-color: $purple-500 !important;
  }

  .radioButtonRowVariantContainer {
    width: unset;
    display: flex;
  }

  .rowVariantRadioButton {
    margin-right: 5px !important;
    padding-right: 5px;
  }

  .rowVariantRadioLabel {
    padding-left: 3px !important;
  }

  .radioButtonRowContainer > .el-radio__label > .singleSelectRadioLabel > span {
    font-size: 16px;
    font-weight: 500;
  }
