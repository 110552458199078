@import "../../../../styles/vars";
@import "../../../../styles/mixins";

.grail-galleri-container {
    display: flex;
    justify-content: center;

    @media #{$la_lg} {
        padding-top: 16px;
    }

    min-height: calc(100vh - 290px);

    .grail-galleri {
        min-height: initial;
        color: #4a494b;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: column;
        border-radius: 4px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: $white-bg-color-v2;
        max-width: 1020px;
        padding: 51px 15px 0px 15px;

        .content-text.inline {
            display: inline-block;
            margin: 0;
            padding: 0;
        }

        .content-text.border {
            border-top: 1px solid #d9d9d9;
            margin-top: 32px;
            padding-top: 0px;
        }

        .main-text {
            margin-bottom: 24px;
        }

        @media #{$la_lg} {
            .content-text.border {
                border-top: none;
            }
        }

        .content-text {
            border: none;
            font: normal 14px/1.5 $rionasans-regular;
            color: $light-gray-text-color-v2;
            display: flex;
            flex-direction: column;
            padding: 40px 0px 0px 0px;

            > b {
                font-family: $rionasans-bold;
                font-weight: bold;
            }

            .content-label {
                @media #{$la_lg} {
                    width: 150px;
                }

                margin-bottom: 16px;
                word-wrap: break-word;
            }

            @media #{$la_lg} {
                .content-desc {
                    width: 690px;
                    margin-left: 32px;
                }
            }

            .content-label.test-purpose {
                margin-top: 32px;
            }

            .content-label > b {
                font-family: $rionasans-bold;
                font-weight: bold;
                text-transform: uppercase;
            }

            > a {
                color: #7e2bca;
            }

            .content-desc {
                > a {
                    color: #7e2bca;
                }
            }

            .agree-text {
                > a {
                    color: #7e2bca;
                }
            }
        }

        @media #{$la_lg} {
            .content-text {
                display: flex;
                flex-direction: row;
                align-items: baseline;
            }

            .content-text.inline {
                border: none;
                padding-bottom: 0px;
            }
        }

        > .outer-button {
            margin-bottom: 22px;
            display: flex;

            button {
                background-color: unset !important;
                width: unset;
                height: unset;
                margin: unset;
                padding: unset;
                color: $focus-border-color-v2;
                font: normal 14px/1.36 $rionasans-regular;

                &:hover,
                &:focus,
                &:active {
                    color: $focus-border-color-v2  !important;
                    text-decoration: underline !important;
                    box-shadow: unset;
                }
            }
        }

        @media #{$la_lg} {
            > .outer-button {
                width: 100%;
            }
        }

        > .header {
            margin-bottom: 31px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin: 0px;

            > h1 {
                opacity: 0.73;
                font: normal 300 32px $rionasans-regular;
                text-align: center;
                color: $text-color-V2;
            }

            @media #{$la_lg} {
                > h1 {
                    font: normal 300 46px $rionasans-regular;
                }
            }

            > p {
                opacity: 0.6;
                font: normal 500 14px/1.6 $rionasans-medium;
                text-align: center;
                color: $text-color-V2;
                margin-top: 1px;
                margin-bottom: 53px;
            }

            @media #{$la_lg} {
                > p {
                    font: normal 500 15px/1.6 $rionasans-medium;
                }
            }

            > div {
                display: flex;
                margin-top: 0;

                &.content-title {
                    font: normal 500 20px/1.14 $rionasans-medium;
                    letter-spacing: 0.04px;
                    color: #5d0aaa;
                    margin-bottom: 16px;
                }

                @media #{$la_lg} {
                    &.content-title {
                        font: normal 500 21px/1.14 $rionasans-medium;
                    }
                }
            }

        }

        @media #{$la_lg} {
            > .header {
                display: flex;
                margin-bottom: 40px;

                > div {
                    display: flex;
                    justify-content: baseline;
                }

                .content-text {
                    width: 615px;
                }
            }
        }

        .fields-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            border: none;
            padding: 0;
            width: 100%;

            > .fields-title {
                width: fit-content;
                font: normal 500 14px/1.43 $rionasans-medium;
                letter-spacing: 0.03px;
                text-align: left;
                color: $text-color-V2;
                margin-bottom: 24px;

                &.required {
                    &:before {
                        content: "*";
                        color: $required-red-color;
                        font-size: 14px;
                        margin-left: -5px;
                    }
                }
            }

            @media #{$la_lg} {
                > .fields-title {
                    width: 149px;
                }
            }

            > .delivery-address {
                > .warning {
                    margin-top: 16px;
                    padding: 12px 18px;
                    border-radius: 6px;
                    background-color: #ffcccc;
                    font: normal 14px $rionasans-regular;
                    color: #3a0907;
                    display: flex;
                    justify-content: center;

                    > img {
                        margin-right: 15px;
                        opacity: 0.26;
                        height: 21px;
                        width: 21px;
                    }

                    b {
                        font-family: $rionasans-bold;
                        font-weight: bold;
                    }
                }

                @media #{$la_lg} {
                    .warning {
                        margin-top: 0px;
                    }
                }

                > .form-row-container {
                    .form-row:nth-child(2) {
                        display: inline-flex;
                        justify-content: space-between;

                        .state-right {
                            margin-left: 18px;
                        }

                        .zip-col {
                            margin-left: 18px;
                        }
                    }
                }
            }

            @media #{$la_lg} {
                .delivery-address {
                    width: 100%;
                    max-width: 472px;

                    > .form-row-container {
                        display: inline-flex;
                    }
                }
            }

            > .checkboxes {
                width: 100%;
                padding: 18px 18px 11px;
                border-radius: 4px;
                border: solid 1px $page-background-color-V2;
                margin-bottom: 32px;

                > .custom-checkbox:not(:last-child) {
                    margin-bottom: 24px;
                }

                @media #{$la_lg} {
                    > .custom-checkbox:not(:last-child) {
                        margin-bottom: 7px;
                    }
                }

                > .custom-checkbox {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    > div {
                        margin: unset;
                    }

                    > .custom-checkbox-rect {
                        width: 14px;
                        height: 14px;
                        border-color: $scp-smoke-color;
                    }

                    @media #{$la_lg} {
                        > .custom-checkbox-rect {
                            width: 18px;
                            height: 18px;
                        }
                    }

                    > .custom-checkbox-label {
                        font: normal 500 14px/1.38 $rionasans-medium;
                        color: $text-color-V2;
                        margin-left: 11px;
                    }

                    @media #{$la_lg} {
                        > .custom-checkbox-label {
                            font: normal 500 18px/1.38 $rionasans-medium;
                        }
                    }

                    &.active {
                        > .custom-checkbox-rect {
                            border-color: $purple-300;

                            > i {
                                color: $light-indigo-V2;
                                font-size: 10px;
                                top: 1px;
                                left: 1.5px;
                            }

                            @media #{$la_lg} {
                                > i {
                                    top: 3px;
                                    left: 3px;
                                }
                            }
                        }
                    }
                }
            }

            @media #{$la_lg} {
                > .checkboxes {
                    padding: 18px 18px 11px;
                    width: 470px;
                    margin-bottom: 0px;
                }
            }
        }

        @media #{$la_lg} {
            > .fields-container {
                padding: 0;
                flex-direction: row;
                margin-bottom: 47px;
            }
        }

        > form {

            .content-text > b {
                text-transform: uppercase;
            }

            .main-fields-container {

                > .buttons {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    max-width: 100%;
                    margin: 41px 15px 148px 15px;

                    @media #{$la_md} {
                        float: none;
                    }

                    .button {
                        text-transform: uppercase;
                        display: inline-block;
                        text-align: center;

                        >button {
                            width: 319px !important;
                            height: 51px !important;
                            font-size: 19px !important;
                        }

                        &.button-next {
                            .btn-default {
                                width: 145px;
                                height: 36px;
                                font: $scp-submit-button-font;
                                letter-spacing: normal;
                                color: #ffffff;

                                &[disabled],
                                &.disabled {
                                    opacity: 0.35;
                                }

                                &[disabled]:hover,
                                &.disabled:hover {
                                    opacity: 0.35;
                                }
                            }
                        }
                    }

                    .button-back {
                        color: $light-indigo-V2;
                        text-decoration: none;
                        cursor: pointer;
                        text-align: left;
                        display: none;
                        font: $scp-submit-button-font;
                        letter-spacing: normal;

                        @media (min-width: 700px) {
                            display: block;
                        }

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                @media #{$la_lg} {
                    > .buttons {
                        margin: 27px 0px 51px 0px;
                        display: flex;
                        justify-content: left;

                        .button {
                            >button {
                                width: 198px !important;
                            }
                        }

                    }
                }
            }

            .narrow-fields-container {
                > .consent-container {
                    padding-top: 48px;
                    margin: 0px 8px 0px 8px;

                    > .title {
                        font: normal 500 22px/1.09 $rionasans-medium;
                        text-align: center;
                        color: $text-color-V2;
                        margin-bottom: 32px;
                    }
                }

                @media #{$la_lg} {
                    > .consent-container {
                        padding-top: 65px;
                        margin: 0px;

                        > .title {
                            margin-bottom: 47px;
                        }
                    }
                }

                .signature-form {
                    padding-top: 0px;
                    justify-content: unset !important;

                    > .sign-col {
                        padding: 0px 13px 0px 13px;
                    }

                    > .date-col {
                        width: 400px;
                        padding: 0px 13px 0px 13px;

                        .input-mask-field-wrapper {
                            input {
                                padding: 3px 16px;
                                height: 48px;
                                font: normal 16px/1.38 $rionasans-regular;

                                &:focus {
                                    &::placeholder {
                                        color: $field-border-color-V2;
                                    }
                                }
                            }

                            &.input-mask-error-field {
                                input {
                                    &::placeholder {
                                        opacity: 1;
                                    }

                                    &:focus {
                                        &::placeholder {
                                            color: $error-text-color-v2;
                                        }
                                    }
                                }
                            }
                        }

                        @media #{$la_lg} {
                            .input-mask-field-wrapper {
                                input {
                                    height: 32px;
                                    font: normal 13px/1.38 $rionasans-regular;
                                }
                            }
                        }
                    }
                }


                > .signature-container {
                    margin-top: 48px;
                }

                @media #{$la_lg} {
                    > .signature-container {
                        margin-top: 24px;
                        display: flex;

                        > .signature-form.form-row {
                            width: auto;
                        }

                        > .signature-form {
                            > .sign-col {
                                padding: 0;
                                width: 227px;

                                input {
                                    width: 227px;
                                    height: 32px;
                                    font: normal 13px/1.38 $rionasans-regular;
                                }
                            }

                            > .date-col {
                                padding: 0;

                                input {
                                    width: 117px;
                                    height: 32px;
                                    padding: 3px 8px;
                                    font: normal 13px/1.38 $rionasans-regular;
                                }

                                margin-left: 17px;
                            }
                        }
                    }
                }

                > .signature-form.consented-at-field {
                    padding-top: 0px;

                    .form-row {
                        margin-bottom: 0px;
                    }
                }

                .form-row.address-title {
                    min-height: 20px;
                    font: bold 14px/1.43 $rionasans-bold;
                    color: $text-color-V2;
                    margin-bottom: 16px !important;
                }

                .form-row {
                    width: 100%;
                    margin-bottom: 19px;

                    .form-col {
                        width: 100%;

                        .required:before {
                            content: "*";
                            color: $required-red-color;
                            font-size: 14px;
                            margin-left: -3px;
                        }

                        .label-section {
                            width: 100%;
                            font: $label_font;
                            font-size: 14px;
                            letter-spacing: normal;
                        }

                        .input-wrapper .el-input .el-input__inner,
                        .select-wrapper .el-select .el-input .el-input__inner {
                            font: normal normal 16px/1.38 $rionasans-regular;
                            letter-spacing: normal;
                            height: 48px;
                            padding: 3px 16px;
                        }

                        .select-wrapper .el-select {
                            .el-select-dropdown {
                                font: $scp-form-element-font;

                                .el-select-dropdown__item {
                                    color: $text-color-V2;
                                    font: $scp-form-element-font;

                                    &.selected {
                                        color: #ffffff;
                                    }
                                }
                            }
                        }

                        @media #{$la_lg} {
                            .label-section {
                                font-size: 13px;
                            }

                            .input-wrapper .el-input .el-input__inner,
                            .select-wrapper .el-select .el-input .el-input__inner {
                                font: normal normal 13px/1.38 $rionasans-regular;
                                letter-spacing: normal;
                                height: 32px;
                                padding: 3px 8px;
                            }
                        }
                    }

                    .form-col.state-col {
                        .el-select-dropdown__wrap {
                            max-height: 250px;
                        }
                    }

                    .form-col.state-col {
                        .select-wrapper {
                            .el-select {
                                .el-input {
                                    height: 48px !important;

                                    .el-input__inner {
                                        height: 48px !important;
                                    }
                                }
                            }
                        }
                    }

                    @media #{$la_lg} {
                        .form-col.city-col {
                            width: 227px;
                        }

                        .form-col.state-col {
                            width: 65px;
                            margin-left: 16px;
                        }

                        .form-col.zip-col {
                            width: 146px;
                            margin-left: 16px;
                        }

                        .form-col.state-col {
                            .select-wrapper {
                                .el-select {
                                    .el-input {
                                        height: 32px !important;

                                        .el-input__inner {
                                            height: 32px !important;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                @media #{$la_lg} {
                    >.fields-container {
                        width: 100%;
                        flex-direction: row;
                    }
                }
            }

            .btn-default {
                min-width: 145px !important;
            }
        }
    }

    @media #{$la_lg} {
        .grail-galleri {
            display: flex;
            justify-content: center;
            align-content: center;
            padding: 40px 96px 0px 96px;
        }
    }
}
