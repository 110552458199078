@import '../../../../styles/vars.scss';

.input-mask-wrapper{
    input{  
        height: 40px;
        width: 100%;
        border-radius: 4px;
        padding: 3px 8px;
        font-family: $rionasans-medium;
        -webkit-font-smoothing: antialiased;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.43;
        border: 1px solid $field-border-color-V2 ;
        color: $text-color-V2
    }
    input::placeholder{
        color: $field-border-color-V2;
    }
    input:hover:not(:disabled), input:focus:not(:disabled){
        border: 1px solid #70cbf1;
        outline: 0;
    }
    
    input:focus::placeholder, .input:hover::placeholder{
        color: #262837;
    }
}
   
.input-mask-wrapper.input-error-field {
    input{
         background-color: $error-field-bg-color;
         border: 1px solid #fb3a2e;
         color: $error-text-color-v2;
     }
     input:hover,
     input:focus
     {
         border:1px solid #fb3a2e;
     }
     input::placeholder,
     input:hover::placeholder,
     input:focus::placeholder {
         color: $error-text-color-v2;
         opacity: 0.5;
     }
    .input-error{
        font-size: 12px;
        color: $error-input-error-v2;
        font-family: $rionasans-regular;
        -webkit-font-smoothing: antialiased;
    }
}