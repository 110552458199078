@import "vars";
@import "helpers";
@import "fonts";
@import "forms";

#app {
  height: 100%;
  -webkit-overflow-scrolling: touch; // enable cute scrolling on iOS
  & > * {
    -webkit-transform: translate3d(0, 0, 0);
  }
}

body {
  background-color: white;
  color: $text-color;
}
html{
  overflow: auto;
}

html,
body {
  height: 100%;
  line-height: 20px;
  text-size-adjust: 100%;
  font-family: $roboto;
}

.main-container {
  display: block !important;
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  //height: 100%;
  &.main-navigator-container{
    min-height: 100vh;
    background-color: #edeff3;
  }
  &.main-education-container {
    padding-top: 16px;
    .main-navigator-wrapper {
      max-width: 1020px;
      margin: auto;
      min-height: calc( 100vh - 290px );
    }
  }
  &.scp {
    background: $page-background-color-V2 url("../images/gray-ribbon-v2.svg")  center repeat-x fixed;
    .full-height {
      min-height: 670px;
    }
  }
}

.clearfix {
  clear: both;
}

.full-height {
  // make min height 100vh without header and footer
  min-height: calc(100vh - 221px);
  @media #{$header-md} {
    min-height: calc(100vh - #{76px + $header-height});
  }
  &.no-header {
    min-height: calc(100vh - 76px);
  }
  &.no-footer {
    height: 100%;
    //min-height: calc(100vh - #{$header-height});
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.heading {
  color: $caption-color;
}

b,
strong {
  font-weight: 500;
}

label {
  font-weight: 500;
}
