@import "../../../styles/vars";

.calendar-toolbar {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
  &.v2{
    border-bottom: 1px solid #d8dde4;
    margin-bottom: 32px !important;
    padding-bottom: 32px;
  }


  @media #{$la_lg} {
    margin: 10px 0 24px 41px;
    flex-direction: row;
  }

  .left {
    flex-grow: 1;
    width: 100%;

    @media #{$la_lg} {
      width: auto;
    }
  }

  .right {
    margin: 20px 0 30px 0;

    @media #{$la_lg} {
      margin: 0;
      padding: 0 0 0 20px;
    }

    //flex-grow: 1;
  }
}

.location-info {
  font-family: $rionasans-bold;
  font-size: 19px;
  font-weight: 700;
  line-height: 23px;
  color: #525968;
}
