@import '../../../../styles/vars.scss';
@import '../../../../components/forms/FormFrame/FormFrame.scss';
$sm: '(min-width: 650px)';
$md: '(min-width: 800px)';
$mainColor: #8cc63f;
.scp-questionnarie{
  @media #{$mobile} {
    padding: 0 16px;
  }
  color: #4a494b;
  -webkit-font-smoothing: antialiased;
  min-height: calc(100vh - 290px);
  .mobile-title{
    font: $scp-title1-font;
    color: #262837;
    height: 80px;
    vertical-align: center;
    padding-left: 18px;
    line-height: 58px;
    @media #{$media-header-visible} {
     display: none;
    }
  }
  .questionarie-survey-form {
    width: 100%;
    max-width: fit-content;
    margin: 0px auto;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    color: #4a494b;
    padding: 25px 0;
    @media #{$sm} {
    padding: 40px 119px 0px 85px;
    }

    @media #{$md} {
      min-width: 1020px;
    }

    h3 {
    color: $text-color-V2;
    font: normal bold 22px/1.45 $rionasans-bold;
    -webkit-font-smoothing: antialiased;
    display: none;
    @media #{$sm} {
      display: block;
      margin-bottom: 32px;
    }
  }
.questionnaire-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media #{$sm} {
    flex-wrap: nowrap;
    flex-direction: row-reverse;
  }
  @media #{$md} {
    max-width: 1085px;
  }
  .left-block {
    flex: 0 0 300px;
    padding: 0 10px 30px 20px;
    @media #{$sm} {
      padding: 83px 10px 30px 20px;
    }
    .care-coord {}
  }
  .survey-container {
    flex: 1 1 550px;
    padding-bottom: 10px;
    @media #{$mobile} {
      margin: auto 10px;
    }
  }
}
}
}
.questionrie-zingtree {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  max-width: 1020px;
  margin: 0px auto;

  > .min-height {
    min-height: 670px;
  }
}
