@import ' ../../../../../../styles/vars';

$paddingLeft: 50px;

.test-request-wizard.scp-confirm-form {
  padding: 0 15px !important;
  margin: 31px 0 31px 0 !important;

  @media #{$la_lg} {
    padding: 40px 0px !important;
    margin: 0 auto !important;
  }

  .gm-select-service__header {
    margin-bottom: 20px !important;
    font: $scp-title-font-V3 !important;
    color: $text-color-V2;
  }

  .confirm-form-content {
    position: relative;

    @media #{$la_md} {
      padding: 0 0px 0 0;
    }

    .confirm-form-items {
      display: flex;
      flex-flow: row wrap;
      margin-bottom: 20px;

      @media #{$la_sm} {
        max-height: 270px;
      }

      .confirm-content-item {
        margin: 12px 80px 12px 0px;
        width: 100%;
        text-align: left;
        white-space: pre;

        @media #{$la_sm} {
          width: 309px;
        }

        .gm-service-text {
          margin-bottom: 0;
          color: $text-color-V2;
          letter-spacing: normal;
          font: $scp-review-page-label-font-V3;
          white-space: normal;
          word-wrap: break-word;
        }

        .gm-service-text__grey {
          letter-spacing: normal;
          color: $light-gray-text-color-v2;
          font: $scp-review-page-label-font-V3;
        }
      }
    }
  }

  .fine-print {
    padding: 12px 0px 32px 0px;
    letter-spacing: normal;
    color: $light-gray-text-color-v2;
    font: $scp-review-page-label-font;
    display: flex;
    font-size: 16px;
    line-height: 20px;
  }

  .cancellation-policy-section {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    padding: 32px 0px;
    border-top: 1px solid $scp-border-color;
    border-bottom: 1px solid $scp-border-color;
    margin-bottom: 32px;

    .visit-amount-section {

      .total-fee-section {
        letter-spacing: normal;
        font: $scp-review-page-value-font-V3;
        color: $light-gray-text-color-v2;

        @media #{$la_sm} {
          width: auto;
        }

        .visit-fee {
          padding-left: 7px;
          font: $scp-review-page-price-font;
          color: $text-color-V2;
        }
      }
    }
  }

  .buttons {
    justify-content: flex-end;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 100%;
    margin-top: 32px;
    float: none;

    .button-back {
      margin-right: 56px;
      height: 40px;
      width: 200px;
      background-color: $white-bg-color-v2 !important;
      border: 1px solid $purple-500;
      text-align: center !important;
      font: $scp-submit-button-font-V3 !important;
      text-transform: capitalize !important;
      color: #5B43BD;


      &:disabled {
        background-color: $purple-200 !important;
      }

      &:hover:not([disabled]) {
        background-color: rgba(123, 38, 201, 0.15) !important;
        text-decoration: none;
        border: 1px solid $purple-500 !important;
        color: $light-indigo-V2 !important;
      }

      &:focus:not([disabled]) {
        background-color: $purple-500 !important;
      }

      &:active:not([disabled]) {
        background-color: rgba(123, 38, 201, 0.3) !important;
      }
    }

    .button.button-next {
      margin-right: 0 !important;

      .btn-default {
        height: 40px !important;
        width: 200px !important;
        font: $scp-submit-button-font-V3 !important;
      }
    }
  }

  .button.button-next.book-appointment {
    [data-pp-btn='true'] {
      padding: 0 0 !important;
    }
  }
}

/* For Mobile Device */
.mobile-schedule-container {
  .test-request-wizard.scp-confirm-form {

    .review-form {
      .buttons {
        margin-top: 32px;
        flex-direction: column-reverse;

        >div {
          width: 100%;
        }

        .button.button-next {
          margin-right: 0;
        }

        .button.book-appointment {
          margin-bottom: 16px;
        }

        .button.button-next .btn-default {
          width: 100% !important;
          border-radius: 6px !important;
          height: 40px !important;
          font: normal 500 16px $rionasans-medium !important;
        }

        .button-back {
          margin-right: 0;
          width: 100% !important;
          border: 1px solid $purple-500;
          font: normal 500 16px $rionasans-medium !important;
          display: flex !important;
          justify-content: center;
          align-items: center;
          border-radius: 6px !important;
          height: 40px !important;
        }
      }
    }
  }
}