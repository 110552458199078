@import '../../../../styles/vars';

[role='scp-modal']
.modal-backdrop.in {
  background-color: rgba(0,0,0,.45);
}

[role='scp-video-modal']
.modal-backdrop.in {
  background-color: $black-bg-color-v2;
  opacity: 0.75;
}

[role='scp-modal']
.modal {
  text-align: center;
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
    @media (max-width: 400px) {
      content: '';
    }
  }
  .modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    margin: auto; // --- end fix for phones ---
    .modal-content {
      border-radius: 4px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35);
      background-color: $white-bg-color-v2;
      border: none;
      .modal-header {
        height: 48px;
        border-bottom: 0;
      }
    }
  }
}

[role='scp-modal']
.modal-close-button {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 24px;
  color: #000;
  cursor: pointer;
  padding: 12px 20px;
  opacity: 0.2;
  text-decoration: none;
  &:hover {
    opacity: 0.5;
  }
}

// copypasted modal max-sizes
@mixin modal-width-mixin {
  width: calc(100% - 10px);
  @media (min-width: 500px) {
    width: calc(100% - 40px);
  }
}

[role='scp-modal']
.md-full {
  &:before {
    content: none !important;
  }
  .modal-dialog {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: auto;
  }
}

[role='scp-modal']
.md900 {
  .modal-dialog {
    @include modal-width-mixin;
    max-width: 900px;
  }
}

[role='scp-modal']
.scp-md368 {
  .modal-dialog {
    @include modal-width-mixin;
    max-width: 368px;
  }
}

[role='scp-modal']
.scp-md448 {
  .modal-dialog {
    @include modal-width-mixin;
    max-width: 448px;
  }
}

[role='scp-modal']
.scp-md464 {
  .modal-dialog {
    @include modal-width-mixin;
    max-width: 464px;
  }
}

[role='scp-video-modal']
.modal-dialog {
  .modal-content{
    background-color: unset;
  }
}

[role='scp-video-modal']
.md1061 {
  .modal-dialog {
    @include modal-width-mixin;
    max-width: 1061px;
  }
}
