@import '../../../../styles/vars';

.scp-yellow-hint {
  display: flex;
  border-right: 1px solid #d8dde4;
  border-bottom: 1px solid #d8dde4;
  border-left: 1px solid #d8dde4;
  padding: 24px 30px;

  .hint-icon {
    padding: 0 20px 0 10px;
  }
  .hint-content {
    width: 100%;
    font: normal 14px/1.36 $rionasans-regular;
    -webkit-font-smoothing: antialiased;
  }
}

.ninetyDays {
  .scp-yellow-hint {
    border-right: 2px solid $scp-border-color;
    border-left: 2px solid $scp-border-color;
    border-bottom: 2px solid $scp-border-color;
    padding: 24px;

    .hint-content {
      border-top: 1px solid #d8dde4;
      padding-top: 24px;
    }
  }
}