@import "../../../../styles/vars.scss";

$md: "(min-width: 590px)";
$max-md: "(max-width: 589px)";

.privacy-policy-form-container {
  background: $page-background-color-V2 url("../../../../images/gray-ribbon-v2.svg") center/100% no-repeat fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .ribbon-img {
    position: absolute;
    width: 100vw;
  }

  .privacy-wrapper {
    max-width: 816px;
    margin: 24px auto;
    background: $white-bg-color-v2;
    z-index: 0;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    -webkit-font-smoothing: antialiased;
    padding: 12px 16px;

    @media (min-width: 700px) {
      padding: 32px 40px;
    }
  }

  .privacy-header {
    width: 100%;
    border-bottom: solid 0.5px #edeff3;

    @media (min-width: 700px) {
      border-bottom: solid 2px #edeff3;
    }

    .genome-header-logo {
      width: 100%;
      text-align: center;
      margin-bottom: 24px;

      .genome-header-img {
        max-width: 130px;
      }
    }

  }

  .privacy-body {
    width: 100%;

    .agree-container {
      margin-top: 24px;
      margin-bottom: 12px;
      padding-top: 24px;
      border-top: 2px solid #edeff3;

      @media (min-width: 700px) {
        margin-bottom: 24px;
        margin-top: 24px;
      }

      p {
        text-align: center;
        font: normal 14px/1.71 $rionasans-regular;
        color: $text-color-V2;

        .font-medium {
          font-family: $rionasans-medium;
        }

        margin-bottom: 8px;

        @media (min-width: 700px) {
          text-align: left;
          margin-bottom: 0;
        }

        .agree-continue {
          font-family: $rionasans-medium;
        }
      }

      .buttons-containers {
        margin-top: 4px;

        @media (min-width: 700px) {
          margin-top: 40px;
        }

        .action-buttons {
          display: flex;
          justify-content: flex-end;

          @media #{$mobile} {
            flex-direction: column-reverse;
          }

          >button {
            min-width: 200px;
            height: 40px;
            margin-bottom: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            border: 1px solid $light-indigo-V2;
            color: $light-indigo-V2;
            font-family: $rionasans-bold;
            font-size: 16px;
            background-color: $white-bg-color-v2;
            cursor: pointer;

            @media #{$mobile} {
              min-width: unset;
              width: 100%;
              margin-bottom: 16px;
            }

            &:disabled {
              opacity: 0.35;
            }

            &.btn-back {
              margin-right: 16px;

              &:disabled {
                cursor: not-allowed;
              }

              &:hover:not(:disabled) {
                background-color: rgba(123, 38, 201, 0.15);
              }

              &:active {
                background-color: rgba(123, 38, 201, 0.3) !important;
              }
            }

            &.btn-next {
              background-color: $light-indigo-V2;
              color: $white-bg-color-v2;

              &:disabled {
                cursor: not-allowed;
              }

              &:hover:not(:disabled) {
                background-color: $scp-button-hover-color;
              }

              &:active {
                background-color: $purple-600 !important;
              }
            }
          }
        }
      }
    }
  }
}