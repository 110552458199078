@import '../../../../styles/vars.scss';

.checkbox-wrapper {
  display: flex;
  width: 100%;

  .guardian-label {
    display: flex;
    align-items: flex-start;
    position: relative;
    cursor: pointer;

    @media #{$mobile} {
      margin: unset;
      padding: 10px 0;
    }

    .checkmark {
      position: relative;
      top: 3px;
      left: 0;
      height: 14px;
      width: 14px;
      background-color: #ffffff;
      border: 1px solid #a0abb9;
      border-radius: 2px;
      margin-right: 9px;
    }

    .checkmark:hover:not(.is-disabled) {
      border: 1px solid $purple-500 !important;
    }

    .checkmark:focus,
    .checkmark:active {
      border: 1px solid $purple-300;
    }

    &:hover input ~ .checkmark {
      background-color: #ffffff;
    }

    input:checked ~ .checkmark {
      background-color: #ffffff;
      border: 1px solid $purple-300;
    }

    input:checked ~ .checkmark:after {
      display: block;
    }

    .checkmark:after {
      content: '';
      position: absolute;
      left: 4px;
      top: 1px;
      width: 4px;
      height: 8px;
      display: none;
      border: solid $purple-500;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    input[type='checkbox'] {
      margin: 0 9px 0 0;
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .label-section {
      width: calc(100% - 23px);

      .privacy-link {
        color: $light-indigo-V2;
        cursor: pointer;
        text-decoration: none;
      }

      .privacy-link:hover {
        text-decoration: underline;
      }
    }
  }

  .checkbox-description {
    @media #{$mobile} {
      padding: 10px 0;
    }
    margin-top: 3px;
    margin-left: 5px;
  }
}
