@import "../../../styles/vars";

.radioButtonGroupContainer {
    width: 100%;
  }

  .radioButtonRowContainer {
    width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    padding-left: 14px;
  }
  .radioButtonRowContainer > .el-radio__label > .singleSelectRadioLabel {
    padding-left: 13px;
  }

  .radioButtonRowContainer > span.el-radio__input.is-checked > span.el-radio__inner {
    border-color: $purple-500 !important;
    background: unset !important;
  }

  .radioButtonRowContainer > span.el-radio__input.is-checked > span.el-radio__inner::after {
    background-color: $purple-500 !important;
    width: 7.5px;
    height: 7.5px;
  }

  .radioButtonRowContainer > span.el-radio__input {
    padding-top: 1px;
    padding-left: 1px;
  }

  .radioButtonRowContainer > span.el-radio__input > span.el-radio__inner {
    border: 2px solid #939394;
    width: 17px;
    height: 17px;
  }

  .radioButtonRowContainer > span.el-radio__input > span.el-radio__inner:hover {
    border-color: $purple-500 !important;
  }

  .radioButtonRowVariantContainer {
    width: unset;
    display: flex;
  }

  .rowVariantRadioButton {
    margin-right: 5px !important;
    padding-right: 5px;
  }

  .rowVariantRadioLabel {
    padding-left: 3px !important;
  }
