@import '../../../../../styles/vars';

.scp-my-health-page {
  color: $gray-text-color-v2;
  padding-top: 16px;
  -webkit-font-smoothing: antialiased;
  min-height: calc(100vh - 290px);
  background-color: white;

  @media #{$la_sm} {
    background-color: #EDEFF3;
  }

  .mobile-title {
    font: $scp-title1-font;
    color: #262837;
    background-color: white;
    height: 80px;
    vertical-align: center;
    padding-left: 18px;
    padding-top: 5px;
    line-height: 58px;
    border-top: 1px #EDEFF3 solid;

    @media #{$media-header-visible} {
      display: none;
    }

  }

  .document-not-found-alert {
    margin-left: 48.5px;
    margin-right: 32px;
  }

  .summary-page-block,
  .results-page-block {
    width: 100%;
    max-width: 1020px;
    margin: 0px auto;
  }

  .scp-my-health-container {
    width: 100%;
    max-width: 1024px;
    margin: 0px auto;
    border-radius: 4px;
    background-color: #ffffff;
    color: #4a494b;
    display: flex;
    flex-flow: row wrap;

    &.full-height {
      min-height: auto !important;
    }

    @media #{$la_lg} {
      flex-flow: initial;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }

    .scp-responsive-step-panel__desktop {
      min-width: 198px;
      flex-basis: 198px;
      width: 198px;
    }

    .scp-my-health-step-view {
      @media #{$la_sm} {
        width: 100%;
      }

      @media #{$mobile} {
        width: 100%;
      }

      .scp-myheath-header {
        font-family: $rionasans-bold;
        font-size: 22px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.45;
        letter-spacing: normal;
        text-align: left;
        color: #262837;
        margin-bottom: 32px;
        display: none;

        @media #{$media-header-visible} {
          display: block;
        }
      }
    }

    .scp-responsive-step-panel__mobile {
      max-width: 340px;
      margin: 10px auto;

      .controlled-button.btn {
        height: 62px;
        width: 150px;
        white-space: normal;
        word-wrap: break-word;
      }

      .controlled-button-group .controlled-button.controlled-button:active {
        box-shadow: none;
      }

      .controlled-button-group .controlled-button.controlled-button {
        border: none;
      }

      .controlled-button-group .controlled-button.controlled-button__active {
        color: $purple-500;
        border-radius: 0px;
        border: none;
        border-bottom: 4px solid $purple-500;
        background-color: white;
      }
    }
  }

  .table-responsive {
    border: none;
  }

  .table {

    table-layout: fixed;
    border: none;
    margin-bottom: 80px;
    width: 100%;

    @media #{$la_sm} {
      margin-bottom: auto;
    }

    thead tr {
      height: 45px;
      border-bottom: 1px solid #CBCBCB;

      th {
        font-family: RionaSans;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: #7b8291;
        border-bottom: none;
        white-space: break-spaces;
      }

      th.narrow-cell {
        width: 100px;
      }

      th.actions-cell {
        width: 60px;
      }
    }

    tbody tr {
      border: none;

      @media #{$la_sm} {
        border-bottom: 1px solid #CBCBCB;
      }

      &.mobile-actions-row {
        border-bottom: 1px solid #CBCBCB;
      }

      td {
        height: 70px;
        vertical-align: top;
        max-width: 300px;
        overflow: hidden;
        overflow-wrap: break-word;
        white-space: normal;
        text-align: left;
        border: none;
        font-family: RionaSans;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.36;
        letter-spacing: normal;
        text-align: left;
        color: #262837;

        @media #{$la_sm} {
          height: 82px;
          vertical-align: middle;
        }
      }
    }
  }

  .results-summary-year {
    display: block;
    padding-left: 3px;
    color: $light-gray-text-color;

    @media #{$la_sm} {
      display: inline;
      color: $dark-gray-text-color;
      padding: 0;

      &:before {
        content: '.';
      }
    }
  }

  .results-summary-date {
    display: block;
    padding-left: 3px;
    color: #000000;

    @media #{$la_sm} {
      display: inline;
      color: #000000;
      padding: 0;

    }
  }
}
