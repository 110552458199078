@import '../../../../styles/vars';
$full-width: auto;
$md: '(min-width: 830px)';

.appointment-details {
  display: flex;
  margin-top: 35px;
  font-family: $sans;
  justify-content: center;
  overflow-x: hidden;

  width: 100%;
  flex-wrap: wrap;
  padding: 0 10px;

  @media #{$md} {
    flex-wrap: nowrap;
    max-width: 812px;
    width: 812px;
    margin: 0px auto;
  }

  @media #{$mobile} {
    margin-top: 0;
    padding: 0 15px;
  }

  .options {
    flex: 1 1 300px;
    max-width: 300px;
    min-width: 230px;
    margin: 0 0 50px 0;

    @media #{$md} {
      margin: 0 30px 0 0;
    }
  }

  .ap-calendar.scp {
    padding: 20px;
    flex: 0 0 100%;
    position: relative;
    width: 100%;

    @media #{$la_lg} {
      padding: 0;
    }

    @media #{$md} {
      flex: 0 1 794px;
    }

    @media #{$mobile} {
      padding: 20px 0;
    }

    .Select.is-open,
    .Select-menu-outer {
      z-index: 1000;
    }

    .header-dark {
      color: $coupon-dark-gray-color;
      margin: 40px 0 32px;
      font: $scp-title1-font;
      line-height: 1.45;
      letter-spacing: normal;
      text-align: unset;

      span {
        color: $scp-calendar-header-light-color;
        font-weight: normal;
        font-family: $rionasans-regular;

        @media #{$mobile} {
          display: block;
        }
      }
    }

    .selected-service {
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      margin: 5px 0 40px;
      color: $coupon-dark-gray-color;
    }

    .care-coordinator {
      margin: 50px 0 50px 40px;
    }

    .state-arrow-container {
      left: 40px;

      img.state-arrow {
        width: 40px;
        height: 122px;
      }

      p.state-arrow-text {
        font: $scp-submit-button-font;
        top: 100px;
        left: 45px;
      }
    }

    .header-need-help {
      margin: 24px 0 8px;
      font: $header6-font;
      color: $text-color-V2;
    }

    .contact-mode-box-wrapper {
      display: flex;
      margin-bottom: 40px;
      font: normal 500 12px/1.42 $rionasans-medium;
      color: $light-gray-text-color-v2;

      @media #{$mobile} {
        margin-bottom: 30px;

        a {
          padding: 10px 0;
        }
      }

      .contact-mode-box {
        flex: 1 1 0px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 8px;
        max-width: 256.67px;
        border-radius: 4px;
        border: 1px solid $scp-border-color;

        @media #{$mobile} {
          padding: 8px;
          width: 0;

          span {
            word-break: break-all;
          }
        }

        .contact-mode {
          margin: 4px 0;
          font: normal bold 16px/1.43 $rionasans-bold;
          color: $text-color-V2;
        }

        .cc-phone,
        .cc-email,
        .gm-link {
          color: $light-indigo-V2  !important;
        }
      }

      .contact-mode-box + .contact-mode-box {
        margin-left: 16px;

        @media #{$mobile} {
          margin-left: 8px;
        }
      }
    }

    .buttons {
      margin-bottom: 40px;

      @media #{$mobile} {
        margin-bottom: 10px;
      }

      .button-back {
        color: $purple-500;
        text-decoration: none;
        cursor: pointer;
        text-align: left;
        font: normal 500 13px/1.38 "RionaSans-Medium", Arial, Helvetica, sans-serif;
        letter-spacing: normal;
      }

      .button-back:hover {
        text-decoration: underline;
      }

    }

  }
}
