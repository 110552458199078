@import '../../styles/vars';
.privacy-story {
    margin: 0;
    padding: 24px 0 0;
    -webkit-font-smoothing: antialiased;

    @media screen and (min-width: 425px) {
      margin: 0;
    }
    h2 {
        font: normal normal 32px/1.44 $rionasans-bold;
        color: $text-color-V2;
        &.v2{
          @media #{$mobile} {
            font-size: 33px;
          }
        }
    }
    h3 {
        font: normal normal 24px/1.42 $rionasans-bold;
        max-width: 100%;
        &.v2{
        @media #{$mobile} {
          font-size: 19px;
        }
      }
    }
    .bullet-point {
        font: normal normal 18px/1.39 $rionasans-bold;
        margin: 24px 0 8px;
    }
    p {
        font: normal 14px/1.43 $rionasans-regular;
        &.v2{
          @media #{$mobile} {
            font-size: 16px;
          }
        }

        margin: 8px 0 40px 0;
        ol {
            counter-reset: item;
            padding: 0;
            li {
                list-style-type: none;
                counter-increment: item;
                margin-bottom: 8px;
                &:before {
                    content: counter(item) ".";
                    font-weight: bold;
                    padding-right: 5px;
                }
            }
        }
        @media #{$mobile} {
            a {
                padding: 10px 0;
            }
        }
    }
    p.font-bold {
      font-weight: bold;
      margin-bottom: 8px;
    }
    span.font-bold {
      font-weight: bold;
    }
    p:last-child {
        margin-bottom: 0;
    }
    .address-upset {
      margin-left: 60px;
    }
    img {
      width: 100%;
    }

    .center-header{
      text-align: center;

      &.v2{
        @media #{$mobile} {
          text-align: left;
        }
      }
    }
}

.grey-text{
  color: $light-gray-text-color-v2 !important;

}
