@import "../../../../../styles/vars";
@import "../../../../../styles/mixins";

.scp-home-container {
  -webkit-font-smoothing: antialiased;
  padding: 24px 0px 0px 0px;
  display: flex;
  justify-content: flex-start;

  @media #{$la_lg} {
    padding-top: 37px;
    justify-content: center;
    min-height: calc(100vh - 290px) !important;
  }

  > div {
    .left {
      .title {
        padding: 0;
      }
    }
    .title {
      font: normal bold 22px/1.45 $rionasans-bold;
      color: $text-color-V2;
      margin-bottom: 24px;
      padding: 0 16px;
      @media #{$la_lg} {
        padding: 0;
        margin-bottom: 33px;
        min-width: 551px;
      }

      .user-name{
        text-transform: capitalize;
      }
    }

    .cards-wrapper {
      display: flex;
      justify-content: center;

      &.multi-section{
        @media #{$mobile} {
          flex-direction: column;  
        }
        
      }

      .gm-alert {
        max-width: 795px;
        @media #{$mobile} {
          margin: 20px 16px;
        }

        .galleri-note {
          font-family: $rionasans-bold;
          margin-top: 10px;
          font-size: 16px;
        }
      }

      .patient-cards {
        @media #{$la_lg} {
          max-width: 551px;
          width: initial;
          margin-right: 16px;
        }

        > div {
          width: 98%;
          @media #{$la_lg} {
            width: 551px;
          }
        }
      }

      .test-info-box {
        max-width: 343px;
        display: none;
        @media #{$la_lg} {
          display: block;
        }
        @media #{$mobile} {
          width: 98%;
          max-width: none;
          &.natera-affilitaion{
            display: block;
          }
          
        }
      }
    }
  }
}

@media #{$la_md} {
  .scp-home-container {
    padding: 24px 15px 0px 15px;
  }
}
