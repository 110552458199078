@import "../../../../styles/vars";

.insurance-dedectible-card {

  -webkit-font-smoothing: antialiased;
  position: relative;
  margin-top: 16px;

  @media #{$la_md} {
    max-width: 551px;
    width: initial;
    margin-right: 16px;
  }

  @media #{$mobile} {
    padding: 0 16px;
  }

  span.badge-icon {
    position: absolute;
    left: -12px;
    top: -12px;
    display: block;
    background: $purple-500;
    color: #ffffff;
    font-size: 18px;
    line-height: 24px;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    text-align: center;

    @media #{$mobile} {
      left: 5px;
    }
  }

  .container {
    width: 100%;
    padding: 16px;
    margin: 0 0 8px;
    display: flex;
    border-radius: 4px;
    border: 1px solid $purple-300;
    background-color: $purple-100;

    @media #{$la_md} {
      margin: 0 0 8px;
    }

    .left {
      min-width: 98px;
      align-self: center;
      text-align: center;
      width: 33%;

      @media #{$la_md} {
        width: initial;
      }

      img {
        width: 68px;
        height: 47.9px;
        margin: 23px 28px 45.1px 0;
      }
    }

    .right {
      margin-right: 16px;
      width: 66%;

      @media #{$la_md} {
        min-width: 415px;
        width: initial;
      }

      .card-title {
        font: normal bold 16px/1.38 $rionasans-bold;
        color: #262837;
        margin-bottom: 16px;
      }

      .verify-text {
        font: normal 14px/1.43 $rionasans-regular;
        color: $text-color-V2;
      }
    }
  }
}