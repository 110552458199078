@import '../../../../styles/vars.scss';
@import './ReferralsPage.scss';
$referral_blue: #00adee;
$navy: #0a80ff;
.referral-iframe {
  .ref-frame.referral-block {
    padding: 0;
    .steps {
      .stepspanel-container.qn-steps {
        @media #{$la_lg} {
          margin-top: 55px;
        }
        .step-item {
          margin: 0;
          .step-item-header {
            color: #000000;
            
            font-size: 14px;
            line-height: 33px;
          }
          &.step-done {
            .step-item-header {
              font-weight: bold;
            }
          }
        }
      }
      .clinician-container.ref-care-coord {
        color: #000000;
        
        font-size: 14px;
        line-height: 21px;
        .info-block {
          display: block;
          .clinician-photo {
            width: auto;
            height: auto;
          }
          .clinician-info {
            margin-left: 0;
            .clinician-name {
              color: #332a85;
            }
          }
        }
        .contacts-info {
          max-width: 161px;
        }
      }
    }
    .ref-form.form-frame {
      .section-name {
        
        font-size: 30px;
        font-weight: 300;
        line-height: 33px;
        color: $referral_blue;
        text-align: left;
      }
      .text-center {
        text-align: left;
      }
      .gm-form-field {
        .gm-form-label {
          color: #000000;
          
          font-size: 12px;
          font-weight: 500;
          line-height: 33px;
          text-transform: uppercase;
        }
        .gm-form-control {
          margin-top: 0;
        }
        .checkbox-group-container {
          margin: 0;
        }
      }
      .buttons-block {
        &.start-block {
          justify-content: start;
          margin: 0;
        }
        .outer-button.button-next {
          .btn.btn-default {
            background-color: $referral_blue !important;
            border-radius: 5px;
            width: 161px;
          }
        }
      }
      .buttons-block.submit-block {
        width: 240px;
        .button-next {
          button:after {
            content: 'Submit';
          }
        }
      }
      .mb20.mt20 {
        color: #838383;
        
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0;
      }
    }
    .fax-panel {
      .fax-form {
        @media #{$la_lg} {
          margin-top: 65px;
        }
        .block {
          background-color: transparent;
          padding: 0;
          .fax-title {
            max-width: 250px;
            color: #000000;
            
            font-size: 16px;
            line-height: 22px;
            text-align: left;
          }
          .icon-container {
            display: flex;
            margin: 16px 0;
            padding: 19px 0;
            box-sizing: border-box;
            border: 1px solid rgba(151, 151, 151, 0.5);
            border-right: none;
            border-left: none;
            .refer-link {
              width: 161px;
              color: $navy;
              
              font-size: 12px;
              line-height: 17px;
              text-align: left;
            }
          }
        }
        .refer-contacts {
          
          font-size: 14px;
          line-height: 21px;
          .gm-link {
            color: #0a80ff !important;
          }
        }
      }
    }
  }

  .thank-you-page {
    .content {
      
      font-size: 21px;
      line-height: 33px;
      max-width: 700px;
      color: #828282;

      .ref-actions .gm-link {
        color: #0a80ff !important;
      }

      .buttons-container {
        .refer-new {
          background-color: $referral_blue !important;
          border-radius: 5px;
        }
      }
    }
  }
}
