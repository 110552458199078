@import '../../../../styles/vars.scss';

.radio-group-wrapper {
    .el-radio-group {
        .el-radio-button {
            margin: 0 8px 0 0;

            .el-radio-button__inner {
                width: 72px;
                height: 32px;
                padding: 0;
                justify-content: center;
                align-items: center;
                font-family: $rionasans-medium;
                border-radius: 24px;
                font-size: 14px;
                font-weight: 500;
                color: #262837;
                border: 1px solid #edeff3;
                display: flex;
                -webkit-font-smoothing: antialiased;

                @media #{$mobile} {
                    min-height: 40px;
                }
            }
        }

        .el-radio-button.is-active {
            .el-radio-button__inner {
                background-color: $purple-200 ;
                border-color: $purple-200;
                box-shadow: unset;
            }
        }

        .el-radio-button__inner:hover {
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15) !important;
        }

    }

    .el-radio-button.pill-disabled {
        .el-radio-button__inner:hover {
            box-shadow: none !important;
        }

        .el-radio-button__inner {
            color: $field-border-color-V2;
        }
    }

    .input-error {
        font-size: 12px;
        font-family: "RionaSans", Arial, Helvetica, sans-serif;
        -webkit-font-smoothing: antialiased;
        color: #ff0000;
        height: 19px;
        height: auto;
    }
}