@import '../../../../../styles/vars';

$md: '(min-width: 700px)';

.scp-schedule-wizard.scp-payment-review-stage {
  max-width: 860px;
  width: 100%;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
  padding: 0 15px !important;
  margin: 31px 0 31px 0 !important;

  @media #{$la_lg} {
    padding: 40px 0px !important;
    margin: 0 auto !important;
  }

  .gm-select-service_header {
    margin-bottom: 32px;
    display: inline-block;
    color: #262837;
    font: normal bold 23px/27.6px $rionasans-bold;
    letter-spacing: normal;
    text-align: center;
  }
  .scp-payment-personal-form{
    .coupon-section{
      @media #{$mobile} {
        margin-bottom: 15px;
        min-height: calc(670px - 330px);


      }
      @media #{$la_lg}{
        min-height: calc(670px - 260px);
      }
      }

.action-buttons {
  display: flex;
  justify-content: flex-end;

  @media #{$mobile} {
    flex-direction: column-reverse;
  }

  >button {
    min-width: 200px;
    height: 40px;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid $light-indigo-V2;
    color: $light-indigo-V2;
    font-family: $rionasans-bold;
    font-size: 16px;
    background-color: $white-bg-color-v2;
    cursor: pointer;

    @media #{$mobile} {
      min-width: unset;
      width: 100%;
      margin-bottom: 16px;
    }

    &:disabled {
      opacity: 0.35;
    }

    &.btn-back {
      margin-right: 56px;

      &:disabled {
        cursor: not-allowed;
      }

      &:hover:not(:disabled) {
        background-color: rgba(123, 38, 201, 0.15);
      }

      &:active {
        background-color: rgba(123, 38, 201, 0.3) !important;
      }
    }

    &.btn-next {
      background-color: $light-indigo-V2;
      color: $white-bg-color-v2;

      &:disabled {
        cursor: not-allowed;
      }

      &:hover:not(:disabled) {
        background-color: $scp-button-hover-color;
      }

      &:active {
        background-color: $purple-600 !important;
      }
    }
  }
}
    }
  }

