@import '../../styles/vars';


.day-select-container {
  border-bottom: 1px solid #d8dde4;
  padding-bottom: 32px;
  margin-bottom: 32px;

  .choose-a-day {
    font-family: $rionasans-bold;
    font-size: 19px;
    font-weight: 700;
    line-height: 23px;
    color: #525968;

  }

  .date-time-week-select {
    text-align: center;
    margin: 16px 0;

    .date {
      font-family: $rionasans-bold;
      font-size: 19px;
      font-weight: 700;
      line-height: 23px;
      letter-spacing: 0em;
      text-align: center;
      margin-left: 16px;
      margin-right: 16px;
    }

    .arrow-right {
      position: relative;
      bottom: 4px;
      cursor: pointer;
    }

    .arrow-left {
      position: relative;
      bottom: 3px;
      cursor: pointer;

    }
  }

  .days-container {
    display: flex;
    flex-direction: row;
    border: 1px solid #D8DDE4;
    border-radius: 4px;

    .days-list {
      cursor: pointer;
      &.active {
        border: 1px solid #43349C;
        background-color: #5B43BD;

        .day-name {
          background-color: #5B43BD;
          color: #FFFFFF;
          border-bottom: 1px solid #43349C;
          padding-top: 7px;
        }

        .date-name {
          >div{
            color: #FFFFFF;

          }
        }
      }
      &.disabled {
        cursor: not-allowed;
        .day-name{
          color: #A0ABB9;
        }
        .date-name{
          >div{
            color: #A0ABB9;
          }


        }
      }

      .day-name {
        font-family: $rionasans-bold;
        font-size: 14px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
        background-color: #D8DDE4;
        padding: 8px 38px 0 38px;
        color:#525968;

        @media #{$mobile} {
          padding: 8px 5px 0 5px;
        }
      }

      .date-name {
        padding: 16px 38px 8px 38px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        @media #{$mobile} {
          padding: 16px 8px 8px 8px;
        }
        >div{
          font-family: $rionasans-regular;
          font-size: 14px;
          font-weight: 400;
          line-height: 14px;
          text-transform: uppercase;
          color: #525968;

          span {
            font-family: $rionasans-bold;
            font-size: 16px;
            font-weight: 700;
            line-height: 16px;
            width: 100%;
            display: inline-block;
          }
        }

      }
    }
  }
}
