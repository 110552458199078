@import '../../../styles/vars';
.password-strength {
  position: relative;
  background-color: white;
  width: 100%;
  min-height: 225px;
  text-align: left;
  padding: 21px 33px;
  margin: 10px auto 10px auto;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.18);

  @media #{$media-services-page} {
    display: block;
    max-width: 280px;
  }

  h4 {
    color: #4A494B;
    margin-bottom: 15px;
  }
  div {
    line-height: 25px;
  }
}

.password-strength-left {
  //display: none;

  @media #{$media-services-page} {
    display: block;
    &:before {
      content: '';
      display: block;
      position: absolute;
      left: -30px;
      top: 85px;
      border: 15px solid transparent;
      border-right: 15px solid rgba(0, 0, 0, 0.1);
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: -30px;
      top: 84px;
      border: 16px solid transparent;
      border-right: 16px solid white;
    }
  }
}

.circled {
  display: flex;
  align-items: flex-start;
  .circle {
    min-width: 10px;
    height: 10px;
    border-radius: 10px;
    margin: 9px 7px 0 0;
    &.red {
      background-color: #FF0000;
    }
    &.green {
      background-color: #8CC63F;
    }
  }
  .error-text {
  }
}
