@import '../../../../styles/vars.scss';

$md: '(min-width: 590px)';
$max-md: '(max-width: 589px)';

.signup-container {
  background: $page-background-color-V2 url('../../../../images/bg-patients.svg') center repeat fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  min-height: calc(100vh - 290px);

  .ribbon-img {
    position: absolute;
    width: 100vw;
  }

  .signup-wrapper {
    max-width: 816px;
    margin: 24px auto 100px !important;
    background: $white-bg-color-v2;
    z-index: 0;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    -webkit-font-smoothing: antialiased;
    @media #{$mobile} {
      margin: auto auto !important;
    }
  }

  .signup-header {
    width: 100%;
    padding: 24px 24px 0;

    .genome-header-logo {
      width: 100%;
      text-align: center;
      margin-bottom: 24px;

      .genome-header-img {
        max-width: 130px;
      }
    }

    .genome-header-title {
      width: 100%;
      text-align: center;
      padding-bottom: 24px;
      border-bottom: 1px solid #edeff3;

      @media #{$la_md} {
        padding: 0 108px 24px 108px;
      }

      .signup-title {
        font-family: $rionasans-medium;
        -webkit-font-smoothing: antialiased;
        font-size: 23px;
        margin: 0 0 4px 0;
        color: $text-color-V2;
        font-weight: 500;
        line-height: 27.6px;
      }

      .signup-desc {
        font-family: $rionasans-regular;
        -webkit-font-smoothing: antialiased;
        font-size: 16px;
        color: $text-color-V2;
        line-height: 1.38;
        text-align: center;
      }
    }

    .login-here {
      margin-top: 20px;
      color: $text-color-V2;
      font: $scp-link-button-font;
      line-height: 1.43;
      -webkit-font-smoothing: antialiased;

      .privacy-link {
        color: $light-indigo-V2;
      }
    }
  }

  .signup-body {
    width: 100%;
    padding: 0 24px 0;

    .signup-form {
      .el-tooltip.set-pwd-tooltip>div.el-tooltip__rel {
        margin-top: -3px;
      }

      width: 100%;

      .input-wrapper .el-input .el-input__inner:focus,
      .input-mask-field-wrapper input:focus,
      .input-mask-wrapper input:focus {
        border: solid 1.5px #40b9ec !important;
      }

      .form-section {
        display: flex;
        width: 100%;
        padding: 32px 0;
        border-bottom: 1px solid #edeff3;
        flex-direction: column;

        @media #{$la_md} {
          flex-direction: row;
        }

        .info-section {
          margin-bottom: 16px;

          @media #{$la_md} {
            width: 240px;
            margin-bottom: unset;
          }

          .header {
            color: $light-gray-text-color-v2;
            font-family: $rionasans-bold;
            -webkit-font-smoothing: antialiased;
            font-size: 19px;
            font-weight: 500;
            line-height: 22.8px;
          }

          .body {
            color: $light-gray-text-color-v2;
            font-family: $rionasans-medium;
            -webkit-font-smoothing: antialiased;
            font-size: 12px;
            line-height: 14.4px;
            margin-top: 8px;
          }

          .login-info-body {
            margin-bottom: 44px;

            @media #{$mobile} {
              margin-bottom: 24px;
            }
          }

          .login-here {
            border: 1px solid $purple-500;
            border-radius: 3px;
            padding: 9px 0px 9px 10px;
            font-family: 'RionaSans-Medium', Arial, Helvetica, sans-serif;
            font-weight: 500;
            color: #7b8291;

            .privacy-link {
              color: $purple-500;
              cursor: pointer;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }

        .body-section {
          @media #{$la_md} {
            width: calc(100% - 240px);
          }

          .row-section {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            &.no-margin {
              margin-bottom: 0;
            }

            &.patient-detail-section {
              @media #{$la_md} {
                display: flex;
                flex-flow: wrap;
              }

              .col-section {
                margin-bottom: 24px;
              }
            }

            @media #{$la_md} {
              margin-bottom: 24px;
              flex-direction: row;
            }

            &:last-child {
              @media #{$la_md} {
                margin-bottom: 0;
              }

              .col-section {
                &:last-child {
                    margin-bottom: 0;
                }
                &:nth-last-child(2){
                  @media #{$la_md} {
                    margin-bottom: 0;
                  }
                }
              }
            }

            .hidden-validation {
              display: none;
            }

            .col-section {
              margin-bottom: 20px;

              &.no-margin {
                margin-bottom: 0;
              }

              &.rltnshp-text {
                font-family: $rionasans-medium;
                font-size: 12px;
                line-height: 14.4px;
                color: $light-gray-text-color-v2 ;

                div {
                  margin-top: 8px;
                }
              }
              .required:before {
                content: '*';
                color: $required-red-color;
                font-size: 14px;
                margin-left: -5px;
              }

              &.consent-text {
                .label-section {
                  font-family: $rionasans-bold;
                  font-weight: 700;
                  line-height: 16.8px;
                  color: #262837;
                }
              }

              @media #{$la_md} {
                width: calc(100% / 2 - 24px);
                margin-left: 24px;
                margin-bottom: unset;
              }

              .input-section {
                .child-input-section {
                  .guardian-label {
                    padding: 8px 0 0 0
                  }

                  .label-section {
                    span {
                      font-family: $rionasans-medium;
                      font-size: 12px;
                      line-height: 14.4px;
                      color: $light-gray-text-color-v2;

                      b {
                        font-family: $rionasans-bold;
                      }
                    }
                  }
                }

                .gm-radio-control {
                  height: unset;

                  .gm-radio-label {
                    display: none;
                  }

                  .gm-radio-group {
                    padding-top: 0;

                    .radio {
                      input[type='radio'] {
                        +.radio-label {
                          font-family: $rionasans-medium;
                          font-size: 16px;
                          line-height: 19.2px;
                          color: #262837;

                          &::before {
                            margin-top: 3px;
                            width: 18px;
                            height: 18px;
                          }
                        }

                        &:checked {
                          +.radio-label {
                            &:before {
                              background-color: $light-indigo-V2
                            }
                          }
                        }
                      }
                    }
                  }
                }

              }

              &.full-col {
                width: 100%;

                .checkbox-wrapper {
                  margin-bottom: 10px;
                  display: flex;
                  align-items: center;

                  .guardian-label {
                    margin: unset;
                    padding: unset;

                    .label-section {
                      margin: unset;
                      padding-top: 4px;
                      text-transform: capitalize;
                    }

                    .checkmark {
                      position: relative;
                      height: 20px !important;
                      width: 20px !important;
                      border-radius: 2px;
                      margin-right: 9px;
                      padding: 1px 2px 5px 1px;

                      &:after {
                        content: '';
                        position: absolute;
                        left: 6px;
                        top: 2px;
                        width: 6px;
                        height: 12px;
                        border:solid $purple-500;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                      }
                    }

                    input:checked~.checkmark {
                      background-color: #ffffff;
                      border-color: $purple-500;
                    }
                  }
                }

                .input-section.consent-to-text {
                  .checkbox-wrapper {
                    margin-bottom: 0px !important;

                    .label-section {
                      padding-top: 1px !important;
                      font: normal 12px $rionasans-regular;
                      color: $light-gray-text-color-v2;
                      text-transform: none !important;
                    }

                    .checkmark {
                      height: 14px !important;
                      width: 14px !important;

                      &::after {
                        width: 5px;
                        top: 0px;
                        left: 4px;
                        height: 9px;
                      }
                    }
                  }
                }
              }


              .label-section {
                color: $light-gray-text-color-v2;
                font-size: 14px;
                font-family: $rionasans-medium;
                -webkit-font-smoothing: antialiased;
                line-height: 1.43;
                font-weight: 500;
                margin-bottom: 4px;

                >span {
                  color: $body-text-color-v2;
                }
              }

              .multi-select {
                input {
                  height: 40px !important;
                }
              }

              .dd-gender {
                .el-input {
                  input {
                    text-overflow: ellipsis;
                  }
                }
              }

              .race {
                .el-input {
                  input {
                    text-overflow: ellipsis;
                    padding-right: 15px;
                  }
                }
              }
            }
          }

          .row-section.race-eth-fields {
            display: flex;
            flex-wrap: wrap;

            .full-col {
              margin-top: 24px;
            }
          }

          @media #{$mobile} {
            .row-section.race-eth-fields {
              display: flex;

              .full-col {
                margin-top: 0px;
              }
            }

            .row-section.race-eth-fields> :nth-child(1) {
              order: 1;
            }

            .row-section.race-eth-fields> :nth-child(2) {
              order: 3;
            }

            .row-section.race-eth-fields> :nth-child(3) {
              order: 2;
            }
          }
        }
      }

      .signup-footer {
        width: 100%;

        .agree-field {
          padding: 24px 0;
          border-bottom: 1px solid #edeff3;

          .power-of-attorney {
            width: 100%;
            display: flex;

            .field-section {
              .checkbox-wrapper {
                .guardian-label {
                  .checkbox-wrapper .guardian-label input:checked~.checkmark {
                    background-color: white;
                  }

                  .checkbox-wrapper .guardian-label .checkmark:after {
                    border: solid $purple-500;
                  }
                }
              }
            }

            .label-section {
              font-size: 14px;
              color: #7b8291;
              font-family: $rionasans-medium;
              -webkit-font-smoothing: antialiased;
            }
          }

          .agree {
            width: 100%;
            display: flex;

            .label-section {
              font-size: 14px;
              color: #7b8291;
              font-family: $rionasans-medium;
              -webkit-font-smoothing: antialiased;
            }
          }
        }

        .sign-button-wrapper {
          display: flex;
          justify-content: flex-end;
          flex-direction: column-reverse;
          margin: 24px 0 82px;

          @media #{$la_md} {
            flex-direction: row;
            margin: unset;
          }
        }

        .btn-section,
        .login-here {
          @media #{$la_md} {
            padding: 24px 0;
          }

          .outer-button {
            .btn-default {
              background-color: $purple-500 !important;
              width: 160px;
              height: 48px;
              font-family: $rionasans-bold;
              -webkit-font-smoothing: antialiased;
              font-size: 16px;
              color: #ffffff;
            }

            .btn-default:hover {
              background-color: $purple-400 !important;
            }

            .btn-default:active {
              background-color: $purple-600 !important;
            }

            .btn-default:focus {
              background-color: $purple-500 !important;
              border: 2px solid rgba(189, 146, 228, 0.5) !important;
            }

            .btn-default:disabled {
              background-color: $purple-500 !important;
              opacity: 0.35;
            }
          }
        }

        .login-here {
          font-family: $rionasans-medium;
          -webkit-font-smoothing: antialiased;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 3;
          letter-spacing: normal;
          text-align: center;

          @media #{$la_md} {
            text-align: right;
          }

          color: #7b8291;

          .privacy-link {
            color: $light-indigo-V2;
            cursor: pointer;
            text-decoration: none;

            @media #{$mobile} {
              padding: 10px 0;
            }
          }

          .privacy-link:hover {
            text-decoration: underline;
          }
        }

        .btn-section {
          margin-bottom: 24px;

          @media #{$la_md} {
            margin-left: 20px;
            margin-bottom: unset;
          }

          button {
            width: 100% !important;

            @media #{$la_md} {
              width: unset;
            }
          }
        }
      }
    }
  }
}

.signup-wrapper {
  .signup-header {
    display: flex;
    flex-direction: column;
  }
}

