@import "../../../../styles/vars";

.scp-info-modal {
    .modal-dialog {
        width: 94%;
        height: 314px;
        border-radius: 4px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35);
        background-color: #ffffff;
        border-bottom: none;
        @media #{$la_md} {
            width: 480px;
        }
        .modal-content {
            border-bottom: none;
            .modal-header {
                border-bottom: 1.5px solid $page-background-color-V2;
                height: 56px;
                padding: 17px 16px 17px 16px;
                @media #{$mobile} {
                    height: unset;
                }

                .modal-close-button {
                    font-size: 12px;
                    color: $text-color-V2;
                    opacity: 1;
                    padding: 17px 20px 17px 16px;
                }
                .info-title {
                    font-family: $rionasans-bold;
                    -webkit-font-smoothing: antialiased;
                    font-size: 16px;
                    font-weight: bold;
                    line-height: 1.38;
                    text-align: left;
                    color: $text-color-V2;
                }
            }
            .modal-body {
                padding: 0px 16px 0px 16px;
                .info-body {
                    padding: 0;
                    .info-desc {
                        font-family: $rionasans-regular;
                        -webkit-font-smoothing: antialiased;
                        font-size: 14px;
                        line-height: 1.43;
                        text-align: left;
                        color: $text-color-V2;
                        padding: 32px 0px 0px 0px;
                        .info-email {
                            font-weight: 500;
                            color: $light-indigo-V2;
                        }
                        .bold {
                            font-family: $rionasans-medium;
                        }
                        .pre {
                            white-space: pre;
                        }
                    }
                    .buttons {
                        padding: 48px 0px 0px 0px;
                        justify-self: center;
                        .btn-default {
                            width: 248px;
                            min-width: 248px;
                            height: 50px;
                            border-radius: 100px;
                            background-color: $light-indigo-V2;
                            font-family: $rionasans-medium;
                            font-weight: normal;
                            font-size: 15px;
                            line-height: 1.6;
                            color: #ffffff;
                            -webkit-font-smoothing: antialiased;
                        }
                        .btn-default:hover {
                            background-color: $scp-button-hover-color !important;
                        }
                        .btn-default:active {
                            background-color: $scp-button-focus-color !important;
                        }
                        .btn-default:focus {
                            background-color: $light-indigo-V2 !important;
                            border: 2px solid rgba(189, 146, 228, 0.5) !important;
                        }
                        .btn-default:disabled {
                            background-color: $light-indigo-V2 !important;
                            opacity: 0.35;
                        }
                        .btn-default:disabled > span {
                            opacity: 0.35;
                        }
                        .info-footer {
                            font-family: $rionasans-regular;
                            -webkit-font-smoothing: antialiased;
                            font-size: 12px;
                            line-height: 1.5;
                            text-align: center;
                            color: $light-indigo-V2;
                            cursor: pointer;
                            padding-top: 16px;
                            a {
                                color: $light-indigo-V2;
                            }
                        }
                    }
                }
            }
        }
    }
&.no-close-modal{
    .modal-close-button{
        display: none;
    }
}
&.self-pay-modal {
    .modal-dialog { 
        height: 281px;
        .modal-content { 
            .modal-body { 
                .info-body { 
                    .buttons {
                        padding: 43px 0px 0px 0px;
                    }
                }
            }
        }
    }
}

&.deductable-modal{
    .modal-dialog { 
        height: 318px;
        @media #{$mobile} {
            height: 340px;
        }

        .modal-content { 
            .modal-body { 
                .info-body { 
                    .buttons {
                        padding: 40px 0px 0px 0px;
                    }
                }
            }
        }
    }
}
}