// This set of classes is used in Questionnaire and Referral form to encapsulate styles necessary for UI elements inside them

@import '../../../styles/vars';

.form-frame {
  color: $gray-text-color;
  
  margin: 0 0 80px 0;
  .section-name {
    
    color: $gray-text-color;
    font-size: 25px;
    line-height: 30px;
    padding: 0 0 15px 0;
    &.offset {
      padding: 45px 0 15px 0;
    }
  }
  .qn-button-next {
    padding: 38px 0 0;
    margin: 0;
    text-align: left;
  }
  .text-area {
    max-width: 720px;
  }
  .reason-field {
    .gm-form-label {
      margin-bottom: 13px;
    }
  }
  .buttons-2 {
    .btn-group {
      max-width: 380px;
    }
  }
  .buttons-3 {
    .btn-group {
      max-width: 570px;
    }
  }
  .short {
    .gm-form-control, .custom-checkbox {
      max-width: 380px;
    }
  }
  .gm-form-field {
    .gm-form-control {
      margin-top: 10px;
    }
  }
  .hint {
    color: $light-gray-text-color;
  }
  .mt10 {
    margin-top: 10px;
  }
  .mt15 {
    margin-top: 15px;
  }
  .mt20 {
    margin-top: 20px;
  }
  .mt30 {
    margin-top: 30px;
  }
  .mt45 {
    margin-top: 45px;
  }
  .mb10 {
    margin-bottom: 10px;
  }
  .mb20 {
    margin-bottom: 20px;
  }
  .mb30 {
    margin-bottom: 30px;
  }
  .mb45 {
    margin-bottom: 45px;
  }
  textarea {
    padding: 10px;
  }
}
