@import '../../styles/vars';
@import '../../styles/mixins';
.header-container {
  padding: 0 30px;
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #e5e5e5;
  box-shadow: 0 1px 5px -2px rgba(0, 0, 0, 0.14);
  background-color: white;
}

header.header {
  display: none; // for small screens
  max-width: 1080px;
  margin: auto;
  height: $header-height - 2;
  background: white;
  @media #{$media-header-visible} {
    display: block;
  }
  .header-logo {
    display: inline-block;
    background-image: url('../../images/logo.svg');
    background-repeat: no-repeat;
    background-size: contain;
    width: 126px;
    height: 63px;
    margin-top: 18px;
  }
  .header-list {
    list-style: none;
    display: inline-block;
    vertical-align: middle;
    li {
      display: inline-block;
      font-size: 18px;
      font-weight: 400;
      font-stretch: 100%;
      position: relative;
      line-height: 103px;
      top: -13px;
      a {
        display: inline-block;
        padding: 0 32.5px 3px;
        color: #828282 !important;
        outline: none;
        &:hover,
        &:focus {
          color: #00b0f5 !important;
          text-decoration: none;
        }
      }
      &.active {
        .line {
          display: block;
        }
      }
    }
  }
  .header-list li.active a {
    color: #00b0f5 !important;
  }
  .signin {
    float: right;
    height: 100%;
    button {
      margin: 33px 20px 0 0;
    }
  }
}

.header-gradient {
  display: block;
  height: 20px;
  background: linear-gradient(to top, white 0%, rgba(239, 245, 249, 0) 100%);
  @media #{$media-header-visible} {
    display: none;
  }
}
