@import '../../../../../styles/vars';
@import '../../../../../styles/mixins';

.scp-shippingwizard-container {
  -webkit-font-smoothing: antialiased;

  .scp-shippingwizard-page {
    color: #4a494b;
    display: flex;
    flex-flow: column;
    margin: 0px auto;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    max-width: 1020px;

    .page-title {
      text-align: left;
      background-color: #edeff3;

      @media #{$la_md} {
        display: none;
      }

      h2 {
        font: normal bold 22px/1.45 $rionasans-bold;
        color: $text-color-V2;
        text-transform: capitalize;
        margin: 12px auto 5px;
      }
    }

    .scp-shipping-wizard {
      max-width: 794px;
      width: 100%;
      margin: 0px auto;
      padding: 18px;

      @media #{$mobile} {
        padding: 15px;
      }

      .gm-select-service__header {
        display: inline-block;
        color: $text-color-V2;
        font: $scp-title1-font  !important;
        letter-spacing: normal;
        text-align: center;
        margin-bottom: 40px;
        -webkit-font-smoothing: antialiased;
        display: none;

        @media #{$la_sm} {
          text-align: left;
          font-size: 18px;
          display: unset;
        }
      }

      .buttons {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        max-width: 100%;
        margin-top: 32px;

        @media #{$la_md} {
          float: none;
        }

        @media #{$mobile} {
          margin-top: 12px;
        }

        .button {
          min-width: 80px;
          text-transform: uppercase;
          display: inline-block;
          text-align: center;

          @media #{$la_sm} {
            &:only-child {}
          }

          @media #{$la_sm} and (-ms-high-contrast: none),
          (-ms-high-contrast: active) {}

          @media #{$la_md} {}

          &.button-next {
            margin-right: 32px;

            @media #{$mobile} {
              margin-right: 0;
              width: 100%;
            }

            @media #{$la_sm} {}

            .btn-default {
              width: 145px;
              height: 36px;
              font: $scp-submit-button-font;
              -webkit-font-smoothing: antialiased;
              letter-spacing: normal;
              color: #ffffff;

              @media #{$mobile} {
                height: 48px !important;
                width: 100% !important;
                border-radius: 24px;
                font: normal 500 16px $rionasans-medium  !important;
              }

              &:hover,
              &:focus,
              &:active {
                outline: none !important;
                border: none !important;
              }

              &:active {
                background-color: $purple-600  !important;
              }
            }
          }
        }

        .button-back {
          color: $purple-500;
          text-decoration: none;
          cursor: pointer;
          text-align: left;
          display: none;
          font: $scp-submit-button-font;
          -webkit-font-smoothing: antialiased;
          letter-spacing: normal;

          @media (min-width: 700px) {
            display: block;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    @media #{$la_lg} {
      .scp-shipping-wizard {
        padding: 40px 0px;
      }
    }

    .reservation-timer {
      display: none;

      @media #{$la_lg} {
        display: flex;
      }
    }
  }
}

@media #{$la_lg} {
  .scp-shippingwizard-container {
    padding-top: 16px;
  }
}