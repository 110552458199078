@import '../../styles/vars';

.three-dots-wrapper {
    position: relative;
    .el-popover {
        margin-top: 0;
        width: max-content !important;
        right: 10px !important;
        left: unset !important;
        border-radius: 4px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.35);
        ul.three-dots-menu {
            padding: 0;
            margin: 0;
            list-style: none;
        }
        ul.three-dots-menu li {
            font: normal 14px $rionasans-regular;
            padding: 12px 8px;
            color: $text-color-V2;
            cursor: pointer;
        }    
    }
    .el-button {
        padding: 0;
        border: none;        
    }
}