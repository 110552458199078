.no-underline {
  text-decoration: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}
