@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
    background-size: $width $height;
  }
}

@mixin only-image-2x($image) {
  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
  }
}

@mixin list-style-image-2x($image) {
  @media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    list-style-image: url($image);
  }
}

@mixin triangle($size) {
  position: absolute;
  left: -1 * $size;
  top: 0;
  width: $size;
  height: $size;
  transform: skew(-53deg);
}

@mixin marker {
  color: $marker-color;
  font-size: 30px;
  vertical-align: middle;
}

@mixin slot-left-border {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #3DAEF2;
}

@mixin small-btn {
  min-width: 100px;
  height: 30px;
  line-height: 30px;
  padding: 0 25px;
  font-size: 13px;
  border-radius: 3px;
}
