@import '../../../../styles/vars';
.custom-checkbox {
  .custom-checkbox-rect {
    height: 14px;
    min-width: 14px;
    width: 14px;
    border: 1px solid #CCCCCC;
    border-radius: 2px;
    background-color: #FFFFFF;
    box-shadow: inset 0 1px 1px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    .glyphicon.glyphicon-ok {
      color: $link-color;
      top: 0;
      position: absolute;
    }
    &.custom-checkbox-circle {
      border-radius: 50%;
    }
  }
  .custom-checkbox-label {
    margin: 10px;
  }
}
