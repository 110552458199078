@import ' ../../../../../../styles/vars';

$md: '(min-width: 700px)';

.test-request-payment-stage {
  max-width: 860px;
  width: 100%;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
  padding: 0 15px !important;
  margin: 31px 0 31px 0 !important;

  @media #{$la_lg} {
    padding: 40px 0px !important;
    margin: 0 auto !important;
  }

  @media #{$mobile} {
    >.scp-payment-personal-form .buttons {
      >div {
        padding: 10px 0;
      }

      button {
        height: 40px !important;
      }
    }
  }

  .gm-select-service__header {
    margin-bottom: 18px !important;
  }

  .payment-sections {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 16px;

    @media #{$la_lg} {
      flex-direction: row;
    }

    .payment-section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 16px;

      @media #{$mobile} {
        margin-right: 0;
      }

      .section-header {
        font: $scp-insurance-star-font;
        letter-spacing: normal;
        text-align: left;
        margin-bottom: 16px;
        font-size: 19px;
        line-height: 22.8px;
        color: $light-gray-text-color-v2;
      }

      .card-section {
        width: 100%;
        margin-top: 24px;
        display: flex;
        flex-direction: column;

        @media #{$la_md} {
          width: 470px;
        }

        .scp-payment-personal-form {
          width: 100%;

        }
      }

      .bill-add-section {
        width: 100%;
        margin-top: 32px;

        @media #{$la_md} {
          width: 470px;
        }

        .radio-label {
          .radio-label {
            width: 56%;
          }
        }
      }

      .form-row {
        display: flex;
        flex-direction: row;
        margin-bottom: 19px;

        .card-name {
          width: 100% !important;

          @media #{$la_md} {
            width: 470px !important;
          }
        }

        .StripeElement {
          width: 470px;
          margin-right: 15px;
        }

        .form-col {
          width: 60%;
          margin-right: 15px;

          @media #{$la_md} {
            width: unset;
          }
          &.city-col{
            @media #{$la_md} {
              width: 100%;
            }
          }


          .label-section {
            width: 100%;
            font-family: $rionasans-medium;
            font-size: 14px;
            line-height: 16.8px;
            color: $light-gray-text-color-v2;
            letter-spacing: normal;
            margin-bottom: 4px;
          }

          .required:before {
            content: "*";
            color: $required-red-color;
            font-size: 14px;
            margin-left: -4px;
          }

          .input-wrapper .el-input .el-input__inner,
          .select-wrapper .el-select .el-input .el-input__inner {
            height: 40px;
            font: $scp-form-element-font;
            letter-spacing: normal;

            @media #{$mobile} {
              height: 40px;
            }
          }

          .input-wrapper .el-input .el-input__inner:focus {
            border: solid 1.5px #40b9ec !important;
          }

          .input-wrapper .el-input .el-input__inner:hover:not(:disabled) {
            border: 1px solid #70cbf1;
          }

          .select-wrapper .el-select {
            .el-select-dropdown {
              font: $scp-form-element-font;

              .el-select-dropdown__item {
                color: $text-color-V2;
                font: $scp-form-element-font;

                &.selected {
                  color: #ffffff;
                }
              }
            }
          }

          input:checked~.checkmark {
            background-color: $purple-500;
          }

          .checkmark:after {
            border-color: #ffffff;
            border-width: 0 2px 2px 0;
          }

        }

        .form-col.full-col {
          width: 100%;
        }

        .form-col.cvv-col,
        .form-col.exp-col {
          width: 35%;
          margin-right: 0px;

          @media #{$la_md} {
            width: 147px;
          }

          .exp-cols {
            display: flex;
            justify-content: space-between;

            .select-wrapper.exp-mon .el-select {
              @media #{$mobile} {
                width: unset;
              }
            }

            .select-wrapper.exp-mon .el-select .el-input .el-input__inner {
              width: 49px;

              @media #{$la_md} {
                width: 69px;
              }
            }

            .select-wrapper.exp-year .el-select .el-input .el-input__inner {
              width: 64px;

              @media #{$la_md} {
                width: 69px;
              }
            }
          }
        }

        .form-col.same-address {
          .checkbox-wrapper {
            width: fit-content;

            .checkmark {
              width: 16px;
            }
          }
        }
      }

      .coupon-section {
        width: 232px;
      }
    }


    .gm-alert {
      margin: 20px 0;
    }
  }

  .card-section {
    display: flex;
    flex-wrap: wrap;
    flex-flow: column-reverse;

    @media (min-width: 700px) {
      flex-wrap: nowrap;
      flex-flow: row;

      .info-block {
        max-width: 332px;
        height: 311px;
        margin: 0 20px 0 0;
      }
    }
  }

  .billing-fields {
    padding: 0 20px 10px;

    @media (min-width: 700px) {
      padding: 0 10px 10px;
    }

    .billing-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @media (min-width: 700px) {
        flex-wrap: nowrap;

        .gm-form-field {
          width: 100%;
        }
      }

      .gm-form-field {
        flex-grow: 1;
        margin: 0 10px;
        width: 100%;
        max-width: 400px;
        margin-bottom: 20px;
      }

      /* fix for zipcode error */
      .gm-form-field.zip-code.error {
        position: relative;

        .gm-form-label {
          position: absolute;
          // top: -18px;
        }

        .gm-form-control.text {
          position: absolute;
          bottom: 0px;
        }
      }
    }

    .billing-row.home-address-row {
      margin-left: 10px;
      margin-top: -20px;
    }

    .billing-row.home-address-row .checkbox {
      margin-top: 0;
    }

    .city-field {
      min-width: calc(50% - 20px);
    }

    .state-field {
      min-width: 100px;
      max-width: 212px;
    }

    .zip-code {
      max-width: 140px;
    }
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    @media #{$la_lg}{

    padding-top: 32px;
    border-top: 1px solid $scp-border-color;
    }

    @media #{$mobile} {
      flex-direction: column-reverse;
    }

    >button {
      min-width: 200px;
      height: 40px;
      margin-bottom: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      border: 1px solid $light-indigo-V2;
      color: $light-indigo-V2;
      font-family: $rionasans-bold;
      font-size: 16px;
      background-color: $white-bg-color-v2;
      cursor: pointer;

      @media #{$mobile} {
        min-width: unset;
        width: 100%;
        margin-bottom: 16px;
      }

      &:disabled {
        opacity: 0.35;
      }

      &.btn-back {
        margin-right: 56px;

        &:disabled {
          cursor: not-allowed;
        }

        &:hover:not(:disabled) {
          background-color: rgba(123, 38, 201, 0.15);
        }

        &:active {
          background-color: rgba(123, 38, 201, 0.3) !important;
        }
      }

      &.btn-next {
        background-color: $light-indigo-V2;
        color: $white-bg-color-v2;

        &:disabled {
          cursor: not-allowed;
        }

        &:hover:not(:disabled) {
          background-color: $scp-button-hover-color;
        }

        &:active {
          background-color: $purple-600 !important;
        }
      }
    }
  }
}

/* For Mobile Device */
.mobile-schedule-container {
  .test-request-payment-stage {
    padding: 0 16px !important;
    .scp-payment-personal-form {
      .el-input {
        width: 100%;
      }

      .bill-add-section {
        .form-row {
          justify-content: space-between;
          width: 100%;

          .form-col {
            margin-right: 0;
          }

          .city-col {
            width: 48%;
          }

          .state-col {
            width: 20% !important;

            .input-field {
              width: unset;
            }
          }

          .zip-col {
            width: 28%;
          }
        }

        .radio-label {
          .radio-label2 {
            @media #{$mobile} {
              width: 30%;
            }
          }
        }
      }

      .buttons {
        margin-top: 0;
        flex-direction: column;

        >div {
          width: 100%;
        }

        .button.button-next {
          margin-right: 0;
        }

        .button.button-next .btn-default {
          height: 48px !important;
          width: 100% !important;
          border-radius: 24px;
          font: normal 500 16px $rionasans-medium !important;
        }

        .button-back {
          height: 48px !important;
          width: 100% !important;
          border: 1px solid $purple-500;
          border-radius: 24px;
          font: normal 500 16px $rionasans-medium !important;
          display: flex !important;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}


.test-authorization-section {
  width:308px;
  padding: 16px 16px;
  border-radius: 4px;
  border: solid 1px $scp-border-color;
  line-height: 22px;
  -webkit-font-smoothing: antialiased;
  @media #{$mobile} {
    border: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .code-summary {
    font-family: $rionasans-bold;
    font-size: 15px;
    @media #{$mobile}{
      display: none;
    }
  }
  .test-authorization-header {
    font-family: $rionasans-bold;
    font-size: 13px;
    line-height: 15.6px;
    text-align: left;
    color: $text-color-V2;
    margin-top: 32px;
    margin-bottom: 6px;
    @media #{$mobile}{
      font-size: 19px;
      line-height: 22.8px;
      margin-top: 16px;
      margin-bottom: 8px;
      color: $light-gray-text-color-v2;
    }
  }

  .test-authorization-detail-section{
    @media #{$mobile} {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  
  .test-authorization-description {
    font-family: $rionasans-medium;
    font-size: 12px;
    color: $light-gray-text-color-v2;
    border-bottom: 1px solid #EDEFF3;
    padding-bottom: 16px;
    margin-bottom: 16px;
    line-height: 14.4px;
    @media #{$mobile}{
      font-size: 12px;
      padding-bottom: 32px;
      margin-bottom: 8px;
    }
  }
  
  .test-authorization-fee {
    font-family: $rionasans-bold;
    font-size: 18px;
    line-height: 21.6px;
    margin-bottom: 35px;
    @media #{$mobile}{
      font-size: 28px;
      line-height: 30.8px;
      text-align: left;
      margin-bottom: 16px;
    }
  }
}

