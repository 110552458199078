@import '../../../styles/vars.scss';

.reservation-timer {
  display: flex;
  width: 120px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;

  span.blue {
    height: 14px;
    width: 94px;
    color: #00aeef;
    
    font-size: 11px;
    line-height: 14px;
    text-align: center;
    font-weight: 400;
  }

  .timer-pie-container {
    width: 54px;
    height: 54px;
    margin-bottom: 7px;
    position: relative;

    .timer-text {
      position: absolute;
      width: 100%;
      margin: 0 auto;
      left: 0;
      right: 0;
      top: 29%;
      color: #ffffff;
      
      font-size: 15px;
      line-height: 20px;
      text-align: center;

      &.time-getting-out {
        color: red;
      }
    }

    .timer-pie-background {
      background-color: #8cc63f;
      position: absolute;
      width: 54px;
      height: 54px;
      border-radius: 27px;
    }

    .timer-pie-slice {
      position: absolute;
      width: 54px;
      height: 54px;
      border-radius: 27px;
      clip: rect(0, 54px, 54px, 27px);

      .timer-pie {
        background-color: #acd675;
        transform: rotate(50deg);
      }
    }
  }
}
