@import '../../../../../styles/vars';

$md: '(min-width: 750px)';

.scp-my-guide-page {
  color: $gray-text-color-v2;
  padding-top: 24px;
  min-height: calc(100vh - 290px);
  padding-bottom: 24px;
  @media #{$mobile} {
    padding-top: unset;
    padding-bottom: unset;
    border-top: 1px solid #edeff3;
  }

  /* start: no appointment section */
  .no-appointments {
    display: flex;
    flex-flow: column;
    align-items: center;
    max-width: 1020px;
    margin: 0px auto;
    padding: 40px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    color: #4a494b;

    @media #{$la_md} {
      border-radius: 4px;
    }

    .illustration-image-spaceholder {
      width: 470px;
      height: 200px;
      background-color: $purple-200;
      padding: 110px 190px;
      text-align: center;
      font: normal 12px/1 $rionasans-regular;
      color: $purple-400;
      -webkit-font-smoothing: antialiased;
    }

    .no-schedule-text {
      margin: 80px 0 32px;
      font: normal 500 18px/1.33 $rionasans-medium;
      -webkit-font-smoothing: antialiased;
      color: #7b8291;
    }

    .button-link .btn-default {
      min-width: 345px;
      padding: 12px;
      border-radius: 24px;
      font: normal 500 15px/1.6 $rionasans-medium;
      -webkit-font-smoothing: antialiased;
      text-transform: none;
    }
  }

  /* end: no appointment section */

  /* start: has appointment section */
  .has-appointments {

    max-width: 1020px;
    margin: 0px auto;
    padding: 18px;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    color: $text-color-V2;

    @media #{$la_md} {
      padding: 40px 113px 102px 113px;
    }

    @media #{$mobile} {
      padding: 24px 15px;
    }

    h3 {
      color: $text-color-V2;
      font: normal bold 22px/1.45 $rionasans-bold;
      -webkit-font-smoothing: antialiased;
      text-transform: capitalize;
    }
  }

  /* end: has appointment section */

  .my-guide-page-container {
    text-align: center;
    max-width: 794px;
    margin: 0 auto;

    .cta {
      margin: 25px auto 20px;
      display: block;

      &.red-cta {
        a {
          background-color: gray !important;
        }
      }
    }
  }

  .progress-track {
    .progress-step:nth-child(4) {
      .step-line {
        border-top-style: dashed;
      }
    }
  }

  .outer-button {
    .button-link {
      display: inline-block;
      text-decoration: none;
    }
  }

  .is-mobile {
    &.no-appointments {
      padding: 24px 15px;
      align-items: stretch;
    }

    .no-schedule-text {
      margin: 24px 8px 48px 0;
      font: normal 16px $rionasans-regular;
    }

    .title-no-appointment {
      font: normal bold 22px $rionasans-bold;
      color: $text-color-V2;
    }
  }
}
