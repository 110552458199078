@import '../../../styles/vars';
.stepspanel-container {
  // flex: 1 1;
  width: 100%;
  color: $body-text-color;
  text-align: left;
  @media (min-width: 1200px) {
    margin-right: 80px;
    max-width: 380px;
    max-height: 400px;
  }
  .steps-container {
    flex: 0 0 250px;
    margin: 0 20px auto 0;
    .step-item {
      position: relative;
      margin: 0;
      max-width: 215px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      &.active {
        cursor: default;
        .step-item-header {
          width: 100%;
          max-width: 100%;
          background-color: $link-color;
          color: #FFFFFF;
          border-radius: 0 1px 1px 0;
        }
        .step-item-header:after {
          content: "";
          position: absolute;
        }
        .step-item-header:after {
          height: 0;
          width: 0;
          right: -12px;
          top: 0;
          border-top: 20px solid transparent;
          border-bottom: 20px solid transparent;
          border-left: 12px solid $link-color;
        }
      }
      .step-done {
        height: 40px;
        width: 50px;
        .ok {
          color: $link-color;
          font-size: 20px;
          line-height: 45px;
          padding: 0 0 0 13px;
          z-index: -1;
          position: relative;
        }
      }
    }
    .step-item-header {
      padding: 10px 0px 10px 13px;
      min-height: 40px;
      max-width: 180px;
    }
  }
}
