@import '../../../../../../styles/vars';

.scp-summary-page-block {
  margin: 30px 16px 0px 16px;

  @media #{$la_sm} {
    margin: 40px 0 30px 40px;
  }

  .summary-cards-table {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .scp-visit-doc-block,
  .scp-other-doc-block {
    .scp-myheath-subheader {
      font: $scp-insurance-star-font;
      text-align: left;
      color: #262837;
      margin: 16px 0px;

      @media #{$mobile} {
        font-size: 16px;
      }

    }

  }

  .no-visits-warn {
    font-size: 14px;
    margin: 25px auto;
    font-family: RionaSans;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #7b8291;

    @media #{$la_sm} {
      min-width: 600px;
    }
  }

  .summary-table.table {
    .desktop-header {
      display: none;

      @media #{$la_sm} {
        display: table-row;
      }
    }

    .mobile-header {
      @media #{$la_sm} {
        display: none;
      }
    }

    .view-summary {
      padding-left: 10px;
      color: $purple-500  !important;

      @media #{$la_sm} {
        padding: 0;
      }
    }

    .mobile-hidden {
      display: none;

      @media #{$la_sm} {
        display: inline;
      }
    }

    tbody tr td:last-child {
      text-align: left;
    }

    thead tr th {
      &.date-type-cell {
        width: 50%;

        @media #{$la_sm} {
          width: auto;
        }
      }

      .sorting-header {
        cursor: pointer;
        position: relative;
        display: inline-block;
        width: 50%;

        @media #{$la_sm} {
          display: block;
          width: auto;
          max-width: 135px;
        }

        &.sorting-header__active {
          &:after {
            content: '';
            position: absolute;
            left: 36px;
            top: 35%;
            display: block;
            border: 5px solid transparent;
            border-top: 5px solid #9B9B9B;
            transition: transform 250ms;
            transform-origin: 50% 2.5px;

            @media #{$la_sm} {
              left: calc(100% - 10px);
            }
          }

          &.sorting-header__reverse {
            &:after {
              transform-origin: 50% 2.5px;
              transform: rotate(180deg);
            }
          }
        }
      }
    }

    tr.archived-row {

      td,
      td a,
      td span {
        color: #bac3ce !important;
        pointer-events: none;
        font-style: italic;
      }
    }

    tr.scanning > td > div {
      height: 100%;

      .el-loading-spinner {
        top: 19px;
      }
    }

    tr.is-malicious {
      border: 1px solid $error-text-color;

      .malicious-icon {
        width: 24px;
      }
    }
  }
}
