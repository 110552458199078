@import "../../../styles/mixins.scss";
@import "../../../styles/vars.scss";

div[role='scp-modal-custom'] .modal-backdrop.in{
 z-index: 1055 !important;
}
.scpcustom-upload-document-dialog {
  z-index: 1055 !important;
  -webkit-font-smoothing: antialiased;
  .modal-dialog {
    width: 90vw;
    height: 90vh;
    left: 2px;
    @media #{$la_sm} {
      max-width: 448px;
      height: 531px;
      left: 0px;
    }
    .modal-header {
      border-bottom: 1px solid $page-background-color-V2 !important;
      padding: 17px 16px 17px 16px;
      padding-top: 20px;
      @media #{$la_sm} {
        height: 48px !important;
        padding-top: 17px;
      }
      height: 60px !important;
        @media #{$la_sm} {
          font-size: 16px;
        }
        font-size: 18px;
        color: $text-color-V2;
        font-family: $rionasans-bold;
      .ion-close-round:before {
        font-size: 17px;
        @media #{$la_sm} {
          font-size: 12px;
        }
      }
      .modal-close-button {
        opacity: 1;
        padding-top: 16px;
        @media #{$la_sm} {
          padding-top: 12px;
        }
      }
      .modal-close-button:hover {
        color: $text-color-V2;
      }
    }
    .modal-body {
      padding: 0;
      color: $text-color-V2;
      font-family: $rionasans-bold;
      overflow: auto;
      max-height: calc(90vh - 100px);
    }
    .modal-content {
      border-radius: 4px;
      max-height: 90vh;
      @media #{$la_sm} {
        height: unset;
      }
      .scp-uploading-modal {
        padding: 0;
        .allowed-files {
          font-size: 11px;
          line-height: 15px;
          font-family: $rionasans-regular;
          color: $info-color;
        }
        h2 {
          font-size: 18px !important;
          font-weight:bold;
          line-height: 30px;
          color: $text-color-brown;
        }
        p {
            line-height: 30px !important;
            font:normal 14px "RionaSans", Arial, Helvetica, sans-serif;
          }
      }
      .col-section {
        margin-top: 6px;
        @media #{$la_sm} {
          margin-top: 0px;
        }
        padding: 0px 16px 0px 16px;
        &:last-child {
          hr {
            display: none;
          }
        }
      }
    }
  }
}
