@import '../../styles/vars.scss';
.check-resend-link-form {
  min-height: 100vh;
  width: 100vw;
  color: $text-color-V2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  -webkit-font-smoothing: antialiased;

  .form-wrapper {
    width: 384px;
    min-height: 417px;
    padding: 32px;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    margin: 0 auto;
    z-index: 1;

    .genome-logo {
      display: inline-block;
      background-image: url('../../images/logo.svg');
      background-repeat: no-repeat;
      background-size: contain;
      width: 126px;
      height: 63px;
      margin: 0px 92px;
    }

    .reset-title {
      font-family: $rionasans-medium;
      font-size: 24px;
      font-weight: 500;
      line-height: 1.42;
      color: $text-color-V2;
      -webkit-font-smoothing: antialiased;
      display: flex;
      justify-content: center;
      margin-top: 50px;
      margin-bottom: 8px;
    }

    .reset-desc {
      font: normal 14px/1.43 $rionasans-regular;
      text-align: center;
      color: $text-color-V2;
      -webkit-font-smoothing: antialiased;
    }
  }
}
