@import '../../../../../styles/vars';

$md: '(min-width: 700px)';

.scp-schedule-wizard.scp-shipping-address-stage {
  max-width: 812px;
  width: 100%;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
  padding: 0 15px !important;
  margin: 31px 0 31px 0 !important;

  @media #{$la_lg} {
    padding: 40px 0px !important;
    margin: 0 auto !important;
  }

  .gm-shipping-address_header {
    margin-bottom: 32px;
    color: $text-color-V2;
    font: normal normal 23px/28px $rionasans-bold;

    @media #{$mobile} {
      margin-bottom: 16px;
    }
  }

  .scp-home-address-form {
    display: flex;
    flex-direction: column;

    .main-fields-container {
      width: 100%;
      display: flex;

      @media #{$mobile} {
        flex-direction: column-reverse;
      }

      .fields-container {
        display: flex;
        flex-direction: column;
        padding: 0;
        border: 0;

        @media #{$la_md} {
          width: 60%;
          margin-right: 32px;
        }

        .label-section {
          width: 100%;
          font: $label_font;
          letter-spacing: normal;
          color: $light-gray-text-color-v2;
          font: normal 14px/17px $rionasans-medium !important;
          margin-bottom: 4px;
        }

        .form-row {
          width: 100%;
          margin-bottom: 16px;
          display: flex;
          justify-content: space-between;

          .form-col {
            width: 100%;

            .input-wrapper .el-input .el-input__inner,
            .select-wrapper .el-select .el-input .el-input__inner {
              height: 32px !important;
              font: $scp-form-element-font;
              letter-spacing: normal;

              @media #{$mobile} {
                height: 40px !important;
              }
            }

            .el-input.is-disabled .el-input__inner {
              background-color: $page-background-color-V2;
              color: #bac3ce;
            }

            .checkbox-wrapper {
              border-radius: 6px;
              border: 1px solid $page-background-color-V2;
              width: 99%;
              max-width: 470px;
              padding: 14px 10px 11px;

              &.shipping-as-home-address-selected {
                border: 1px solid $purple-500;
              }

              label {
                margin-bottom: 0;
                padding: 0;
              }

              .label-section {
                font: normal 16px/19px $rionasans-medium !important;
              }
            }
          }

          .form-col.city-col {
            width: 227px;
          }

          .form-col.state-col {
            width: 65px;

            .el-select-dropdown__wrap {
              max-height: 250px;
            }
          }

          .form-col.zip-col {
            width: 146px;
          }

          @media #{$mobile} {
            .city-col {
              width: 50% !important;
            }

            .state-col {
              width: 20% !important;
            }

            .zip-col {
              width: 25% !important;
            }
          }
        }
      }

      .shipping-address-info-container {
        @media #{$la_md} {
          width: 40%;
          border-left: 1px solid $page-background-color-V2;
          padding-left: 32px;
          font: normal 16px/20px $rionasans-regular;
          color: $light-gray-text-color-v2;
        }

        @media #{$mobile} {
          padding-left: 0;
          width: 100%;
          margin-bottom: 32px;
        }
      }
    }

    .info-text {
      color: $light-gray-text-color-v2;
      letter-spacing: normal;
      font: normal 500 12px/1.42 $rionasans-medium;
      margin-top: 4px;
    }

    .action-buttons {
      display: flex;
      justify-content: flex-end;
      border-top: 1px solid $scp-border-color;
      padding-top: 32px;
      margin-top: 32px;

      @media #{$mobile} {
        border-top: none;
        padding-top: 0;
        flex-direction: column-reverse;
      }

      .button-back {
        margin-right: 56px;
        height: 40px;
        width: 200px;
        background-color: #ffffff !important;
        border: 1px solid $purple-500;
        text-align: center !important;
        font: $scp-submit-button-font-V3 !important;
        text-transform: capitalize !important;
        color: $light-indigo-V2;

        @media #{$mobile} {
          margin-right: 0;
          width: 100%;
          border-radius: 6px !important;
          height: 40px !important;
        }

        &:disabled {
          background-color: $purple-200 !important;
        }

        &:hover:not([disabled]) {
          background-color: rgba(123, 38, 201, 0.15) !important;
          text-decoration: none;
          border: 1px solid $purple-500 !important;
          color: $light-indigo-V2 !important;
        }

        &:focus:not([disabled]) {
          background-color: $purple-500 !important;
        }

        &:active:not([disabled]) {
          background-color: rgba(123, 38, 201, 0.3) !important;
        }
      }

      .button.button-next {
        .btn-default {
          height: 40px;
          width: 200px;
          font: $scp-submit-button-font-V3 !important;
          padding: 0;

          @media #{$mobile} {
            width: 100%;
            margin-bottom: 16px;
            border-radius: 6px !important;
            height: 40px !important;
          }
        }
      }
    }
  }
}

.test-request {

  .scp-schedule-wizard.scp-shipping-address-stage {
    .scp-home-address-form {
      .main-fields-container {
        .fields-container {
          .form-row {
            .form-col {

              .input-wrapper .el-input .el-input__inner,
              .select-wrapper .el-select .el-input .el-input__inner {
                height: 40px !important;
              }
            }
          }
        }
      }
    }
  }
}