@import '../../../styles/vars';

[role='global-loader'] {
  .modal-backdrop.in {
    background-color: black;
    opacity: 0.5;
    z-index: 99999;
  }
  [role='dialog'] {
    z-index: 999999;
    .modal-dialog {
      width: 248px;
      position: relative;
      .modal-content {
        border: 0;
        border-radius: 5px;
        .loader-body {
          .loader-text {
            font-size: 24px;
            color: $gray-text-color;
          }
          .thin-loader {
            position: relative;
            width: 248px;
            height: 248px;

            & > img {
              width: 248px;
              position: absolute;
              bottom: 0;
            }

            @-webkit-keyframes rotate {
              100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
              }
            }

            @keyframes rotate {
              100% {
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
              }
            }

            @-webkit-keyframes dash {
              0% {
                stroke-dasharray: 1, 200;
                stroke-dashoffset: 0;
              }
              50% {
                stroke-dasharray: 89, 200;
                stroke-dashoffset: -35;
              }
              100% {
                stroke-dasharray: 89, 200;
                stroke-dashoffset: -124;
              }
            }

            @keyframes dash {
              0% {
                stroke-dasharray: 1, 200;
                stroke-dashoffset: 0;
              }
              50% {
                stroke-dasharray: 89, 200;
                stroke-dashoffset: -35;
              }
              100% {
                stroke-dasharray: 89, 200;
                stroke-dashoffset: -124;
              }
            }

            @keyframes color {
              0% {
                stroke: #332a86;
              }
              50% {
                stroke: #8dc63f;
              }
              100% {
                stroke: #00aeef;
              }
            }
          }
        }
      }
    }
  }
}
