// This is just a MESS that is used from different places
// It's really PAINFUL to change such styles!
@import './../../../styles/vars';
.gm-forgot-pwd {
  
  margin: 30px auto;
  display: flex;
  justify-content: center;
  text-align: left;
  .gm-forgot-pwd-fields {
    width: 100%;
    .gm-forgot-pwd-field {
      margin-top: 11px;
      width: 100%;
      .gm-forgot-pwd-label {
        padding-bottom: 0.5em;
        color: #9B9B9B;
        white-space: nowrap;
        text-align: center;
        text-align: left;
      }
      .gm-forgot-pwd-control {
        height: 40px;
        width: 100%;
        input {
          width: 100%;
          height: 100%;
          border: 1px solid #DCDCDC;
          border-radius: 3px;
          padding: 12px;
          text-align: center;
          &[readonly] {
            background-color: #EEEEEE;
          }
        }
      }
      .gm-forgot-pwd-error {
        color: red;
      }
      &.disabled {
        .button-next {
          color: lightgray !important;
        }
      }
    }
    @media #{$media-landing-bottom-stripes} {
      .gm-forgot-pwd-field {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        .gm-forgot-pwd-label {
          padding: 0;
          padding-right: 20px;
          flex: 0 1 250px;
          text-align: right;
        }
        .gm-forgot-pwd-control input {
          text-align: left;
        }
      }
    }
    .outer-button {
      margin: 30px auto 0;
      button {
        text-transform: uppercase;
      }
    }
  }
}

.gm-form-checkbox {
  margin-top: 0 !important;
  white-space: nowrap;
  label {
    display: inline-block;
    height: 15px;
    margin-bottom: 0;
    input[type=checkbox] {
      width: 15px;
      height: 15px;
      vertical-align: middle;
      display: inline-block;
      position: relative;
      top: -1px;
      margin: 0 5px 0 0;
      *overflow: hidden;
    }
  }
}

.email-sent-letter {
  text-align: center;
  margin-top: -10px;
  i {
    font-size: 150px;
    color: #00AEEF;
  }
}
