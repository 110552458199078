@import "../../../../styles/vars";

.scp-confirm-modal {
  -webkit-font-smoothing: antialiased;
  .modal-header {
    height: 56px !important;
    border-bottom: 1px solid $page-background-color-V2 !important;
    .header-text {
      font: bold 16px/22px $rionasans-bold;
      color: $text-color-V2;
      display: flex;
      align-items: center;
      img {
        height: 24px;
        width: 24px;
      }
      span {
        margin-left: 16px;
      }
    }
    .modal-close-button {
      display: none;
    }
  }
  .modal-body {
    padding: 0;
  }
  .scp-confirm-modal-body {
    padding: 0;
    padding-bottom: 8px;
    @media #{$la_sm} {
      padding-bottom: 0px;
    }
    .confirm-title {
      font: normal 16px $rionasans-regular;
      @media #{$la_sm} {
        font: normal 13px/18px $rionasans-medium;
      }
      color: $text-color-V2;
      margin-top: 32px;
      padding: 0px 16px 0px 16px;
    }
    .confirm-buttons {
      margin-top: 32px;
      margin-bottom: 16px;
      display: flex;
      flex-direction: column-reverse;
      @media #{$la_sm} {
        border-top: 1px solid $page-background-color-V2;
        margin-bottom: 0px;
      }
      padding: 12px 16px 12px 16px;
      @media #{$la_sm} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      .cancel-button {
        @media #{$la_sm} {
          font: normal 13px/18px $rionasans-medium;
          width: 160px;
          height: 36px;
          border-radius: 6.1px;
          margin-top: 0px;
        }
        font: normal 16px $rionasans-bold;
        width: 100%;
        height: 48px;
        border-radius: 24px;
        border: 1px solid $required-red-color;
        display: flex;
        margin-top: 8px;
        justify-content: center;
        align-items: center;
        color: $required-red-color;
        cursor: pointer;
      }
      .cancel-button:hover {
        background-color: rgba(231, 76, 60, 0.16);
      }
      .cancel-button:focus {
        background-color: rgba(231, 76, 60, 0.16);
        border: 2px solid rgba(231, 76, 60, 0.5);
      }
      .btn-default {
        @media #{$la_sm} {
          font: normal 13px/18px $rionasans-medium;
          width: 160px;
          height: 36px;
          border-radius: 6.1px;
        }
        border-radius: 24px;
        font: normal 16px $rionasans-bold;
        width: 100%;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $required-red-color !important;
        text-transform: none;
      }
      .btn-default:hover {
        background-color: rgba(236, 103, 89, 1) !important;
      }
      .btn-default:focus {
        background-color: $required-red-color !important;
        border: 2px solid #f0968d;
      }
    }
  }
}
