.scheduling-page.test-request{
    color: #4a494b;
    display: flex;
    flex-flow: column;
    margin: 0px auto;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    max-width: 1020px;
    &.full-height {
        min-height: auto !important;
    }
    
}

.test-request-privacy-policy{
    .privacy-wrapper{
        margin: auto !important;

    }
}