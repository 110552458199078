@import ' ../../../../../../styles/vars';

.test-request-wizard.test-confirm-form {
    padding: 0 15px;
    margin: 32px 0 32px 0;
    
    @media #{$md} {
        max-width: 812px;
        width: 812px;
    }

    @media #{$la_lg} {
        padding: 40px 0px;
        margin: 0 auto;
    }

    .gm-select-service__header {
        margin-bottom: 32px !important;
        font: $scp-title-font-V3 !important;
        color: $text-color-V2;
    }

    .natera-cont {
        padding: 32px;
        border: 2px solid $scp-border-color;
        border-radius: 6px;
        @media #{$mobile} {
            border:none;
            padding:0;
        }

        .natera-detail {
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid $scp-border-color;
            padding-bottom: 24px;
            margin-bottom: 24px;
            @media #{$mobile} {
                flex-direction: column;
                padding-bottom: 32px;
                margin-bottom: 32px;
            }

            .img-section {
                margin-bottom: 24px;
                img{
                    width: 100%;
                }
                @media #{$mobile} {
                    margin-bottom: 32px;
                }
            }

            .expect-next {
                .expect-heading {
                    font-family: $rionasans-bold;
                    font-size: 19px;
                    line-height: 22.8px;
                    text-align: left;
                    padding-bottom: 16px;
                }

                .expect-desc {
                    ul {
                        font-family: $rionasans-regular;
                        font-size: 16px;
                        line-height: 20px;
                        color: #262837;
                        margin-bottom: 0;
                        @media #{$mobile} {
                            padding-inline-start: 20px;
                        }
                        li{
                            .bold{
                                font-family: $rionasans-bold;
                            }
                        }

                    }
                }
            }
        }

        .still-have-question {
            .still-label {
                font-family: $rionasans-bold;
                font-size: 19px;
                line-height: 22.8px;
                color: $light-gray-text-color-v2;
                margin-bottom: 16px;
            }

            .reach-out {
                font-family: $rionasans-regular;
                font-size: 16px;
                line-height: 20px;
                &:last-child {
                    margin-top: 16px;
                }
                a{
                    color: #0099D1;
                    font-weight: 700;
                }
            }
        }


    }
}