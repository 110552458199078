@import '../../../../../../styles/vars';

.scp-complete-checklist-button-title {
  padding: 10px 20px;
}

.scp-complete-checklist-button {
  width: 342px;
  min-width: 342px;
  border-radius: 100px;
  font: normal 500 16px/1.6 $rionasans-medium;
  -webkit-font-smoothing: antialiased;
}
.ninetyDays {
  .questionnaire-info {
    padding: 32px 0;

    .questionnaire-description {
      margin: 16px 0 24px;
    }  
    .scp-complete-checklist-button {   
      width: 268px;
      height: 40px;
      border-radius: 6px;
      font: normal 19px/22px $rionasans-medium;
      -webkit-font-smoothing: antialiased;
    }
  }
  @media #{$mobile} {
    .questionnaire-info {
      padding-bottom: 24px;
      border-bottom: 1px solid #edeff3;    
      h3 {
        font: normal 700 23px/27px $rionasans-bold !important;
      }
      .questionnaire-description {
        font: normal 400 16px/20px $rionasans-regular;
      }  
      .scp-complete-checklist-button {   
        border-radius: 6px;
        margin-top: 16px;
        font: normal 500 19px/22px $rionasans-medium !important;
      }
    }
  }
}
.is-mobile {
  .scp-complete-checklist-button {
    width: 100%;
  }
}