@import '../../../styles/vars.scss';
$color1: #f4f4f4;
$color2: #00bcd4;
.gm-radio-control {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 85px;
  width: 256px;
  .gm-radio-label {
    user-select: none;
    color: rgba(0, 0, 0, 0.3);
    
    margin: 0;
    font-size: 16px;
    padding-top: 18px;
  }
  .gm-radio-group {
    padding-top: 10px;
    display: flex;
    align-items: flex-end;
    .radio {
      input[type='radio'] {
         position: absolute;
         opacity: 0;
         z-index: -6;
        +.radio-label {
          color: #4a494b;
          margin-left: 20px;
          padding-left: 0px;
          &:before {
            content: '';
            background: $color1;
            border-radius: 100%;
            border: 1px solid darken($color1, 25%);
            display: inline-block;
            width: 1.5em;
            height: 1.5em;
            position: relative;
            top: -0.2em;
            margin-right: 1em;
            vertical-align: top;
            cursor: pointer;
            text-align: center;
            transition: all 250ms ease;
          }
        }
        &:checked {
          +.radio-label {
            &:before {
              background-color: $color2;
              box-shadow: inset 0 0 0 4px $color1;
            }
          }
        }
        &:focus {
          +.radio-label {
            &:before {
              outline: none;
              border-color: $color2;
            }
          }
        }
        &:disabled {
          +.radio-label {
            &:before {
              box-shadow: inset 0 0 0 4px $color1;
              border-color: darken($color1, 25%);
              background: darken($color1, 25%);
            }
          }
        }
        +.radio-label {
          &:empty {
            &:before {
              margin-right: 0;
            }
          }
        }
      }
    }
    .radio:first-child {
      margin-left: -20px;
    }
    .radio-button-description {
      display: inline-block;
      margin-left: 5px;
      min-width: 420px;
      cursor: pointer;
        strong {
          font-weight: bold;
        }
      .info-outline-icon {
        display: inline-block;
        background: url('./info-outline-icon.svg');
        background-size: 100%;
        width: 19px;
        height: 19px;
        vertical-align: top;
      }

      .info-outline-icon.shake {
        animation: shake-animation 4.72s ease infinite;
        transform-origin: 50% 50%;
      }

      @keyframes shake-animation {
        0% { transform:translate(0,0) }
        1.78571% { transform:translate(5px,0) }
        3.57143% { transform:translate(0,0) }
        5.35714% { transform:translate(5px,0) }
        7.14286% { transform:translate(0,0) }
        8.92857% { transform:translate(5px,0) }
        10.71429% { transform:translate(0,0) }
        100% { transform:translate(0,0) }
      }

      .description-hint {
        display: none;
        background: #ffffff;
        padding: 28px;
        font-family: $sans;
        line-height: 24px;        
        box-shadow: 0 0 3px 0.5px rgba(0, 0, 0, 0.3);
        .description-hint-header {
          color: #4a494b;          
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;
          margin-bottom: 12px;
        }
      }
      i {
        font-size: 16px;
        color: $color2;
      }
      &:hover {
        .description-hint {
          display: block;
          position: absolute;
          z-index: 99;
        }
      }
    }
  }
  .gm-radio-control__invalid {
    position: absolute;
    bottom: -15px;
    
    font-size: 12px;
    line-height: 12px;
    color: rgb(244, 67, 54);
    transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  }
}
