@import '../../../../styles/vars.scss';

.selection-check-wrapper {
  background: $page-background-color-V2 url("../../../../images/gray-ribbon-v2.svg") center/100% no-repeat fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .dividertext {
    font-family: $rionasans-medium;
    -webkit-font-smoothing: antialiased;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.39;
    text-align: left;
    color: #262837;
  }

  .selection-check-section {
    margin: 91px 0;
    max-width: 800px;
    width: 100%;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;

    .sign-up-title {
      font-family: $rionasans-medium;
      -webkit-font-smoothing: antialiased;
      font-size: 24px;
      font-weight: 500;
      line-height: 1.42;
      text-align: center;
      color: #262837;
      min-height: 34px;
    }

    .gm-link {
      color: $purple-500  !important;
    }

    ul {
      margin-top: 8px;
      padding-left: 24px;
      clear: both;
      margin-bottom: 0px;
    }

    li {
      line-height: 24px;
      margin-bottom: 16px;
      list-style: none;
    }

    li:before {
      content: "• ";
      font-weight: 500;
      margin-right: 5px;
    }

    li:last-child {
      margin-bottom: 24px;
    }

    .headingcheckbox {
      width: 688px;
      height: 41px;
      font-family: $rionasans-regular;
      -webkit-font-smoothing: antialiased;
      font-size: 14px;
      line-height: 1.43;
      text-align: left;
      color: #7b8291;
      margin-top: 8px;
    }

    .label-cbx {
      display: flex;
      margin-bottom: 0;
      font-family: $rionasans-medium;
      -webkit-font-smoothing: antialiased;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.71;
      text-align: left;
      color: #262837;
    }

    .ribbon-img {
      top: 300px;
      left: 50%;
      transform: translateX(-50%);
      position: absolute;
      display: none;
      opacity: 0.6;

      @media (min-width: 700px) {
        display: block;
      }

      width: 100%;
    }

    .gm-selection-check-header {
      font-family: $rionasans-medium;
      --webkit-font-smoothing: antialiased;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.39;
      text-align: left;
      color: #262837;
      margin-top: 24px;
    }

    .form-wrapper {
      .gm-selection-header {
        width: 100%;
        text-align: center;
        padding-bottom: 24px;
        border-bottom: 1px solid #edeff3;
      }

      .genome-header-logo {
        width: 100%;
        text-align: center;
        margin-bottom: 24px;

        .genome-header-img {
          max-width: 130px;
        }
      }

      .gm-selection-footer {
        display: flex;
        align-items: center;
        flex-direction: column;

        .submit-button > button:hover {
          background-color: $purple-400  !important;
        }

        .submit-button > button:active {
          background-color: $purple-600  !important;
        }

        .submit-button > button:focus {
          background-color: $purple-500  !important;
          border: 2px solid rgba(189, 146, 228, 0.5) !important;
        }

        .submit-button > button:disabled {
          background-color: $purple-500  !important;
          opacity: 0.35;
        }

        .submit-button > button:disabled > span {
          opacity: 0.35;
        }
      }

      @media #{$la_sm} {
        padding: 32px 56px 32px 56px;
      }

      .gm-selection-check-form {
        .gm-selection-check-fields {
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;

          .gm-selection-check-field {
            padding: 0;
            margin-bottom: 0;
            width: 100%;
            margin-top: 24px;

            input::-ms-clear {
              display: none;
            }

            .selection-checkbox {
              input[type="checkbox"] {
                display: none;
              }

              label {
                position: relative;
                display: inline-block;
                padding-left: 22px;
                margin-bottom: 0px;
              }

              label::before,
              label::after {
                position: absolute;
                content: "";
                display: inline-block;
              }

              label::before {
                width: 14px;
                height: 14px;
                border: 1px solid #a0abb9;
                left: 0px;
                top: 3px;
                border-radius: 2px;
                cursor: pointer;
              }

              label::after {
                height: 5px;
                width: 9px;
                border-left: 2px solid;
                border-bottom: 2px solid;
                transform: rotate(-45deg);
                left: 3px;
                top: 6px;
                color: $purple-500;
                cursor: pointer;
              }

              input[type="checkbox"] + label::after {
                content: none;
              }

              input[type="checkbox"]:checked + label::after {
                content: "";
              }

              input[type="checkbox"]:checked + label::before {
                border: 1px solid $purple-300;
              }

              input[type="checkbox"]:active + label::before {
                border: 1px solid $purple-300  !important;
              }

              input[type="checkbox"]:hover + label::before {
                border: 1px solid $purple-500  !important;
              }
            }
          }

          .gm-selection-check-field:last-child {
            border-top: 1px solid #edeff3;
          }
        }
      }

      .gm-selection-check-appendix {
        margin-top: 40px;
        display: none;
        width: 100%;
        color: $light-gray-text-color;
        text-align: center;

        &.visible {
          display: block;
        }
      }
    }

    .selection-warning {
      font-family: $rionasans-regular;
      -webkit-font-smoothing: antialiased;
      font-size: 12px;
      line-height: 1.67;
      text-align: center;
      color: #7b8291;
      margin-top: 16px;
    }
  }

  .gm-create-account-error {
    position: relative;
    font-size: 12px;
    line-height: 12px;
    color: #ff0000;
    float: left;
    margin-top: 2px;
  }
}
