@import '../../../styles/vars';
.resend-link-form {
  min-height: 100vh;
  width: 100vw;
  color: $text-color-V2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background: $page-background-color-V2 url(../../../images/gray-ribbon-v2.svg) center/100% no-repeat fixed;
  -webkit-font-smoothing: antialiased;

  .form-wrapper {
    width: 384px;
    min-height: 417px;
    padding: 32px;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    margin: 0 auto;
    z-index: 1;

    .genome-logo {
      display: inline-block;
      background-image: url('../../../images/logo.svg');
      background-repeat: no-repeat;
      background-size: contain;
      width: 126px;
      height: 63px;
      margin: 0px 92px;
    }

    .reset-title {
      font-family: $rionasans-medium;
      font-size: 24px;
      font-weight: 500;
      line-height: 1.42;
      color: $text-color-V2;
      -webkit-font-smoothing: antialiased;
      display: flex;
      justify-content: center;
      margin-top: 24px;
      margin-bottom: 4px;
    }

    .reset-desc {
      font: normal 14px/1.43 $rionasans-regular;
      text-align: center;
      color: $text-color-V2;
      -webkit-font-smoothing: antialiased;
    }

    .reset-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 24px;
    }

    .reset-icon > img {
      height: 120px;
      width: 120px;
    }
    
    .gm-form-label.unstyled {
      display: none;
    }
    
    .captcha-field {
      width: 304px;
      margin: 24px auto;
    }
  }
}
