@import "../../../../styles/vars";

.resend-confirmation-email {
  -webkit-font-smoothing: antialiased;
  position: relative;

  @media #{$la_md} {
    width: 100%;
    margin-right: 16px;
  }

  .verify-email-badge-icon {
    position: absolute;
    left: 4px;
    top: -12px;
    display: block;
    background: $purple-500;
    color: #ffffff;
    font-size: 18px;
    line-height: 24px;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    text-align: center;
  }

  @media #{$la_lg} {
    .verify-email-badge-icon {
      left: -12px;
    }
  }

  .content {
    padding: 16px;
    margin: 0 0 8px;
    display: flex;
    border-radius: 4px;
    border: 1px solid $purple-300  !important;
    background-color: $purple-100  !important;

    @media #{$la_md} {
      margin: 0 0 8px;
    }

    .left {
      min-width: 98px !important;
      width: 20% !important;

      @media #{$la_md} {
        width: initial;
        min-width: 85px !important;
      }
    }

    .right {
      width: 66% !important;

      button {
        width: auto !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      @media #{$la_md} {
        .verify-text {
          width: auto !important;
        }
      }

      .verify-text {
        text-align: left;
        word-break: break-word;
        min-width: 200px;
        font: normal 14px/1.43 $rionasans-regular;
        color: $text-color-V2;

        .email {
          font: normal 14px/1.43 $rionasans-medium;
        }
      }
    }

    @media #{$la_lg} {
      .right {
        width: initial !important;
        margin-left: 10px;
        position: relative;
        top: -10px;
      }
    }

    .resend-email {
      background-color: transparent !important;
      color: $light-indigo-V2;
      padding: 0;
      margin-top: 8px;
      text-align: left;
      font: normal 500 13px/1.38 $rionasans-medium;
      height: initial;
    }

    .resend-email:hover {
      color: $light-indigo-V2  !important;
      text-decoration: underline;
    }

    .resend-email:active {
      color: $light-indigo-V2  !important;
      box-shadow: none;
    }

    .resend-email:focus {
      color: $light-indigo-V2  !important;
    }
  }
}