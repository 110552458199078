@import '../../../../../styles/vars';

.scp-test-review-confirm-wrapper {
  max-width: 794px;
  margin: 0px auto;
  width: 100%;
  padding: 18px;

  @media #{$mobile} {
    padding: 15px;
  }

  .review-confirm-header {
    font: normal bold 22px/1.45 $rionasans-bold;
    color: $text-color-V2;
    margin-bottom: 34px;
    -webkit-font-smoothing: antialiased;
  }

  .review-info-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    .review-info-wrapper {
      display: flex;
      flex-flow: column wrap;
      flex: 0 208px;
      margin-bottom: 24px;

      .lbl-section {
        font: normal 12px/1.33 $rionasans-regular;
        color: $light-gray-text-color-v2;
      }

      .val-section {
        font: normal 14px/1.36 $rionasans-regular;
        color: $text-color-V2;
      }
    }

    .lab-detail-wrapper,
    .ship-address-detail-wrapper {
      margin-right: 92px;
    }
  }

  .confirm-info-container {
    margin: 23px 0 24px;
    padding: 24px 0 32px;
    border-top: 1px solid $page-background-color-V2;
    border-bottom: 1px solid $page-background-color-V2;

    @media #{$mobile} {
      padding: 24px 0 12px;
      margin-top: 0;
    }

    .label-section {
      color: $text-color-V2;
      font: normal 14px/1.36 $rionasans-regular;
      -webkit-font-smoothing: antialiased;

      .video-link-testing-cost {
        color: #40b9ec;
      }
    }
  }

  .buttons {
    display: flex;
    align-items: center;
    flex-flow: row wrap;

    @media #{$mobile} {
      flex-flow: column wrap;
    }

    .btn-request-test {
      margin: 0 32px 6px 4px;

      @media #{$mobile} {
        margin: 0 0px 12px 0px;
      }
    }

    .btn-default {
      width: 160px;
      height: 36px;
      font: $scp-submit-button-font;
      -webkit-font-smoothing: antialiased;
      color: #ffffff;

      @media #{$mobile} {
        height: 48px !important;
        width: 100% !important;
        border-radius: 24px;
        font: normal 500 16px $rionasans-medium  !important;
      }
    }

    .btn-back {
      color: $light-indigo-V2;
      font: $scp-submit-button-font;
      -webkit-font-smoothing: antialiased;
      cursor: pointer;
      display: inline-block;
      width: 30px;
      margin-bottom: 6px;

      @media #{$mobile} {
        height: 48px;
        width: 100%;
        border: 1px solid $purple-500;
        border-radius: 24px;
        font: normal 500 16px $rionasans-medium;
        display: flex !important;
        justify-content: center;
        align-items: center;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    .request-test-tooltip > div.el-tooltip__popper.is-dark {
      width: 200px;
      height: 66px;
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
      background-color: #262837;
      border-radius: 8px;
      padding: 12px;
      font: normal 12px/1.42 $rionasans-regular;
    }

    @media #{$mobile} {
      .request-test-tooltip {
        width: 100%;
      }
    }
  }
}

@media #{$la_lg} {
  .scp-test-review-confirm-wrapper {
    width: 794px;
    padding: 40px 0px;
  }
}
