@import '../../../../../styles/vars';
@import '../../../../../styles/mixins';

.document-card {
  width: 100%;
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 6px;
  background-color: #ffffff;
  border: 1px solid $page-background-color-V2;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  flex-flow: column wrap;
  color: $text-color-V2;
  -webkit-font-smoothing: antialiased;
  position: relative;
  @media #{$la_sm} {
    margin-right: 20px;
    width: 373px;
  }
  .document-card-data {
    font-size: 14px;
    width: calc(100% - 56px);
    .document-card-title {
      font-family: $rionasans-medium;
      font-size: 19px;
      margin-bottom: 4px;
    }
    .document-card-date {
      font-family: $rionasans-regular;
      margin-bottom: 12px;
    }
    .document-card-consultation-filename {
      font-family: $rionasans-regular;
      margin-bottom: 8px;
      padding-right: 8px;
    }
    .document-card-counselor-header {
      font-family: $rionasans-regular;
      color: $light-gray-text-color-v2;
    }
    .document-card-counselor-value {
      font-family: $rionasans-bold;
      color: $light-gray-text-color-v2;
    }
  }

  .document-card-row {
    display: flex;  
    flex-flow: row wrap;
    @media #{$mobile} {
      flex-flow: row wrap;
    }
    .doc-type {
      margin-left: 5px;
      font: normal bold 18px $rionasans-bold;
    }
    .doc-column-container {
      display: flex;
      flex-flow: column wrap;
      margin: 16px 32px 16px 0;
      min-width: 82px;
      .col-label {
        font: normal 12px $rionasans-regular;
        color: $light-gray-text-color-v2;
      }
      .col-value {
        font: normal 14px $rionasans-regular;
        
        .el-tooltip.descriptionTooltip {
          .el-tooltip__popper {
            font: 12px/1.42 $rionasans-bold;
            width: 252px;
            padding: 12px;
            background: $text-color-V2;
            border-radius: 8px;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
            color: $white-bg-color-v2;
            word-break: break-all;
          }
          .el-tooltip__rel > div {
            word-break: break-all;
          }
        }
      }
      &.upload-doc-col {
        min-width: 108px;
      }
    }
    @media #{$la_md} {
      .doc-column-container:nth-child(3){
        margin-right:0;
      }
    }

    .doc-column-container:last-child {
      margin: 16px 0 16px;
    }
    &.doc-type-title {
      align-items: center;
    }
    @media #{$mobile} {
      &.flex-column {
        flex-flow: column wrap;
      }
    }
    .download-container {
      font: normal 500 14px $rionasans-medium;
      color: $light-indigo-V2;
      cursor: pointer;
      .download-text {
        margin-left: 4px;                  
      }
    }       
    .delete-container {
      font:  normal 500 14px $rionasans-medium;
      color: $scp-info-box-failure-color;
      cursor: pointer;
      margin-left: 10px;
      .delete-text {
        margin-left: 4px;
      }
    }
    .delete-container.is-disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
  .document-card-row.display-block {
    display: block;
  }
  .document-card-row:last-child {
    border-top: 1px solid $page-background-color-V2;
    padding-top: 10px;
    margin-top: auto;
  }
  &.document-card-malicious {
    border: 1px solid red;
    .malicious-icon {
      width: 28px;
      margin-left: auto;
    }
    .document-card-row:last-child {
      border-top: none;
    }
  }
  .document-card-loader {
    top: 58px;
    right: 68px;
    position: absolute !important;
    @media #{$mobile} {
      top: 60px;
      right: 80px;
    }
  }

}

.document-card-patient {
  padding: 16px;
  margin-bottom: 8px;  
}

.document-card-archived {
  opacity: 50%;
  cursor: no-drop;
}