@import '../../../../styles/vars';
$md: '(min-width: 750px)';
$sm: '(min-width: 414px)';

.scp-event-info {
  position: relative;
  margin: 20px auto 0;
  text-align: left;

  .block-title {
    text-align: center;
    margin-top: 40px;
    font-size: 25px;
    color: $gray-text-color;
  }
  .block-content {
    position: relative;
    min-height: 300px;
    margin: 25px auto 0;
    background-color: #e7f6fd;
    display: flex;
    @media #{$md} {
      flex-flow: nowrap;
    }
    .photo {
      position: relative;
      width: 240px;
      align-self: flex-end;
      display: none;
      @media #{$ie_only} {
        top: 32px;
      }
      @media #{$md} {
        display: inline-block;
      }
      &.cal-day {
        display: inline-block;
        padding: 20px 0 20px;
        align-self: center;
      }
    }
    .event-fields {
      align-self: center;
      margin-left: 20px;
      margin-right: 20px;
      @media #{$ie_only} {
        margin-top: 32px;
      }
      .caption {
        color: $light-gray-text-color-v2;
        font: normal 12px/1.33 $rionasans-regular;
        -webkit-font-smoothing: antialiased;
      }
      .value {
        color: $text-color-V2;
        font: normal 14px/1.36 $rionasans-regular;
        -webkit-font-smoothing: antialiased;
        padding: 0 0 24px 0;
        &:last-child {
          padding: 0;
        }
      }
    }
  }
}

.ninetyDays {
  .scp-event-info {
    .block-content {
      background-color: #ffffff;
      border-top: 2px solid $scp-border-color;
      border-right: 2px solid $scp-border-color;
      border-left: 2px solid $scp-border-color;
      padding: 24px 24px 0 24px;
      min-height: initial;

      .event-fields {
        border-left: 1px solid #d8dde4;
        margin: 0;
        padding-left: 24px;
        .caption {
          font: normal 14px/16px $rionasans-bold;        
        }
        .value {
          font: normal 16px/20px $rionasans-regular;
          padding: 0 0 32px 0;
          &:last-child {
            padding: 0;
          }
        }
      }
    }
  }
}

