@import "../../styles/vars";
@import "./CalendarLoader";

.gm-cal-container {
  position: relative;
  .rbc-calendar.gm-calendar {
    max-width: $calendar-width;
    width: 100%;
    height: 950px;
    color: #4A494B;
    &.white {
      .rbc-day-slot.rbc-time-column {
        background-color: white;
        background-image: none;
      }
    }
    .rbc-time-header .rbc-allday-cell {
      /* remove all day row*/
      display: none;
    }
    .rbc-current-time-indicator {
      /* disable current time indicator*/
      display: none;
    }
    .rbc-current-time-indicator {
      display: none !important;
    }
    .rbc-time-gutter.rbc-time-column {
      .rbc-time-slot {
        background-color: white;
      }
    }
    .rbc-time-view {
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: none;
    }
    .rbc-time-content {
      border-top: none;
    }
    .rbc-time-header {
      .rbc-time-header-content {
        border: none;
      }

      &.rbc-overflowing {
        border: none;
      }
    }
    .rbc-time-header > .rbc-row > * + * {
      border: none;
    }
    .rbc-row:first-child {
      border: none;
    }
    .rbc-day-slot {
      .rbc-time-slot:nth-child(1) {
        border-top: none;
      }
      .rbc-time-slot:nth-child(2) {
        border-top: 1px dashed #DDD;
      }
      .rbc-time-slot:nth-child(3) {
        border-top: 1px dashed #DDD;
      }
      .rbc-time-slot:nth-child(4) {
        border-top: 1px dashed #DDD;
      }
      .rbc-events-container {
        right: 0;
      }
    }
    .rbc-today {
      background-color: white;
    }
    .rbc-header {
      border: none;
    }
    .rbc-header, .rbc-time-gutter.rbc-time-column {
      font-size: 11px;
      font-weight: normal;
      text-transform: uppercase;
      .rbc-label.rbc-now {
        font-weight: normal;
      }
    }
    .rbc-time-gutter.rbc-time-column {
      .rbc-timeslot-group {
        border-left: none;
        border-top: none;
        border-bottom: none;
      }
    }
    .rbc-day-slot.rbc-time-column {
      border-top: 1px solid #DDD;
      .rbc-timeslot-group {
        z-index: 2 !important;
        background-color: transparent;
        pointer-events: none;
      }
      &:last-child {
        .rbc-timeslot-group {
          border-right: 1px solid #DDD;
        }
      }
    }
    .rbc-time-column {
      .rbc-timeslot-group {
        .rbc-label {
          span {
            position: relative;
            top: -3px; // FIX ME: I can't get the first span upper because it gets cropped
          }
        }
      }
    }
    &.shaded {
      opacity: 0.5;
      .rbc-day-slot.rbc-time-column {
        background-color: white !important;
        background-image: none;
      }
    }
  }
  .rbc-timeslot-group {
    min-height: 60px;
  }
  .event-border-right {
    border-right: 1px solid #DDD;
  }
  .calendar-loader {
    position: absolute;
    z-index: 10;
    top: calc(50% - 18rem);
    left: calc(50% - 4rem);
  }
}
