@import '../../../../styles/vars';
@import '../../../../styles/mixins';

body {
  margin: 0;
}

.login-form {

  .label-field {
    font: normal 500 14px/1.43 $rionasans-medium;
    margin-bottom: 7px;
    margin-top: 8px;
  }

  .required::before {
    content: '*';
    color: $required-red-color;
    font-size: 14px;
    margin-left: -5px;
  }

  .input-section {
    input {
      font: normal 16px/1.71 $rionasans-regular;
    }

    .gm-form-control.email {
      padding: 0 1px;
    }
  }

  .no-account-text {
    font: normal 500 14px/1.71 $rionasans-medium;
    color: #7b8291;
    margin-top: 24px;
    text-align: center;
    @media #{$mobile} {
      .reset-password-link {
        padding: 10px 0;
      }
    }
  }

  .submit-button {
    button {
      width: 100%;
    }
  }

  .gm-login-form-inline {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    margin-top: 8px;
    margin-bottom: 24px;
    .form-inline-left {
      flex: 1 1 auto;
      padding: 0 10px 0 0;
    }
  }
  
  .label-section {
    font: normal 500 14px/1.43 $rionasans-medium;
    -webkit-font-smoothing: antialiased;
  }

  @media #{$mobile} {
    .form-inline-right {
      display: flex;
      align-items: center;

      .checkbox-wrapper {
        display: flex;
        align-items: center;
      }
    }
  }
      
  .reset-password-link {
    font: normal 500 14px/1.43 $rionasans-medium;
    color: $light-indigo-V2;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
  }
  
  .form-fields {
    margin-top: 65.5px;
    form {
      max-width: 400px;
      margin: 0 auto;
      .submit-button {
        button {
          width: 100%;
        }
      }
    }
  }
}