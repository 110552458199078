@import '../../../styles/vars';
@import '../../../styles/mixins';

.header-user-menu {
  position: absolute;
  right: 4px;
  top: 2px;
  height: 90px;
  min-width: 240px;
  padding: 30px 45px 0;
  background: transparent;
  border: 1px solid transparent;
  text-align: left;
  cursor: pointer;
  overflow: hidden;
  color: #828282;

  &:hover {
    height: 240px;
    background: #fff;
    border: 1px solid #e5e5e5;
    z-index: 20;

    .user-hidden-menu {
      display: block;
    }
  }

  .user-name {
    position: relative;
    max-width: 190px;
    height: 25px;
    overflow: hidden;
    word-break: break-all;
    text-overflow: ellipsis;

    font-size: 18px;
    padding-right: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    background: rgba(#fff, 0.8);
    color: #00b0f5;

    @media #{$la_xl} {
      max-width: 250px;
    }

    &.lite-account {
      border: none;
      top: 6px;
      padding: 0 5px;
      @media #{$la_xl} {
        padding-right: 10px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 10px;
      border: 4px solid transparent;
      border-top: 7px solid #828282;
    }
  }

  .user-role {
    font-size: 13.5px;
    font-weight: 600;
    color: #6d6d6d;
    padding-left: 1px;
  }

  .user-hidden-menu {
    display: none;
    margin-top: 35px;
    font-weight: 400;

    .user-menu-point {
      padding: 5px 0;
      color: #828282;
      font-size: 16px;
      a {
        color: #828282;
      }
      a:hover {
        color: #00b0f5 !important;
        text-decoration: none;
      }
      &.hover {
        color: #00b0f5 !important;
      }
    }
  }
}
