@import './vars';
@import './forms/input';
@import './forms/select';

.gm-form-field {
  margin-top: 15px;
  align-items: center;
  &.horisontal {
    display: flex;
    flex-wrap: wrap;
    .gm-form-control {
      flex-shrink: 1;
      flex-grow: 1;
      flex-basis: 300px;
    }
    &.error {
      margin-bottom: 20px !important;
    }
  }
  &.error {
    .gm-form-label {
      display: block;
      text-align: left;
      color: red !important;
      &.unstyled {
        color: inherit;
      }
      &.label-bottom {
        padding: 4px 0 0 0;
      }
      &.lbl-zipcode-error {
        max-width: 208px;
        font-size: 0.71em;
      }
    }
  }
  .gm-form-label {
    color: $body-text-color;
    font-weight: 300;
    font-size: 15px;
    margin: 0;
    &.error-label {
      display: block;
      text-align: left;
      color: red !important;
    }
    input[type='checkbox'] {
      padding: 0 0 10px 0;
    }
  }
  .gm-form-control {
    margin: 5px 0 0 0;
    min-height: 40px;
    input {
      width: 100%;
      height: 100%;
      border: 1px solid #dcdcdc;
      border-radius: 3px;
      padding: 10px 12px;
      &[readonly],
      &[disabled] {
        background-color: #eeeeee;
      }
    }
    textarea {
      resize: vertical;
      width: 100%;
      min-height: 120px;
      max-height: 300px;
      border: 1px solid #dcdcdc;
      border-radius: 3px;
      background-color: #ffffff;
      &[readonly],
      &[disabled] {
        background-color: #eeeeee;
      }
    }
  }
}

.captcha-field {
  width: 304px;
  margin: 20px auto;
}

// &.disabled {
//   .button-next {
//     color: lightgray !important;
//   }
// }
