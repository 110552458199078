@import '../vars';
$select-text-color: $light-gray-text-color;
$select-placeholder-color: $select-text-color;
$select-triangle-color: #373a3c;
.Select.gm-select {
  .Select-control {
    border-radius: 3px;
    border: $form-element-border;
    box-shadow: none !important;
    .Select-input {
      height: $form-element-height - 2px;
      input {
        padding: 0;
      }
    }
    .Select-value {
      line-height: $form-element-height;
      .Select-value-label {
        color: $dark-gray-text-color;
      }
    }
    .Select-placeholder {
      line-height: $form-element-height;
      color: $select-placeholder-color;
    }
    .Select-arrow {
      border-color: $select-triangle-color transparent transparent;
    }
  }
  &.is-open {
    .Select-arrow {
      border-color: transparent transparent $select-triangle-color;
      border-top-color: $select-triangle-color;
    }
  }
}

.Select.scp-gm-select {
  &.is-focused{
    .Select-control {
      border-radius: 4px;
      border: solid 1px #70cbf1;
    }
  }
  .Select-control {
    border-radius: $scp-form-element-border-radius;
    border: $scp-form-element-border;
    box-shadow: none !important;
    .Select-input {
      height: $scp-form-element-height - 2px;
      color: $scp-form-element-color;
      font: $scp-form-element-font;
      input {
        padding: 0;
      }
    }
    .Select-value {
      line-height: $scp-form-element-height;
      .Select-value-label {
        color: $scp-form-element-color;
        font: $scp-form-element-font;
      }
    }
    .Select-placeholder {
      font: $scp-form-element-font;
      color: $scp-form-element-placeholder-color;
      line-height: $scp-form-element-height;
    }
    .Select-arrow {
      border-color: #bac3ce transparent transparent;
    }
  }
  &.is-open {
    .Select-arrow {
      border-color: transparent transparent #bac3ce;
      border-top-color: #bac3ce;
    }
  }
  .Select.is-open,
    .Select-menu-outer {
      .Select-menu {
        max-width: 239px;
        padding: 4px 0;
        overflow: auto;
        &::-webkit-scrollbar {
          width:  3px;
        }
        &::-webkit-scrollbar-thumb {
          background: #bac3ce;
        }
        &::-webkit-scrollbar-track {
          background: transparent;
        }
        .Select-option {
          font: $scp-form-element-font;
          color: $scp-form-element-color;
          &:hover {
            background-color: #e4e8f1;
          }
        }
        .Select-option.is-selected,
        .Select-option.is-selected.is-focused
         {
          background-color: #1c8de0;
          color:#fff;
          &:hover {
            background-color: #1c8de0;
            color:#fff;
          }
        }
        .Select-option.is-focused {
          background-color: #e4e8f1;
        }
      }
    }

}
