@import '../../../styles/vars';

.scp-test-order-progressbar-wrapper {
  width: 100%;
  display: flex;
  z-index: 1;
  justify-content: center;

  ul {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 532px;
    list-style: none;
    padding: 0;

    li:first-child {
      &::before {
        content: none;
      }
    }

    li {
      width: 100%;
      position: relative;
      text-align: center;

      &::before {
        content: '';
        position: absolute;
        width:100%;
        top: 13px;
        left: -50%;
        // z-index: -1;
        border-top: 1.5px solid #bac3ce;
      }

      span {
        &::after {
          width: 30px;
          height: 30px;
          margin: 0 auto 8px;
          border-radius: 50%;
          line-height: 30px;
          text-align: center;
          display: block;
          background: #ffffff;
          color: #ffffff;
          border: 1px solid #bac3ce;
          content: " ";
          font-weight: bold;
          font-size: 16px;
          position: relative;
          z-index: 1;
        }
      }
      &.done {
        span {
          &::after {
            background: #ffffff;
            color: #00d65b;
            border-color: transparent;
            content: url('../../../images/check-outline.svg');
          }

        }
      }
      &.done::before {
        border-top: 1.5px solid #00d65b;
      }

      &.current {
        span {
          &::after {
            background: #ffffff;
            color: #00d65b;
            border: 1.5px dotted #00d65b;
            background-color: #a2ffad;
          }
        }
      }

      &.current::before {
        border-top: 1.5px solid #98edbb;
      }

      &.cancel {
        span {
          &::after {
            background: #ffffff;
            color: #00d65b;
            border-color: transparent;
            content: url('../../../images/cross-outline.svg');
          }
        }
      }

      &.cancel::before {
        border-top: 1.5px solid #F4A4A5;
      }

      .step-name {
        font: normal 14px/1.36 $rionasans-regular;
        text-align: center;
        color: $text-color-V2;
        max-width: 78px;
        min-width: 78px;
        margin: 0 auto;
        
        @media #{$la_md} {
          min-width: 87px;
          max-width: 150px;
        }

      }
    }

    li:last-child {
      .step-name {
        width: 134px;
      }
    }
  }
}

