@import '../../../../../styles/vars';

.scp-schedule-wizard.scp-insurance-stage {
  @media #{$la_lg} {
    max-width: 814px;
    width: 814px;
  }
  .required:before {
    content: '*';
    color: $required-red-color;
    font-size: 14px;
    margin-left: -5px;
  }

  .label-section {
    width: 100%;
    font: normal normal 14px/16.8px $rionasans-medium;
    letter-spacing: normal;
    display: flex;
    align-items: flex-start;
    color:$light-gray-text-color-v2;
    margin-bottom: 4px;
  }

  -webkit-font-smoothing: antialiased;
  padding: 0 15px !important;
  margin: 32px 0 32px 0 !important;

  @media #{$la_lg} {
    padding: 40px 0px !important;
    margin: 0 auto !important;
  }

  .gm-select-service__header {
    margin-bottom: 32px !important;
    font-size: 23px !important;
    line-height: 27.6px !important;
  }

  .insurance-form {
    max-width: 860px;
    margin: 0 auto;

    .action-buttons {
      display: flex;
      justify-content: flex-end;

      @media #{$mobile} {
        flex-direction: column-reverse;
      }

      >button {
        min-width: 200px;
        height: 40px;
        margin-bottom: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        border: 1px solid $light-indigo-V2;
        color: $light-indigo-V2;
        font-family: $rionasans-bold;
        font-size: 16px;
        background-color: $white-bg-color-v2;
        cursor: pointer;

        @media #{$mobile} {
          min-width: unset;
          width: 100%;
          margin-bottom: 16px;
        }

        &:disabled {
          opacity: 0.35;
        }

        &.btn-back {
          margin-right: 56px;

          &:disabled {
            cursor: not-allowed;
          }

          &:hover:not(:disabled) {
            background-color: rgba(123, 38, 201, 0.15);
          }

          &:active {
            background-color: rgba(123, 38, 201, 0.3) !important;
          }
        }

        &.btn-next {
          background-color: $light-indigo-V2;
          color: $white-bg-color-v2;

          &:disabled {
            cursor: not-allowed;
          }

          &:hover:not(:disabled) {
            background-color: $scp-button-hover-color;
          }

          &:active {
            background-color: $purple-600 !important;
          }
        }
      }
    }

    // @media #{$mobile} {
    //   > .buttons {
    //     > div {
    //       padding: 10px 0;
    //     }

    //     button {
    //       height: 40px !important;
    //     }
    //   }
    // }

    .scp-insurance-fields {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap-reverse;

      @media #{$la_md} {
        flex-wrap: unset;
        border-bottom: 1px solid #D8DDE4;
        margin-bottom: 32px;
        padding-bottom: 32px;
      }
      .insurance-group{
        width: 100%;
        display: flex;
        flex-flow: wrap;
      }

      .scp-insurance-sections {
        width: 470px;
        margin-right: 16px;
        min-width: 100%;
        display: flex;
        flex-direction: column;

        @media #{$la_md} {
          min-width: 503px;
          border-right: 2px solid #edeff3;
          padding-right: 24px;
          margin-right: 24px;
        }
        @media #{$mobile} {
          border-top: 2px solid #edeff3;
          padding-top: 32px;
          margin-top: 32px;
        }

        .scp-insurance-section {
          // margin-bottom: 32px;
          margin-bottom: 0px;

          &.insurance-name-section{
            @media #{$mobile}{
            padding-bottom: 16px;
            }
          }
          &.address-section{
            @media #{$mobile} {
            margin-bottom: 32px;
            }
          }
          &.upload-section{
            @media #{$la_lg} {
              margin-bottom: 32px;
            }
          }
          // @media #{$mobile}{
          //   padding-bottom: 16px;
          // }

          &.insurance-section{
            @media #{$mobile} {
              border-bottom: 1px solid #EDEFF3;
              padding-bottom: 16px;
              margin-bottom: 32px;
            }
            @media #{$la_md} {
              margin-bottom: 17px;
            }

        }

          .head-label-section {
            width: 100%;
            font: normal 500 16px/20px $rionasans-medium;
            letter-spacing: normal;
            color: #262837;

            &.required:after {
              content: '*';
              color: $required-red-color;
              font-size: 14px;
              margin-right: 5px;
            }
          }

          .head-label-section-main {
            width: 100%;
            font: normal bold 19px/22.8px 'RionaSans-Bold', Arial, Helvetica, sans-serif;
            letter-spacing: normal;
            padding-bottom: 32px;
            color:$light-gray-text-color-v2;
            @media #{$la_md} {
              padding-bottom: 16px;
            }
          }

          .form-row {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &.addres-row {
              flex-direction: row;
              flex-wrap: wrap;
            }

            @media #{$la_md} {
              flex-direction: row;
              margin-bottom: 19px;
            }

            .form-col {
              width: 100%;
              margin-bottom: 16px;

              @media #{$la_md} {
                width: 227px;
                margin-bottom: 0px;
              }

              &.city-col {
                width: 100%;

                @media #{$la_md} {
                  width: 40%;
                }
              }

              &.state-col {
                width: 35%;

                @media #{$la_md} {
                  width: 25%
                }
              }

              &.zip-col {
                width: 60%;

                @media #{$la_md} {
                  width: 146px;
                }
              }

              &.address-col {
                width: 100%;
              }
            }

            .datefield-wrapper .rdt .form-control:hover {
              border: solid 1px #70cbf1;
            }


            .input-wrapper .el-input .el-input__inner,
            .datefield-wrapper .rdt .form-control,
            .input-mask-wrapper input,
            .select-wrapper .el-select .el-input .el-input__inner {
              height: 32px;
              font: $scp-form-element-font;
              letter-spacing: normal;

              @media #{$mobile} {
                height: 40px;
              }
            }

            .select-wrapper .el-select {
              .el-select-dropdown {
                font: $scp-form-element-font;

                .el-select-dropdown__item {
                  color: $text-color-V2;
                  font: $scp-form-element-font;

                  &.selected {
                    color: #ffffff;
                  }
                }
              }
            }
          }
        }

        // Insurance card
        .insurance-upload-block {
          padding-top: 24px;
          display: flex;
          max-width: 100%;
          flex-direction: column;
          justify-content: center;

          @media #{$la_md} {
            flex-direction: row;
            justify-content: space-between;
          }
          @media #{$mobile} {
            margin-bottom: 32px;
          }

          &.disable-insurance-upload-block {
            cursor: not-allowed;

            .upload-card {
              pointer-events: none;
            }
          }

          .upload-card {
            &:first-child{
              @media #{$mobile}{
                margin-bottom: 48px;
              }

            }
            .uploaddoctitle {
              width: 192px;
              height: 16px;
              font-family: $rionasans-medium;
              font-size: 14px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 16.8px;
              letter-spacing: normal;
              text-align: left;
              color: #525968;
            }

            .card-img {
              width: 227px;
              height: 142px;

              .cross-icon {
                opacity: 0;
                position: absolute;
                top: 11px;
                right: 15px;
                color: #fff;
                font-size: 16px;
                cursor: pointer;
              }

              .update-image {
                position: absolute;
                left: 50%;
                top: 50%;
                -moz-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                display: none;

                .camera-img {
                  width: 76px;
                  height: 76px;
                }
              }

              .upload-progressbar {
                position: absolute;
                left: 50%;
                top: 50%;
                -moz-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                width: 195px;
              }

              .doc-img {
                width: 227px;
                height: 142px;
                opacity: 1;
              }
            }

            .border-box {
              border-radius: 4px;
              border: dashed 1px #bac3ce;

              &.file-selected {
                border: unset !important;

                .doc-img {
                  border-radius: 9px;
                }
              }

              &.scanning {
                border: dashed 1px #bac3ce !important;

                &:hover {
                  cursor: unset;
                }

                .el-loading-spinner {
                  top: 40%;
                }
              }

              &.is-malicious {
                &:not(:hover) {
                  border: 1px solid $error-text-color !important;
                }

                .card-img {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }

                .malicious-icon {
                  width: 40px;
                  height: 40px;
                }
              }
            }

            .border-box:hover {
              border-radius: 9px;
              border: 1px solid transparent;

              &.file-selected:not(.scanning),
              &.is-malicious:not(.scanning) {
                background-color: rgba(0, 0, 0, 0.7) !important;
              }
            }

            .back-border-box:hover {
              border-radius: 9px;
              background-color: transparent;

              &:not(.file-selected) {
                border: dashed 1px #bac3ce;
              }
            }

            .border-box:hover .doc-img {
              opacity: 0.2;
            }

            .border-box:hover .cross-icon {
              opacity: 1;
            }

            .border-box:hover .update-image {
              cursor: pointer;
              display: block;
            }

            .uplpad-img-radius {
              border-radius: 9px;
              border: 1px solid transparent;
              overflow: hidden;

              &.is-malicious:hover {
                overflow: unset;
              }
            }

            .uploaddoc {
              width: 227px;
              height: 142px;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              cursor: pointer;

              @media #{$mobile} {
                width: 100%;
                height: 234px;
                margin-top:5px;
              }

              .uploaddoc-dropblock {
                display: block;
                z-index: 1;
                position: absolute;
                left: 50%;
                top: 50%;
                -moz-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
              }

              .text-center {
                text-align: center;
              }

              .cloud-img {
                width: 30px;
                height: 30px;
              }

              .uploaddoctext {
                width: 193px;
                height: 16px;
                font-family: $rionasans-medium;
                font-size: 14px;
                font-weight: 500;
                line-height: 16.8px;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: center;
                color: #262837;
                span {
                    color: #5B43BD;
                    @media screen {
                      text-decoration: underline;
                    }
                  }
                .uploaddoclink {
                  color: #40b9ec;
                  text-decoration: underline;
                }
              }

              .dochover-text {
                height: 20px;
                font-family: RionaSans-Bold !important;
                font-size: 14px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: #ffffff;
              }
            }

            .error {
              font-size: 12px;
              margin-top: 10px;
              display: block;
              color: $error-text-color;
            }

            .error-div {
              width: 298px;
            }
          }
        }

        &.secondary-section{
          padding-top: 32px;
          .insurance-section{
            padding-top:32px;
            border-bottom: none;
            padding-bottom:0;
            @media #{$la_lg} {
              margin-bottom: 0;
            }
            .main-fields-container{
              .form-row{
                &:last-child{
                  @media #{$la_lg} {
                    margin-bottom: 0;
                  }
                }

              }
            }
          }
        }
      }

      .insurance-type-description {
        flex-basis: 100%;
        font-family: $rionasans-regular;

        @media #{$la_md} {
            width: 287px;
        }

        @media #{$mobile} {
          a {
            padding: 12px 0;
          }
        }
        .heading {
          margin-bottom: 16px;
          color: $light-gray-text-color-v2;
          font: normal bold 19px/22.8px $rionasans-bold;
          @media #{$mobile} {
            display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0;
          }
        }

        .subheading {
          font-size: 14px;
          font-family: $rionasans-bold;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
          margin-bottom: 8px;
          padding-top: 16px;
          color: $light-gray-text-color-v2
        }
                .description {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 18px;
                  letter-spacing: 0em;
                  text-align: left;
                  @media #{$mobile}{
                    // font-size: 16px;
                    // line-height: 20px;
                  }

                }
      }

      .shake-insurance-description {
        animation: shake 0.82s cubic-bezier(0.66, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
      }

      @keyframes shake {
        10%,
        90% {
          transform: translate3d(-1px, 0, 0);
        }

        20%,
        80% {
          transform: translate3d(12px, 0, 0);
        }

        30%,
        50%,
        70% {
          transform: translate3d(-4px, 0, 0);
        }

        40%,
        60% {
          transform: translate3d(4px, 0, 0);
        }
      }
    }

    .info-text {
      color: $light-gray-text-color-v2;
      letter-spacing: normal;
      font: normal 500 12px/1.42 $rionasans-medium;

      a {
        color: #40b9ec;
        text-decoration: underline;
      }
    }

    .main-fields-container {
      max-width: 770px;
      padding-top: 16px;

      &.cursor-not-allowed {
        cursor: not-allowed;

        label {
          cursor: not-allowed;
        }

        .radio {
          input[type='radio'] {
            + .radio-label {
              cursor: not-allowed;

              &:before {
                cursor: not-allowed;
              }
            }
          }
        }
      }
      .address-line-1 {
        @media #{$la_lg} {
          width: 100% !important;
        }
      }
      .address-line-2 {
        @media #{$la_lg} {
          width: 100% !important;
        }
      }
      .city-state-zip-row{
        margin-bottom: 0px !important;
        @media #{$mobile} {
          flex-direction: row !important;
        }
        .address-city-col {
          width: 50% !important;
        }
        .address-state-col {
          width: 20% !important;
        }
        .address-zip-col {
          width: 25% !important;
        }
      }

    }

    .narrow-field {
      width: 370px;

      .react-datepicker-wrapper {
        width: 100%;

        .react-datepicker__input-container {
          width: 100%;
        }
      }
    }

    .insurance-form-checkbox {
      font-size: 14px;
      width: 100%;
      margin-bottom: 32px;
      @media #{$la_lg} {
        margin-top: 8px;
      }

      @media #{$mobile} {
        height: unset;
      }

      .label-section {
        padding-top: 0;
        font: normal 500 14px/1.43 $rionasans-medium;
        letter-spacing: normal;
        color: #262837;
      }

      .checkbox-description {
        .info-outline-icon {
          background: url('./info-outline-icon.svg');
          background-size: 100%;
          width: 16px;
          height: 16px;
          vertical-align: top;
        }

        .checkbox-tooltip {
          .el-tooltip__popper {
            min-width: 200px;
            padding: 9px !important;
            font: $scp-review-page-label-font;
            border-radius: 8px !important;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5) !important;
            color: #262837 !important;
            background-color: #ffffff !important;
            -webkit-font-smoothing: antialiased;
            @media #{$la_md} {
              min-width: 420px;
            }
            max-width: 420px;
          }
          .description-hint-header {
            font: $scp-review-page-price-font !important;
            line-height: 21px;
            color: #262837 !important;
            margin-bottom: 12px;
            -webkit-font-smoothing: antialiased;
          }
          .popper__arrow {
            border-right-color: rgba(0, 0, 0, 0.5) !important;
            &::after {
              border-right-color: #ffffff !important;
            }
          }
        }
      }
    }

    .insurance-form-radiogroup.scp-gm-radio-control {
      font-size: 14px;
      width: 100%;

      @media #{$mobile} {
        height: unset;
      }

      .gm-radio-label {
        padding-top: 0;
        font: $scp-insurance-star-font;
        letter-spacing: normal;
        color: #262837;
      }

      .gm-radio-group {
        align-items: flex-start;
        flex-flow: column;
        padding-top: 0;

        .radio:first-child {
          margin-left: 0;
          margin-bottom: 15px;
        }

        .radio-button-description .info-outline-icon {
          background: url('./info-outline-icon.svg');
          background-size: 100%;
          width: 16px;
          height: 16px;
          vertical-align: top;
        }
      }

      &.insurance-form-radiogroup {
        .scp-gm-radio-control__invalid {
          bottom: -20px;
          left: 25px;
        }

        &.fz15px {
          justify-content: flex-start;
          height: auto;

          .gm-radio-group {
            display: flex;
            flex-direction: row;
          }

          .gm-radio-label {
            display: none;
            padding: 0;
            margin: 0;
          }

          .radio {
            margin-top: 0px;
            margin-right: 5px;
            margin-bottom: 0px;
            margin-left: 0px;
          }

          .radio:first-child {
            margin-bottom: 0px;
            margin-right: 35px;
          }
        }

        &.fz15px .scp-gm-radio-control__invalid {
          font-size: 15px !important;
        }
      }

      .radio {
        background: transparent;

        input[type='radio'] {
          + .radio-label {
            padding: 8px 0;
            font: $scp-review-page-value-font !important;
            letter-spacing: normal;
            text-align: left;
            color: #262837;

            &:before {
              border: 2px solid $form-element-border;
              width: 1em;
              height: 1em;
              top: 0.15em;
              margin-right: 0.8em;
              background-color: transparent;
            }
          }

          &:checked {
            + .radio-label {
              &:before {
                background-color: $purple-500;
                border: 1px solid $purple-300;
                box-shadow: inset 0 0 0 3.5px #fff;
              }
            }
          }

          &:focus {
            + .radio-label {
              &:before {
                border-color: $purple-300;
              }
            }
          }
        }
      }
    }

    .upload-content {
      text-align: left;
      margin-top: 8px;

      &.info-text {
        // color: #7b8291;

        @media #{$mobile} {
          a {
            padding: 12px 0;
          }
        }
      }
    }

    .insurance-tooltip {
      .info-outline-icon {
        background: url('./info-outline-icon.svg');
        background-size: 100%;
        width: 16px;
        height: 16px;
        vertical-align: top;
        margin-left: 4px;
      }

      .el-tooltip__popper {
        min-width: 200px;
        padding: 9px !important;
        font: $scp-review-page-label-font;
        border-radius: 8px !important;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5) !important;
        color: #262837 !important;
        background-color: #ffffff !important;
        -webkit-font-smoothing: antialiased;
        @media #{$la_md} {
          min-width: 420px;
        }
        max-width: 420px;
      }
      .description-hint-header {
        font: $scp-review-page-price-font !important;
        line-height: 21px;
        color: #262837 !important;
        margin-bottom: 12px;
        -webkit-font-smoothing: antialiased;
      }
      .popper__arrow {
        border-right-color: rgba(0, 0, 0, 0.5) !important;
        &::after {
          border-right-color: #ffffff !important;
        }
      }
    }
  }
}

/* For Mobile Device */
.mobile-schedule-container {
  .scp-schedule-wizard.scp-insurance-stage {
    .insurance-form {
      .scp-insurance-fields {
        margin-top: 0;

      }

      // .buttons {
      //   margin-top: 0;
      //   flex-direction: column;

      //   > div {
      //     width: 100%;
      //   }

      //   .button.button-next {
      //     margin-right: 0;
      //   }

      //   .button.button-next .btn-default {
      //     height: 48px !important;
      //     width: 100% !important;
      //     border-radius: 24px;
      //     font: normal 500 16px $rionasans-medium !important;
      //   }

      //   .button-back {
      //     height: 48px !important;
      //     width: 100% !important;
      //     border: 1px solid $purple-500;
      //     border-radius: 24px;
      //     font: normal 500 16px $rionasans-medium !important;
      //     display: flex !important;
      //     justify-content: center;
      //     align-items: center;
      //   }
      // }
    }
  }
}

.bordered-div {
  border: 1px solid #edeff3;
}
