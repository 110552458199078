@import '../../../styles/vars';

.scp-test-order-detail-container {
  color: $text-color-V2;
  -webkit-font-smoothing: antialiased;
  clear: both;

  .order-detail-title {
    font: normal 500 16px/1.5 $rionasans-medium;
    margin-top: 30px;
    color: $text-color-V2;
  }

  ul {
    padding-left: 6px;

    li {
      margin-top: 16px;
      padding-left: 10px;
      font: normal 14px/1.21 $rionasans-regular;
      color: $light-gray-text-color-v2;
      list-style-type: '•';
    }
  }

  .outer-button.go-to-home-button > a > button {
    width: 248px;
    height: 50px;
    border-radius: 100px !important;
    margin-bottom: 40px;
    font: normal 500 15px/1.38 $rionasans-medium;
  }

  .scp-test-detail-wrapper {
    margin: 0 -16px 32px -16px;
    padding: 32px 24px;
    border: none;
    display: flex;
    flex-flow: row wrap;
    border-top: 1px solid $scp-border-color;
    border-bottom: 1px solid $scp-border-color;

    @media #{$la_md} {
      margin: 40px 0 80px;
      border: 1px solid $scp-border-color;
    }

    .lab-detail-wrapper {
      display: flex;
      flex-flow: column wrap;
      flex: 0 219px;

      .lab-label,
      .test-label {
        font: normal bold 14px/1.43 $rionasans-bold;
        color: $scp-smoke-color;
      }

      .lab-value,
      .test-value {
        font: normal bold 16px/1.13 $rionasans-medium;
        color: $text-color-V2;
      }

      .test-label {
        margin-top: 22px;
      }
    }

    .test-detail-wrapper {
      display: flex;
      flex-flow: column wrap;
      flex: 0 469px;
      margin-top: 16px;

      .test-label {
        font: normal bold 14px/1.43 $rionasans-bold;
        color: $scp-smoke-color;
      }

      .test-description {
        font: normal 14px/1.21 $rionasans-regular;
        color: $light-gray-text-color-v2;

        p {
          font: normal 12px/1.58 $rionasans-regular;
          margin-top: 16px;
          color: #7b8291;

          .chat {
            color: $light-indigo-V2;
            font-weight: bold;
            cursor: pointer;
          }
        }
      }
    }

    .divider {
      height: 1px;
      width: 794px;
      margin: 32px -12px 32px -12px;
      background-color: $scp-border-color;
    }

    .ship-address-detail-wrapper {
      display: flex;
      flex-flow: column wrap;
      flex: 0 208px;

      .ship-label {
        font: normal 12px/1.33 $rionasans-regular;
        color: $light-gray-text-color-v2;
      }

      .ship-address-val {
        font: normal 14px/1.36 $rionasans-regular;
        color: $text-color-V2;
      }
    }

    .personal-info-wrapper {
      display: flex;
      flex-flow: column wrap;
      flex: 0 150px;
      margin-left: 0;

      @media #{$la_md} {
        margin-left: 165px;
      }

      .persnl-info-label {
        font: normal 12px/1.33 $rionasans-regular;
        color: $light-gray-text-color-v2;
      }

      .persnl-info-val {
        font: normal 14px/1.36 $rionasans-regular;
        color: $text-color-V2;
      }
    }

    .test-order-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1 1 auto;

      @media #{$la_md} {
        flex-direction: row;
      }

      .test-info-wrapper {
        margin-right: 70px;
        margin-bottom: 10px;

        @media #{$la_md} {
          margin-bottom: 0;
        }

        .title-label {
          .success-icon {
            height: 12px;
            width: 12px;
            margin-right: 4px;
          }

          span {
            letter-spacing: normal;
            text-align: left;
            color: #525968;
            font: $scp-review-page-label-font;
          }
        }

        .title-value {
          span {
            font: $scp-review-page-value-font;
            letter-spacing: normal;
            text-align: left;
            color: #262837;
          }
        }
      }

    }
  }

  .question-title {
    font: normal 500 16px/1.5 $rionasans-medium;
    margin-bottom: 36px;
    padding: 0 16px;

    @media #{$la_md} {
      padding: 0;
    }
  }

  .question {
    font: normal bold 14px/1.43 $rionasans-bold;
    color: #2a273b;
    padding: 0 16px;

    @media #{$la_md} {
      padding: 0;
    }
  }

  .answer {
    font: normal 14px/1.21 $rionasans-regular;
    color: $light-gray-text-color-v2;
    margin-bottom: 24px;
    padding: 0 16px;

    @media #{$la_md} {
      padding: 0;
    }
  }

  .more-question-container {
    margin-top: 90px;
    display: flex;

    .more-question-wrapper {
      display: flex;
      flex-flow: column wrap;
      flex: 0 551px;

      .title-more-question {
        font: normal bold 22px/1.45 $rionasans-bold;
        color: $text-color-V2;
      }

      .description-more-question {
        font: normal 14px/1.43 $rionasans-regular;
        color: $info-color;
        margin: 24px 0 32px;
      }

      .outer-button {
        text-align: left;

        .btn-default {
          width: 248px;
          height: 50px;
          padding: 15px 36px 14px 42px;
          border-radius: 100px;
          background-color: $focus-border-color-v2  !important;
          font: normal 15px/1.6 $rionasans-medium;
        }
      }
    }

    .illustration-wrapper {
      margin-top: 90px;
      margin-left: 16px;
      width: 227px;
      height: 224px;

      .placeholder-illustration {
        background-color: $purple-200;
        padding: 102px 74px;
        font: normal 14px/1 $rionasans-regular;
        color: $purple-400;
      }
    }

  }
}
