@import '../../../../../styles/mixins';
@import '../../../../../styles/vars';
$md: '(min-width: 500px)';

.scp-filled-card-block {
  display: flex;
  flex-wrap: wrap;
  flex-flow: column-reverse;

  @media (min-width: 700px) {
    flex-flow: row;
    flex-wrap: nowrap;
  }

  .credit-card-block {
    width: 100% !important;
    max-width: none;
    margin: 0;
    border-radius: 0;
    background: unset;
    padding: 16px 16px 16px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.16);
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);

    .brand-icon {
      min-width: 36px;
      height: 24px;
      background-size: 100%;
      margin-right: 16px;
      display: none;

      @media (min-width: 400px) {
        display: block;
      }

      &[data-brand='visa'] {
        background-image: url('./icon-Visa.png');
        @include only-image-2x('./icon-Visa@2x.png');
      }

      &[data-brand='mastercard'] {
        background-image: url('./icon-MasterCard.png');
        @include only-image-2x('./icon-MasterCard@2x.png');
      }

      &[data-brand='amex'] {
        background-image: url('./icon-AmericanExpress.png');
        @include only-image-2x('./icon-AmericanExpress@2x.png');
      }
    }

    .first-block {
      flex-grow: 1;

      .name {
        margin-bottom: 8px;
        color: #262837;
        font-size: 14px;
        font-weight: normal;
        font-family: RionaSans;
        text-transform: capitalize;
        word-break: break-all;
      }

      .brand {
        font-family: RionaSans-Bold;
        font-size: 14px;
        color: #262837;
      }

      .ending {
        font-family: RionaSans;
        font-size: 14px;
        color: #262837;
      }
    }

    .second-block {
      display: flex;
      align-items: center;
    }

    .ending,
    .expiry-text {
      color: #a0a0a0;
    }

    .card-info {
      margin-bottom: 0px;
    }

    .expiry {
      margin-bottom: 0;
      text-align: left;

      .expiry-value {
        color: #262837;
        font-family: RionaSans;
        font-size: 14px;
        line-height: 1.36;
        font-weight: normal;
      }
    }

    .update-btn {
      text-align: right;
      color: $purple-500  !important;
      font-family: RionaSans;
      font-weight: normal;
      text-transform: capitalize !important;
    }
  }

  .notice-block {
    max-width: 332px;
    min-height: 114px;
    padding: 35px 50px;
    background-color: #fefacf;
    margin: 0 10px 20px 10px;

    @media (min-width: 500px) {
      margin: 0 40px 20px 10px;
    }
  }
}

.scp-filled-card-block-layout {
  @media (min-width: 700px) {
    flex-flow: column-reverse !important;
  }

  .description-block {
    margin: 24px 0 16px 0 !important;

    h3 {
      font-size: 14px !important;
      font-family: RionaSans-Bold;
      padding-left: 0px !important;
    }
  }

  .notice-block-layout {
    width: 98% !important;
    padding: 25px 20px !important;
    max-width: none;
  }

  .credit-card-block-layout {
    width: 98% !important;
    max-width: none;
    display: flex;
    padding-left: 5px;

    @media #{$la_md} {
      padding-left: 0;
    }
    @media #{$mobile} {
      border-bottom: none;

    }
  }
}
