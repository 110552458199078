@import '../../../../styles/vars';
$xsm: '(min-width: 400px)';
$sm: '(min-width: 460px)';
$md: '(min-width: 600px)';

.modal.scp-appointment-modal {

  .modal-dialog {
    min-height: 476px;
    width: 100%;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35);
    color: $text-color-V2;
    font: normal 14px/1.43 $rionasans-regular;
    -webkit-font-smoothing: antialiased;

    @media #{$la_md} {
      width: 650px;
      margin-left: 0;
    }

    .modal-header {
      height: 56px;
      padding: 17px 16px;
      border-bottom: 1px solid #edeff3;
      font: normal bold 16px/1.38 $rionasans-bold;
      -webkit-font-smoothing: antialiased;
      text-transform: capitalize;

      .modal-close-button {
        padding: 12px 10px;
        top: 4px;
        color: $text-color-V2;
        font: normal bold 16px/1.38 $rionasans-bold;
        opacity: 1;

        @media #{$la_md} {
          padding: 10px 22px;
        }
      }
    }

    .modal-body {
      padding: 61px 66px 40px 90px;
    }
  }

  .outer-block {
    text-align: center;

    .title {
      color: $gray-text-color;
      font-size: 25px;
      text-align: center;
      padding: 10px 0 30px;

      @media #{$md} {
        padding: 10px 0 50px;
      }
    }

    .block {
      text-align: left;
      display: flex;
      margin: auto;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;

      @media #{$md} {
        flex-wrap: nowrap;
      }

      .cal-day {
        padding: 0 0 20px 0;

        @media #{$md} {
          padding: 10px 90px 10px 0;
        }
      }

      .info {
        .caption {
          color: $light-gray-text-color-v2;
          font: normal 12px/1.33 $rionasans-regular;
          -webkit-font-smoothing: antialiased;
        }

        .value {
          color: $light-gray-text-color-v2;
          font: normal 14px/1.36 $rionasans-regular;
          -webkit-font-smoothing: antialiased;
          padding: 0 0 24px 0;

          &:last-child {
            padding: 0;
          }
        }
      }
    }

    .error {
      color: $gray-text-color;
      font-size: 16px;
      padding: 15px 25px;
      margin: 30px auto 0;
      background-color: lightcoral;
      display: inline-block;
      min-height: 52px;
    }

    .buttons {
      margin: 76px auto auto;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;

      .btn-default {
        width: 248px;
        min-width: 248px;
        padding: 14px 38px 14px 44px;
        border-radius: 100px;
        font: normal 500 16px $rionasans-medium;
        -webkit-font-smoothing: antialiased;
        text-transform: capitalize;
        order: 1;
      }

      .reject {
        width: 100%;
        height: 40px;
        margin-top: 16px;
        text-align: center;
        font: normal 12px/1.5 $rionasans-regular;
        -webkit-font-smoothing: antialiased;
        color: $light-indigo-V2;
        cursor: pointer;
        order: 2;

        &:hover {
          text-decoration: underline;
        }

        @media #{$sm} {
          flex-basis: auto;
          margin: 0 auto 0 auto;
          order: 1;
          padding: 10px 0 10px 10px;
          width: fit-content;
        }
      }

    }
  }

  &.is-mobile {
    .outer-block .buttons {
      margin: 48px auto auto;
    }

    .modal-dialog {
      width: 87%;
      min-width: 327px;
      min-height: unset;

      .modal-content {
        border: unset;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

        .modal-header {
          .warning-icon {
            margin-right: 12px;
          }
        }

        .modal-body {
          padding: 30px 16px 40px 16px;

          .block {
            font: normal 16px $rionasans-regular;

            .appointment-date,
            .appointment-time-timezone,
            .provider-name {
              font: normal bold 16px $rionasans-bold;
            }
          }
        }
      }
    }

    .modal-close-button {
      display: none;
    }

    .btn-default {
      width: 100% !important;
      padding: 12px !important;
    }

    .button-cancel-appointment {
      border: 1px solid $purple-500  !important;
      border-radius: 24px !important;
      background-color: #ffffff !important;
      margin-top: 8px;
      color: $light-indigo-V2  !important;
      text-decoration: none;
    }
  }
}