@import "../../../../styles/vars.scss";

$md: "(min-width: 590px)";
$max-md: "(max-width: 589px)";

.abn-container {
  background: $page-background-color-V2 url("../../../../images/gray-ribbon-v2.svg") center/100% no-repeat fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .ribbon-img {
    position: absolute;
    width: 100vw;
  }

  .abn-wrapper {
    max-width: 816px;
    margin: 24px auto;
    padding: 0 40px;
    background: $white-bg-color-v2;
    z-index: 0;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    -webkit-font-smoothing: antialiased;
    padding: 12px 16px;

    @media (min-width: 700px) {
      padding: 32px 40px;
    }
  }

  .abn-header {
    width: 100%;
    border-bottom: solid 2px #edeff3;

    .genome-header-logo {
      width: 100%;
      text-align: center;
      padding-bottom: 24px;

      .genome-header-img {
        max-width: 130px;
      }
    }
  }

  .abn-body {
    width: 100%;
    border-bottom: solid 2px #edeff3;
    padding-bottom: 4px;
    padding-top: 12px;

    @media (min-width: 700px) {
      padding-top: 24px;
    }

    .form-row {
      width: 100%;

      display: flex;
      flex-direction: column;

      @media (min-width: 700px) {
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 7px;
      }

      .form-col {
        width: 100%;
        margin-bottom: 19px;

        @media (min-width: 700px) {
          width: 352px;
          margin-bottom: unset;
        }

        &.right {
          .form-label {
            @media (min-width: 700px) {
              text-align: right;
            }
          }
        }
      }

      .form-label {
        font: $scp-menu-item;
        letter-spacing: normal;
        text-align: left;
        color: #000000;

        @media (min-width: 700px) {
          margin-bottom: 0px;
        }

        span {
          opacity: 0.5;
        }
      }

      &.signature-form {
        display: flex;
        justify-content: start;

        .form-col {
          width: 100%;
          margin-right: 16px;

          @media (min-width: 700px) {
            width: 240px;
          }
        }

        .form-lbl {
          font-family: $rionasans-medium;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.43;
          letter-spacing: normal;
          text-align: left;
          color: #262837;

          &.required::before {
            content: '*';
            color: $required-red-color;
            font-size: 14px;
            margin-left: -5px;
          }

          .el-tooltip {
            &.signature-tooltip {
              margin-left: 4px;

              img {
                vertical-align: top;
              }
            }
          }
        }

        .input-wrapper {
          .el-input {
            .el-input__inner {
              height: 40px;
              font-family: $rionasans-medium;
              font-size: 14px;
              font-weight: 500;
              line-height: 1.43;
              border-radius: 4px;
              border: 1px solid $field-border-color-V2;
            }

            .el-input__inner:hover:not(:disabled) {
              border: 1px solid $hover-border-color-v2;
            }

            .el-input__inner:focus:not(:disabled) {
              border: 1.5px solid $focus-border-color-v2;
            }

            .el-input__inner::placeholder {
              color: $field-border-color-V2;
            }

            .el-input__inner:focus::placeholder,
            .el-input__inner:hover::placeholder {
              color: $field-border-color-V2;
            }
          }

          &.input-error-field {
            .el-input {
              .el-input__inner {
                background-color: $error-field-bg-color;
                border: 1px solid #fb3a2e;
                color: $error-text-color-v2;
              }

              .el-input__inner:hover,
              .el-input__inner:focus {
                border: 1px solid #fb3a2e;
              }

              .el-input__inner::placeholder,
              .el-input__inner:hover::placeholder,
              .el-input__inner:focus::placeholder {
                color: $error-text-color-v2;
              }
            }
          }

          .input-error {
            font-size: 12px;
            font-family: $rionasans-regular;
          }
        }

        .input-mask-field-wrapper {
          input {

            height: 40px;
            font-family: $rionasans-medium;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.43;
            border-radius: 4px;
            border: 1px solid $field-border-color-V2;
          }

          input::placeholder {
            color: $field-border-color-V2;
          }

          input:hover {
            border: 1px solid #70cbf1;
            outline: 0;
          }

          input:focus {
            border: 1.5px solid $focus-border-color-v2;
          }

          input:focus::placeholder,
          .input:hover::placeholder {
            color: #262837;
          }

          &.input-mask-error-field {
            input {
              background-color: $error-field-bg-color;
              border: 1px solid #fb3a2e;
              color: $error-text-color-v2;
            }

            input:hover,
            input:focus {
              border: 1px solid #fb3a2e;
            }

            input::placeholder,
            input:hover::placeholder,
            input:focus::placeholder {
              color: $error-text-color-v2;
              opacity: 0.5;
            }

            .input-error {
              color: $error-input-error-v2;
              font-size: 12px;
              font-family: $rionasans-regular;
            }
          }

        }
      }
    }

    .web {

      margin-bottom: 24px;

      .form-row {
        .form-col {
          margin-bottom: 0px;
        }
      }
    }

    h3 {
      font: $header3-font;
      letter-spacing: normal;
      text-align: left;
      color: #262837;
      margin-bottom: 8px;
    }

    p {
      font: $label_font;
      letter-spacing: normal;
      text-align: left;

      &.note {
        font: $scp-menu-item;

        b {
          font: $scp-link-button-font;
          color: #000000;
        }
      }

      &.notice {
        color: #7b8291;
      }

      b {
        font: $scp-insurance-star-font;
        letter-spacing: normal;
        text-align: left;
        color: #262837;

        &.medium {
          font: $scp-link-button-font;
          letter-spacing: normal;
          text-align: left;
          color: #262837;
        }
      }

      &.additional {

        font: $scp-menu-item;
        color: #000000;

        b {

          letter-spacing: normal;
          text-align: left;
          font: $scp-insurance-star-font;
          color: #262837;
        }
      }

      &.cms {
        text-align: center;
        color: #7b8291;
        font-family: RionaSans;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;

        @media (min-width: 700px) {
          text-align: left;
        }

        a {
          color: #70cbf1;

          @media #{$mobile} {
            padding: 10px 0;
          }
        }
      }
    }

    .abn-table {
      margin-bottom: 8px;
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      border: solid 1px #bac3ce;
      border-radius: 0px;

      @media (min-width: 700px) {
        flex-direction: row;
      }

      .col-sec {
        display: flex;
        flex-direction: column;
        width: 100%;

        @media (min-width: 700px) {
          width: 33.33%;
          border-right: solid 1px #bac3ce;
          &.first {
            width: 248px
          }

          &.second {
            flex-grow: 1;
          }

          &.third {
            width: 171px
          }
        }

        &:last-child {
          @media (min-width: 700px) {
            border-right: unset;
          }

          .abn-cell {
            &:last-child {
              border-bottom: unset;
            }
          }
        }

        .abn-cell {
          box-sizing: border-box;
          border-bottom: solid 1px #bac3ce;
          padding: 4px 8px;

          @media (min-width: 700px) {
            padding: 8px 16px;

            &:last-child {
              border-bottom: unset;
            }
          }

          &.second {
            padding-top: 8px;

            @media (min-width: 700px) {
              padding-top: 16px;
            }
          }

          span {
            padding-top: 8px;
            padding-bottom: 8px;
            font: $scp-menu-item;
            letter-spacing: normal;
            text-align: left;
            color: #262837;
          }

          b {
            font: $scp-link-button-font;
            letter-spacing: normal;
            text-align: left;
            color: #000000;
          }
        }
      }

    }

    ol {
      list-style-position: inside;
      padding-inline-start: 0px;

      li {
        letter-spacing: normal;
        font: $scp-menu-item;
        color: #262837;
        margin-bottom: 8px;
      }
    }

    .sub-sec {
      margin-top: 24px;

      h6 {
        font: normal bold 16px/1.38 $rionasans-bold;
        letter-spacing: normal;
        text-align: left;
        color: #262837;
        margin-bottom: 4px;
      }

      h5 {
        font: normal 500 16px/1.38 $rionasans-medium;
        margin-bottom: 24px;
        letter-spacing: normal;
        text-align: left;
        color: #262837;
      }

    }
  }

  .abn-footer {
    width: 100%;

    .form-row {
      display: flex;
      flex-direction: column;
      font-family: RionaSans;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.43;
      letter-spacing: normal;
      text-align: center;
      color: #7b8291;
      margin-top: 9px;

      @media (min-width: 700px) {
        flex-direction: row;
        justify-content: space-between;
        text-align: left;
      }

    }

    .buttons-containers {
      margin-top: 24px;

      .rights-buttons {
        margin: auto;
        display: flex;
        flex-direction: column;
        justify-content: end;

        @media (min-width: 700px) {
          flex-direction: row-reverse;
          margin: 0 0 0 auto;
          max-width: 310px;
        }

        .agree-button {
          width: 100%;
          margin: auto;

          button {
            width: 100%;
            text-transform: none;

            @media (min-width: 700px) {
              max-width: 220px;
              width: 220px;
            }

            font-family: $rionasans-medium;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.38;
          }

          @media (min-width: 700px) {
            margin: unset;
            max-width: 220px;
            width: 220px;
          }
        }

        .button-back {
          margin: auto;
          color: $purple-500;
          text-decoration: none;
          cursor: pointer;
          text-align: left;
          font: $scp-link-button-font;
          letter-spacing: normal;
          line-height: 50px;

          @media (min-width: 700px) {
            margin-right: 16px;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}