@import '../../../../styles/vars';

.scp-calendar-day {
  width: 108px;
  height: 106px;
  border: 2px solid $light-indigo-V2;
  margin: auto;
  .month {
    height: 37px;
    background-color: $light-indigo-V2;
    color: white;
    font: normal bold 14px/1.43 $rionasans-bold;
    line-height: 37px;
    -webkit-font-smoothing: antialiased;
    text-align: center;
  }
  .day {
    font: normal 32px/0.63 $rionasans-regular;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    color: $light-indigo-V2;
    padding: 20px 34px;
  }
}
