@import '../../../../../styles/vars';

$select-width: 45%;

.scp-appointment.v2 {
  margin-bottom: 8px;

  .scp-appointment-service-options {
    color: $gray-text-color;

    @media #{$md} {
      margin: auto;
      flex-direction: column;
    }

    @media #{$la_lg} {
      display: flex;
      flex-direction: row;
      flex-flow: wrap;
    }

    @media #{$mobile} {
      .select-wrapper.input-field {
        height: auto !important;
      }
    }

    .input-wrapper .el-input .el-input__inner,
    .select-wrapper .el-select .el-input .el-input__inner {
      height: 32px;
      font: $scp-form-element-font;
      letter-spacing: normal;
    }

    .select-wrapper .el-select {
      .el-select-dropdown {
        font: $scp-form-element-font;

        .el-select-dropdown__item {
          color: $text-color-V2;
          font: $scp-form-element-font;

          &.selected {
            color: #ffffff;
          }
        }
      }
    }

    .title {
      font: normal normal 14px/16.8px $rionasans-medium;
      color: $light-gray-text-color-v2;
      letter-spacing: normal;
      margin-bottom: 4px;

      .help-icon {
        height: 13px;
        width: 13px;
        cursor: pointer;
      }

      .specialty-tooltip {
        background-color: #fff;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
        max-width: 272px;
        font-family: RionaSans;
        font-size: 10px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        color: #262837;
      }
    }

    .select-country {
      @media #{$la_lg} {
        min-width: $select-width;
        margin:0 16px 16px 0;
      }
    }

    .select-speciality {
      @media #{$la_lg} {
        min-width: $select-width;
        margin:0 16px 16px 0;
      }
    }

    .select-state {
      @media #{$la_lg} {
        min-width: $select-width;
        margin:0 16px 16px 0;
      }


      .state-arrow-container {
        display: none;

        @media #{$la_lg} {
          display: flex;
          position: absolute;
          z-index: 998;
          top: 71px;
          left: 55px;
        }

        .state-arrow {
          width: 26px;
          height: 61px;
        }

        .state-arrow-text {
          position: absolute;
          height: 40px;
          width: 281px;
          top: 50px;
          left: 35px;

          color: #686868;
          font-size: 14px;
          font-weight: bold;
          line-height: 20px;
        }
      }
    }

    .select-timezone {
      margin: 16px 0 0 0;

      @media #{$la_lg} {
        margin:0 16px 16px 0;
        min-width: $select-width;
      }
    }

    .select-speciality {
      margin: 16px 0 0 0;
       @media #{$la_lg} {
        margin:0 16px 0 0;
        width: $select-width;
      }
    }
  }

  .tooltip-inner {
    border-radius: 10px !important;
  }

  .service-help {
    color: $light-gray-text-color;
    font-size: 14px;
    margin-top: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 17.5px;
    letter-spacing: normal;
    text-align: left;
    font-family: $rionasans-medium;
  }

  .btn-schedule-appointment {
    background-color: $purple-500  !important;
    height: 36px;
    font-size: 14px !important;
    border-radius: 6.5px;
    font-family: $rionasans-medium;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    text-transform: none;
    margin-top: 8px;
    padding: 0 56px;
  }

  .btn-schedule-appointment:disabled {
    background-color: $purple-200 !important;
  }

  .btn-schedule-appointment:active:not(:disabled) {
    background-color: $purple-600 !important;
  }

  .btn-schedule-appointment:hover:not(:disabled) {
    background-color: $purple-400 !important;
  }

  .btn-schedule-appointment:focus:not(:disabled) {
    background-color: $purple-500 !important;
  }

  .radio-button-description {
    display: inline-block;
    margin-left: 5px;
    cursor: pointer;

    strong {
      font-weight: bold;
    }

    .info-outline-icon {
      display: inline-block;
      background: url('../../../../forms/controls/information.png');
      background-size: 100%;
      width: 16px;
      height: 16px;
      vertical-align: top;
      margin-top: 2px
    }

    .description-hint {
      display: none;
      background: #ffffff;
      padding: 20px;
      font-family: $sans;
      line-height: 24px;
      box-shadow: 0 0 3px 0.5px rgba(0, 0, 0, 0.3);

      .description-hint-header {
        color: #cccc;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 12px;
      }
    }

    i {
      font-size: 16px;
      color: '#333';
    }

    &:hover {
      .description-hint {
        display: block;
        position: absolute;
        z-index: 99;
      }
    }
  }
}

.selector-panels-container.v2 {
  > div{
    &:first-child{
      min-height: calc(670px - 130px);
    }
  }
  .action-buttons {
    display: flex;
    justify-content: flex-end;

    @media #{$mobile} {
      flex-direction: column-reverse;
    }

    >button {
      min-width: 200px;
      height: 40px;
      margin-bottom: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      border: 1px solid $light-indigo-V2;
      color: $light-indigo-V2;
      font-family: $rionasans-bold;
      font-size: 16px;
      background-color: $white-bg-color-v2;
      cursor: pointer;

      @media #{$mobile} {
        min-width: unset;
        width: 100%;
        margin-bottom: 16px;
      }

      &:disabled {
        opacity: 0.35;
      }

      &.btn-back {
        margin-right: 56px;

        &:disabled {
          cursor: not-allowed;
        }

        &:hover:not(:disabled) {
          background-color: rgba(123, 38, 201, 0.15);
        }

        &:active {
          background-color: rgba(123, 38, 201, 0.3) !important;
        }
      }

      &.btn-next {
        background-color: $light-indigo-V2;
        color: $white-bg-color-v2;

        &:disabled {
          cursor: not-allowed;
        }

        &:hover:not(:disabled) {
          background-color: $scp-button-hover-color;
        }

        &:active {
          background-color: $purple-600 !important;
        }
      }
    }
  }
}

.selector-panels-container.v2.is-mobile {
  > div{
    &:first-child{
      min-height: calc(670px - 200px);
    }
  }
  .panels-group-title {
    margin-top: 0;
  }


  .appointment-content {
    .select-speciality {
      .title {
        margin-bottom: 5px;
      }
      .select-wrapper{
        .btn{
          font-size: 19px;
        }
      }
      .input-icon-container {
        flex-direction: column;
      }
    }

    .select-state {
      margin-top: 22px;

      .title {
        margin-bottom: 5px;
      }
    }

    .select-timezone {
      margin-top: 22px;

      .title {
        margin-bottom: 4px;
      }
    }

    .select-speciality {
      margin-top: 22px;
    }

    .el-select .el-input {

      input {
        height: 40px !important;
      }
    }

    .btn-schedule-appointment {
      width: 100%;
      height: 48px;
      margin-top: 8px;
      color: #ffffff;
      font: normal 500 16px $rionasans-medium;
    }
  }
}

.selector-panel.v2 {
  .appointment-content {
    .select-speciality-btn {
      background-color: transparent !important;
      color: $light-indigo-V2;
      padding: 0;
      margin-top: 8px;
      text-align: left;
      font: normal 500 14px/1.38 $rionasans-medium;
      height: initial;
      text-transform: none;
      &::after {
        content: '>';
        font-size: 17px;
        margin-left: 3px;
      }

    }
    .select-speciality-btn.edit {
      margin-top: 16px;
    @media #{$la_lg} {
        margin-top: 8px;
      }
    }

    .select-speciality-btn:hover {
      color: $light-indigo-V2 !important;
    }

    .select-speciality-btn:active {
      color: $light-indigo-V2 !important;
      box-shadow: none;
    }

    .select-speciality-btn:focus {
      color: $light-indigo-V2 !important;
    }

    .canada-language{
      border-top: 1px solid #EDEFF3;
      padding-top: 16px;
      margin: 16px 0 0 0;
    }
  }
  .btn-schedule-container {
    display: flex;
    justify-content: flex-end;
    padding-top: 16px;
    border-top: 1px solid #EDEFF3;
    margin-top:16px
  }
  .input-icon-container{
    display: flex;
    flex-direction: column;

    .edit-icon {
      margin-left: 8px;
      cursor: pointer;
    }
  }
}
