@import '../../../../../styles/vars';

$md: '(min-width: 700px)';

.scp-schedule-wizard.scp-payment-stage {
  max-width: 860px;
  width: 100%;
  line-height: 20px;
  -webkit-font-smoothing: antialiased;
  padding: 0 15px !important;
  margin: 31px 0 31px 0 !important;

  @media #{$la_lg} {
    padding: 40px 0px !important;
    margin: 0 auto !important;
  }

  @media #{$mobile} {
    > .scp-payment-personal-form .buttons {
      > div {
        padding: 10px 0;
      }

      button {
        height: 40px !important;
      }
    }
  }

  .gm-select-service__header {
    margin-bottom: 18px !important;
    font-family: $rionasans-bold;
    font-size: 23px !important;
    font-weight: 700;
    line-height: 27.6px;
  }

  .payment-sections {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    @media #{$la_lg} {
      flex-direction: row;
    }

    .payment-section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-right: 16px;

      @media #{$mobile} {
        margin-right: 0;
      }

      .no-payment-section {
        padding-top: 24px;

        .marg-btm-32 {
          margin-bottom: 32px
        }

        .not-required-text {
          font-family: $rionasans-italic;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 1.36;
          letter-spacing: normal;
          text-align: left;
          color: #7b8291;
          -webkit-font-smoothing: antialiased;
        }
      }

      .payment-info-block {
        width: 100%;
        margin-bottom: 19px;

        @media #{$la_md} {
          width: 470px;
          margin-bottom: 0px;
        }
        @media #{$mobile} {
          margin-bottom: 0px;

        }
      }

      .section-header {
        font: normal bold 19px/22.8px $rionasans-bold;
        letter-spacing: normal;
        text-align: left;
        margin-bottom: 16px;
      }

      .card-section {
        width: 100%;
        margin-top: 24px;
        display: flex;
        flex-direction: column;

        @media #{$la_md} {
          width: 470px;
        }

        .scp-payment-personal-form {
          width: 100%;

        }
      }

      .bill-add-section {
        width: 100%;
        margin-top: 16px;
        .billing-checkbox{
          margin-right: 15px;
          .checkbox-wrapper{
            height: 42px;
              display: flex;
              align-items: center;
              padding: 12px;
              border-radius: 4px;
              border: solid 1px #5B43BD;
              background-color: #ffffff;
              .guardian-label {
                margin: 0 !important;
                padding: 0 !important;
                .label-section{
                  font-family: $rionasans-medium;
                  font-size: 16px;
                  font-weight: 490;
                  line-height: 19.2px;
                  color:#262837;
                }
                .checkmark {
                  top: 1px !important;
                  margin-right: 8px !important;
                  height: 16px !important;
                  width: 16px !important;
                  &:after{
                    left: 4px;
                    top: 2px;
                    width: 5px;
                    height: 9px;
                  }
                }
              }

          }
        }

        .radio-label {
          .radio-label {
            width: 56%;
          }
        }
      }


      .form-row {
        display: flex;
        flex-direction: row;
        margin-bottom: 16px;
        .card-name{
        width: 100% !important;

          @media #{$la_md} {
            width: 470px !important;
          }
        }

        .StripeElement{
          width:470px;
          margin-right: 15px;
        }

        .form-col {
          // width: 60%;
          margin-right: 15px;
          &.city-col{
            width: 50%;
          }
          &.state-col{
            width: 20%;
          }
          &.zip-col{
            width: 25%;
          }

          // @media #{$la_md} {
          //   width: 309px;
          // }


          .label-section {
            width: 100%;
            font-family: $rionasans-medium;
            font-size: 14px;
            font-weight: 500;
            line-height: 16.8px;
            color: #525968;
            letter-spacing: normal;
            margin-bottom: 4px;
          }

          .required:before {
            content: "*";
            color: $required-red-color;
            font-size: 14px;
            margin-left: -4px;
          }

          .input-wrapper .el-input .el-input__inner,
          .select-wrapper .el-select .el-input .el-input__inner {
            height: 32px;
            font: $scp-form-element-font;
            letter-spacing: normal;

            @media #{$mobile} {
              height: 40px;
            }
          }


          .select-wrapper .el-select {
            .el-select-dropdown {
              font: $scp-form-element-font;

              .el-select-dropdown__item {
                color: $text-color-V2;
                font: $scp-form-element-font;

                &.selected {
                  color: #ffffff;
                }
              }
            }
          }

          input:checked~.checkmark {
            background-color: $purple-500;
          }

          .checkmark:after {
            border-color: #ffffff;
            border-width: 0 2px 2px 0;
          }

        }

        .form-col.full-col {
          width: 100%;
        }

        .form-col.cvv-col,
        .form-col.exp-col {
          width: 35%;
          margin-right: 0px;

          @media #{$la_md} {
            width: 147px;
          }

          .exp-cols {
            display: flex;
            justify-content: space-between;

            .select-wrapper.exp-mon .el-select {
              @media #{$mobile} {
                width: unset;
              }
            }

            .select-wrapper.exp-mon .el-select .el-input .el-input__inner {
              width: 49px;

              @media #{$la_md} {
                width: 69px;
              }
            }

            .select-wrapper.exp-year .el-select .el-input .el-input__inner {
              width: 64px;

              @media #{$la_md} {
                width: 69px;
              }
            }
          }
        }
      }

      .coupon-section {
        width: 232px;
      }
    }
    .gm-alert {
      margin: 20px 0;
    }
  }
  .action-buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 32px;
    @media #{$la_lg}{

    padding-top: 32px;
    border-top: 1px solid #D8DDE4;
    }

    @media #{$mobile} {
      flex-direction: column-reverse;
    }

    >button {
      min-width: 200px;
      height: 40px;
      margin-bottom: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      border: 1px solid $light-indigo-V2;
      color: $light-indigo-V2;
      font-family: $rionasans-bold;
      font-size: 16px;
      background-color: $white-bg-color-v2;
      cursor: pointer;

      @media #{$mobile} {
        min-width: unset;
        width: 100%;
        margin-bottom: 16px;
      }

      &:disabled {
        opacity: 0.35;
      }

      &.btn-back {
        margin-right: 56px;

        &:disabled {
          cursor: not-allowed;
        }

        &:hover:not(:disabled) {
          background-color: rgba(123, 38, 201, 0.15);
        }

        &:active {
          background-color: rgba(123, 38, 201, 0.3) !important;
        }
      }

      &.btn-next {
        background-color: $light-indigo-V2;
        color: $white-bg-color-v2;

        &:disabled {
          cursor: not-allowed;
        }

        &:hover:not(:disabled) {
          background-color: $scp-button-hover-color;
        }

        &:active {
          background-color: $purple-600 !important;
        }
      }
    }
  }

  .card-section {
    display: flex;
    flex-wrap: wrap;
    flex-flow: column-reverse;

    @media (min-width: 700px) {
      flex-wrap: nowrap;
      flex-flow: row;

      .info-block {
        max-width: 332px;
        height: 311px;
        margin: 0 20px 0 0;
      }
    }
  }

  .card-block {
    width: 100%;
    max-width: 509px;
    min-height: 312px;
    border-radius: 16px;
    background-color: #eff5f9;
    padding: 30px 40px;
    text-align: right;

    @media #{$md} {
      margin: 0 20px;
    }

    .card-fields {
      text-align: left;

      .cardNumberField {
        margin-top: 0;
      }

      .line-fields {
        display: flex;
        flex-wrap: wrap;

        @media (min-width: 500px) {
          flex-wrap: nowrap;

          .cardholderName {
            width: auto !important;
          }
        }

        .cardholderName {
          margin-right: 20px;
          flex-grow: 1;
          width: 100%;

          input[name='cardholderName'] {
            text-transform: uppercase;
          }
        }

        .exp-month {
          width: 90px;
          margin-right: 12px;

          .gm-form-label {
            min-width: 150px;
          }
        }

        .exp-year {
          width: 90px;
        }
      }

      .bottom-row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-flow: column-reverse;

        @media (min-width: 500px) {
          flex-wrap: nowrap;
          flex-flow: row;

          .card-error {
            width: auto;
          }
        }

        .card-error {
          margin: 38px 20px 0 0;
          color: red;
          width: 100%;
        }

        .cvc {
          max-width: 90px;
          min-width: 90px;
        }
      }
    }
  }

  .info-block {
    background-color: #fefacf;
    margin: 20px 0;
    max-width: 509px;
    display: flex;
    padding: 0.7%; //5.7%
    font-size: 14px;
    flex-grow: 1;
    font-weight: 400;

    @media #{$md} {
      margin: 20px;
    }

    .info-block-inner {
      margin: auto;
    }

    .space {
      height: 10px;

      @media #{$md} {
        height: 25px;
      }
    }
  }

  .billing-fields {
    padding: 0 20px 10px;

    @media (min-width: 700px) {
      padding: 0 10px 10px;
    }

    .billing-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      @media (min-width: 700px) {
        flex-wrap: nowrap;

        .gm-form-field {
          width: 100%;
        }
      }

      .gm-form-field {
        flex-grow: 1;
        margin: 0 10px;
        width: 100%;
        max-width: 400px;
        margin-bottom: 20px;
      }

      /* fix for zipcode error */
      .gm-form-field.zip-code.error {
        position: relative;

        .gm-form-label {
          position: absolute;
          // top: -18px;
        }

        .gm-form-control.text {
          position: absolute;
          bottom: 0px;
        }
      }
    }

    .billing-row.home-address-row {
      margin-left: 10px;
      margin-top: -20px;
    }

    .billing-row.home-address-row .checkbox {
      margin-top: 0;
    }

    .city-field {
      min-width: calc(50% - 20px);
    }

    .state-field {
      min-width: 100px;
      max-width: 212px;
    }

    .zip-code {
      max-width: 140px;
    }
  }

  .info-block.info-block-min-with {
    max-width: 100% !important;
  }

  .card-block.card-block-min-with {
    max-width: 100% !important;
  }

  .info-block-padding {
    padding: 1.7% !important;
  }

  .out-of-ntwrk-container {
    display: flex;
    padding: 40px 0 0 36px;

    .icon-out-network-wrapper {
      background-color: $purple-200;
      margin-right: 41px;
      padding: 20px 15px 17px 20px;
      border-radius: 41px;
      width: 82px;
      height: 82px;
    }

    h4 {
      width: 194px;
      height: 22px;
      font: normal bold 16px/1.38 "Roboto", Arial, Helvetica, sans-serif;
      color: #4a494b;
      margin-bottom: 9px;
    }

    .out-of-ntwrk-text {
      font: normal 13px/1.38 "Roboto", Arial, Helvetica, sans-serif;
      color: #333233;
    }
  }

  .holding-aptmnt-container {
    display: flex;
    margin-top: 24px;

    .holding-aptmnt-wrapper {
      margin-right: 16px;
      height: 32px;
      width: 32px;
    }

    h4 {
      height: 22px;
      font-family: $rionasans-bold;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: left;
      color: #262837;
    }

    .holding-aptmnt-text {
      font: $scp-review-page-label-font;
      letter-spacing: normal;
      text-align: left;
      color: #7b8291;
    }
  }
}

/* For Mobile Device */
.mobile-schedule-container {
  .scp-schedule-wizard.scp-payment-stage {
    .scp-payment-personal-form {
      .el-input {
        width: 100%;
      }

      .bill-add-section {
        .form-row {
          justify-content: space-between;
          width: 100%;


          .form-col {
            margin-right: 0;
          }

          .city-col {
            width: 48%;
          }

          .state-col {
            width: 20% !important;

            .input-field {
              width: unset;
            }
          }

          .zip-col {
            width: 25%;
          }
        }

        .radio-label {
          .radio-label2 {
            @media #{$mobile} {
              width: 30%;
            }
          }
        }
      }

      // .buttons {
      //   margin-top: 0;
      //   flex-direction: column;

      //   > div {
      //     width: 100%;
      //   }

      //   .button.button-next {
      //     margin-right: 0;
      //   }

      //   .button.button-next .btn-default {
      //     height: 48px !important;
      //     width: 100% !important;
      //     border-radius: 24px;
      //     font: normal 500 16px $rionasans-medium  !important;
      //   }

      //   .button-back {
      //     height: 48px !important;
      //     width: 100% !important;
      //     border: 1px solid $purple-500;
      //     border-radius: 24px;
      //     font: normal 500 16px $rionasans-medium  !important;
      //     display: flex !important;
      //     justify-content: center;
      //     align-items: center;
      //   }
      // }
    }
  }
}
