$md: '(min-width: 840px)';
$sm: '(min-width: 650px)';
$xsm: '(min-width: 376px)';

.ref-frame {
  display: flex;
  justify-content: space-around;
  max-width: 1090px;
  width: 100%;
  margin: auto;
  flex-wrap: wrap;
  @media #{$sm} {
    flex-wrap: nowrap;
  }
  .steps {
    flex-basis: 250px;
  }
  .ref-care-coord {
    padding: 30px 0;
  }
  .ref-form {
    flex-basis: 460px;
    padding: 0 15px;
    .section-name {
      text-align: center;
    }
    .buttons-block {
      padding: 30px 0;
      margin: auto;
      display: flex;
      justify-content: space-between;
      width: 240px;
      align-items: center;
      &.start-block {
        justify-content: center;
      }
      &.submit-block {
        width: 240px;
        @media #{$xsm} {
          width: 350px;
        }
        .button-next {
          button:after {
            content: 'Submit';
          }
          @media #{$xsm} {
            button:after {
              content: 'Submit to Genome Medical';
            }
          }
        }
      }

      .back-button {
        font-size: 15px;
        text-transform: uppercase;
        cursor: pointer;
        padding: 10px;
      }
    }
  }
  .fax-panel {
    flex-basis: 260px;
    display: none;
    @media #{$md} {
      display: block;
    }
  }
}

.ref-error-block {
  max-width: 600px;
  margin: auto;
}
