@import "../../../../styles/vars";

.scp-test-info-box.consultation-video {
    margin-bottom: 8px;
    padding:0;
    cursor: pointer;
    border-radius: 4px;
    border: solid 1px $scp-border-color;
    background-color: $white-bg-color-v2;
    width: 228px;
    min-height: 288px;
    -webkit-font-smoothing: antialiased;
    .c-video {
        height: 129px;
        position: relative;
        .c-image {
            width: 226px;
            height: 129px;
            border-radius: 4px 4px 0px 0px;
            margin-top: -0.5px;
            margin-left: -0.5px;
        }
        .c-play {
            box-sizing: border-box;
            width:32px;
            height:32px;
            border-radius: 50%;
            background-color:$light-indigo-V2;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 97px;
            top: 49px;
            cursor: pointer;
            .fa-play {
                color: $white-bg-color-v2;
                font-size: 10px;
                margin-left: 2px;
            }
        }
        .c-video-pill {
            width: 48px;
            height: 24px;
            opacity: 0.95;
            border-radius: 100px;
            background-color: $white-bg-color-v2;
            position: absolute;
            right: 9px;
            top: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            .v-text {
                font: normal 12px/1.33 $rionasans-regular;
                text-align: center;
                color: $text-color-V2;
            }
        }
        .c-duration-pill {
            width: 48px;
            height: 24px;
            opacity: 0.55;
            background-color: $black-bg-color-v2;
            position: absolute;
            left: 8px;
            bottom: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            .d-text {
                font: normal 12px/1.33 $rionasans-medium;
                text-align: center;
                color: $white-bg-color-v2;
            }
        }
    }
    .c-body {
        padding: 8px;
        .c-title {
            min-height: 40px;
            font: bold 14px/1.43 $rionasans-bold;
            color: $text-color-V2;
            margin-top: 8px;
            margin-bottom: 8px;
        }
        .c-description {
            min-height: 71px;
            font: normal 12px/1.5 $rionasans-regular;
            color: $body-text-color-v2;
            margin-bottom: 16px;
        }
    }
}

.scp-test-info-box.consultation-video:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px $scp-border-color;
}
.scp-test-info-box.consultation-video:focus, .scp-test-info-box.consultation-video:active{
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
    border: solid 1px $scp-border-color;
}

.has-appointments.is-mobile {
    .scp-test-info-box.consultation-video {
        .c-play {
            left: 150px;
            top: 80px;
        }
    }    
}