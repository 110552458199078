@import '../../../../../styles/vars';
@import '../../../../../styles/mixins';

.scp-schedule-container {
  padding-top: 24px;
  padding-bottom: 24px;
  min-height: calc(100vh - 290px);

  .mobile-title {
    font: $scp-title1-font;
    color: #262837;
    height: 80px;
    vertical-align: center;
    padding-left: 18px;
    line-height: 58px;

    @media #{$media-header-visible} {
      display: none;
    }

  }

  .scp-scheduling-page {
    color: #4a494b;
    display: flex;
    flex-flow: column;
    margin: 0px auto;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    max-width: 1020px;

    .clinician-container {
      margin: 300px auto 0 20px;
    }

    .scp-schedule-wizard {

      @media #{$la_lg} {
        max-width: 812px;
        width: 812px;
        padding: 40px 0px !important;
      }

      @media #{$la_sm} {
        padding: 40px 18px;
      }

      margin: 0px auto;
      padding: 40px 0px;

      .gm-select-service__header {
        display: none;
        color: $text-color-V2;
        font: $scp-title1-font;
        letter-spacing: normal;
        text-align: center;
        margin-bottom: 34px;

        @media #{$la_sm} {
          text-align: left;
          font-size: 18px;
          display: inline-block;
        }
        @media #{$la_xsm} {
          display: inline-block;
        }
      }

      .form-divider {
        border-top: 1px solid #ebebeb;
      }

      .description-block {
        max-width: 860px;
        margin: 44px 10px 27px;

        text-align: left;
        color: $text-color-brown;

        .description {
          margin-top: 18px;
          padding: 15px 20px 0;
          border-top: 1px solid #ebebeb;

          @media #{$la_sm} {
            padding-left: 60px;
          }
        }

        h3 {
          font-size: 19px;

          letter-spacing: 0.1px;
          color: $text-color-brown;
          padding: 0 20px;

          @media #{$la_sm} {
            padding-left: 6px;
          }
        }
      }

      .buttons {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        max-width: 100%;
        margin-top: 32px;

        @media #{$la_md} {
          float: none;
        }

        @media #{$mobile} {
          >div {
            min-width: 40px;
            padding: 10px 0;
          }

          button {
            height: 40px !important;
          }
        }

        .button {
          min-width: 80px;
          text-transform: uppercase;
          display: inline-block;
          text-align: center;

          @media #{$la_sm} {
            &:only-child {}
          }

          @media #{$la_sm} and (-ms-high-contrast: none),
          (-ms-high-contrast: active) {}

          @media #{$la_md} {}

          &.button-next {
            margin-right: 32px;

            @media #{$la_sm} {}

            .btn-default {
              width: 145px;
              height: 36px;
              font: $scp-submit-button-font;
              letter-spacing: normal;
              color: #ffffff;

              &[disabled],
              &.disabled {
                opacity: 0.35;
              }

              &[disabled]:hover,
              &.disabled:hover {
                opacity: 0.35;
              }
            }
          }

          &.button-next__wide {
            .btn {}
          }
        }

        .button-back {
          color: $purple-500;
          text-decoration: none;
          cursor: pointer;
          text-align: left;
          display: none;
          font: $scp-submit-button-font;
          letter-spacing: normal;

          @media (min-width: 700px) {
            display: block;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .reservation-timer {
      display: none;

      @media #{$la_lg} {
        display: flex;
      }
    }
  }

  &.mobile-schedule-container {
    padding-top: 0;
    padding-bottom: 0;
  }
}
