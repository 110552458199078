@import '../../../../styles/vars';

.SelectedServiceDetail {
  padding: 20px 0;
  margin: 20px 0;
  border-top: 1px solid #d8dde4;
  border-bottom: 1px solid #d8dde4;
  @media #{$mobile} {
    margin-bottom:32px;
    border-bottom: none;
    border-top: 1px solid #EDEFF3;

  }
  .appointment-detail {
    font-family: $rionasans-bold;
    font-size: 19px;
    font-weight: 700;
    line-height: 23px;
    padding-bottom: 8px;
    color:$light-gray-text-color-v2;
  }
  .detail-container {
    display: flex;
    padding-top: 24px;
    @media #{$mobile} {
      padding-top:8px;
    }

    .detail-item-section {
      margin-right: 16px;
      min-width: 258px;
      &:first-child{
        @media #{$mobile} {
          margin-right: 59px !important;
        }
      }
      @media #{$mobile} {
        display: flex;
        flex-direction: row;
        min-width: 142px;
        margin-right: 0;
      }
      .item-label {
        font-family: $rionasans-medium;
        font-size: 16px;
        font-weight: 490;
        line-height: 20px;
        color: #525968;
        @media #{$mobile} {
          margin-right: 2px;
          color:#262837;
        }
      }

      .item-value {
        font-family: $rionasans-medium;
        font-size: 16px;
        font-weight: 490;
        line-height: 20px;
        color: #262837;
        @media #{$mobile} {
        font-family: $rionasans-regular;
        // color: #525968;

        }
      }
    }
  }
}
