@import '../../../../styles/vars';
$subheader-color: #3E6D8D;
$max-sheet-width: 1228px;
.privacy-container {
  display: flex;
  width: 100%;
  background-color: $content-background-color;
  color: #262837;
  text-align: left;
  padding: 0;
  position: relative;
  overflow: hidden;
  @media #{$media-header-visible} {
    padding: 3%;
  }
  .sheet {
    background-color: $white-bg-color-v2;
    z-index: 10;
    position: relative;    
    max-width: 816px;
    margin: 24px auto;
    border-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    padding: 8px 12px;
    @media (min-width: 700px) {
      padding: 24px 40px;
    }
    .consent-header {
      width: 100%;
      border-bottom: solid 2px #edeff3;
      @media (min-width: 700px) {
        border-bottom: solid 2px #edeff3;
      }
      .genome-header-logo {
        width: 100%;
        text-align: center;
        margin-bottom: 24px;
        .genome-header-img {
          max-width: 130px;
        }
      }
    }
  }  
}
