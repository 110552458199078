@import '../../../../../styles/vars';

.ap-calendar.scp {
    -webkit-font-smoothing: antialiased;
    .gm-calendar {
        .rbc-time-view {
            border: 1px solid $scp-calendar-table-border-color !important;
            border-radius: 4px;

            .rbc-header {
                border-left: 1px solid $scp-calendar-table-border-color;
            }
        }
    }

    .calendar-toolbar {
        margin: 0;

        .appointment-options>div {
            margin: unset;
        }

        .appointment-options .select-state {
          margin: 0;
          @media #{$la_lg} {
            margin: 0 16px;
          }

          .title {
              .buttons {
                margin-left :5px;
              }
          }
        }

        .legend-circle {
            height: 18px;
            width: 18px;
        }

        .legend-text {
            font: $scp-submit-button-font;
            line-height: 1.38;
            letter-spacing: normal;
            color: $scp-calendar-legend-color;
            text-transform: capitalize;
        }

        .gm-select input {
            font: $scp-submit-button-font;
            line-height: 1.38;
            letter-spacing: normal;
            color: $text-color-V2;
        }

        .left .title {
            font: $label_font;
            line-height: 1.42;
            letter-spacing: normal;
            color: $scp-calendar-header-light-color;
        }

        .right {
            padding: 0px;
            align-self: baseline;
            @media #{$la_lg} {
              padding-left: 178px;
              padding-right: 30px;
              align-self: unset;
            }
        }

        .cal-legend {
            position: unset;
            margin: unset;
        }
    }

    .date-switcher {
        justify-content: center;
        margin: 50px 0 16px;

        .circle {
            height: unset;
            width: unset;
            padding: unset;

            img {
                margin: 0 13px;
                vertical-align: unset;
            }
        }

        .date-text {
            text-transform: unset;
            font-family: $rionasans-bold;
            font-size: 15px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.4;
            letter-spacing: normal;
            color: $text-color-V2;
            padding: unset;
        }
    }
}
