@import '../../../../../../styles/vars';


.is-mobile.video-instruction-container {
    .appointment-help-container {
        padding-bottom: 40px;

        .btn-back-appointment-info {
            border-radius: 24px;
            border: unset;
            background-color: #ffffff !important;
            width: auto;
            min-width: auto;
            padding: 0;
            color: $light-indigo-V2;
            font: normal 500 14px/1.71 $rionasans-medium;
            height: unset;
            margin-bottom: 6px;

            .fa-chevron-left {
                margin-right: 4px;
                font-size: 13px;
            }
        }

        ul.appointment-help {
            padding-left: 0;
            margin: 0 auto;
            list-style-type: none;
            counter-reset: steps;
            margin-top: 24px;
            font-family: $rionasans-bold;

            li {
                padding: 0 0 24px 42px;
                position: relative;
                margin: 0;

                .title {
                    font: normal 16px/1.25 $rionasans-bold;
                    color: $text-color-V2;
                }

                .description {
                    font: normal 14px/1.29 $rionasans-regular;
                    color: $body-text-color-v2;
                }

                a {
                    color: $focus-border-color-v2;
                    text-decoration: underline;
                }
            }

            li:after {
                position: absolute;
                top: 2px;
                left: 0;
                content: counter(steps);
                counter-increment: steps;
                border-radius: 50%;
                height: 32px;
                width: 32px;
                background: $purple-200;
                color: $scp-button-focus-color;
                font-size: 14px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            li:before {
                position: absolute;
                left: 14px;
                top: 2px;
                content: "";
                height: 100%;
                width: 0;
                border-left: 1px dashed $purple-200;
            }

            li:last-child {
                padding-bottom: 0px;
            }

            li:last-of-type:before {
                border: none;
            }
        }
    }

    .consultation-video-container {
        border-top: 1px solid #edeff3;
        padding-top: 40px;

        .scp-test-info-box.consultation-video {
            width: 100%;

            .c-video {
                height: 180px;

                .c-image {
                    width: 100%;
                    height: 180px;
                }
            }
        }
    }
}


.scp-test-info-box.consultation-video:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px $scp-border-color;
}

.scp-test-info-box.consultation-video:focus,
.scp-test-info-box.consultation-video:active {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
    border: solid 1px $scp-border-color;
}