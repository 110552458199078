@import "../../../../styles/vars";

.scp-responsive-step-panel__mobile {
  @media #{$la_lg} {
    display: none;
  }

  .controlled-button-group {
    -webkit-font-smoothing: antialiased;
    .controlled-button {
      border-color: #8591a1;
      outline: none;
      color: #7b8291;
      font :$scp-review-page-value-font;
      letter-spacing: normal;
      &:active {
        outline: none;
        background-color: $white-bg-color-v2;
      }
      &:hover {
        background-color: $white-bg-color-v2;
      }
      @media #{$la_xsm} {
        min-width: 165px;
        width: auto;
      }
    }
    .controlled-button.controlled-button__active {
      color: #ffffff;
      border-color: $light-indigo-V2;
      background: $light-indigo-V2;
      font: $scp-insurance-star-font;
    }
  }
}

.scp-responsive-step-panel__desktop {
  display: none;
  width: 226.5px;

  @media #{$la_lg} {
    display: block;
    border-right: 1px solid $page-background-color-V2;
  }
}
