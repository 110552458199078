@import '../../../../styles/vars';

$line-color: #d8dde4;
$green: #00d65b;
$active-bg-color: #a2ffad;
$md: '(min-width: 540px)';

.scp-progress-track {
  max-width: 1050px;
  width: 100%;
  margin: 48px auto 90px;
  display: flex;
  .progress-step {
    flex: 1 1 auto;
    position: relative;
    .step-circle {
      width: 30px;
      height: 30px;
      position: absolute;
      top: -15px;
      left: -15px;
      border: 2px solid $line-color;
      border-radius: 15px;
      background-color: white;
    }
    .step-title {
      max-width: 130px;
      text-align: center;
      position: absolute;
      top: 26px;
      transform: translateX(-50%);
      display: none;
      left: 30px;
      margin-left: -30px;
      margin-right: -20px;
      font: normal 14px/1.36 $rionasans-regular;
      @media #{$md} {
        margin: 0;
        left: 0;
        display: block;
      }
    }
    .step-line {
      border-top: 2px solid $line-color;
    }
    &.done {
      .step-circle {
        border-color: transparent;
        &::after {
          color: $green;          
          content: url('../../../../images/check-outline.svg');
          font-weight: 700;
          font-size: 16px;
        }
      }
      .step-line {
        border-top: 2px solid $scp-progress-green;
      }
    }
    &.active {
      .step-circle {
        border: 2px dotted $green;
        background-color: $active-bg-color;
      }
      .step-title {
        display: block;
      }
    }
    &:first-child,
    &:last-child {
      .step-line {
        display: none;
      }
    }
  }
}
