@import 'vars';


@font-face {
  font-family: RionaSans;
  src: url("./RionaSans-Regular.otf") format("opentype");
}

@font-face {
  font-family: RionaSans-Light;
  src: url("./RionaSans-Light.otf") format("opentype");
}

@font-face {
font-family: RionaSans-Bold;
src: url("./RionaSans-Bold.otf");
}

@font-face {
font-family: RionaSans-Medium;
src: url("./RionaSans-Medium.otf");
}

@font-face {
  font-family: RionaSans-Italic;
  src: url("./RionaSans-Italic.otf");
}

html {
  font-size: 16px; // 16px = 1rem. don't change.
}

body {
  font-size: 14px; // default font for most elements
  font-weight: 400;

  font-stretch: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-style: normal;
  color: $body-text-color;
}

h1,
h2,
h3,
h4,
h5,
h6,
.heading {
  font-weight: 400;
}
