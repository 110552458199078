.gm-alert {
  margin: 20px auto;
}
.gm-alert.fade-out {
  @keyframes fade {
    0%,95% { opacity: 1 }
    100% { opacity: 0 }
  }
}

