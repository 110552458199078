@import '../../../../styles/vars';
@import '../../../../styles/mixins';

.modal.scp-phone-appointment-modal {
  .wrapper {
    .phone-appointment-header {
      color: $gray-text-color;
      text-align: center;
      font-size: 1.3rem;

      @media #{$media-landing-bottom-stripes} {
        font-size: 1.6rem;
      }
    }
  }

  .modal-dialog {
    min-height: 476px;
    width: 100%;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.35);
    color: $text-color-V2;
    font: normal 16px $rionasans-regular;
    -webkit-font-smoothing: antialiased;

    @media #{$la_md} {
      width: 650px;
      margin-left: 0;
    }

    .modal-content {
      // border: none;
      min-height: 476px;

      .modal-header {
        height: 56px;
        padding: 17px 16px;
        border-bottom: 1px solid #edeff3;
        font: normal bold 16px $rionasans-bold;
        -webkit-font-smoothing: antialiased;
        text-transform: capitalize;

        .modal-close-button {
          padding: 10px 22px;
          top: 4px;
          color: $text-color-V2;
          font: normal bold 16px/1.38 $rionasans-bold;
          opacity: 1;
        }
      }

      .modal-body {
        padding: 30px 16px 40px;
      }
    }

    .scp-gm-link {
      color: $focus-border-color-v2;
      cursor: pointer;

      &:hover {
        color: $focus-border-color-v2;
      }
    }
  }

  .phone-appointment-content {
    .phone-number {
      margin: 32px auto;
      font: normal bold 20px $rionasans-bold;
      text-align: center;
    }

    .zoom-instruction-link {
      color: #40b9ec;
    }
  }

  .buttons-container {
    width: 100%;
    margin: 40px auto auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media #{$la_md} {
      flex-direction: row;
    }

    @media #{$media-landing-bottom-stripes} {
      margin: 48px auto auto;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
    }

    .btn-default {
      width: 248px;
      min-width: 248px;
      padding: 14px 64px 14px 70px;
      border-radius: 100px;
      font: normal 500 16px $rionasans-medium;
      -webkit-font-smoothing: antialiased;
      text-transform: capitalize;
    }

    .button-to-phone {
      text-align: center;
      margin: 15px auto 0 auto;
      width: fit-content;
      height: 40px;
      font: normal 12px/1.5 $rionasans-regular;
      -webkit-font-smoothing: antialiased;
      color: $light-indigo-V2;
      cursor: pointer;

      &:hover,
      &:focus,
      &:active {
        outline: none;
        text-decoration: underline;
        color: $light-indigo-V2;
      }
    }
  }

  &.is-mobile {
    .modal-dialog {
      width: 87%;
      min-width: 327px;

      .modal-content {
        border: unset;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

        .modal-header {
          .warning-icon {
            margin-right: 12px;
          }
        }
      }
    }

    .modal-close-button {
      display: none;
    }

    .button-stay-with-zoom {
      width: 100%;
      padding: 12px;
    }

    .button-to-phone.btn {
      height: unset;
      padding: 12px;
      border: 1px solid $purple-500  !important;
      border-radius: 24px;
      background-color: #ffffff !important;
      margin-top: 8px;
      width: 100%;
      color: $light-indigo-V2  !important;
      font: normal 500 16px $rionasans-medium;
      text-decoration: none;
    }
  }
}