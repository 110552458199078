@import "../../../styles/vars.scss";
$footer-height: 58px;
$line-height: 40px;
$medium-footer-width: 880px;
$large-footer-width: 1417px;

.scp-footer-conteiner {
  min-height: 214px;
  padding: 64px;
  text-align: center;
  background-color: #d8dde4;
  -webkit-font-smoothing: antialiased;
  @media #{$la_md} {
    margin-top: 72px;
  }
  .social-media a {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    border: solid 1px #bac3ce;
    margin: 0 4px;
    display: inline-block;
    @media #{$mobile} {
      width: 40px;
      height: 40px;
      padding: 4px;
    }

    &:hover,
    &:focus,
    &:active {
      border: solid 1px $body-text-color-v2;

      svg {
        fill: #262837;
      }
    }

    svg {
      vertical-align: -webkit-baseline-middle;
      fill: #525968;
    }
  }

  .copyright {
    font-family: $rionasans-regular;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: $body-text-color-v2;
    margin-top: 40px;
  }
}
