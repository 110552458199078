@import '../../../styles/vars';
$md: '(min-width: 600px)';
.clinician-container {
  position: relative;
  align-items: center;
  width: 240px;
  color: $gray-text-color;
  
  line-height: 24px;

  .info-block {
    display: flex;
    .clinician-photo {
      width: 45px;
      height: 45px;
      border-radius: 50%;
    }
    .clinician-info {
      margin: 5px 12px;
      .clinician-name {
        font-weight: 500;
        color: black;
      }
    }
  }
  .contacts-info {
    margin: 10px 0;
    width: 220px;
    a {
      font-weight: normal;
    }
  }
}
