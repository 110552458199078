@import "../../../../styles/vars";

.scp-home-container .patient-cards {
  -webkit-font-smoothing: antialiased;

  @media #{$mobile} {
    a:not(.button-link) {
      padding: 12px 0 !important;
    }

    button {
      height: 40px !important;
    }
  }

  > div {
    .today {
      padding: 8px 16px;
      background-color: $scp-border-color;
      font: $scp-insurance-star-font;
      color: $text-color-V2;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      @media #{$mobile} {
        margin: 0px 16px 0px 16px;
      }

      + .content {
        border-top-left-radius: unset;
        border-top-right-radius: unset;
      }
    }

    .content {
      padding: 16px;
      margin: 0 16px 8px;
      border-radius: 4px;
      border: solid 1px $scp-border-color;
      background-color: $white-bg-color-v2;

      @media #{$la_md} {
        margin: 0 0 8px;
      }

      .static {
        display: flex;
      }

      .left {
        width: 66%;

        @media #{$la_md} {
          min-width: 415px;
          width: initial;
        }

        @media #{$mobile} {
          width: unset;

          &.action-items {
            width: unset;
          }
        }

        .tag {
          margin-bottom: 10px;

          span {
            padding: 2px 4px;
            border-radius: 4px;
            font: normal 500 11px/1.36 $rionasans-medium;

            &.violet {
              border: solid 1px $purple-300;
              background-color: #ddcaef;
              color: $radio-btn-color-v2;
            }

            &.blue {
              border: solid 1px $hover-border-color-v2;
              background-color: rgba(159, 220, 245, 0.25);
              color: #005570;
            }

            &.green {
              border: solid 1px #61e097;
              background-color: rgba(162, 255, 173, 0.5);
              color: #006a2d;
            }
          }
        }

        .title {
          font: normal bold 16px/1.5 $rionasans-bold;
          color: $text-color-V2;
          margin: unset;
        }

        .time {
          font: normal 500 14px/1.43 $rionasans-medium;
          color: $body-text-color-v2;
          white-space: pre;

          @media #{$mobile} {
            white-space: unset;
          }
        }

        .text {
          margin-top: 16px;
          font: $scp-review-page-value-font;
          line-height: 1.43;
          color: $text-color-V2;

          > b {
            font-family: $rionasans-medium;
            font-weight: 500;
          }
        }

        .outer-button {
          text-align: left;
          margin-top: 16px;

          button,
          > a {
            width: auto;
            height: 36px;
            font: $scp-submit-button-font;
            color: $white-bg-color-v2;
            text-transform: none;

            &.btn {
              padding: 9px 12px;
            }

            @media #{$mobile} {
              width: 100%;
              border-radius: 20px !important;
            }
          }

          &.outline button {
            border: solid 1px $light-indigo-V2  !important;
            border-radius: 6.5px;
            box-shadow: 0 0px 0px 0 transparent !important;
            background-color: $white-bg-color-v2  !important;
            font: $scp-submit-button-font;
            color: $light-indigo-V2;

            &:hover {
              background-color: rgba(123, 38, 201, 0.15) !important;
              color: $light-indigo-V2  !important;
            }

            &:focus,
            &:active {
              background-color: rgba(123, 38, 201, 0.3) !important;
              color: $light-indigo-V2  !important;
            }
          }
        }

        .link {
          margin-top: 16px;

          & > a {
            font: normal normal 13px/1.67 $rionasans-medium;
            color: $light-indigo-V2;

            &:hover,
            &:focus,
            &:active {
              text-decoration: unset;
            }
          }

          a:hover {
            text-decoration: underline;
          }
        }

        .resend-email {
          margin-top: 16px;
          width: 192px;
          height: 36px;
          text-transform: none;
          padding: 9px 12px;

          border: solid 1px $light-indigo-V2  !important;
          border-radius: 6.5px;
          box-shadow: 0 0px 0px 0 transparent !important;
          background-color: $white-bg-color-v2  !important;
          font: $scp-submit-button-font;
          color: $light-indigo-V2;

          &:hover {
            background-color: rgba(123, 38, 201, 0.15) !important;
            color: $light-indigo-V2  !important;
          }

          &:focus,
          &:active {
            background-color: rgba(123, 38, 201, 0.3) !important;
            color: $light-indigo-V2  !important;
          }
        }

        .test-status {
          border: 1px solid #70cbf1;
          border-radius: 4px;
          padding: 3px;
          background-color: rgba(159, 220, 245, 0.25);
          font: normal 500 11px/1.36 $rionasans-medium;
          color: #005570;
        }

        .bold {
          font-weight: 700;
        }
      }

      .right {
        min-width: 88px;
        align-self: center;
        text-align: center;
        width: 33%;

        @media #{$la_md} {
          width: initial;
        }

        @media #{$mobile} {
          width: unset;
          min-width: unset;
          margin-left: 16px;
        }
        .biopharma-img {
          width: 88px;
          height: 88px;
          margin: auto;
          display: flex;
          align-items: center;
        }

        .visual-content,
        .provider-icon {
          width: 88px;
          height: 88px;
          margin: auto;
          display: flex;
          align-items: center;
          border-radius: 45%;

          @media #{$mobile} {
            width: 64px;
            height: 64px;
          }
        }

        .date {
          & > div:first-of-type {
            margin-bottom: 6px;
            font: $scp-insurance-star-font;
            color: $light-indigo-V2;
            text-transform: capitalize;
          }

          & > div:last-of-type {
            width: 64px;
            height: 64px;
            display: flex;
            justify-content: center;
            margin: auto;
            background-color: $light-indigo-V2;
            border-radius: 50%;

            & > span {
              align-self: center;
              font: normal 500 26px/0.69 $rionasans-medium;
              color: $white-bg-color-v2;
            }
          }
        }
      }
    }

    .content.galleri-content {
      border: 3px solid $purple-300  !important;

      .required-text {
        display: block;
        margin-top: 10px;
      }

      .required-text.p16 {
        font-size: 16px;
      }

      .mt-10 {
        margin-top: 10px;
      }

      .galleri-benefit-badge {
        font: normal 14px/1.43 $rionasans-medium;
        background-color: #870dd1;
        padding: 5px;
        color: #ffffff;
        position: relative;
        left: -30px;
        top: 0px;
        margin-bottom: 10px;
        border-radius: 4px;
        min-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      @media #{$la_lg} {
        .galleri-benefit-badge {
          width: fit-content;
        }
      }
    }

    .content.galleri-content.mb15 {
      margin-bottom: 15px;
    }
  }

  /* start: followup appointment card */
  .followup-appointment-wrapper {
    border: 1px solid #d8dde4;
    margin-bottom: 8px;
    border-radius: 4px;

    @media #{$mobile} {
      padding: 0 16px;
      border: unset;
    }

    .header {
      display: flex;
      align-items: center;
      padding: 8px 16px;
      color: $text-color-V2;
      font: normal bold 16px/1.25 RionaSans-bold;
      cursor: pointer;
    }

    .followup-appointment-header {
      background-color: #d8dde4;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    .new-schedule-header {
      background-color: white;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    &.new-appointment-wrapper {
      .content {
        padding-top: 0px;

        .left {
          .text {
            margin-top: 0px;
          }
        }
      }
    }

    .accordian-icon {
      margin-left: auto;

      &.svg g > path {
        fill: transparent;
      }
    }

    .content {
      margin-bottom: 0;
      border-style: none;
      border-radius: unset;

      @media #{$mobile} {
        margin: unset;
      }

      .btn-fu-aptmnt-low-priority-card {
        margin-top: 16px;
        width: auto;
        height: 36px;
        padding: 6px 12px;
        border: 1px solid $purple-500;
        color: $purple-500;
        background-color: transparent !important;
        font: normal 500 13px/1.38 "RionaSans-Medium", Arial, Helvetica, sans-serif;

        @media #{$mobile} {
          width: 100%;
          border-radius: 20px !important;
        }

        &:hover {
          background-color: rgba(123, 38, 201, 0.15) !important;
          color: $light-indigo-V2  !important;
          border: 1px solid $purple-500  !important;
        }

        &:focus,
        &:active {
          background-color: rgba(123, 38, 201, 0.3) !important;
          color: $light-indigo-V2  !important;
          border: 1px solid $purple-500  !important;
        }
      }

      .btn-fu-aptmnt-high-priority-card {
        margin-top: 16px;
        width: auto;
        height: 36px;
        padding: 6px 12px;
        color: #ffffff;
        font: normal 500 13px/1.38 "RionaSans-Medium", Arial, Helvetica, sans-serif;

        &:hover {
          background-color: $scp-button-hover-color  !important;
        }

        &:focus,
        &:active {
          background-color: $scp-button-focus-color  !important;
        }
      }
    }
  }

  /* end: followup appointment card */

  /* out line button */

  .btn-click-outline {
    margin-top: 16px;
    width: auto;
    height: 36px;
    padding: 6px 12px;
    border: 1px solid $purple-500;
    color: $purple-500;
    background-color: transparent !important;
    font: normal 500 13px/1.38 "RionaSans-Medium", Arial, Helvetica, sans-serif;

    @media #{$mobile} {
      width: 100%;
      border-radius: 20px !important;
    }

    &:hover {
      background-color: rgba(123, 38, 201, 0.15) !important;
      color: $light-indigo-V2  !important;
      border: 1px solid $purple-500  !important;
    }

    &:focus,
    &:active {
      background-color: rgba(123, 38, 201, 0.3) !important;
      color: $light-indigo-V2  !important;
      border: 1px solid $purple-500  !important;
    }
  }

  .biopharma-authorization {
    .content{
      margin-bottom: 15px;
      .left{
        .outer-button{
          .button-link{
            button {
              @media #{$mobile} {
                border-radius: 6px !important;
              }
            }
          }
        }
      }
    }
    .right{
      min-width:90px !important;
      @media #{$mobile} {
        display: none;
      }
    }

  }
}

[role="verify-email-sent"] {
  .modal {
    @media #{$mobile} {
      &::before {
        margin: unset;
      }
    }
  }

  .modal-dialog {
    @media #{$la_md} {
      width: 384px;
    }

    @media #{$mobile} {
      width: 90%;
    }

    width: 100%;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);

    .modal-content {
      border-radius: 4px;
      background-color: $white-bg-color-v2;
      text-align: center;
      padding: 32px 10px;

      img {
        width: 160px;
        height: 160px;
        margin-bottom: 12px;
      }

      .title {
        font: normal 500 24px/1.42 $rionasans-medium;
        color: $text-color-V2;
        margin-bottom: 48px;
      }

      .text {
        font: $scp-menu-item;
        color: $text-color-V2;

        b {
          font-family: RionaSans-Medium;
          font-weight: 500;
          word-break: break-word;
        }

        &.with-margin {
          margin-bottom: 48px;
          margin-top: 24px;
        }
      }

      .action-buttons {
        display: flex;

        button {
          width: 150px;
        }

        .btn-back {
          button {
            border: solid 1px $light-indigo-V2  !important;
            background-color: $white-bg-color-v2  !important;
            color: $light-indigo-V2;
            margin-left: 15px;
            margin-right: 30px;

            &:hover {
              background-color: rgba(123, 38, 201, 0.15) !important;
              color: $light-indigo-V2  !important;
            }

            &:focus,
            &:active {
              background-color: rgba(123, 38, 201, 0.3) !important;
              color: $light-indigo-V2  !important;
            }
          }
        }
      }

      a {
        font: normal 500 14px/1.71 $rionasans-medium;
        color: $light-indigo-V2;
      }
    }
  }
}
