@import '../../../../../styles/vars';
@import '../../../../../styles/mixins';
$image-dir: '../../../../../images/select-service/';

$md: '(min-width: 560px)';
$sm: '(max-width: 559px)';

.scp-select-service-page {
  color: #343234;
  -webkit-font-smoothing: antialiased;
  .selector-panels-container {
    padding: 30px 30px 100px;
    @media #{$mobile} {
      padding: 30px 15px;
    }
    @media #{$md} {
      padding: 0;
    }
    .extra-consultations-container {
      margin-top: -45px;
    }
    @media #{$mobile} {
      .buttons > div {
        padding: 10px 0;
      }
    }

    .panels-group-title {
      width: 100%;
      max-width: 861px;
      height: 25px;
      font-size: 22px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.45;
      letter-spacing: normal;
      color: $text-color-V2;
      margin: 0 auto;
      font-family: $rionasans-bold;
    }

    .mgt2per {
      margin-top: 2%;
    }

    span.service-button-place > button {
      height: 34px !important;
      background-color: #85ca47 !important;
      font-weight: normal !important;
      font-style: normal !important;
      font-stretch: normal !important;
      line-height: normal !important;
      letter-spacing: normal !important;
      text-align: center !important;
      color: #ffffff !important;
    }

    .cdt-icon-contain
    {
      min-width: 64px;
      min-height: 64px;
      width: 64px;
      height: 64px;
      background-color: rgba(76, 205, 245, 0.25);
      border-radius: 50%;
      position: relative;
    }

    .display-flex
    {
      display: flex;
    }




    .panels-group-description {
      color: $light-gray-text-color;
      width: 100%;
      max-width: 861px;
      padding: 20px 5px 10px;
      margin: 0 auto;
      position: relative;
      .panels-group-description-content {
        max-width: 570px;
        font-size: 12px;
        line-height: 18px;
      }
      .panels-group-description-header {
        width: 280px;
        margin-bottom: 18px;
        padding: 0 20px;
        h4 {
          color: #4A494B;
          line-height: 20px;
          font-size: 16px;
          letter-spacing: 0.3px;
        }
      }
      .cdt-icon-contain:before {
        content: '';
        position: absolute;
        top: 10px;
        left: 4px;
        height: 70px;
        width: 50px;
        background: url($image-dir + 'testing-guidance.png') no-repeat top center;
      }
    }

    .selector-panel {
      width: 100%;
      max-width: 861px;
      margin: 16px auto;

      border-radius: 6px;
      border: solid 1px #d8dde4;
      background-color: #ffffff;
      padding: 24px;
      &.v2{
        margin: 24px auto;
      }
      @media #{$mobile} {
        margin: 15px 0;
        padding: 15px;
      }

      &.unselected{
        cursor: pointer;
        .service-content {
          padding: 0 0 15px 0 ;
        }
      }

      &.unselected:hover{
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      }
      &.unselected:active{
        border: solid 3px #d8dde4;
        padding: 22px;
      }

      .service-content {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: stretch;
        padding: 0 0 20px 0 ;

        @media #{$md} {
          flex-wrap: nowrap;
        }

        .icon-contain {
          min-width: 64px;
          min-height: 64px;
          width: 64px;
          height: 64px;
          background-color: rgba(76, 205, 245, 0.25);
          border-radius: 50%;
          position: relative;
        }

        .has-follow-up {
          background-color: rgba(135, 14, 209, 0.25);
        }

        .icon-section {
          flex-grow: 0;
          flex-shrink: 0;
          width: 50px;
          margin: 5px 0 0 -3px;

          &.icon-testing-guidance {
            height: 36px;
            width: 30px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            background-repeat: no-repeat;
            background-position: center;
            margin: 0;
            background-image: url($image-dir + 'testing-guidance.png');
          }
          &.icon-follow-up {
            height: 36px;
            width: 30px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            background-repeat: no-repeat;
            background-position: center;
            margin: 0;
            background-image: url($image-dir + 'follow-up.png');
          }
          &.icon-results-review {
            height: 36px;
            width: 30px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            background-repeat: no-repeat;
            background-position: center;
            margin: 0;
            background-image: url($image-dir + 'results-review.png');
          }
          &.icon-full-service-package {
            height: 36px;
            width: 30px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            background-repeat: no-repeat;
            background-position: center;
            margin: 0;
            background-image: url($image-dir + 'full-service-package.png');
          }
        }

        .name-section {
          flex-grow: 1;
          flex-basis: calc(100% - 52px);
          padding-top: 0px;
          padding-right: 15px;
          padding-bottom: 0px;
          padding-left: 24px;
          @media #{$md} {
            padding-right: 88px;
            flex-basis: auto;
          }
          @media #{$sm} {
            padding: 15px 0;
          }

          h4.service-name {
            min-height: 22px;
            font-size: 18px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.33;
            letter-spacing: normal;
            color: $text-color-V2;
            text-align: left;
            font-family: $rionasans-bold;

            &.service-name__no-icon {
              line-height: 30px;
              vertical-align: bottom;
            }
          }
        }

        .service-description {
          margin: 8px 0;
          min-height: 20px;
          font-size: 14px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.36;
          letter-spacing: normal;
          color: $text-color-V2;
          text-align: left;
          font-family: $rionasans-regular;
          white-space: pre-wrap;
          &.v2 {
            margin: 16px 0 8px 0;
            font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          }
        }

        .service-info {
          flex-grow: 0;
          flex-shrink: 0;
          width: 185px;
          text-align: left;
          margin: 25px 0 0 auto;

          @media #{$md} {
            margin: 18px 0 0 0;
          }
          @media #{$sm} {
            width: 100%;
            text-align: left;
          }

          .price-block {
            width: 100%;

            .btn-save {
              @include small-btn;
              width: 100%;
              margin-top: 13px;
            }
            .service-old-price{
              display: inline-block;
              min-width: 100px;
              font-family: $rionasans-bold;
              font-size: 24px;
              font-weight: bold;
              line-height: 1.42;
              padding: 0 0 4px 1px;
              color: #a0abb9;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
              text-decoration: line-through;
            }
            .service-price,
            .service-price__insurance,
            .service-new-price {
              display: inline-block;
              font-family: $rionasans-bold;
              font-size: 44px;
              font-weight: bold;
              line-height: 1.41;
              padding: 0 0 4px 1px;
              color: $text-color-V2;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: normal;
            }
            .service-price {
              padding-left: 60px;
              line-height: 36px;
            }
            .price-block-insurance-info {
              display: inline-block;
              width: 100%;
              font-size: 12px;
              font-weight: normal;
              font-style: italic;
              font-stretch: normal;
              line-height: 1.33;
              letter-spacing: normal;
              color: $info-color;
              font-family: $rionasans-regular;
            }
            .price-insurance-tooltip {
              display: inline-block;
              width: 25px;
              cursor: pointer;
              margin-left: 8px;

              .info-outline-icon {
                display: inline-block;
                background: url('./info-grey-icon.svg');
                background-size: 100%;
                width: 14px;
                height: 14px;
                position: relative;
                top:10px;
              }
            }
            .zero-price-text {
              width: 100%;
              font-size: 10px;
            }
            .price-asterisk {
              font-size: 25px;
            }
          }

          .service-hint {
            font-size: 12px;
            line-height: 18px;
            font-style: italic;
            color: $light-gray-text-color;
            padding-top: 10px;
          }
        }
      }
    }
    &.is-mobile {
      padding: 15px;
      .panels-group-title {
        margin-top: 13px;
      }
      .panels-group-description {
        display: flex;
        flex-flow: column;
        .panels-group-description-header {
          padding: 0;
          margin: 16px 0;
        }
      }
      .selector-panel.unselected {
        .service-content {
          padding: 0;
          .icon-contain {
            margin-top: 0;
          }
          .name-section {
            padding-bottom: 0;
          }
          .select-service-button {
            width: 100%;
            border-radius: 24px;
            margin-top: 8px;
            color: #ffffff;
            font: normal 500 16px $rionasans-medium;
            &.v2{
              border-radius: 6px;
            }
          }
        }
      }
      .selector-panel {
        .service-content {
          padding: 0;
        }
        .btn-back-service-page {
          background-color: #ffffff !important;
          width: unset;
          padding: 0;
          color: $light-indigo-V2;
          font: normal 500 14px/1.71 $rionasans-medium;
          height: unset;
          min-width: unset;
          .fa-chevron-left {
              margin-right: 4px;
              font-size: 13px;
          }
       }
      }
      .button-back-to-address {
        border-radius: 24px;
        margin: auto;
        font: normal 500 16px $rionasans-medium !important;
        background-color: $white-bg-color-v2 !important;
        color: $light-indigo-V2 !important;
        border: 1px solid $light-indigo-V2 !important;
      }
    }
  }
}

.scp-schedule-container .scp-scheduling-page .scp-schedule-wizard
  .is-mobile {
    .buttons button {
      height: 48px !important;
      &.v2{
        border-radius: 6px;
      }

    }
  }


#specialty-help-tooltip {
  .tooltip-inner {
    max-width: 175px;
    font-family: RionaSans;
    font-size: 10px;
    line-height: 1.4;
    padding: 10px !important;
  }
}

.speciality-container {
  padding:0 !important;
  .service-content{
    padding:24px !important;
    border-bottom: solid 1px #d8dde4;

    .name-section{
      @media #{$sm} {
        padding: 0 !important;
      }
    }
  }
  .appointment-content{
    padding: 24px !important;
  }
    .footer-content {
      display: flex;
      flex-direction: column;
      padding: 24px;
      border-top: 1px solid #BAC3CE;
      @media #{$sm} {
        flex-direction: column-reverse;
      }


      .btn-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top:8px;



        .btn {
          height: 36px;
          @media #{$md} {
            width: auto;
          }
          @media #{$sm} {
            margin-bottom: 16px;
          }

        }
      }
  }

  .radioButtonGroupContainer {
    height: 175px;
    overflow: auto;
    padding: 24px !important;
    @media #{$mobile} {
      height: 420px;
      padding: 24px 0 !important
    }

    .radioButtonRowContainer {
      align-items: unset !important;
    }
  }
}

.panel-container {
  .outline {
    .btn {
      border: solid 1px $light-indigo-V2  !important;
      border-radius: 6.5px;
      box-shadow: 0 0px 0px 0 transparent !important;
      background-color: $white-bg-color-v2  !important;
      font: $scp-submit-button-font;
      color: $light-indigo-V2;

      &:hover {
        background-color: rgba(123, 38, 201, 0.15) !important;
        color: $light-indigo-V2  !important;
      }

      &:focus,
      &:active {
        background-color: rgba(123, 38, 201, 0.3) !important;
        color: $light-indigo-V2  !important;
      }
    }
  }
}
