@import "../../../../styles/vars";

.scp-test-info-box {
  -webkit-font-smoothing: antialiased;
  padding: 16px;
  border-radius: 4px;
  border: solid 1px $scp-calendar-table-border-color;
  background-color: $white-bg-color-v2;
  text-align: left; 
  @media #{$la_lg} {
    width: 343px;
  }

  .content {
    margin-top: 10px;

    .placeholder {
      padding: 0 8px;
      margin-bottom: 32px;

      > div:first-of-type {
        margin-bottom: 24px;

        img {
          margin-right: 8px;
          width: 32px;
        }

        .name {
          font: $scp-insurance-star-font;
          letter-spacing: normal;
          color: $text-color-V2;
        }

        .role {
          font: $scp-review-page-label-font;
          letter-spacing: normal;
          color: $body-text-color-v2;
        }
      }

      > div:last-of-type {
        font-family: $rionasans-regular;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.43;
        letter-spacing: normal;
        text-align: left;
        color: $text-color-V2;

        span {
          font-family: $rionasans-medium;
          font-weight: 500;
        }

        a {
          color: $light-indigo-V2;

          &:hover,
          &:focus,
          &:active {
            text-decoration: unset;
          }
        }
      }
    }

    .no-orders {
      padding: 32px 8px;
      font: $scp-review-page-value-font;
      line-height: 1.36;
      font-style: italic;
      letter-spacing: normal;
      color: $scp-calendar-header-light-color;
    }

    .top-half {
      padding: 24px 0px 16px;

      .test-name {
        font: normal normal 19px/23.75px $rionasans-regular;
        letter-spacing: normal;
        color: $text-color-V2;
        margin-bottom: 4px;
      }

      .lab-name {
        font: $scp-review-page-value-font-V3;
        letter-spacing: normal;
        color: $body-text-color-v2;
      }
    }

    .bottom-half {
      padding: 24px 8px 0;
      border-top: solid 1px $scp-calendar-table-border-color;

      .status-text {
        font: $scp-review-page-value-font-V3;
        letter-spacing: normal;
        color: $scp-calendar-legend-color;
        margin-bottom: 4px;
      }

      .status {
        font: normal 16px/22.66px $rionasans-bold;;
        letter-spacing: normal;

        > img {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }

        &.success {
          color: $scp-info-box-success-color;
          margin-bottom: 24px;
        }

        &.warning {
          color: $scp-info-box-warning-color;
        }

        &.failure {
          color: $scp-info-box-failure-color;
        }

        &.failure + i {
          font: $scp-review-page-label-font;
          letter-spacing: normal;
          font-style: italic;
          color: #ec6759;
        }
      }

      .date-description {
        font: $scp-review-page-value-font-V3;
        letter-spacing: normal;
        color: $body-text-color-v2;

        > img {
          width: 12px;
          height: 12px;
          margin-right: 4px;
        }
      }

      .date-description.mgt32px {
        margin-top: 32px;
      }

      .date-populated {
        font: normal 19px/23.75px $rionasans-regular;
        letter-spacing: normal;
        color: $text-color-V2;
      }
    }

  }

  .header {
    font: normal bold 19px/22.8px $rionasans-bold;
    letter-spacing: normal;
    color: $text-color-V2;
  }

  .footer {
    background-color: unset;
    margin-top: 40px;
    padding: unset;
    cursor: pointer;

    & > a {
      font-family: $rionasans-regular;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.67;
      letter-spacing: normal;
      color: $field-border-color-V2;
      text-decoration: none;
    }
  }
}

.scp-test-info-box.mgb8px {
  margin-bottom: 8px;
  @media #{$mobile} {
    margin: 0 16px 8px;
  }
}
