@import '../../../../styles/vars.scss';

.datefield-wrapper {
    width: 100%;
    
    .rdt {       
        .form-control {
           height: 40px;
           font: normal 500 14px/1.43  $rionasans-medium;
           border: 1px solid $field-border-color-V2;        
       }

       .form-control::placeholder {
           color: #bac3ce;
       }
    }
}

.datefield-wrapper.input-error-field{
    .rdt {
        .form-control {
            background-color: $error-field-bg-color;
            border: 1px solid #fb3a2e;
            color: $error-text-color-v2;
        }
        .form-control:hover,
        .form-control:focus
        {
            border:1px solid #fb3a2e;
        }
        .form-control::placeholder,
        .form-control:hover::placeholder,
        .form-control:focus::placeholder{
            color: $error-text-color-v2;
            opacity: 0.5;
        }

    }
    .input-error{
        font-size: 12px;
        color: $error-input-error-v2;
        font-family: $rionasans-regular;
        -webkit-font-smoothing: antialiased
    }
}