@import '../../../../../../styles/vars';
$md: '(min-width: 750px)';
.scp-my-guide-step2 {
  position: relative;
  max-width: 794px;
  margin: auto;
  text-align: center;
  .free-space {
    height: 40px;
  }
}
