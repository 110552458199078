@import '../../../../styles/vars.scss';

.consent-detail{
    border-bottom: none;
    .info-section{
      @media #{$mobile} {
        display: none;
      }
    }
      .label-section{
        font: normal normal 14px/16.8px $rionasans-medium;
        color:#525968;

        .certification-text{
          margin-bottom: 8px;
        }
        ul{
          margin-bottom: 24px;
          padding-inline-start: 25px;
          
        }
        .gm-link{
          color: $purple-500 !important;
        }
      }
    
    
  }