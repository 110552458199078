@import '../../../../styles/vars.scss';

$md: '(min-width: 590px)';
$max-md: '(max-width: 589px)';

.signup-wrapper.test-request {
  max-width: 816px;
  margin: 24px auto !important;
  background: $white-bg-color-v2;
  z-index: 0;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
  -webkit-font-smoothing: antialiased;

  @media #{$mobile} {
    margin: auto auto !important;
  }

  .signup-header {
    width: 100%;
    padding: 24px 24px 0;
    display: flex;
    flex-direction: column;

    .genome-header-logo {
      width: 100%;
      text-align: center;
      margin-bottom: 24px;

      .genome-header-img {
        max-width: 130px;
      }
    }

    .genome-header-title {
      width: 100%;
      text-align: center;
      padding-bottom: 24px;
      border-bottom: 1px solid #edeff3;

      @media #{$la_md} {
        padding: 0 108px 24px 108px;
      }

      .signup-title {
        font-family: $rionasans-medium;
        -webkit-font-smoothing: antialiased;
        font-size: 23px;
        margin: 0 0 4px 0;
        color: $text-color-V2;
        line-height: 27.6px;
      }

      .signup-desc {
        font-family: $rionasans-regular;
        -webkit-font-smoothing: antialiased;
        font-size: 16px;
        color: $text-color-V2;
        line-height: 1.38;
        text-align: center;
      }
    }
  }

  .signup-body {
    width: 100%;
    padding: 0 24px 0;

    .signup-form {
      width: 100%;

      .input-wrapper .el-input .el-input__inner:focus,
      .input-mask-field-wrapper input:focus,
      .input-mask-wrapper input:focus {
        border: solid 1.5px #40b9ec !important;
      }

      .form-section {
        display: flex;
        width: 100%;
        padding: 32px 0 8px 0;
        border-bottom: 1px solid #edeff3;
        flex-direction: column;

        @media #{$la_md} {
          flex-direction: row;
        }

        .info-section {
          margin-bottom: 16px;

          @media #{$la_md} {
            width: 240px;
            margin-bottom: unset;
          }

          .header {
            color: $light-gray-text-color-v2;
            font-family: $rionasans-bold;
            -webkit-font-smoothing: antialiased;
            font-size: 19px;
            line-height: 22.8px;
          }
          .helper-text {
            color: $light-gray-text-color-v2;
            font-size: 14px;
            font-family: $rionasans-medium;
            -webkit-font-smoothing: antialiased;
            line-height: 1.43;
            margin-bottom: 4px;
            > span {
              color: $body-text-color-v2;
            }
          }
        }

        .body-section {
          @media #{$la_md} {
            width: calc(100% - 240px);
          }

          .row-section {
            width: 100%;
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            &.aoe-form {
              @media #{$la_md} {
                flex-flow: column;
              }

              .col-section {
                margin-bottom: 24px;
                width: auto;
              }
            }

            &.phone-number-section {
              @media #{$la_md} {
                display: flex;
                flex-flow: wrap;
                margin-bottom: 0;
              }

              .col-section {
                margin-bottom: 24px;
              }
            }

            @media #{$la_md} {
              margin-bottom: 24px;
              flex-direction: row;
            }

            &:last-child {
              @media #{$la_md} {
                margin-bottom: 0;
              }

              .col-section {
                &:last-child {
                  margin-bottom: 0;
                }

                // &:nth-last-child(2){
                //   @media #{$la_md} {
                //     margin-bottom: 0;
                //   }
                // }
              }
            }

            .col-section {
              margin-bottom: 20px;

              .required:before {
                content: '*';
                color: $required-red-color;
                font-size: 14px;
                margin-left: -5px;
              }

              &.consent-text {
                .label-section {
                  font-family: $rionasans-bold;
                  line-height: 16.8px;
                  color: $text-color-V2;
                }
              }

              @media #{$la_md} {
                width: calc(100% / 2 - 24px);
                margin-left: 24px;
                margin-bottom: unset;
              }

              .input-section {
                .gm-radio-control {
                  height: unset;
                  width: auto;

                  .gm-radio-label {
                    display: none;
                  }

                  .gm-radio-group {
                    padding-top: 0;
                    flex-direction: column;
                    align-items: flex-start;

                    .radio {
                      margin-left: 0;

                      input[type='radio'] {
                        + .radio-label {
                          font-family: $rionasans-medium;
                          font-size: 16px;
                          line-height: 19.2px;
                          color: $text-color-V2;
                          margin-left: 0;
                          position: relative;
                          padding-left: 2em;

                          &::before {
                            margin-top: 3px;
                            width: 18px;
                            height: 18px;
                            position: absolute;
                            left: 0;
                          }
                        }

                        &:checked {
                          + .radio-label {
                            &:before {
                              background-color: $light-indigo-V2;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              &.full-col {
                width: 100%;

                .checkbox-wrapper {
                  margin-bottom: 10px;
                  display: flex;
                  align-items: center;
                }

                .input-section.consent-to-text {
                  .checkbox-wrapper {
                    margin-bottom: 0px !important;

                    .label-section {
                      padding-top: 1px !important;
                      font: normal 12px $rionasans-regular;
                      color: $light-gray-text-color-v2;
                      text-transform: none !important;
                    }

                    .checkmark {
                      height: 14px !important;
                      width: 14px !important;

                      &::after {
                        width: 5px;
                        top: 0px;
                        left: 4px;
                        height: 9px;
                      }
                    }
                  }
                }
              }

              .label-section {
                color: $light-gray-text-color-v2;
                font-size: 14px;
                font-family: $rionasans-medium;
                -webkit-font-smoothing: antialiased;
                line-height: 1.43;
                margin-bottom: 4px;

                > span {
                  color: $body-text-color-v2;
                }
              }
            }
          }

          .row-section.race-eth-fields {
            display: flex;
            flex-wrap: wrap;

            .full-col {
              margin-top: 24px;
            }
          }
        }
      }

      .signup-footer {
        width: 100%;

        .sign-button-wrapper {
          display: flex;
          justify-content: flex-end;
          flex-direction: column-reverse;
          margin: 24px 0 0px;

          @media #{$la_md} {
            flex-direction: row;
            margin: unset;
          }
        }

        .btn-section {
          margin-bottom: 24px;

          @media #{$la_md} {
            margin-left: 20px;
            margin-bottom: unset;
            padding: 24px 0;
          }

          button {
            width: 100% !important;

            @media #{$la_md} {
              width: unset;
            }
          }
        }
      }
    }
  }
}

.partner-auth {
  &.is-disabled {
    cursor: no-drop;
    opacity: 0.35;
    .guardian-label {
      cursor: no-drop;
    }
  }
}
