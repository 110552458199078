@import '../../../../../styles/vars';

.scp-reschedule-container {
  padding-top: 24px;
  padding-bottom: 24px;
  @media #{$mobile} {
    padding-top: unset;
    padding-bottom: unset;
    border-top: 1px solid #edeff3;
  }

  .scp-reschedule-page {
    color: $body-text-color;
    display: flex;
    flex-flow: column;
    margin: 0px auto;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    max-width: 1020px;
  }
}
