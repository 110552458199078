@import '../../styles/vars';

.new-calendar-container {
  margin: 32px 0 32px;

  @media #{$mobile} {
    width: unset;
    margin: 0;
  }

  >.calendar-group {
    display: flex;
    margin-bottom: 30px;

    @media #{$mobile} {
      flex-direction: column;
      margin-bottom: 0;
    }

    .new-calendar {
      width: inherit;
      min-height: 267px;
      border: unset;
      background: unset;
      display: flex;

      @media #{$mobile} {
        min-width: unset;
      }

      .react-datepicker__navigation {
        width: 5.71px;
        height: 10px;
        top: 5px;
        border: none;

        &.react-datepicker__navigation--previous {
          left: 100px;
          background: url(./arrow-left.svg) no-repeat;
        }

        &.react-datepicker__navigation--next {
          right: 100px;
          background: url(./arrow-right.svg) no-repeat;
        }
      }

      .react-datepicker__month-container {
        height: 100%;

        @media #{$mobile} {
          width: unset;
          margin: 0 auto;
        }

        >div {
          margin: unset;
          padding: unset;

          &.react-datepicker__header {
            background: unset;
            border: unset;

            .react-datepicker__current-month {
              height: 38px;
              font-size: 16px;
              font-family: $rionasans-medium;
              color: $text-color-V2;
            }

            .react-datepicker__day-names {
              display: flex;

              >div {
                margin: 7px;
                min-height: 32px;
                min-width: 32px;
                display: flex;
                justify-content: center;
                align-items: end;
                font-size: 16px;
                font-family: $rionasans-medium;
                color: $field-border-color-V2;

                @media #{$mobile} {
                  align-items: center;
                }
              }
            }
          }

          &.react-datepicker__month {
            .react-datepicker__week {
              display: flex;

              >div {
                margin: 7px;
                min-height: 32px;
                min-width: 32px;
                display: flex;
                justify-content: center;
                align-items: end;
                font-size: 16px;
                font-family: $rionasans-bold;
                color: $scp-calendar-legend-color;
                border-radius: 50%;

                @media #{$mobile} {
                  align-items: center;
                }

                &:hover:not(.react-datepicker__day--disabled) {
                  background-color: $purple-200;
                }

                &.react-datepicker__day--selected {
                  color: $white-bg-color-v2;
                  background-color: $light-indigo-V2 !important;
                }

                &.react-datepicker__day--disabled {
                  color: $field-border-color-V2;
                }
              }
            }
          }
        }
      }
    }

    .slots-container {

      @media #{$mobile} {
        min-width: unset;
        margin-left: unset;
      }

      &.no-slots {
        text-align: center;
        width: 100%;
        font-family: $rionasans-regular;
        font-size: 16px;
        line-height: 20px;
        color: #262837;
      }

      >div {
        // margin-bottom: 32px;

        .header {
          font-family: $rionasans-bold;
          font-size: 19px;
          font-weight: 700;
          line-height: 23px;
          color: #525968;
          margin-bottom: 32px;

          @media #{$mobile} {
            margin-bottom: 16px;
          }
        }

        .slots {
          display: flex;
          flex-wrap: wrap;
          column-gap: 22px;

          @media #{$mobile} {
            column-gap: 8px;
          }

          >div {

            font-family: $rionasans-medium;
            color: $text-color-V2;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            text-transform: uppercase;

            font-size: 16px;
            font-weight: 490;
            line-height: 19px;
            padding: 10px 0;
            // margin-right: 22px;
            border: 1px solid #EDEFF3;
            border-radius: 24px;
            margin-bottom: 8px;
            min-width: 180px;

            @media #{$mobile} {
              padding: 10px 18px;
              min-width: 109px;
            }

            &:hover {
              box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
            }

            &:active,
            &.selected {
              color: $radio-btn-color-v2;
              background-color: $purple-200;
            }
          }

          @media #{$mobile} {
            div:nth-child(3n + 3) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;

    @media #{$mobile} {
      flex-direction: column-reverse;
    }

    >button {
      min-width: 200px;
      height: 40px;
      margin-bottom: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      border: 1px solid $light-indigo-V2;
      color: $light-indigo-V2;
      font-family: $rionasans-bold;
      font-size: 16px;
      background-color: $white-bg-color-v2;
      cursor: pointer;

      @media #{$mobile} {
        min-width: unset;
        width: 100%;
        margin-bottom: 16px;
      }

      &:disabled {
        opacity: 0.35;
      }

      &.btn-back {
        margin-right: 56px;

        &:disabled {
          cursor: not-allowed;
        }

        &:hover:not(:disabled) {
          background-color: rgba(123, 38, 201, 0.15);
        }

        &:active {
          background-color: rgba(123, 38, 201, 0.3) !important;
        }
      }

      &.btn-next {
        background-color: $light-indigo-V2;
        color: $white-bg-color-v2;

        &:disabled {
          cursor: not-allowed;
        }

        &:hover:not(:disabled) {
          background-color: $scp-button-hover-color;
        }

        &:active {
          background-color: $purple-600 !important;
        }
      }
    }
  }

  .divider {
    padding: 0 32px;
    border: 1px solid $scp-border-color;

    @media #{$mobile} {
      border: 1px solid #EDEFF3;
    }
  }
}
