@import "../../../styles/vars";

.singleSelectContainer {
  position: relative;
  width: fit-content;

  .selectPillContainer {
    height: 36px;
    min-width: 36px;
    border-radius: 36px;
    border: 1px solid #d8dde4;
    background-color: white;
    cursor: pointer;
    padding: 9px 12px;
    font-family: "RionaSans-Medium";
    -webkit-font-smoothing: antialiased;
    font-size: 13px;
    color: #262837;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:hover {
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.15);
    }

    &:active {
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30);
    }

    &.selectPillContainerActive {
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.30);
    }

    .selectPillArrow {
      position: relative;

      img {
        width: 20px;
        height: 20px;
      }
    }

    .selectPillText {
      max-width: 200px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: pre;
      user-select: none;
    }
  }

  .singleSelectModalContainer {
    position: absolute;
    top: 40px;
    right: 0px;
    width: 328px;
    max-height: 520px;
    border-radius: 4px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    background-color: white;
    z-index: 10;

    @media #{$mobile} {
      right: unset;
    }

    .singleSelectModalRow {
      width: 100%;
      min-height: 48px;
    }

    .singleSelectModalTopRow {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #edeff3;
      padding-left: 16px;
      height: 40px;


      .singleSelectModalTopRowTitle {
        font-family: "RionaSans-Medium";
        -webkit-font-smoothing: antialiased;
        font-size: 14px;
        color: #262837;
        padding-top: 2px;
      }

      .singleSelectModalXIcon {
        width: 40px;
        height: 40px;
        border-top-right-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: #edeff3;
        }

        &:active {
          background-color: #d8dde4;
        }

        img {
          padding: 10px;
        }
      }

    }

    .singleSelectModalBodyContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      border-bottom: 0px solid #edeff3;
      max-height: 432px;
      overflow-y: auto;
      padding: 8px 6px;


      .singleSelectRadioRow {
        padding-left: 11px;
        margin: 0px !important;
      }

      .singleSelectRadioLabel {
        font-size: 14px !important;
        color: #262837;
        font-weight: 500;
        line-height: 1.30;
        font-family: "RionaSans-Medium";
      }
    }

    .singleSelectBottomRowContainer {
      min-height: 48px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      border-top: 2px solid #edeff3;
      align-items: center;
      padding: 0px 8px;


      .singleSelectReset {
        font-family: "RionaSans-Medium";
        color: $purple-500;
        font-size: 13px;
        cursor: pointer;
        width: 50px;
      }

      .singleSelectReset:hover {
        text-decoration: underline;
      }

      .singleSelectSave {
        color: white;
        background-color: $purple-500;
        border-radius: 4px;
        width: 160px;
        height: 32px;
        font-family: "RionaSans-Medium";
        font-size: 13px;
        text-align: center;
        padding-top: 7px;
        cursor: pointer;
        user-select: none;

        &:hover {
          background-color: $purple-400;
        }

        &:active {
          background-color: $purple-600;
        }
      }

      .singleSelectSaveDisabled,
      .singleSelectSaveDisabled:hover,
      .singleSelectSaveDisabled:active {
        background-color: $purple-500;
        opacity: 0.35;
        cursor: no-drop;
      }

    }

  }

}