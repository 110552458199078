
@import '../../../../styles/vars';
@import '../../../../styles/mixins';

.schedule-intro-page {
  background-color: #ffffff;
  max-width: 1440px;
  width:100%;
  margin: 0 auto;
  color:#262837;

  .intro-container {
    display: flex;
    box-sizing: border-box;
    min-width: calc(100% - 20px);
    font-family: $rionasans-regular;

    .Text-container {
      flex-grow: 2;
      flex-basis: 60%;
      padding: 80px 30px 22px 72px;

      @media #{$mobile} {
        padding: 56px 16px 45px 16px;
      }

      .heading {
        font-size: 40px;
        font-family:$rionasans-bold;
        line-height: 48px;
        letter-spacing: 0em;
        text-align: left;
        padding-bottom: 24px;
        color:#262837;

        @media #{$mobile} {
          font-size: 28px;
          line-height: 34px;
        }
      }

      .description {
        font-size: 23px;
        font-weight: 400;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        padding-bottom: 40px;

        @media #{$mobile} {
          font-size: 16px;
          line-height: 20px;
          padding-bottom: 32px;
        }
      }

      .subheading {
        font-size: 28px;
        font-family:$rionasans-bold;
        line-height: 34px;
        letter-spacing: 0em;
        text-align: left;
        padding-bottom: 32px;
        color: #525968;

        @media #{$mobile} {
          font-size: 23px;
          line-height: 28px;
        }
      }

      .ul-content {
        margin-bottom: 8px;

        @media #{$mobile} {
          margin-bottom: 18px;
        }

        .li-section {
          display: flex;
          flex-direction: row;
          padding-bottom: 32px;

          @media #{$mobile} {}

          .steps {
            font-size: 40px;
            font-family:$rionasans-bold;
            line-height: 50px;
            letter-spacing: 0em;
            text-align: left;
            margin-right: 24px;
            color: #4F7723;

            @media #{$mobile} {
              font-size: 36px;
              line-height: 45px;
            }
          }

          .label-step {
            font-size: 19px;
            font-family: $rionasans-medium;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;

            @media #{$mobile} {
              font-size: 16px;
              line-height: 20px;
              color: #262837;
            }
          }

          .sub-label {
            font-size: 16px;
            font-family: $rionasans-italic;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: left;

            @media #{$mobile} {}
          }
        }
      }

      .divider {
        margin-top: 40px;
        margin-bottom: 40px;
        border:1px solid #D8DDE4;

        @media #{$mobile} {
          margin-top: 32px;
          margin-bottom: 32px;
        }
      }

      .intro-footer {
        .title-question {
          font-size: 19px;
          font-family:$rionasans-bold;
          line-height: 23px;
          margin-bottom: 8px;
          color:#525968;

          @media #{$mobile} {}
        }

        .description-question {
          font-size: 14px;
          font-weight: 400;
          line-height: 18px;

          @media #{$mobile} {}
        }
      }
      .btn {
        padding: 0px 24px 0px 24px;
        gap: 8px;
        border-radius: 6px;

        @media #{$mobile} {
          width: 100%;
        }
      }
    }

    .image-container {
      flex-basis: 40%;
      padding: 80px 57px 0 57px;
      background-color: #D0C9EC;

      @media #{$mobile} {
        display: none;
      }

      img {
        width: 100%;
        height: auto;
        border-radius: 6%;
      }
    }
  }
}

.scp-footer-conteiner{
  margin-top:0 !important;
}
