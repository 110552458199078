@import "../../../../../styles/vars";
@import "../../../../../styles/mixins";

.profile-selection-container {
    -webkit-font-smoothing: antialiased;
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 538px;
    @media #{$la_md} {
        width: 75%;
    }

    .profile-selection {
        width: 473px;
        h3.title {
            font: bold 22px/1.25 $rionasans-bold;
            color: $text-color-V2;
            padding: 24px 15px 24px 15px;
            border-top: 1px solid $page-background-color-V2;
            @media #{$la_md} {
                border-top: none;
            }
        }

        .profile-selection-links {
            div.header {
                min-height: 51px;
                border-top: 1px solid $page-background-color-V2;
                font: normal 500 16px $rionasans-medium;
                color: $text-color-V2;
                display: flex;
                cursor: pointer;
                
                div.header-content {
                    padding: 0px 15px 0px 15px;
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    span.text-icon {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        span.icon {
                            height: 28px;
                            width: 28px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-right: 31px;
                        }
                    }

                    span.left-icon {
                        height: 28px;
                        width: 28px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
            div.header:first-child {
                display: none;
            }
            div.header:last-child {
                border-bottom: 1px solid $page-background-color-V2;
            }
        }
    }
}
 
@media #{$la_md} {
    .profile-selection-container {
        display: none;
    }
}