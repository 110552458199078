@import "../../../../styles/vars";
.fancy-page-frame-V2 {
  background: $page-background-color-V2 url("../../../../images/gray-ribbon-v2.svg") center/100% no-repeat fixed;
  display: flex;
  flex-direction: row;
  justify-content: center;
  .content-block {
    margin-top: 181px;
    margin-bottom: 181px;
    max-width: 800px;
    width: 100%;
    min-height: 389px;
    background-color: white;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
  }
}

.fancy-page-frame-V2.thank-you {
  .content-block {
    margin-top: 100px !important;
  }
}
