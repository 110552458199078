@import '../../../../../styles/vars';
@import '../../../../../styles/mixins';

.scp-test-order-confirm-container {
  background: $page-background-color-V2 url('../../../../../images/gray-ribbon-v2.svg') center/100% no-repeat fixed;
  padding-top: 16px;

  .test-order-header {
    font: normal bold 22px/1.45 $rionasans-bold;
    color: $text-color-V2;
    padding: 0 16px 16px 16px;
    display: block;
    @media #{$la_md} {
      display: none;
    }  
  }

  .scp-test-order-confirm-page {
    display: flex;
    flex-flow: column;
    margin: 0px auto;
    max-width: 375px;
    border-radius: 4px;
    box-shadow: none;
    background-color: #ffffff;
    -webkit-font-smoothing: antialiased;
    color: $text-color-V2;
    @media #{$la_md} {      
      max-width: 1020px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }

    .scp-test-order-confirm-wrapper {      
      width: initial;
      margin: initial;
      padding: 21px;      

      @media #{$la_md} {
        max-width: 794px;
        width: 794px;
        margin: 0px auto;
        padding: 40px 0px;
      }

      .go-home {
        font: normal 14px/1.36 $rionasans-regular;
        color: $focus-border-color-v2;
        cursor: pointer;
        display: none;
        
        @media #{$la_md} {
          display: block;
        }
        
      }

      .test-order-status-header {
        font: normal bold 22px/1.45 $rionasans-bold;
        color: $text-color-V2;
        margin-bottom: 48px;
        padding: 0 16px;        
        
        @media #{$la_md} {
          padding: 0;
        }

        .warning-icon{
          height: 18px;
          width: 18px;
          vertical-align: unset;
          margin-right: 8px;
        }
      }

    }
  }
}
