@import '../../../styles/vars';

.referrals-page {
  overflow: hidden;
  .thank-you-page {
    .content {
      max-width: 650px;
      margin: 65px auto;
      
      padding: 0 20px;
      color: $dark-gray-text-color;
      font-size: 1.28em;
      p {
        margin-bottom: 30px;
      }
      .phone {
        color: $link-color;
      }
      ul.ref-actions {
        list-style: none;
        padding-left: 0;
        li {
          margin-left: 15px;
          &::before {
            content: "- ";
            margin-left: -15px;
          }
        }
      }
    }
    .buttons-container {
      width: 100%;
      margin: 60px auto;
      text-align: center;
      .refer-new {
        display: inline-block;
        margin-left: 20px;
      }
      .back-button {
        font-size: 15px;
        color: $dark-gray-text-color;
        text-decoration: none;
      }
    }
  }
}
