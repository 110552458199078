.el-tooltip.set-pwd-tooltip > div.el-tooltip__rel {
        margin-left: 8px;
      }
.el-tooltip.set-pwd-tooltip > div.el-tooltip__popper.is-dark{
  width: 200px;
  height: 173px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #262837;
  border-radius: 8px;
  pointer-events: none;
}


.password-requirement-title{
  font-family: RionaSans-Bold;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  -webkit-font-smoothing: antialiased;
}

.password-requirement-list{
  padding: 3px;
  margin-left: 12px;
  font-family: RionaSans;

  li {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
  }
}
