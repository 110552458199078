@import "../../../../../styles/vars";
@import "../../../../../styles/mixins";

.card-wrapper {
    -webkit-font-smoothing: antialiased;
    min-height: calc(492px - 25px);

    p.title {
        font: bold 22px $rionasans-bold;
        color: $text-color-V2;
    }

    .card-add {
        margin-bottom: 32px;
        margin-top: 24px
    }

    .card-items {
        min-height: 72px;
        margin-top: 32px;
        border: 1px solid $page-background-color-V2;
        background-color: rgba(237, 239, 243, 0.5);
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .card-item {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin: 0px 16px 0px 16px;

            .card-info {
                display: flex;
                align-items: center;
                .user-info {
                    display: flex;
                    flex-direction: column;
                    margin-left: 16px;
                    .name {
                        font: normal 500 16px $rionasans-medium;
                        color: $text-color-V2;
                        word-break: break-all;
                    }
                    .card-number {
                        font: normal 500 16px $rionasans-medium;
                        color: $info-color;
                    }
                }
                .brand-icon {
                    width: 40px;
                    height: 24px;
                    &[data-brand='visa'] {
                        background-image: url('./icon-Visa-2.png');
                        background-repeat: no-repeat;
                        background-size: 40px 24px;
                    }
                    &[data-brand='mastercard'] {
                        background-image: url('./icon-MasterCard-2.png');
                        background-repeat: no-repeat;
                        background-size: 40px 24px;
                    }
                    &[data-brand='amex'] {
                        background-image: url('./icon-AmericanExpress-2.png');
                        background-repeat: no-repeat;
                        background-size: 40px 24px;
                    }
                }
            }
        }
        .card-item.padded-card {
            padding: 16px;
        }
    }
}

.__PrivateStripeElement iframe {
  font-family: $rionasans-regular
}
