.checkbox-group-container {
  margin: 5px 0 0 0;
  .checkbox-group-item {
    border: 1px solid #DCDCDC;
    border-radius: 3px;
    min-height: 40px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    margin: 10px 0 0 0;
    &.without-border {
      border: none;
      padding-left: 0px;
      margin: 0;
    }
  }
}
