.radio-button-description {
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
    strong {
      font-weight: bold;
    }
  .info-outline-icon {
    display: inline-block;
    background: url('../../forms/controls/information.png');
    background-size: 100%;
    width: 16px;
    height: 16px;
    vertical-align: top;
    margin-top: 2px
  }
  .description-hint {
    display: none;
    background: #ffffff;
    padding: 20px;
    font-family: 'RionaSans';
    line-height: 24px;
    box-shadow: 0 0 3px 0.5px rgba(0, 0, 0, 0.3);
    .description-hint-header {
      color: #cccc;
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      margin-bottom: 12px;
    }
  }
  i {
    font-size: 16px;
    color: '#333';
  }
  &:hover {
    .description-hint {
      display: block;
      position: absolute;
      z-index: 99;
    }
  }
}
